import { useReducer } from "react";
import { container } from "../../services/storage/context.container";
import { APIService } from "../../services/api/request";
import { message } from "antd";
// import { CookieStorage,  } from "../../services/storage/cookie.storage";

const ApiRoutes = {
  base: `/inspections`,
};

export type StateType = {
  documents: any;
  pagination: any;
  inspection: any;
};

const initialState: StateType = {
  documents: [],
  pagination: {},
  inspection: {},
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_ALL":
      return { ...state, ...payload };
    case "GET_ONE":
      return { ...state, vehicle: { ...payload } };
    default:
      return state;
  }
};

export const { useContext: useInspections, Provider: InspectionsProvider } =
  container(() => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });

    const getAll = (callback?: (data: any) => void) => {
      APIService.get(
        ApiRoutes.base,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          const response: any = res.data;
          dispatch({ type: "GET_ALL", payload: response });
          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const updateOne = (
      id: string,
      data: any,
      callback?: (data: any) => void
    ) => {
      APIService.put(
        `${ApiRoutes.base}/${id}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          message.success("Updated Successfully!!!");
          // dispatch({ type: "GET_VEHICLES", payload: response });
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const createOne = (data: any, callback?: (data: any) => void) => {
      APIService.post(
        `${ApiRoutes.base}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          message.success("Created Successfully!!!");
          getAll();
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const getOne = (id: string, callback?: (data: any) => void) => {
      APIService.get(
        `${ApiRoutes.base}/${id}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          const response: any = res.data;
          dispatch({ type: "GET_ONE", payload: response });
          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    return {
      state,
      actions: {
        getAll,
        getOne,
        updateOne,
        createOne
      },
    };
  });
