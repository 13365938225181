import { Modal } from "antd";
import { Switch } from "antd";
import { CloseIcon } from "../../atoms/ListingSelectedBar";
import FlipUpload from "../../icons/designIcons/FlipUpload.svg";
interface SelectedBarProps {
  selectedItems?: any;
  onClose: () => void;
  onSuccessClick: () => void;
}
// export interface SelectedBarProps
//   extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const VinSelectedFloatBar: React.FC<SelectedBarProps> = ({
  selectedItems,
  onClose,
  onSuccessClick,
}: SelectedBarProps) => {
  return (
    <div className="selected_rows flex justify-between items-center z-99 ">
      <div
        className="cursor-pointer vin_float_bar_after_effect relative "
        onClick={onClose}
      >
        <CloseIcon />
      </div>

      <p className=" text-white font-semibold infleet_after relative pr-[29px] mt-[5px]">
        Infleet the Selected Vehicle
      </p>
      <div className="cursor-pointer" onClick={onSuccessClick}>
        {" "}
        <img src={FlipUpload} />
      </div>
    </div>
  );
};
