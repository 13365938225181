export const InActiveGridView: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1032_3509)">
        <path
          d="M4.66667 0H2.66667C1.95942 0 1.28115 0.280952 0.781049 0.781049C0.280952 1.28115 0 1.95942 0 2.66667L0 4.66667C0 5.37391 0.280952 6.05219 0.781049 6.55229C1.28115 7.05238 1.95942 7.33333 2.66667 7.33333H4.66667C5.37391 7.33333 6.05219 7.05238 6.55229 6.55229C7.05238 6.05219 7.33333 5.37391 7.33333 4.66667V2.66667C7.33333 1.95942 7.05238 1.28115 6.55229 0.781049C6.05219 0.280952 5.37391 0 4.66667 0V0ZM6 4.66667C6 5.02029 5.85952 5.35943 5.60948 5.60948C5.35943 5.85952 5.02029 6 4.66667 6H2.66667C2.31304 6 1.97391 5.85952 1.72386 5.60948C1.47381 5.35943 1.33333 5.02029 1.33333 4.66667V2.66667C1.33333 2.31304 1.47381 1.97391 1.72386 1.72386C1.97391 1.47381 2.31304 1.33333 2.66667 1.33333H4.66667C5.02029 1.33333 5.35943 1.47381 5.60948 1.72386C5.85952 1.97391 6 2.31304 6 2.66667V4.66667Z"
          fill="#212121"
        />
        <path
          d="M13.3332 0H11.3332C10.6259 0 9.94765 0.280952 9.44755 0.781049C8.94745 1.28115 8.6665 1.95942 8.6665 2.66667V4.66667C8.6665 5.37391 8.94745 6.05219 9.44755 6.55229C9.94765 7.05239 10.6259 7.33334 11.3332 7.33334H13.3332C14.0404 7.33334 14.7187 7.05239 15.2188 6.55229C15.7189 6.05219 15.9998 5.37391 15.9998 4.66667V2.66667C15.9998 1.95942 15.7189 1.28115 15.2188 0.781049C14.7187 0.280952 14.0404 0 13.3332 0V0ZM14.6665 4.66667C14.6665 5.02029 14.526 5.35943 14.276 5.60948C14.0259 5.85953 13.6868 6 13.3332 6H11.3332C10.9795 6 10.6404 5.85953 10.3904 5.60948C10.1403 5.35943 9.99984 5.02029 9.99984 4.66667V2.66667C9.99984 2.31305 10.1403 1.97391 10.3904 1.72386C10.6404 1.47381 10.9795 1.33333 11.3332 1.33333H13.3332C13.6868 1.33333 14.0259 1.47381 14.276 1.72386C14.526 1.97391 14.6665 2.31305 14.6665 2.66667V4.66667Z"
          fill="#212121"
        />
        <path
          d="M4.66667 8.66663H2.66667C1.95942 8.66663 1.28115 8.94758 0.781049 9.44768C0.280952 9.94777 0 10.6261 0 11.3333L0 13.3333C0 14.0405 0.280952 14.7188 0.781049 15.2189C1.28115 15.719 1.95942 16 2.66667 16H4.66667C5.37391 16 6.05219 15.719 6.55229 15.2189C7.05238 14.7188 7.33333 14.0405 7.33333 13.3333V11.3333C7.33333 10.6261 7.05238 9.94777 6.55229 9.44768C6.05219 8.94758 5.37391 8.66663 4.66667 8.66663ZM6 13.3333C6 13.6869 5.85952 14.0261 5.60948 14.2761C5.35943 14.5262 5.02029 14.6666 4.66667 14.6666H2.66667C2.31304 14.6666 1.97391 14.5262 1.72386 14.2761C1.47381 14.0261 1.33333 13.6869 1.33333 13.3333V11.3333C1.33333 10.9797 1.47381 10.6405 1.72386 10.3905C1.97391 10.1404 2.31304 9.99996 2.66667 9.99996H4.66667C5.02029 9.99996 5.35943 10.1404 5.60948 10.3905C5.85952 10.6405 6 10.9797 6 11.3333V13.3333Z"
          fill="#212121"
        />
        <path
          d="M13.3332 8.66663H11.3332C10.6259 8.66663 9.94765 8.94758 9.44755 9.44768C8.94745 9.94777 8.6665 10.6261 8.6665 11.3333V13.3333C8.6665 14.0405 8.94745 14.7188 9.44755 15.2189C9.94765 15.719 10.6259 16 11.3332 16H13.3332C14.0404 16 14.7187 15.719 15.2188 15.2189C15.7189 14.7188 15.9998 14.0405 15.9998 13.3333V11.3333C15.9998 10.6261 15.7189 9.94777 15.2188 9.44768C14.7187 8.94758 14.0404 8.66663 13.3332 8.66663ZM14.6665 13.3333C14.6665 13.6869 14.526 14.0261 14.276 14.2761C14.0259 14.5262 13.6868 14.6666 13.3332 14.6666H11.3332C10.9795 14.6666 10.6404 14.5262 10.3904 14.2761C10.1403 14.0261 9.99984 13.6869 9.99984 13.3333V11.3333C9.99984 10.9797 10.1403 10.6405 10.3904 10.3905C10.6404 10.1404 10.9795 9.99996 11.3332 9.99996H13.3332C13.6868 9.99996 14.0259 10.1404 14.276 10.3905C14.526 10.6405 14.6665 10.9797 14.6665 11.3333V13.3333Z"
          fill="#212121"
        />
      </g>
      <defs>
        <clipPath id="clip0_1032_3509">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const ActiveGridView: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1032_5225)">
        <path
          d="M4.66667 0H2.66667C1.95942 0 1.28115 0.280952 0.781049 0.781049C0.280952 1.28115 0 1.95942 0 2.66667L0 4.66667C0 5.37391 0.280952 6.05219 0.781049 6.55229C1.28115 7.05238 1.95942 7.33333 2.66667 7.33333H4.66667C5.37391 7.33333 6.05219 7.05238 6.55229 6.55229C7.05238 6.05219 7.33333 5.37391 7.33333 4.66667V2.66667C7.33333 1.95942 7.05238 1.28115 6.55229 0.781049C6.05219 0.280952 5.37391 0 4.66667 0V0ZM6 4.66667C6 5.02029 5.85952 5.35943 5.60948 5.60948C5.35943 5.85952 5.02029 6 4.66667 6H2.66667C2.31304 6 1.97391 5.85952 1.72386 5.60948C1.47381 5.35943 1.33333 5.02029 1.33333 4.66667V2.66667C1.33333 2.31304 1.47381 1.97391 1.72386 1.72386C1.97391 1.47381 2.31304 1.33333 2.66667 1.33333H4.66667C5.02029 1.33333 5.35943 1.47381 5.60948 1.72386C5.85952 1.97391 6 2.31304 6 2.66667V4.66667Z"
          fill="#FB6DB2"
        />
        <path
          d="M13.3332 0H11.3332C10.6259 0 9.94765 0.280952 9.44755 0.781049C8.94745 1.28115 8.6665 1.95942 8.6665 2.66667V4.66667C8.6665 5.37391 8.94745 6.05219 9.44755 6.55229C9.94765 7.05239 10.6259 7.33334 11.3332 7.33334H13.3332C14.0404 7.33334 14.7187 7.05239 15.2188 6.55229C15.7189 6.05219 15.9998 5.37391 15.9998 4.66667V2.66667C15.9998 1.95942 15.7189 1.28115 15.2188 0.781049C14.7187 0.280952 14.0404 0 13.3332 0V0ZM14.6665 4.66667C14.6665 5.02029 14.526 5.35943 14.276 5.60948C14.0259 5.85953 13.6868 6 13.3332 6H11.3332C10.9795 6 10.6404 5.85953 10.3904 5.60948C10.1403 5.35943 9.99984 5.02029 9.99984 4.66667V2.66667C9.99984 2.31305 10.1403 1.97391 10.3904 1.72386C10.6404 1.47381 10.9795 1.33333 11.3332 1.33333H13.3332C13.6868 1.33333 14.0259 1.47381 14.276 1.72386C14.526 1.97391 14.6665 2.31305 14.6665 2.66667V4.66667Z"
          fill="#FB6DB2"
        />
        <path
          d="M4.66667 8.66663H2.66667C1.95942 8.66663 1.28115 8.94758 0.781049 9.44768C0.280952 9.94777 0 10.6261 0 11.3333L0 13.3333C0 14.0405 0.280952 14.7188 0.781049 15.2189C1.28115 15.719 1.95942 16 2.66667 16H4.66667C5.37391 16 6.05219 15.719 6.55229 15.2189C7.05238 14.7188 7.33333 14.0405 7.33333 13.3333V11.3333C7.33333 10.6261 7.05238 9.94777 6.55229 9.44768C6.05219 8.94758 5.37391 8.66663 4.66667 8.66663V8.66663ZM6 13.3333C6 13.6869 5.85952 14.0261 5.60948 14.2761C5.35943 14.5262 5.02029 14.6666 4.66667 14.6666H2.66667C2.31304 14.6666 1.97391 14.5262 1.72386 14.2761C1.47381 14.0261 1.33333 13.6869 1.33333 13.3333V11.3333C1.33333 10.9797 1.47381 10.6405 1.72386 10.3905C1.97391 10.1404 2.31304 9.99996 2.66667 9.99996H4.66667C5.02029 9.99996 5.35943 10.1404 5.60948 10.3905C5.85952 10.6405 6 10.9797 6 11.3333V13.3333Z"
          fill="#FB6DB2"
        />
        <path
          d="M13.3332 8.66663H11.3332C10.6259 8.66663 9.94765 8.94758 9.44755 9.44768C8.94745 9.94777 8.6665 10.6261 8.6665 11.3333V13.3333C8.6665 14.0405 8.94745 14.7188 9.44755 15.2189C9.94765 15.719 10.6259 16 11.3332 16H13.3332C14.0404 16 14.7187 15.719 15.2188 15.2189C15.7189 14.7188 15.9998 14.0405 15.9998 13.3333V11.3333C15.9998 10.6261 15.7189 9.94777 15.2188 9.44768C14.7187 8.94758 14.0404 8.66663 13.3332 8.66663V8.66663ZM14.6665 13.3333C14.6665 13.6869 14.526 14.0261 14.276 14.2761C14.0259 14.5262 13.6868 14.6666 13.3332 14.6666H11.3332C10.9795 14.6666 10.6404 14.5262 10.3904 14.2761C10.1403 14.0261 9.99984 13.6869 9.99984 13.3333V11.3333C9.99984 10.9797 10.1403 10.6405 10.3904 10.3905C10.6404 10.1404 10.9795 9.99996 11.3332 9.99996H13.3332C13.6868 9.99996 14.0259 10.1404 14.276 10.3905C14.526 10.6405 14.6665 10.9797 14.6665 11.3333V13.3333Z"
          fill="#FB6DB2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1032_5225">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const InActiveListView: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1032_5230)">
        <path
          d="M11.3335 0H4.66683C3.7831 0.00105857 2.93587 0.352588 2.31098 0.97748C1.68608 1.60237 1.33455 2.4496 1.3335 3.33333V12.6667C1.33455 13.5504 1.68608 14.3976 2.31098 15.0225C2.93587 15.6474 3.7831 15.9989 4.66683 16H11.3335C12.2172 15.9989 13.0645 15.6474 13.6894 15.0225C14.3142 14.3976 14.6658 13.5504 14.6668 12.6667V3.33333C14.6658 2.4496 14.3142 1.60237 13.6894 0.97748C13.0645 0.352588 12.2172 0.00105857 11.3335 0V0ZM4.66683 1.33333H11.3335C11.8639 1.33333 12.3726 1.54405 12.7477 1.91912C13.1228 2.29419 13.3335 2.8029 13.3335 3.33333V7.33333H2.66683V3.33333C2.66683 2.8029 2.87754 2.29419 3.25262 1.91912C3.62769 1.54405 4.1364 1.33333 4.66683 1.33333ZM11.3335 14.6667H4.66683C4.1364 14.6667 3.62769 14.4559 3.25262 14.0809C2.87754 13.7058 2.66683 13.1971 2.66683 12.6667V8.66666H13.3335V12.6667C13.3335 13.1971 13.1228 13.7058 12.7477 14.0809C12.3726 14.4559 11.8639 14.6667 11.3335 14.6667Z"
          fill="#212121"
        />
        <path
          d="M7.33317 4.66671H8.6665C8.84331 4.66671 9.01288 4.59647 9.1379 4.47144C9.26293 4.34642 9.33317 4.17685 9.33317 4.00004C9.33317 3.82323 9.26293 3.65366 9.1379 3.52864C9.01288 3.40361 8.84331 3.33337 8.6665 3.33337H7.33317C7.15636 3.33337 6.98679 3.40361 6.86177 3.52864C6.73674 3.65366 6.6665 3.82323 6.6665 4.00004C6.6665 4.17685 6.73674 4.34642 6.86177 4.47144C6.98679 4.59647 7.15636 4.66671 7.33317 4.66671Z"
          fill="#212121"
        />
        <path
          d="M8.6665 11.3334H7.33317C7.15636 11.3334 6.98679 11.4036 6.86177 11.5286C6.73674 11.6537 6.6665 11.8232 6.6665 12C6.6665 12.1769 6.73674 12.3464 6.86177 12.4715C6.98679 12.5965 7.15636 12.6667 7.33317 12.6667H8.6665C8.84331 12.6667 9.01288 12.5965 9.1379 12.4715C9.26293 12.3464 9.33317 12.1769 9.33317 12C9.33317 11.8232 9.26293 11.6537 9.1379 11.5286C9.01288 11.4036 8.84331 11.3334 8.6665 11.3334Z"
          fill="#212121"
        />
      </g>
      <defs>
        <clipPath id="clip0_1032_5230">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const ActiveListView: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1032_3516)">
        <path
          d="M11.3335 0H4.66683C3.7831 0.00105857 2.93587 0.352588 2.31098 0.97748C1.68608 1.60237 1.33455 2.4496 1.3335 3.33333V12.6667C1.33455 13.5504 1.68608 14.3976 2.31098 15.0225C2.93587 15.6474 3.7831 15.9989 4.66683 16H11.3335C12.2172 15.9989 13.0645 15.6474 13.6894 15.0225C14.3142 14.3976 14.6658 13.5504 14.6668 12.6667V3.33333C14.6658 2.4496 14.3142 1.60237 13.6894 0.97748C13.0645 0.352588 12.2172 0.00105857 11.3335 0V0ZM4.66683 1.33333H11.3335C11.8639 1.33333 12.3726 1.54405 12.7477 1.91912C13.1228 2.29419 13.3335 2.8029 13.3335 3.33333V7.33333H2.66683V3.33333C2.66683 2.8029 2.87754 2.29419 3.25262 1.91912C3.62769 1.54405 4.1364 1.33333 4.66683 1.33333ZM11.3335 14.6667H4.66683C4.1364 14.6667 3.62769 14.4559 3.25262 14.0809C2.87754 13.7058 2.66683 13.1971 2.66683 12.6667V8.66666H13.3335V12.6667C13.3335 13.1971 13.1228 13.7058 12.7477 14.0809C12.3726 14.4559 11.8639 14.6667 11.3335 14.6667Z"
          fill="#FB6DB2"
        />
        <path
          d="M7.33317 4.66671H8.6665C8.84331 4.66671 9.01288 4.59647 9.1379 4.47144C9.26293 4.34642 9.33317 4.17685 9.33317 4.00004C9.33317 3.82323 9.26293 3.65366 9.1379 3.52864C9.01288 3.40361 8.84331 3.33337 8.6665 3.33337H7.33317C7.15636 3.33337 6.98679 3.40361 6.86177 3.52864C6.73674 3.65366 6.6665 3.82323 6.6665 4.00004C6.6665 4.17685 6.73674 4.34642 6.86177 4.47144C6.98679 4.59647 7.15636 4.66671 7.33317 4.66671Z"
          fill="#FB6DB2"
        />
        <path
          d="M8.6665 11.3334H7.33317C7.15636 11.3334 6.98679 11.4036 6.86177 11.5286C6.73674 11.6537 6.6665 11.8232 6.6665 12C6.6665 12.1769 6.73674 12.3464 6.86177 12.4715C6.98679 12.5965 7.15636 12.6667 7.33317 12.6667H8.6665C8.84331 12.6667 9.01288 12.5965 9.1379 12.4715C9.26293 12.3464 9.33317 12.1769 9.33317 12C9.33317 11.8232 9.26293 11.6537 9.1379 11.5286C9.01288 11.4036 8.84331 11.3334 8.6665 11.3334Z"
          fill="#FB6DB2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1032_3516">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
