import { message } from "antd";
import { useReducer } from "react";
import { APIService } from "../../../services/api/request";
import { container } from "../../../services/storage/context.container";
import { buildQueryString } from "../../../services/utils/url";
import { destroyMessage, showMessage } from "../../../utils";

const ApiRoutes = {
  base: "/contracts",
};
export type StateType = {
  minimalDocuments: any;
  documents: any;
  pagination: any;
  contract: any;
  vinsList: any;
  users: [];
  groups: [];
  availableVehicle: {};
  contacts: [];
  vehicleTypes: any;
  vehicles: [];
};
const initialState: StateType = {
  documents: [],
  pagination: {},
  contract: {},
  minimalDocuments: [],
  vinsList: {},
  users: [],
  groups: [],
  contacts: [],
  vehicleTypes: [],
  availableVehicle: {},
  vehicles: [],
};
const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_MININAL_CONTRACTS":
      return {
        ...state,
        minimalDocuments: payload.map((x: any) => ({
          value: x.id,
          label: x.name,
        })),
      };
    case "GET_CONTRACTS":
      return {
        ...state,
        ...payload,
      };

    case "GET_ONE_CONTRACT":
      return { ...state, contract: { ...payload } };
    case "GET_AVAILABILITY":
      return { ...state, availableVehicle: payload };
    case "GET_AVAILABILITY_VEHICLE":
      return { ...state, vehicle: payload };
    case "CLEAR_CONTRACTS":
      return { ...state, contract: {} };
    case "GET_VEHICLE_TYPE":
      return {
        ...state,
        vehicleTypes: payload.map((x: any) => ({
          value: x.id,
          label: x.name,
        })),
      };
    case "GET_USERS":
      return {
        ...state,
        users: payload.map((item: any) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        })),
      };
    case "GET_GROUPS":
      return {
        ...state,
        groups: payload.map((item: any) => ({
          label: `${item.name}`,
          value: item.id,
        })),
      };
    case "GET_CONTACTS":
      return {
        ...state,
        contacts: payload.map((item: any) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        })),
      };
    default:
      return state;
  }
};
export const { useContext: useContract, Provider: ContractsProvider } =
  container(() => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });
    const getAllContracts = (
      mininal?: boolean,
      params?: any,
      callback?: (data: any) => void
    ) => {
      showMessage();
      params = buildQueryString(params);
      APIService.get(
        mininal
          ? `${ApiRoutes.base}?all=true`
          : `${ApiRoutes.base}${params ? `${params}` : ""}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          const response: any = res.data;
          if (mininal) {
            dispatch({ type: "GET_MININAL_CONTRACTS", payload: response });
          } else {
            dispatch({ type: "GET_CONTRACTS", payload: response });
          }
          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const getVehicleTypes = (callback?: (data: any) => void) => {
      showMessage();
      APIService.get(
        `/vehicles/info/vehicle-types`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          const response: any = res.data;
          dispatch({ type: "GET_VEHICLE_TYPE", payload: response });
          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const getAllUsers = (callback?: (response: any) => void) => {
      APIService.get(
        `/users?all=true`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          dispatch({ type: "GET_USERS", payload: response.data });
          callback && callback(response.data);
        })
        .catch((err: any) => {});
    };

    const getAllContacts = (id: any, callback?: (response: any) => void) => {
      APIService.get(
        `/contacts?filter[group]=${id}&all=true`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          dispatch({ type: "GET_CONTACTS", payload: response.data });
          callback && callback(response.data);
        })
        .catch((err: any) => {});
    };

    const getAllGroups = (callback?: (response: any) => void) => {
      APIService.get(
        `/groups?all=true`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          dispatch({ type: "GET_GROUPS", payload: response.data });
          callback && callback(response.data);
        })
        .catch((err: any) => {});
    };
    const AddContact = (data: any, callback?: (data: any) => void) => {
      APIService.post(`/contacts`, data, process.env.REACT_APP_API_ENDPOINT)
        .then((res: any) => {
          message.success("Contact Added Successfully!!!");

          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          } else {
            message.error("Contact Creation Failed");
          }
        });
    };

    const assignVehicles = (
      url: string,
      data: any,
      callback?: (data: any) => void
    ) => {
      APIService.post(
        `${ApiRoutes.base}/${url}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          message.success("Vehicle Assigned Successfully!!!");
          // getAllContacts();
          if (typeof callback === "function") {
            callback(res);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          } else {
            message.error("Contact Creation Failed");
          }
        });
    };

    const createOne = (data: any, callback?: (data: any) => void) => {
      APIService.post(
        `${ApiRoutes.base}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          message.success("Created Successfully!!!");
          // getAllContacts();
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          } else {
            message.error("Contact Creation Failed");
          }
        });
    };
    const updateOne = (
      id: string,
      data: any,
      callback?: (data: any) => void
    ) => {
      showMessage();
      APIService.put(
        `${ApiRoutes.base}/${id}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          message.success("Updated Successfully!!!");
          // dispatch({ type: "GET_VEHICLES", payload: res });

          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };
    const AddGroup = (data: any, callback?: (data: any) => void) => {
      APIService.post(`/groups`, data, process.env.REACT_APP_API_ENDPOINT)
        .then((res: any) => {
          message.success("Group Added Successfully!!!");
          // history.goBack();
          getAllGroups();
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          } else {
            message.error("Group Creation Failed");
          }
        });
    };

    const getOneContract = (id: string, callback?: (data: any) => void) => {
      showMessage();
      APIService.get(
        `${ApiRoutes.base}/${id}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          const response: any = res.data;
          dispatch({ type: "GET_ONE_CONTRACT", payload: response });
          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };
    const getAllAssignedVehicles = (
      url: string,
      callback?: (data: any) => void
    ) => {
      showMessage();
      APIService.get(
        `${ApiRoutes.base}/${url}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          if (typeof callback === "function") {
            callback(res.data);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const deleteOne = (id: string, callback?: (response: any) => void) => {
      APIService.delete(
        `${ApiRoutes.base}/${id}`,
        {},
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          callback && callback(response);
          getAllContracts();
        })
        .catch((err: any) => {});
    };
    const deleteAssignedVehicle = (
      url: string,
      data: any,
      callback?: (response: any) => void
    ) => {
      APIService.delete(
        `${ApiRoutes.base}/${url}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          callback && callback(response);
        })
        .catch((err: any) => {});
    };
    const getAvailability = (
      start: any,
      end: any,
      type?: any,
      callback?: (data: any) => void
    ) => {
      showMessage();

      APIService.get(
        `/vehicles/availability/all?start=${start}&end=${end}${
          type ? `&vehicleType=${type}` : ""
        }`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          dispatch({
            type: "GET_AVAILABILITY",
            payload: res.data.availability,
          });
          if (typeof callback === "function") {
            callback(res);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };
    const getAvailableVehicles = (
      start: any,
      end: any,
      type?: any,
      params?: any,
      callback?: (data: any) => void
    ) => {
      showMessage();
      APIService.get(
        `/contracts/available/assign-vehicles?start=${start}&end=${end}${
          type ? `&vehicleType=${type}${params ? `&q=${params}` : ""}` : ""
        }`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          dispatch({
            type: "GET_AVAILABILITY_VEHICLE",
            payload: res.data,
          });
          if (typeof callback === "function") {
            callback(res);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    return {
      state,
      actions: {
        getAllContracts,
        getAllUsers,
        getAllGroups,
        getAvailableVehicles,
        getAllContacts,
        AddGroup,
        AddContact,
        createOne,
        getOneContract,
        deleteAssignedVehicle,
        updateOne,
        deleteOne,
        getAvailability,
        assignVehicles,
        getAllAssignedVehicles,
        getVehicleTypes,
        clearAvailability: () =>
          dispatch({ type: "GET_AVAILABILITY", payload: {} }),
        clearOne: () => dispatch({ type: "GET_ONE_CONTRACT", payload: {} }),
      },
    };
  });
