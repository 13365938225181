import { useReducer } from "react";
import { container } from "./../../services/storage/context.container";
import { APIService } from "../../services/api/request";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { buildQueryString } from "../../services/utils/url";
import { destroyMessage, showMessage, UpdatedSuccessfully } from "../../utils";
import { changeDocument } from "./ReportsLoad";

const ApiRoutes = {
  base: `/vehicles/reports`,
};

export type StateType = {
  minimalDocuments: any;
  documents: any;
  pagination: any;
  formattedDocument: any;
  contact: any;
};

const initialState: StateType = {
  documents: [],
  formattedDocument: [],
  pagination: {},
  contact: {},
  minimalDocuments: [],
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_REPORTS":
      return { ...state, documents: payload };
    case "GET_FORMATTED_CODE":
      return { ...state, formattedDocument: payload };
    case "GET_ONE_CONTACTS":
      return { ...state, contact: { ...payload } };
    case "CLEARALL":
      return { ...state, documents: [] };
    default:
      return state;
  }
};

export const { useContext: useReports, Provider: ReportsProvider } = container(
  () => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });
    let history = useHistory();
    const getAllReports = (params?: any, callback?: (data: any) => void) => {
      showMessage();
      params = buildQueryString(params, true);
      APIService.get(
        `${ApiRoutes.base}/daily-analytics${params ? `${params}` : ""}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          const response: any = res.data;

          dispatch({ type: "GET_REPORTS", payload: response });

          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };
    const getRentReports = (params?: any, callback?: (data: any) => void) => {
      showMessage();
      params = buildQueryString(params, true);
      APIService.get(
        `${ApiRoutes.base}/on-rent${params ? `${params}` : ""}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          const response: any = res.data;

          dispatch({ type: "GET_REPORTS", payload: response });

          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    return {
      state,
      actions: {
        getAllReports,
        getRentReports,
        clearOne: () => dispatch({ type: "CLEARALL", payload: {} }),
      },
    };
  }
);
