import { Form, Formik } from "formik";
import { FiCheck, FiEdit, FiXCircle } from "react-icons/fi";
import { Button } from "../../../../../atoms/Button/Button";
import * as Yup from "yup";
import { FormikText } from "../../../../../atoms/Text/Text";
import { useEffect, useState } from "react";
import { useVehicles } from "../../../context";
import {
  FormikSinglSelect,
  SelectWithIcon,
} from "../../../../../atoms/Select/Select";
import {
  AdditionalFeatures,
  getCurrentObjectKey,
  Modals,
  selectOptionFormat,
  States,
  SubModals,
  VehicleFuelType,
  VehicleTypes,
} from "../../../../../options/VehicleLoan";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  CreateSelect,
  FormikCreateSinglSelect,
} from "../../../../../atoms/Select/CretableSelect";
import { FormikDatePicker } from "../../../../../atoms/DatePicker/DatePicker";
import { setSelectedValue, showMessage } from "../../../../../utils";
interface VehicleInformationsProps {
  initialData?: any;
  id?: string;
  isNew?: boolean;
  licensePlate?: string;
  onClose?: () => void;
  formRef?: any;
}

export const VehicleInformations: React.FC<VehicleInformationsProps> = ({
  initialData,
  id,
  isNew,
  formRef,
  onClose,
}: VehicleInformationsProps) => {
  let history = useHistory();
  const [canEdit, setEdit] = useState(false);
  const [additionalFeatures, setAdditionalFeatures]: any = useState([]);
  const [expiryError, setExpiryError] = useState("");
  const [makeDropDown, setMake] = useState([]);
  const [modelDropDown, setModel] = useState([]);
  const [ZeebaGroupList, setZeebaGroup] = useState([]);
  const [initialValues, setInitialValues]: any = useState({
    vehicleName: "",
    year: "",
    vehicleType: "",
    vin: "",
    licensePlate: "",
    make: "",
    model: "",
    subModel: "",
    group: "",
    color: "",
    fuelType: "",
    registrationState: "",
    registrationExpirationDate: null,
  });

  const {
    state: { vehicle, groups, vehicleTypes },
    actions: {
      updateOne,
      getOneVehicle,
      createOne,
      getAllGroups,
      getAllMakeModel,
      getVehicleTypes,
    },
  } = useVehicles();

  useEffect(() => {
    getAllGroups();
    getVehicleTypes();
    getAllMakeModel({ type: "make" }, (res: any) => {
      setMake(selectOptionFormat(res));
    });
  }, []);

  useEffect(() => {
    if (groups) {
      setZeebaGroup(selectOptionFormat(groups));
    }
  }, [groups]);

  const changeSelect = (value: any) => {
    setAdditionalFeatures(value);
  };
  useEffect(() => {}, [vehicleTypes]);

  useEffect(() => {
    if (id) {
      setAdditionalFeatures(
        vehicle.additionalFeatures
          ? vehicle.additionalFeatures.map((x: string) => ({
              label: x,
              value: x,
            }))
          : []
      );
      setInitialValues({
        vehicleName: vehicle.vehicleName,
        licensePlate: vehicle.licensePlate,
        vin: vehicle.vin,
        make: { label: vehicle?.make?.name, value: vehicle?.make?.id },
        year: vehicle?.year,
        registrationExpirationDate: moment(vehicle?.registrationExpirationDate),
        vehicleType: {
          label: vehicle.vehicleType?.name,
          value: vehicle.vehicleType?.id,
        },
        model: { label: vehicle?.model?.name, value: vehicle?.model?.id },
        subModel: vehicle.subModel,
        group: vehicle?.group ? setSelectedValue(vehicle?.group) : null,
        color: vehicle.color,
        fuelType: getCurrentObjectKey(vehicle.fuelType, "VehicleFuelType"),
        registrationState: getCurrentObjectKey(
          vehicle.registrationState,
          "States"
        ),
      });
      getAllMakeModel(
        {
          parent: vehicle?.make?.id,
        },
        (res: any) => {
          setModel(selectOptionFormat(res));
        }
      );
    } else {
      setInitialValues({
        vehicleName: "",
        year: "",
        vehicleType: "",
        vin: "",
        licensePlate: "",
        make: "",
        model: "",
        subModel: "",
        // group: "",
        color: "",
        fuelType: "",
        registrationState: "",
        registrationExpirationDate: null,
      });
    }
  }, [vehicle]);

  const getAdditionalFeatures = (array: any) => {
    let newArray: any[] = [];

    if (array && array.length > 0) {
      array.map((item: any) => {
        newArray.push(item.value);
      });
    } else {
      return [];
    }
    return newArray;
  };
  return (
    <>
      <div className="card_default rounded-[5px] mb-10 p-[24px]">
        <h2 className="text-[18px] font-[600] mb-5">
          {id ? "Edit" : "Add"} Vehicle
        </h2>
        <div className="">
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object({
              ...(id
                ? {
                    vehicleName: Yup.string()
                      .matches(
                        /^[0-9]{4}$/,
                        "Name field should contain only four numbers"
                      )
                      .required("Vehicle name is required"),
                  }
                : {}),

              licensePlate: Yup.string().required("License Plate is required"),
              vin: Yup.string().required("VIN number is required"),
              make: Yup.mixed().required("Make of the vehicle is required"),
              model: Yup.mixed().required("Model of the vehicle is required"),
              subModel: Yup.string().required(
                "Sub Model of the vehicle is required"
              ),
              year: Yup.string()
                .matches(
                  /^[0-9]{4}$/,
                  "Year field should contain only four numbers"
                )
                .required("Year of the vehicle is required"),
              vehicleType: Yup.mixed().required("Zeeba Type is required"),
              color: Yup.string().required("Zeeba color is required"),
              fuelType: Yup.mixed().required("Fuel type is required"),
              registrationExpirationDate: Yup.mixed()
                .nullable()
                .required("Registration expiration date is required"),
            })}
            onSubmit={(values: any, actions) => {
              let data = values;
              // update the state
              // Create One

              if (id) {
                updateOne(
                  id,
                  {
                    ...values,
                    registrationState: values.registrationState?.value
                      ? values.registrationState?.value
                      : "",

                    fuelType: values.fuelType.value,
                    year: parseInt(values.year),
                    vehicleType: values.vehicleType.value,
                    subModel: values.subModel,
                    model: values.model.value,
                    make: values.make.value,
                    group: values.group ? values.group.value : null,
                    registrationExpirationDate:
                      values.registrationExpirationDate
                        ? moment(
                            values.registrationExpirationDate
                          ).toISOString()
                        : "",
                    additionalFeatures:
                      getAdditionalFeatures(additionalFeatures),
                  },
                  (res) => {
                    history.goBack();
                    actions.resetForm();
                  }
                );
              } else {
                delete values?.vehicleName;
                let obj = {
                  ...values,
                  registrationState: values.registrationState.value,
                  fuelType: values.fuelType.value,
                  year: parseInt(values.year),
                  vehicleType: values.vehicleType.value,
                  subModel: values.subModel,
                  model: values.model.value,
                  make: values.make.value,
                  registrationExpirationDate: values.registrationExpirationDate
                    ? moment(values.registrationExpirationDate).toISOString()
                    : "",
                  additionalFeatures: getAdditionalFeatures(additionalFeatures),
                };

                delete obj.group;
                createOne(obj);
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <Form className="" id="my-form">
                <div
                  className={
                    id
                      ? "flex justify-between mb-[30px]"
                      : "flex gap-16 mb-[30px] md "
                  }
                >
                  {id && (
                    <FormikText must={true} label="Name" name="vehicleName" />
                  )}

                  <div className="min-w-[360px] ">
                    <FormikText label="Year " must={true} name="year" />
                  </div>

                  <div className="min-w-[360px] ">
                    <FormikSinglSelect
                      isClearable
                      must={true}
                      options={vehicleTypes}
                      label="Zeeba Type"
                      name="vehicleType"
                      value={values.vehicleType}
                      inputClassName="adjust_select_height"
                      onChange={(newValue) => {
                        setFieldValue("vehicleType", newValue);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between gap-5 mb-[30px]">
                  <FormikText must={true} label="VIN Number" name="vin" />
                  <FormikText
                    must={true}
                    label="License Plate"
                    name="licensePlate"
                  />{" "}
                  <div className="min-w-[360px]">
                    {" "}
                    <SelectWithIcon
                      options={VehicleFuelType}
                      must={true}
                      isClearable
                      label="Fuel Type"
                      name="fuelType"
                      inputClassName="adjust_select_height"
                      onChange={(newValue) =>
                        setFieldValue("fuelType", newValue)
                      }
                    />
                  </div>
                </div>
                <div className="flex justify-between gap-5 mb-[30px]">
                  <div className="min-w-[360px]">
                    <FormikSinglSelect
                      must={true}
                      isClearable
                      options={makeDropDown}
                      label="Zeeba Make"
                      name="make"
                      inputClassName="adjust_select_height"
                      onChange={(newValue: any) => {
                        setFieldValue("make", newValue);
                        setFieldValue("model", "");
                        getAllMakeModel(
                          {
                            parent: newValue.value,
                          },
                          (res: any) => {
                            setModel(selectOptionFormat(res));
                          }
                        );
                      }}
                    />
                  </div>
                  <div className="min-w-[360px]">
                    <FormikSinglSelect
                      must={true}
                      isClearable
                      options={modelDropDown}
                      label="Zeeba Model"
                      name="model"
                      inputClassName="adjust_select_height"
                      onChange={(newValue: any) => {
                        setFieldValue("model", newValue);
                      }}
                    />
                  </div>
                  <div className="min-w-[360px]">
                    {" "}
                    <FormikText must={true} label="Sub Model" name="subModel" />
                  </div>
                </div>
                <div className="flex gap-5 mb-[30px]">
                  <div className="mr-12">
                    <FormikText must={true} label="Color " name="color" />{" "}
                  </div>

                  {id && (
                    <div className="min-w-[360px]">
                      <FormikSinglSelect
                        isClearable
                        options={ZeebaGroupList}
                        label="Zeeba Group"
                        name="group"
                        inputClassName="adjust_select_height"
                        onChange={(newValue) =>
                          setFieldValue("group", newValue)
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="flex  mb-[40px]">
                  <CreateSelect
                    className="basis-1/2"
                    label="Additional Features"
                    options={AdditionalFeatures}
                    value={additionalFeatures}
                    name="additionalFeatures"
                    // inputClassName="multi_select_v"
                    onChange={(value) => changeSelect(value)}
                  />
                </div>
                <div>
                  <h3 className="font-semibold text-[18px] mb-[33px]">
                    Registration
                  </h3>
                  <div className="flex gap-5">
                    <div className="min-w-[360px]">
                      <FormikDatePicker
                        inputClassName="datepicket_styles"
                        label="Expiration Date"
                        must={true}
                        name="registrationExpirationDate"
                        onChange={(value: any) =>
                          setFieldValue("registrationExpirationDate", value)
                        }
                        value={values.registrationExpirationDate}
                      />
                    </div>
                    <div className="min-w-[360px]">
                      <FormikSinglSelect
                        options={States}
                        inputClassName="adjust_select_height"
                        label="State"
                        name="registrationState"
                        onChange={(newValue) =>
                          setFieldValue("registrationState", newValue)
                        }
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
