import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useDashboard } from "./context";
import { useEffect } from "react";
ChartJS.register(ArcElement, Tooltip, Legend);
export interface RemainderDonutProps {
  value?: any;
}
export const RemainderDonut: React.FC<RemainderDonutProps> = ({}) => {
  const {
    state: { documents },
  } = useDashboard();
  useEffect(() => {
  }, [documents]);
  const data = {
    // `Total-${documents.totalVehicles}`,
    labels: [
      `Overdue - ${documents?.renewal?.overDueTotal}`,
      `Due Soon - ${documents?.renewal?.dueSoonTotal}`,
      `Healthy -${documents?.renewal?.healthyTotal}`,
    ],
    datasets: [
      {
        label: "Vehicles",
        data: [
          documents?.renewal?.overDueTotal,
          documents?.renewal?.dueSoonTotal,
          documents?.renewal?.healthyTotal,
        ],
        backgroundColor: [
          "rgba(230, 146, 32, 0.7)",
          "rgba(242, 81, 56, 0.7)",
          "rgba(130, 217, 126, 1)",
        ],
        hoverBackgroundColor: [
          "rgba(230, 146, 32, 0.7)",
          "rgba(242, 81, 56, 0.7)",
          "rgba(130, 217, 126, 1)",
        ],
        borderColor: ["white", "white", "white"],
      },
    ],
    options: {
      legend: {
        position: "left",
      },
    },
  };
  const options: any = {
    aspectRatio: 1.05,
    layout: {
      padding: 0,
    },
    plugins: {
      datalabels: { display: false },
      legend: {
        display: true,
        labels: {
          font: { size: 12, fontColor: "#1C1C1C" },
          padding: 20,
          usePointStyle: true,
          boxWidth: 8,
          boxHeight: 6,
        },
        position: "bottom" as const,
      },
    },

    elements: {
      spacing: "20px",
      rotation: 90,
      pointStyle: "circle",
      radius: 10,
      arc: {
        borderWidth: 3,
      },
    },
    cutout: "70%",

    borderWidth: 0,
    borderRadius: 30,
    animation: {
      animateRotate: true,
    },
  };
  return (
    <>
      {Object.keys(documents).length > 0 && (
        <div className="w-full h-full flex items-center justify-between">
          {" "}
          <Doughnut
            id="donut"
            width={"inherit"}
            options={options}
            data={data}
          />
        </div>
      )}{" "}
    </>
  );
};
