import { Empty, Tabs } from "antd";
import { useEffect, useState } from "react";
import {
  FiCreditCard,
  FiGrid,
  FiMap,
  FiSearch,
  FiSettings,
} from "react-icons/fi";
import { IoIosCalendar } from "react-icons/io";
import { useHistory, useLocation } from "react-router";
import { SingleSelect } from "../../../atoms/Select/Select";
import { DetailHeader } from "../../../molecules/list-header/DetailHeader";
const { TabPane } = Tabs;

export const OneInspectionPage: React.FC<{}> = (props) => {
  const [currentTab, setCurrentTab] = useState("info");
  const { hash } = useLocation();
  const history = useHistory();
  useEffect(() => {
    setCurrentTab(hash || "#info");
  }, [hash]);
  return (
    <div className="w-full  bg-white p-2">
      <DetailHeader title="One Vehicle">
        <div className="flex justify-end w-full">
          <div className="w-1/2">
            <SingleSelect label="Current Vehicle Status" />
          </div>
        </div>
      </DetailHeader>
      <Tabs
        activeKey={currentTab}
        className="ml-2"
        onChange={(activeKey) => {
          setCurrentTab(activeKey);
          history.push({
            hash: activeKey,
          });
        }}
      >
        <TabPane
          tab={
            <span className="tab-item flex justify-center items-center ">
              <FiGrid className="mr-1" />
              Information
            </span>
          }
          key="#info"
        >
          {/* <BasicInformations isNew={false} /> */}
        </TabPane>
        <TabPane
          tab={
            <span className="tab-item flex justify-center items-center">
              <FiCreditCard className="mr-1" />
              Financial
            </span>
          }
          key="#financial"
        >
          {/* <FinancialInformations isNew={false} /> */}
        </TabPane>
        <TabPane
          tab={
            <span className="tab-item flex justify-center items-center">
              <FiSettings className="mr-1" />
              Services
            </span>
          }
          key="#service"
        >
          <Empty
            style={{ height: 350 }}
            className="flex justify-center items-center"
            description="Yet to be developed"
          />
        </TabPane>
        <TabPane
          tab={
            <span className="tab-item flex justify-center items-center">
              <IoIosCalendar className="mr-1" />
              Schedules
            </span>
          }
          key="#schedule"
        >
          <Empty
            style={{ height: 350 }}
            className="flex justify-center items-center"
            description="Yet to be developed"
          />
        </TabPane>
        <TabPane
          tab={
            <span className="tab-item flex justify-center items-center">
              <FiSearch className="mr-1" />
              Inspections
            </span>
          }
          key="#inspection"
        >
          <Empty
            style={{ height: 350 }}
            className="flex justify-center items-center"
            description="Yet to be developed"
          />
        </TabPane>
        <TabPane
          tab={
            <span className="tab-item flex justify-center items-center">
              <FiMap className="mr-1" />
              Trip Activity
            </span>
          }
          key="#trip-activity"
        >
          <Empty
            style={{ height: 350 }}
            className="flex justify-center items-center"
            description="Yet to be developed"
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
