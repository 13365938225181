import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./common.scss";
import { RootRoute } from "./routes/RootRoute";
import { AuthProvider } from "./routes/RouteContext";
import { Layout } from "./templates/layout";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout className="grid z-layout">
          <RootRoute />
        </Layout>
      </Router>
    </AuthProvider>
  );
}

export default App;
