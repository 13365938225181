import { Redirect, Route, Switch } from "react-router";
import { AllMakeModelList } from "./make-model/all/AllMakeModelList";
import { MakeProvider } from "./make-model/context";

export interface MasterMainLayoutProps {}
export const MasterMainLayout: React.FC<MasterMainLayoutProps> = () => {
  return (
    <>
      {" "}
      <MakeProvider>
        <Switch>
          <Route path="/master/make-model" exact component={AllMakeModelList} />
          <Route path="/master">
            <Redirect to="/master/make-model" />
          </Route>
        </Switch>
      </MakeProvider>
    </>
  );
};
