import { isPremitted } from "../../services/utils/permission";


export interface PermissionGateProps {
    children?: React.ReactNode;
    action: 'create' | 'edit' | 'list' | 'get' | 'delete';
    module: string;
}

export const PermissionsGate: React.FC<PermissionGateProps> = ({
    children,
    action,
    module
}) => {
    const isPermitted = isPremitted(module, action);
    if (!isPermitted) return <></>;

    return <>{children}</>;
}
