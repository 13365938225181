import { Link } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { BreadCrumbs } from "../../../../atoms/BreadCrumb/BreadCrumb";
import { Table } from "../../../../atoms/Table/Table";
import { FiltersIcon } from "../../../../icons/Pagination";
import { ListHeader } from "../../../../molecules/list-header/ListHeader";
import { useEffect, useState } from "react";
import { Dropdown, Menu, MenuProps, Modal } from "antd";
import { Button } from "../../../../atoms/Button/Button";
import { PlusIcon, PlusIconDropDown } from "../../../../icons";
import { DropdownArrow } from "../../../../icons/MoreIcon";
import { AddMake } from "../one/AddMake";
import { AddModel } from "../one/AddModel";
import { SubModel } from "../one/SubModel";
import { useMake } from "../context";
import { ListingSubItem } from "../../../../icons/ListingSubItems";
import { ShowDateFormat } from "../../../../utils";
import { colorCode, getCurrentStatusFromKey } from "../../MasterLoan";
import { ListTrash } from "../../../../icons/home";
import { EditPenIcon } from "../../../../icons/edit-pen";
import { useSearchParams } from "../../../../hooks/useSearchParams";
import { Pagination } from "../../../../atoms/Pagination/pagination";
const { confirm } = Modal;
export interface AllMakeModelListProps {}
export const AllMakeModelList: React.FC<
  AllMakeModelListProps
> = ({}: AllMakeModelListProps) => {
  const [makeDragger, setMakeDragger] = useState({
    visible: false,
    isNew: false,
  });
  const [modelDragger, setmodelDragger] = useState({
    visible: false,
    isNew: false,
  });
  const [subModel, setSubModel] = useState(false);
  const { query, updateSearchParams }: any = useSearchParams();
  const [data, setData] = useState([]);
  const {
    state: { documents, pagination },
    actions: { getAllMake, getOneMake, deleteOne },
  } = useMake();
  useEffect(() => {
    if (documents) {
      documents.map((item: any, i: any) => {
        item["key"] = i.toString();
      });
      setData(documents);
    }
  }, [documents]);

  useEffect(() => {
    if (Object.keys(query).length !== 0) {
      getAllMake(false, { ...query, type: "make" });
    } else {
      updateSearchParams({ page: 1, perPage: 10 });
    }
  }, [query]);

  const setSelectedValue = (id: any) => {
    setMakeDragger({ visible: true, isNew: false });
    getOneMake(id);
  };

  const columns: ColumnsType = [
    {
      title: "Make",
      dataIndex: "name",
      render: (value, record: any) => (
        <div className="flex items-center">
          <span className="mr-2"> {value}</span>
          <span
            className="cursor-pointer"
            onClick={() => GetsubClientsData(record.id)}
          >
            {record.totalAssigns > 0 && <ListingSubItem />}
          </span>
        </div>
      ),
    },
    {
      title: "No of Models",
      dataIndex: "totalAssigns",
    },
    {
      title: "Date Created",
      dataIndex: "updatedAt",
      render: (value, record: any) => ShowDateFormat(value),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value) => (
        <p className={`capitalize font-[600] ${colorCode(value)}`}>
          {getCurrentStatusFromKey(value)?.label}
        </p>
      ),
    },

    {
      title: "Action",
      // sorter: true,
      render: (value, record: any) => (
        <div className="flex  items-center">
          {/* <PermissionsGate action="edit" module="group"> */}
          <div
            className="underline flex items-center mr-[10px] cursor-pointer"
            onClick={() => {
              setSelectedValue(record.id);
            }}
          >
            <EditPenIcon />
          </div>
          {/* </PermissionsGate> */}
          {/* <PermissionsGate action="delete" module="group"> */}
          <div
            className="cursor-pointer"
            onClick={() => {
              confirm({
                title: "Warning",
                content: "Are you sure want to deactivate this Make?",
                onOk() {
                  deleteOne(record.id, () => {
                    getAllMake(false, { ...query, type: "make" });
                  });
                },
                onCancel() {},
              });
            }}
          >
            <ListTrash />
          </div>
          {/* </PermissionsGate> */}
        </div>
      ),
    },
  ];

  const GetsubClientsData = (id: any) => {
    setSubModel(true);
    getAllMake(false, { parent: id });
  };

  const items: MenuProps["items"] = [
    {
      label: "Add Make",
      key: "1",
      icon: <PlusIconDropDown />,
    },
    {
      label: "Add Model",
      key: "2",
      icon: <PlusIconDropDown />,
    },
  ];
  const handleMenuClick: MenuProps["onClick"] = (e: any) => {
    e.key === "1"
      ? setMakeDragger({ visible: true, isNew: true })
      : setmodelDragger({ visible: true, isNew: true });
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <>
      {makeDragger.visible && (
        <AddMake
          makeDragger={makeDragger}
          onClose={() => setMakeDragger({ visible: false, isNew: false })}
        />
      )}
      {modelDragger.visible && (
        <AddModel
          modelDragger={modelDragger}
          onClose={() => setmodelDragger({ visible: false, isNew: false })}
        />
      )}
      {subModel && (
        <SubModel
          isVisibible={subModel}
          onClose={() => {
            setSubModel(false);
            getAllMake(false, { type: "make" });
          }}
        />
      )}

      <div className="w-full h-full bg-white px-6">
        {" "}
        <div className="flex justify-between items-center py-[24px]">
          <BreadCrumbs />
          <div>
            <div className="flex flex-row-reverse gap-4">
              <Dropdown
                trigger={["click"]}
                overlayClassName="button_dropdown"
                menu={menuProps}
                placement="bottomLeft"
                overlayStyle={{ width: "180px" }}
              >
                <Button className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center">
                  <span>
                    <PlusIcon />
                  </span>
                  Add
                  <span>
                    <DropdownArrow />
                  </span>
                </Button>
              </Dropdown>
              {/* <PermissionsGate action="create" module="group">
                <Button
                  className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <span>
                    <PlusIcon />
                  </span>
                  Add Client
                </Button>
              </PermissionsGate> */}
            </div>
          </div>
        </div>
        <div className="card_style ">
          {/* Title, Search, Filter */}
          <ListHeader
            searchPlaceHolder="Search by Name"
            // pagination={pagination}
          >
            {/* {hasFilter && (
                <div
                  onClick={() => {
                    setFiltersDrawer(true);
                    setFiltersGroup({
                      type: extraFilters["type"] ? extraFilters["type"] : null,
                      status: extraFilters["status"]
                        ? extraFilters["status"]
                        : null,
                    });
                  }}
                  className="flex ml-2 justify-center items-center rounded-[4px] cursor-pointer border w-[40px] h-[40px] border-[#f0f0f0]"
                >
                  {" "}
                  <FiltersIcon />
                </div>
              )} */}
          </ListHeader>
          {/* Table View */}
          <Table
            className="group_table"
            columns={columns}
            dataSource={data}
            pagination={false}
          />
          <div className="mr-[24px] my-5">
            <Pagination
              pagination={pagination}
              onNext={() => {
                let newQuery: any = { ...query };
                newQuery.page = Number(newQuery.page) + 1;
                updateSearchParams({ ...newQuery });
              }}
              onPrevious={() => {
                let newQuery: any = { ...query };
                newQuery.page = Number(newQuery.page) - 1;
                updateSearchParams({ ...newQuery });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
