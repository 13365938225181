import { Drawer, message, Spin } from "antd";
import { Form, Formik } from "formik";
import { Button } from "../../../atoms/Button/Button";
import { FormikText } from "../../../atoms/Text/Text";
import { SaveIcon } from "../../../icons/SaveIcon";
import * as Yup from "yup";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { FormikSinglSelect } from "../../../atoms/Select/Select";
import { returnStatusKey, ServiceStatus } from "../ServiceLoan";
import { useServiceHistory } from "../context";
import uploadIcon from "../../../icons/designIcons/uploadIcon.svg";
import { CancelUpload, PdfImage } from "../../../icons/UploadIcons";
import { useParams } from "react-router";
import { useVehicles } from "../../vehicles/context";
import { addIsUploadedKey, getAwsUploadUrl } from "../../../utils";
export interface AddServiceHistoryProps {
  draggerKeys?: any;
  onClose: () => void;
}
export const AddServiceHistory: React.FC<AddServiceHistoryProps> = ({
  draggerKeys,
  onClose,
}) => {
  const {
    state: { allVehicles, allVendors, history },
    actions: { createOne, updateOne, getOneHistory, getAllHistory },
  } = useServiceHistory();
  const {
    state: { vehicle },
  } = useVehicles();
  const [initialValues, setInitialValues]: any = useState({
    name: "",
    vehicle: "",
    vendor: "",
    status: "",
    invoiceAmount: 0,
  });
  const [vehicleOption, setVehicleOption] = useState([]);
  const [file, setFile]: any = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [vendorOption, setVendorOption] = useState([]);
  const { id }: any = useParams();
  const [isUploadable, setIsUploadable] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  useEffect(() => {
    if (allVehicles) {
      let newArray: any = [];
      allVehicles?.data?.map((item: any) => {
        let obj = {
          label: item.vehicleName,
          value: item.id,
        };
        newArray.push(obj);
      });
      setVehicleOption(newArray);
    }
    if (allVendors) {
      let newArray: any = [];
      allVendors?.data?.map((item: any) => {
        let obj = {
          label: item.name,
          value: item.id,
        };
        newArray.push(obj);
      });
      setVendorOption(newArray);
    }
  }, [allVehicles, allVendors]);

  useEffect(() => {
    if (!draggerKeys.isNew) {
      setInitialValues({
        ...history,
        vehicle: {
          label: history?.vehicle?.vehicleName,
          value: history?.vehicle?.id,
        },
        vendor: { label: history?.vendor?.name, value: history?.vendor?.id },
        status: returnStatusKey(history?.status),
      });
      if (history.attachments?.length > 0) {
        setFile(addIsUploadedKey(history.attachments));
      }
      if (history?.vehicle?.vehicleName) {
        setIsUploadable(true);
      }
    } else {
      setInitialValues({
        name: "",
        vehicle: id ? { label: vehicle.vehicleName, value: id } : "",
        vendor: "",
        status: "",
        invoiceAmount: 0,
      });
      setFile({});
      if (id) {
        setIsUploadable(true);
      }
    }
  }, [draggerKeys.isNew, history, vehicle]);

  const uploadFunction = (e: any) => {
    let Uploadedfile = e.target.files;
    if (Uploadedfile.length > 0) {
      for (let item of Uploadedfile) {
        if (
          item.type !== "image/png" &&
          item.type !== "image/svg" &&
          item.type !== "image/jpeg" &&
          item.type !== "application/pdf"
        ) {
          message.error("Please upload only images or pdfs");
        } else if (item.size > 5 * 1000000) {
          message.error("Couldn't upload large files");
        } else {
          let data = {
            orginal_file: item,
            name: item.name,
            url: window.URL.createObjectURL(item),
            type: item.type,
          };
          setFile((individualFiles: any) =>
            file.length > 0 ? [...individualFiles, data] : [data]
          );
        }
      }
    }
  };

  const deleteUploaded = (i: any) => {
    file.filter((item: any, index: any) => {
      if (index === i) {
        file.splice(i, 1);
      }
      setFile([...file]);
    });
  };
  const uploadAttachments = async (vehicle_id: string) => {
    if (file.length > 0) {
      let attachments: any = [];
      let uploadedArray = await getAwsUploadUrl(
        file,
        `vehicles/${vehicle_id}/service-history/`
      );
      uploadedArray.map((item: any) => {
        let obj = {
          url: item.url,
          type: item.type,
          name: item.name,
        };
        attachments.push(obj);
      });
      return attachments;
    }
  };
  const closeFunction = () => {
    onClose();
    setFile([]);
    setIsUploadable(false);
  };
  return (
    <>
      {draggerKeys.visible && (
        <Drawer
          title={`${draggerKeys.isNew ? "Add" : "Edit"} Service History`}
          visible={draggerKeys.visible}
          placement="right"
          width="33%"
          onClose={closeFunction}
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object({
              name: Yup.string().required("Name is required"),
              vehicle: Yup.mixed().required("Vehicle is required"),
              vendor: Yup.mixed().required("Vendor is required"),
              status: Yup.mixed().required("Status is required"),
            })}
            onSubmit={async (values: any) => {
              setSpinner(true);
              if (file.length > 0) {
                values["attachments"] = await uploadAttachments(
                  values.vehicle.value
                );
              }
              if (draggerKeys.isNew) {
                //create

                createOne(
                  {
                    ...values,
                    vehicle: values.vehicle.value,
                    vendor: values.vendor.value,
                    status: values.status.value,
                    attachments: values.attachments ? values.attachments : [],
                  },
                  () => {
                    setSpinner(false);
                    setFile([]);
                    if (id) {
                      getAllHistory(false, {
                        page: 1,
                        perPage: 10,
                        vehicle: id,
                      });
                    } else {
                      getAllHistory();
                    }
                    onClose();
                  }
                );
              } else {
                //update
                updateOne(
                  history.id,
                  {
                    ...values,
                    vehicle: values.vehicle.value,
                    vendor: values.vendor.value,
                    status: values.status.value,
                    attachments: values.attachments ? values.attachments : [],
                  },

                  () => {
                    setSpinner(false);
                    setFile([]);
                    if (id) {
                      getAllHistory(false, {
                        page: 1,
                        perPage: 10,
                        vehicle: id,
                      });
                    } else {
                      getAllHistory();
                    }
                    onClose();
                  }
                );
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <Form className="" id="my-form">
                <FormikText label="Name" name="name" must={true} />
                <div className="min-w-[360px] mt-5 mb-5">
                  <FormikSinglSelect
                    must={true}
                    options={vehicleOption}
                    label="Vehicle"
                    name="vehicle"
                    readOnly={(id || !draggerKeys.isNew) && true}
                    inputClassName="adjust_select_height"
                    onChange={(newValue) => {
                      setFieldValue("vehicle", newValue);
                      setIsUploadable(true);
                    }}
                  />
                </div>
                <div className="min-w-[360px] mt-4 mb-4">
                  <FormikSinglSelect
                    must={true}
                    options={vendorOption}
                    label="Vendor"
                    name="vendor"
                    inputClassName="adjust_select_height"
                    onChange={(newValue) => {
                      setFieldValue("vendor", newValue);
                    }}
                  />
                </div>
                <FormikText
                  label="Invoice Amount"
                  name="invoiceAmount"
                  type={"number"}
                />{" "}
                <div className="min-w-[360px] mt-4 mb-4">
                  <FormikSinglSelect
                    must={true}
                    isClearable
                    options={ServiceStatus}
                    label="Status"
                    name="status"
                    inputClassName="adjust_select_height"
                    onChange={(newValue) => {
                      setFieldValue("status", newValue);
                    }}
                  />
                </div>
                {isUploadable && (
                  <div className="mt-8 input flex items-center  justify-center relative field w-full h-[100px] border-2 rounded-[10px] border-dashed border-[#9C9C9C]">
                    <input
                      id="InputUpload"
                      type="file"
                      className="w-full h-full opacity-0 absolute cursor-pointer"
                      accept="image/*,application/pdf"
                      onChange={(e) => uploadFunction(e)}
                      multiple={true}
                    />
                    <img className="mr-5" src={uploadIcon} />
                    <div>
                      {" "}
                      <p className="text-[16px] font-medium ">
                        Click to Upload{" "}
                      </p>
                      <p className="text-[14px] text-[#757575]">
                        Maximum file size 5 MB
                      </p>
                    </div>
                  </div>
                )}
                <div className="mt-8">
                  {file.length > 0 && (
                    <>
                      {file.map((item: any, index: any) => (
                        <div
                          className={`border mb-5 rounded-[4px] ${
                            item.type === "application/pdf" && "bg-[#FAFAFA]"
                          }  border-[#EBEBEB] px-[14px] py-[12px] flex justify-between items-center`}
                        >
                          <div className="flex  items-center">
                            <div
                              className={`w-[32px] mr-[15px] ${
                                item.type === "application/pdf" &&
                                "bg-[#D9D9D9] p-[3px]"
                              }   h-[32px] rounded-[3px] `}
                            >
                              {item.type === "application/pdf" ? (
                                <PdfImage />
                              ) : (
                                <div className="w-[32px] mr-[15px] h-[32px] rounded-[3px] ">
                                  <a
                                    className=""
                                    href={
                                      !item.is_uploaded
                                        ? item.url
                                        : `${
                                            process.env
                                              .REACT_APP_UPLOAD_API_ENDPOINT
                                          }/${item.url}?${Date.now()}`
                                    }
                                    target="_blank"
                                  >
                                    {" "}
                                    <img
                                      className="w-full h-full object-cover"
                                      src={
                                        !item.is_uploaded
                                          ? item.url
                                          : `${
                                              process.env
                                                .REACT_APP_UPLOAD_API_ENDPOINT
                                            }/${item.url}?${Date.now()}`
                                      }
                                    />
                                  </a>
                                </div>
                              )}
                            </div>
                            <p className="font-medium text-[14px]">
                              {item.name}
                            </p>
                          </div>
                          <div
                            className="cursor-pointer"
                            onClick={() => deleteUploaded(index)}
                          >
                            <CancelUpload />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div className="flex justify-end mt-8 items-center">
                  {" "}
                  <>
                    {!spinner && (
                      <Button
                        className="border mr-2 ml-4  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                        type="button"
                        onClick={onClose}
                      >
                        <span className="flex items-center text-[#FB6DB2]">
                          Cancel
                        </span>
                      </Button>
                    )}
                    <Button
                      type="submit"
                      disabled={spinner}
                      className="rounded-[4px] px-5 py-3 bg-primary text-[13px] flex items-center text-white font-semibold flex items-center gap-2 justify-center"
                    >
                      <>
                        {spinner && (
                          <Spin className="btn-spinner" indicator={antIcon} />
                        )}
                        {draggerKeys.isNew ? "Add" : "Save"} Service History
                      </>
                    </Button>{" "}
                  </>
                </div>
              </Form>
            )}
          </Formik>
        </Drawer>
      )}
    </>
  );
};
