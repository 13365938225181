import { useField } from "formik";
import Select, { Props as RSProps } from "react-select";

export interface MultiSelectProps extends RSProps {
  label: string;
  error?: string;
  inputClassName?: string;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  label,
  error,
  inputClassName,
  ...props
}: MultiSelectProps) => {
  return (
    <div className="form-input-group w-full ">
      {label && (
        <div className="form-label mb-3 font-medium w-full">
          <p>{label}</p>
        </div>
      )}

      <div className={`${inputClassName && `${inputClassName}`} form-input`}>
        <Select
          className={`${inputClassName && `${inputClassName}`} text-[13px]`}
          isMulti
          {...props}
        />
      </div>
      {error && (
        <div className="form-error text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};
