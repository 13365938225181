import { Avatar, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CommentsDragger } from "../../atoms/CommentsDragger/Comments";
import { Pagination } from "../../atoms/Pagination/pagination";
import { PermissionsGate } from "../../atoms/PermissionComponent/PermissionGate";
import { SingleSelect } from "../../atoms/Select/Select";
import { Table } from "../../atoms/Table/Table";
import { useSearchParams } from "../../hooks/useSearchParams";
import { ListTrash } from "../../icons/home";
import { ListHeader } from "../../molecules/list-header/ListHeader";
import { removeAndReturnFilter, ShowDateFormat } from "../../utils";
import { useProcurement } from "./context";
import {
  colorCode,
  getCurrentStatusFromQuery,
  procurementFilterStatus,
} from "./procurementLoad";
const { confirm } = Modal;
export interface AllPipelineListingProps {}
export const AllPipelineListing: React.FC<AllPipelineListingProps> = ({}) => {
  const { query, updateSearchParams }: any = useSearchParams();
  const [document, setDocuments]: any = useState({});

  const [extraFilters, setExtraFilters]: any = useState({});
  const {
    actions: { getAllProcurement, deleteProcurement },
    state: { documents, pagination },
  } = useProcurement();

  useEffect(() => {
    // getAllProcurement();
  }, []);

  useEffect(() => {
    if (Object.keys(query).length !== 0) {
      getAllProcurement(false, query);
      setExtraFilters({
        ...extraFilters,
        status: getCurrentStatusFromQuery(query.status),
      });
    } else {
      updateSearchParams({ page: 1, perPage: 10 });
    }
  }, [query]);

  const columns: ColumnsType = [
    {
      title: "Title",
      dataIndex: "title",
      render: (value, record: any) => (
        <Link
          className="cursor-pointer underline font-medium text-[#398CC0]"
          to={`/procurement/${record.id}`}
        >
          {value}
        </Link>
      ),
    },

    {
      title: "Created",
      dataIndex: "createdAt",
      render: (value, record: any) => ShowDateFormat(value),
    },
    {
      title: "Updated",
      dataIndex: "updatedAt",
      render: (value, record: any) => ShowDateFormat(value),
    },
    {
      title: "Assigned to",
      dataIndex: "",
      render: (value, record: any) => (
        <div className="flex  items-center">
          {" "}
          <Avatar
            className="capitalize"
            style={{ backgroundColor: `${record?.assignedTo?.color}` }}
          >
            {`${record?.assignedTo?.firstName[0]}${record?.assignedTo?.lastName[0]}`}
          </Avatar>
          <p className="ml-2 font-semibold text-[13px]">
            {" "}
            {`${record?.assignedTo?.firstName}${record?.assignedTo?.lastName}`}
          </p>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value, record: any) => (
        <div className={`capitalize ${colorCode(value)}`}>
          {getCurrentStatusFromQuery(value)?.label}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      render: (value, record: any) => (
        <div className="flex">
          {record.status !== "deleted" && (
            <PermissionsGate action="delete" module="procurement">
              <div className="flex  items-center">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    confirm({
                      title: "Warning",
                      content: "Are you sure you want to delete this Lead?",
                      onOk() {
                        deleteProcurement(record.id, (res) => {
                          getAllProcurement();
                        });
                      },
                      onCancel() {},
                    });
                  }}
                >
                  {" "}
                  <ListTrash />{" "}
                </div>
              </div>
            </PermissionsGate>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="card_style ">
        {" "}
        <ListHeader
          hash={"#users"}
          onNewClicked={() => {}}
          onFilterClicked={() => {}}
          searchPlaceHolder="Search by Title"
          pagination={pagination}
        >
          <SingleSelect
            inputClassName="adjust_select_height text-[13px]"
            className="w-[160px] ml-2 mt-4"
            isClearable
            placeholder="Status"
            value={extraFilters["status"] ? extraFilters["status"] : null}
            options={procurementFilterStatus}
            onChange={(newValue: any) => {
              updateSearchParams(
                newValue
                  ? {
                      ...query,
                      status: newValue && newValue.value,
                    }
                  : removeAndReturnFilter("status", query)
              );
            }}
          />
        </ListHeader>
        <Table
          className="vehicle_table"
          // onChange={onTableActivity}
          columns={columns}
          dataSource={documents}
          pagination={false}
        />
        <div className="mr-[24px] my-5">
          <Pagination
            pagination={pagination}
            onNext={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) + 1;
              updateSearchParams({ ...newQuery });
            }}
            onPrevious={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) - 1;
              updateSearchParams({ ...newQuery });
            }}
          />
        </div>
      </div>
    </>
  );
};
