import { GetuploadUrl, UploadFiles } from "../../utils";

export const VendorTypeOptions = [
  {
    label: "Bodyshop",
    value: "bodyshop",
  },
  {
    label: "Mechanic",
    value: "mechanic",
  },

  {
    label: "Tire",
    value: "tire",
  },
  {
    label: "Towing",
    value: "towing",
  },
  {
    label: "Glass",
    value: "glass",
  },
  {
    label: "Upholstery",
    value: "upholstery",
  },
  {
    label: "Dealer",
    value: "dealer",
  },
  {
    label: "Manheim",
    value: "manheim",
  },
];

export const getCurrentVendorTypeFromKey = (key: string) =>
  VendorTypeOptions.find((x) => x.value === key);

