import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useDashboard } from "./context";
import { useEffect } from "react";
ChartJS.register(ArcElement, Tooltip, Legend);
export interface RemainderDonutProps {}
export const FuelTypeDonut: React.FC<RemainderDonutProps> = ({}) => {
  const {
    state: { documents },
    actions: { getDashboard },
  } = useDashboard();
  useEffect(() => {
    getDashboard();
  }, []);
  useEffect(() => {}, [documents]);
  const data = {
    labels: [`Gasoline`, "Diesel", "Electric", "Flex Fuel"],
    datasets: [
      {
        label: "Gas Type",
        fontFamily: "Poppins",
        data: [
          documents?.gasType?.gasoline,
          documents?.gasType?.diesel,
          documents?.gasType?.ev,
          documents?.gasType?.flexFuel,
        ],
        backgroundColor: ["#FF8B60", "#00AEEF", "#3BA936", "#98fb98"],
        hoverBackgroundColor: ["#FF8B60", "#00AEEF", "#3BA936"],
        borderColor: ["white", "white", "white", "white"],
      },
    ],
    options: {
      fontFamily: "Poppins",
      legend: {
        position: "left",
      },
    },
  };
  const options: any = {
    layout: {
      padding: 0,
    },
    plugins: {
      datalabels: { display: false },
      legend: {
        labels: {
          fontFamily: "Poppins",
          font: { size: 12, fontColor: "#1C1C1C" },
          padding: 20,
          usePointStyle: true,
          boxWidth: 8,
          boxHeight: 6,
        },
        position: "right" as const,
      },
    },

    elements: {
      spacing: "20px",

      pointStyle: "circle",
      responsive: true,
      radius: 10,
      arc: {
        borderWidth: 3,
      },
    },
    cutout: "70%",
    borderRadius: 30,
    borderWidth: 0,

    animation: {
      animateRotate: true,
    },
  };
  return (
    <>
      <div className="w-full flex items-center justify-center h-full">
        {" "}
        {Object.keys(documents).length > 0 && (
          <Doughnut
            id="donut"
            options={options}
            height={"100%"}
            width={"100%"}
            data={data}
          />
        )}
      </div>{" "}
    </>
  );
};
