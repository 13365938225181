import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BreadCrumbs } from "../../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../../atoms/Button/Button";
import { SelectedBarProps } from "../../../atoms/ListingSelectedBar/ListingSelectedBar";
import { Pagination } from "../../../atoms/Pagination/pagination";
import { SingleSelect } from "../../../atoms/Select/Select";
import { Table } from "../../../atoms/Table/Table";
import { useSearchParams } from "../../../hooks/useSearchParams";
import { PlusIcon } from "../../../icons";
import { EditPenIcon } from "../../../icons/edit-pen";
import { ListTrash } from "../../../icons/home";
import { ListHeader } from "../../../molecules/list-header/ListHeader";
import { getStatusFromKey, Status } from "../../../options/status";
import { useVendors } from "../context";
import { Drawer, Modal } from "antd";
import { getCurrentVendorTypeFromKey, VendorTypeOptions } from "../VendorLoans";
import { colorCode } from "../../../utils";
import { FiltersIcon } from "../../../icons/Pagination";
import { PermissionsGate } from "../../../atoms/PermissionComponent/PermissionGate";
import { ListCommentIcon } from "../../../icons/SendComments";
import { CommentsDragger } from "../../../atoms/CommentsDragger/Comments";
import { useCommonContext } from "../../../hooks/commonStateProvider";
const { confirm } = Modal;
export interface AllVendorProps {}

export const AllVendors: React.FC<AllVendorProps> = () => {
  const {
    state: { documents: allVendors, pagination },
    actions: { getAllVendors, deleteVendor },
  } = useVendors();
  const [extraFilters, setExtraFilters]: any = useState({});
  const [hasFilter, setHasFilter]: any = useState(false);
  const [commentsObj, setComments] = useState({ visible: false, id: "" });
  const { query, updateSearchParams }: any = useSearchParams();
  const [filterDrawer, setFiltersDrawer] = useState(false);
  const [filtersGroup, setFiltersGroup]: any = useState({
    status: "",
    type: "",
  });
  useEffect(() => {
    if (Object.keys(query).length !== 0) {
      getAllVendors(false, query);
      setExtraFilters({
        ...extraFilters,
        status: getStatusFromKey(query.status),
        type: getCurrentVendorTypeFromKey(query.type),
      });
    } else {
      updateSearchParams({ page: 1, perPage: 10 });
    }
  }, [query]);

  const updateSearch = () => {
    let queryParams: any = {};
    if (filtersGroup.status) queryParams["status"] = filtersGroup.status.value;
    if (filtersGroup.type) queryParams["type"] = filtersGroup.type.value;
    return { ...query, ...queryParams };
  };

  useEffect(() => {
    let filter = { ...query };
    delete filter["page"];
    delete filter["perPage"];
    delete filter["sort"];
    const hasFilter = Object.keys(filter).length > 0;
    setHasFilter(hasFilter);
  }, [query]);
  const {
    actions: { getAllComments },
  } = useCommonContext();

  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Full Address",
      render: (value, record: any) =>
        `${record.address}, ${record.addressLineTwo}`,
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
    },
    {
      title: "Website",
      dataIndex: "website",
      render: (value) => (
        <a href={value} target="_blank">
          {" "}
          Open in New Tab{" "}
        </a>
      ),
    },
    {
      title: "Contact Name",
      dataIndex: "contactPersonName",
    },
    {
      title: "Contact Email",
      dataIndex: "contactEmail",
    },
    {
      title: "Vendor Type",
      dataIndex: "type",
      render: (value) => getCurrentVendorTypeFromKey(value)?.label,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value) => (
        <p className={`capitalize font-[600] ${colorCode(value)}`}>
          {getStatusFromKey(value)?.label}
        </p>
      ),
    },
    {
      title: "Action",
      // sorter: true,
      render: (value, record: any) => (
        <div className="flex  items-center">
          <div
            className="cursor-pointer  mr-[12px]"
            onClick={() => {
              setComments({ visible: true, id: record.id });
              getAllComments(record?.id);
            }}
          >
            {" "}
            <ListCommentIcon />
          </div>
          <PermissionsGate action="edit" module="vendor">
            <Link to={`/vendors/edit/${record.id}`}>
              <a className="underline flex items-center mr-[10px]">
                <EditPenIcon />
              </a>
            </Link>
          </PermissionsGate>
          <PermissionsGate action="delete" module="vendor">
            <div
              className="cursor-pointer"
              onClick={() => {
                confirm({
                  title: "Warning",
                  content: "Are you sure want to deactivate this Vendor?",
                  onOk() {
                    deleteVendor(record.id, (res) => {
                      getAllVendors();
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              <ListTrash />
            </div>
          </PermissionsGate>
        </div>
      ),
    },
  ];

  const removeAndReturnFilter = (filterName: string) => {
    let filter = { ...query };
    delete filter[filterName];
    return filter;
  };

  return (
    <div className="w-full h-full bg-white px-6">
      <CommentsDragger
        title="Vendor Comments"
        onClose={() => setComments({ visible: false, id: "" })}
        commentsObj={commentsObj}
        model="vendor"
      />
      <Drawer
        visible={filterDrawer}
        placement="right"
        onClose={() => setFiltersDrawer(false)}
        width="33%"
        title="Filters"
        className=""
      >
        <div className="mb-8">
          <div className="mb-5">
            {" "}
            <SingleSelect
              inputClassName="adjust_select_height text-[13px]"
              className=""
              label="Status"
              placeholder="Status"
              value={filtersGroup.status}
              options={Status}
              onChange={(newValue: any) => {
                setFiltersGroup({
                  ...filtersGroup,
                  status: newValue,
                });
              }}
            />
          </div>

          <div className="mb-5">
            <SingleSelect
              inputClassName="adjust_select_height text-[13px]"
              className=""
              label="Vendor Type"
              placeholder="Vendor Type"
              value={filtersGroup.type}
              options={VendorTypeOptions}
              onChange={(newValue: any) => {
                setFiltersGroup({
                  ...filtersGroup,
                  type: newValue,
                });
              }}
            />
          </div>
        </div>
        <div className="flex justify-end">
          {" "}
          <Button
            className="border mr-2  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
            type="button"
            onClick={() => {
              setFiltersGroup({
                status: "",
                type: "",
              });
              setExtraFilters({});
              updateSearchParams({ page: "1", perPage: "10" });
              setFiltersDrawer(false);
            }}
          >
            <span className="flex items-center text-[#FB6DB2]">Clear All</span>
          </Button>
          <Button
            type="submit"
            onClick={() => {
              setExtraFilters({
                ...extraFilters,
                status: filtersGroup.status,
                type: filtersGroup.type,
              });
              updateSearchParams(updateSearch());
              setFiltersDrawer(false);
            }}
            className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
          >
            Apply
          </Button>
        </div>
      </Drawer>
      <div className="flex justify-between items-center py-[24px]">
        <BreadCrumbs />
        <div>
          <div className="flex flex-row-reverse gap-4">
            <PermissionsGate action="create" module="vendor">
              <Link to={"/vendors/add"}>
                <Button className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center">
                  <span>
                    <PlusIcon />
                  </span>
                  Add Vendor
                </Button>
              </Link>
            </PermissionsGate>
          </div>
        </div>
      </div>
      {/* Title, Search, Filter */}
      <div className="card_style ">
        <ListHeader
          searchPlaceHolder="Search by All Fields"
          pagination={pagination}
        >
          <div className="w-[180px] mx-2">
            {" "}
            <SingleSelect
              className="max-w-[180px]"
              isClearable
              inputClassName="adjust_select_height"
              placeholder="Status"
              value={extraFilters["status"] ? extraFilters["status"] : null}
              options={Status}
              onChange={(newValue: any) => {
                updateSearchParams(
                  newValue
                    ? {
                        ...query,
                        status: newValue && newValue.value,
                      }
                    : removeAndReturnFilter("status")
                );
              }}
            />
          </div>

          <div className="w-[180px]">
            {" "}
            <SingleSelect
              className="max-w-[180px]"
              isClearable
              inputClassName="adjust_select_height"
              placeholder="Vendor Type"
              value={extraFilters["type"] ? extraFilters["type"] : null}
              options={VendorTypeOptions}
              onChange={(newValue: any) => {
                updateSearchParams(
                  newValue
                    ? {
                        ...query,
                        type: newValue && newValue.value,
                      }
                    : removeAndReturnFilter("type")
                );
              }}
            />
          </div>
          {hasFilter && (
            <div
              onClick={() => {
                setFiltersDrawer(true);
                setFiltersGroup({
                  type: extraFilters["type"] ? extraFilters["type"] : null,
                  status: extraFilters["status"]
                    ? extraFilters["status"]
                    : null,
                });
              }}
              className="flex ml-2 justify-center items-center rounded-[4px] cursor-pointer border w-[40px] h-[40px] border-[#f0f0f0]"
            >
              {" "}
              <FiltersIcon />
            </div>
          )}
        </ListHeader>
        {/* Table View */}
        <Table
          className="vendor_table"
          columns={columns}
          dataSource={allVendors}
          pagination={false}
        />
        <div className="mr-[24px] my-5">
          <Pagination
            pagination={pagination}
            onNext={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) + 1;
              updateSearchParams({ ...newQuery });
            }}
            onPrevious={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) - 1;
              updateSearchParams({ ...newQuery });
            }}
          />
        </div>
      </div>
    </div>
  );
};
