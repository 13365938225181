export const colorCode = (status: any) => {
  switch (status) {
    case "active":
      return "text-[#2ACB70]";
    case "deactivated":
      return "text-[#00AEEF]";
  }
};
export const getCurrentStatusFromKey = (key: string) =>
  MakeModelStatuses.find((x) => x.value === key);

export const MakeModelStatuses = [
  { value: "active", label: "Active" },
  { value: "deactivated", label: "Archieved" },
];
