import { Drawer } from "antd";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button } from "../../atoms/Button/Button";
import { FormikCreateSinglSelect } from "../../atoms/Select/CretableSelect";
import { FormikSinglSelect } from "../../atoms/Select/Select";
import { FormikText } from "../../atoms/Text/Text";
import { useProcurement } from "./context";

export interface AddFlippedProps {
  dragger?: boolean;
  setDragger?: any;
  data?: any;
}
export const AddFlipped: React.FC<AddFlippedProps> = ({
  dragger,
  setDragger,
  data,
}) => {
  const [initialValues, setInitialValues]: any = useState({
    purchaser: "",
    pricePerVehicle: "",
    sales: "",
    profit: "",
  });
  const {
    actions: { updateToFlipped, getOneDetail },
    state: { procurement },
  } = useProcurement();
  useEffect(() => {
    if (procurement) {
      setInitialValues({
        ...initialValues,
        purchaser: procurement.flipped?.purchaser,
        pricePerVehicle: procurement.flipped?.pricePerVehicle,
        sales: procurement.flipped?.sales,
        profit: procurement.flipped?.profit,
      });
    }
  }, [procurement]);
  useEffect(() => {
    if (!dragger) {
      setInitialValues({
        purchaser: "",
        pricePerVehicle: "",
        sales: "",
        profit: "",
      });
    }
  }, [dragger]);
  return (
    <>
      <Drawer
        visible={dragger}
        placement="right"
        onClose={() => {
          setDragger(false);
        }}
        width="33%"
        title="Flipped"
      >
        <div>
          <Formik
            // innerRef={formRef}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
              purchaser: Yup.string().required("Purchaser is required"),
              pricePerVehicle: Yup.string().required(
                "Price per vehicle is required"
              ),

              sales: Yup.mixed().required("Sales is required"),
              profit: Yup.mixed().required("Profit is required"),
            })}
            onSubmit={(values: any, actions) => {
              updateToFlipped(
                procurement.id,
                {
                  ...values,
                },
                () => {
                  setDragger(false);
                  getOneDetail(procurement.id);
                }
              );
            }}
          >
            {({ values, setFieldValue, setErrors }) => (
              <Form>
                {" "}
                <div className="mb-4">
                  <FormikText label="Purchaser" name="purchaser" must={true} />
                </div>
                <div className="mb-4">
                  <FormikText
                    label="Price per vehicle"
                    name="pricePerVehicle"
                    type="number"
                    must={true}
                  />
                </div>
                <div className="mb-4">
                  <FormikText
                    label="Total sales"
                    type="number"
                    name="sales"
                    must={true}
                  />
                </div>
                <div className="mb-4">
                  <FormikText
                    label="Total Profit"
                    type="number"
                    name="profit"
                    must={true}
                  />
                </div>
                <div className="mt-10">
                  <div className="flex flex-row-reverse gap-4">
                    {" "}
                    <Button
                      type="submit"
                      className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                    >
                      Save
                    </Button>
                    <Button
                      className="border  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                      type="reset"
                      onClick={() => {
                        setErrors({});
                        setDragger(false);
                      }}
                    >
                      <span className="flex items-center text-[#FB6DB2]">
                        Cancel
                      </span>
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Drawer>
    </>
  );
};
