import { useState } from "react";
import { TextProps } from "../Text/Text";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useField } from "formik";

export const Password: React.FC<TextProps> = ({
  label,
  error,
  inputClassName,
  ...props
}: TextProps) => {
  const [type, setType] = useState(props.type || "password");
  return (
    <div className="form-input-group w-full ">
      <div className="form-label mb-[12px] text-[14px] font-medium w-full">
        <p>{label}</p>
      </div>
      <div className="form-input relative">
        <input
          className={`${inputClassName && inputClassName} outline-none ${
            error ? "border-red-500" : "border-[#e9e9e9]"
          } border p-1.5 w-full rounded-[5px]`}
          type={type}
          {...props}
        />
        <div
          className="passwordShow cursor-pointer"
          onClick={() => setType(type === "password" ? "text" : "password")}
        >
          {type === "password" ? (
            <FiEye className="absolute top-1/3 right-3" />
          ) : (
            <FiEyeOff className="absolute top-1/3 right-3" />
          )}
        </div>
      </div>
      {error && (
        <div className="form-error text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export interface FormikPasswordGroupProps extends TextProps {
  name: string;
}

export const FormikPassword: React.FC<FormikPasswordGroupProps> = ({
  name,
  ...props
}: FormikPasswordGroupProps) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || "";
  return <Password {...field} {...props} error={error} />;
};
