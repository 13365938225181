import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { LeftArrow, RightArrow } from "../../../icons/Pagination";
import { useContract } from "../planning/context";
import {
  calculateWeek,
  CardColor,
  checkVehicleCount,
  daysInCalender,
  getWeeklyDateFormat,
  returnLabel,
} from "../ScheduleLoad";
import { EventSideBar } from "./EventSideBar";
export interface WeeklyCalendarProps {
  date?: any;
  totalWeeks?: any;
}
export const CalenderWeeklyView: React.FC<WeeklyCalendarProps> = ({
  date,
  totalWeeks,
}) => {
  const [currentWeek, setCurrentWeek] = useState(moment().week());
  const [showEvent, setShowEvent] = useState(false);
  const [selectedData, setSelectedData]: any = useState();
  const [startWeek, setStartWeek] = useState(
    moment().week(currentWeek).startOf("week").format("MMM DD")
  );
  const divItem: any = useRef(null);
  const [weeksOfMonth, setDropDownWeeks] = useState(calculateWeek(date));
  const [endWeek, setEndWeek] = useState(
    moment().week(currentWeek).endOf("week").format("MMM DD")
  );
  const [showDropDown, setDropDown] = useState(false);

  const {
    actions: { getAvailability, clearAvailability },
    state: { availableVehicle },
  } = useContract();
  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
  });

  const closeOpenMenus = (e: any) => {
    if (divItem && divItem.current && !divItem?.current.contains(e.target)) {
      setDropDown(false);
    }
  };
  const returnDateFormat = () => {
    // return moment(data.displayDate).format("YYYYMMDD");
  };

  useEffect(() => {
    setStartWeek(
      moment().week(currentWeek).startOf("week").format("MMM DD YYYY")
    );
    setEndWeek(moment().week(currentWeek).endOf("week").format("MMM DD YYYY"));
    getAvailability(
      moment().week(currentWeek).startOf("week").format("YYYY-MM-DD"),
      moment().week(currentWeek).endOf("week").format("YYYY-MM-DD")
    );
  }, [currentWeek]);

  return (
    <>
      <div className="flex justify-between overflow-hidden ease-in-out duration-500 ">
        <div
          className={`${
            showEvent ? "w-[74%]" : "w-full"
          } ease-in-out duration-500 `}
        >
          <div className="bg-[#F5F5F5] py-[16px] rounded-t-[5px] rounded-r-5 flex items-center justify-center">
            <div className="flex relative justify-between items-center">
              <div
                className="cursor-pointer mr-4"
                onClick={() => {
                  setCurrentWeek(currentWeek - 1);
                }}
              >
                {" "}
                <LeftArrow />
              </div>
              <div className="">
                <div
                  className="ml-4 cursor-pointer font-medium text-[#333333] text-[16px]"
                  onClick={() => setDropDown(true)}
                >
                  {startWeek} - {endWeek}
                </div>
                {showDropDown && (
                  <div
                    ref={divItem}
                    className="absolute top-[100%] left-0 overflow-y-scroll hide_scroll  border border-[#E9E9E9] rounded-[5px] h-[280px] w-[290px] bg-[white]"
                  >
                    {totalWeeks.map((item: any, i: any) => (
                      <div
                        className="flex px-[14px] items-center justify-between border-b last:border-b-0  border-[#F5F5F5] cursor-pointer py-[22px] text-[13px]"
                        key={i}
                      >
                        {" "}
                        <p className="font-semibold text-[13px] mr-2 text-[#212121] ">
                          Week {item.weekNumber}
                        </p>
                        <p
                          className="text-[13px] text-[#716D6D]"
                          onClick={() => {
                            setCurrentWeek(item.weekNumber);
                          }}
                        >
                          {item.start} - {item.end}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div
                className="ml-4 cursor-pointer"
                onClick={() => {
                  setCurrentWeek(currentWeek + 1);
                }}
              >
                {" "}
                <RightArrow />
              </div>
            </div>
          </div>
          <div className="border  border-[#F2F5F6]">
            {daysInCalender.map((item: any, i) => (
              <div className="flex border-b   border-[#F2F5F6]">
                <div className="py-[50px] w-[15%] px-[30px]  border-r border-l-0 border-b-0  border-[#F2F5F6]">
                  <div className="text-[#757575] font-medium">
                    {item} {getWeeklyDateFormat(currentWeek, i).displayDate}
                  </div>{" "}
                </div>
                <div className="p-10 w-full">
                  <div
                    className={`bg-[#FAF4FF] rounded-[12px] ${checkVehicleCount(
                      availableVehicle[
                        getWeeklyDateFormat(currentWeek, i).dateFormat
                      ]?.totalAvailable
                    )} w-full p-8`}
                    onClick={() => {}}
                  >
                    {availableVehicle &&
                      Object.keys(availableVehicle).length > 0 && (
                        <div
                          className={`text-[white] max-w-[120px] font-semibold text-[12px] cursor-pointer ${CardColor(
                            availableVehicle[
                              getWeeklyDateFormat(currentWeek, i).dateFormat
                            ]?.totalAvailable
                          )} px-[14px] py-[7px] rounded-[4px]`}
                          onClick={() => {
                            setShowEvent(true);
                            setSelectedData(
                              getWeeklyDateFormat(currentWeek, i).actualDate
                            );
                          }}
                        >
                          {
                            availableVehicle[
                              getWeeklyDateFormat(currentWeek, i).dateFormat
                            ]?.totalAvailable
                          }{" "}
                          vehicles
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className={`${
            showEvent ? "w-[25%]" : "w-0"
          } ease-in-out duration-500 `}
        >
          <EventSideBar
            date={selectedData}
            onClose={() => setShowEvent(false)}
          />
        </div>
      </div>
    </>
  );
};
