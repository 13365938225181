import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button } from "../../../atoms/Button/Button";
import { FormikDatePicker } from "../../../atoms/DatePicker/DatePicker";
import { FormikSinglSelect } from "../../../atoms/Select/Select";
import { useReports } from "../context";
import { ReportType, sendDateFormat } from "../ReportsLoad";
import * as Yup from "yup";
import { ReportsTable } from "./ReportsTable";
import moment from "moment";
import { ReportsOnRent } from "./ReportOnRent";
export interface ReportsProps {}
export const Reports: React.FC<ReportsProps> = () => {
  const [initialValues, setInitialValues] = useState({
    type: "",
    start: "",
    end: "",
  });
  const [showData, setShowData] = useState(false);
  const {
    actions: { getAllReports, getRentReports, clearOne },
    state: { documents },
  } = useReports();

  const formRef = useRef() as any;
  const handleSubmit = () => {
    formRef.current.handleSubmit();
  };
  const getCurrentReport = (value: any) => {};
  const checkType = (values: any) => {
    return values.type.value !== "on-rent";
  };
  useEffect(() => {}, [documents]);
  return (
    <>
      <div className="p-[24px]">
        <div className="card_default rounded-[5px] mb-10 p-[24px]">
          <div className="flex justify-between">
            <p className="text-[18px] font-semibold mb-5">Reports</p>
          </div>
          <Formik
            validationSchema={Yup.object({
              type: Yup.mixed().required("Report Type is required"),
              start: Yup.mixed().when("type", {
                is: (type: any) => type && type.value !== "on-rent",
                then: Yup.mixed().required("Start date is required"),
              }),
              end: Yup.mixed().when("type", {
                is: (type: any) => type && type.value !== "on-rent",
                then: Yup.mixed().required("End date is required"),
              }),
            })}
            onSubmit={(values: any) => {
              if (values.type.value === "on-rent") {
                getRentReports();
              } else {
                getAllReports({
                  type: values?.type?.value,
                  start: sendDateFormat(values?.start),
                  end: sendDateFormat(values?.end),
                });
              }
            }}
            initialValues={initialValues}
            innerRef={formRef}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="flex mb-[20px] items-center justify-between">
                  <div className="min-w-[360px]">
                    <FormikSinglSelect
                      label="Report Type"
                      name="type"
                      options={ReportType}
                      onChange={(e: any) => {
                        setFieldValue("type", e);
                        setShowData(false);
                        setFieldValue("start", "");
                        setFieldValue("end", "");
                        clearOne();
                      }}
                    />
                  </div>
                  {checkType(values) && (
                    <>
                      <div className="min-w-[360px]">
                        <FormikDatePicker
                          inputClassName="datepicket_styles"
                          label="Start Date"
                          onChange={(e: any) => {
                            setFieldValue("start", e);
                            setShowData(false);
                          }}
                          value={values.start}
                          name="start"
                        />
                      </div>
                      <div className="min-w-[360px]">
                        <FormikDatePicker
                          inputClassName="datepicket_styles"
                          label="End Date"
                          value={values.end}
                          onChange={(e: any) => {
                            setFieldValue("end", e);
                            setShowData(false);
                          }}
                          name="end"
                        />
                      </div>
                    </>
                  )}
                </div>{" "}
                <div className="flex mb-[20px] justify-end">
                  <Button
                    onClick={() => {
                      handleSubmit();
                      setShowData(true);
                    }}
                    className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                  >
                    View
                  </Button>
                </div>
                {showData && (
                  <div className="overflow-scroll">
                    {checkType(values) ? (
                      <ReportsTable
                        data={{
                          ...values,
                          start: sendDateFormat(values?.start),
                          end: sendDateFormat(values?.end),
                        }}
                      />
                    ) : (
                      <ReportsOnRent />
                    )}
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
