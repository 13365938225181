import { Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Table } from "../../../../../atoms/Table/Table";
import { ListTrash } from "../../../../../icons/home";
import { getVehicleTypeLabel } from "../../../../../options/VehicleLoan";
import { getCurrentStatusFromKey } from "../../../../../options/VehicleStatus";
import { colorCode } from "../../../../../utils";
import { useContract } from "../../context";

export interface AssignedVehicleListProps {
  onClose?: any;
  modelData?: any;
  visible: boolean;
}
export const AssignedVehicleList: React.FC<AssignedVehicleListProps> = ({
  onClose,
  visible,
  modelData,
}) => {
  const {
    actions: { getAllAssignedVehicles, deleteAssignedVehicle, getOneContract },
    state: { users, contacts, groups, contract },
  } = useContract();
  const { confirm } = Modal;
  const [data, setData]: any = useState([]);
  const { id }: any = useParams();
  useEffect(() => {
    if (Object.keys(contract).length > 0) getVehicles();
  }, [modelData]);

  const getVehicles = () => {
    getAllAssignedVehicles(
      `${contract?.id}/assign-vehicle/${modelData.id}`,
      (res) => {
        if (res.length === 0) onClose();
        setData(res);
      }
    );
  };
  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "vehicleName",
      render: (value: any, record: any) => (
        <>
          <div className="flex items-center">
            <Link
              to={`/vehicles/${record.id}`}
              className="underline font-medium text-[#398CC0]"
            >
              {record.vehicleName}
            </Link>
          </div>
        </>
      ),
    },
    {
      title: "License Plate",
      dataIndex: "licensePlate",
      render: (value, record: any) => record.licensePlate,
    },
    {
      title: "VIN",
      dataIndex: "vin",
      // sorter: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (value, record: any) => (
        <div className="capitalize">{record.vehicleType.name}</div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (value) => (
        <p className={`capitalize font-[600] ${colorCode(value)}`}>
          {getCurrentStatusFromKey(value)?.label}
        </p>
      ),
    },
    {
      title: "Action",
      // sorter: true,
      render: (value: any, record: any) => (
        <div className="flex  items-center">
          {contract.status !== "signed" && (
            <div
              className="cursor-pointer"
              onClick={() => {
                confirm({
                  title: "Warning",
                  content: "Are you sure want to delete this assigned vehicle?",
                  onOk() {
                    deleteAssignedVehicle(
                      `${contract.id}/assign-vehicle/${modelData.id}`,
                      { unAssignedVehicles: [`${record.id}`] },
                      (res) => {
                        getVehicles();
                        getOneContract(id);
                      }
                    );
                  },
                  onCancel() {},
                });
              }}
            >
              <ListTrash />
            </div>
          )}
        </div>
      ),
    },
  ];
  return (
    <>
      <Modal
        title="Assigned Vehicles"
        // header={false}
        width={"85%"}
        className="add_model"
        open={visible}
        footer={false}
        centered={true}
        onCancel={onClose}
      >
        {" "}
        <Table
          className="vehicle_table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </Modal>
    </>
  );
};
