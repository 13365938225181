import { returnContact, returnDashboard, returnGroup, returnInspection, returnIssue, returnService, returnUser, returnVehicle, returnVendor } from "../../icons/permissions";

export const returnModuleIconForPermission = (cModule: any) => {
    const { module, permission } = cModule;
    switch (module) {
        case "contact":
            return returnContact(permission)
        case "dashboard":
            return returnDashboard(permission)
        case "group":
            return returnGroup(permission)
        case "user":
        case "role":
            return returnUser(permission)
        case "inspection":
        case "inspection-template":
            return returnInspection(permission)
        case "issue":
            return returnIssue(permission)
        case "service":
            return returnService(permission)
        case "vendor":
            return returnVendor(permission)
        case "vehicle":
            return returnVehicle(permission)
    }
}

