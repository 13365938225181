import React from "react";
export const MenuBackIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "25"}
      height={props.height || "14"}
      fill="none"
      viewBox={`0 0 ${props.width || "25"} ${props.height || "14"}`}
    >
      <path
        fill={`${props.fill || "#7B7B7B"}`}
        d="M25 7.707v-2l-21.446.031 4.322-4.324L6.462 0 1.876 4.586a3 3 0 000 4.242l4.586 4.586L7.876 12 3.614 7.738 25 7.707z"
      ></path>
    </svg>
  );
};

export const MenuBackIconPrimary: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.17758 14.1148C7.03508 14.1148 6.89258 14.0623 6.78008 13.9498L2.22758 9.39734C2.01008 9.17984 2.01008 8.81984 2.22758 8.60234L6.78008 4.04984C6.99758 3.83234 7.35758 3.83234 7.57508 4.04984C7.79258 4.26734 7.79258 4.62734 7.57508 4.84484L3.42008 8.99984L7.57508 13.1548C7.79258 13.3723 7.79258 13.7323 7.57508 13.9498C7.47008 14.0623 7.32008 14.1148 7.17758 14.1148Z"
        fill="#FB6DB2"
      />
      <path
        d="M15.3749 9.5625H2.75244C2.44494 9.5625 2.18994 9.3075 2.18994 9C2.18994 8.6925 2.44494 8.4375 2.75244 8.4375H15.3749C15.6824 8.4375 15.9374 8.6925 15.9374 9C15.9374 9.3075 15.6824 9.5625 15.3749 9.5625Z"
        fill="#FB6DB2"
      />
    </svg>
  );
};
