import { Route, Switch } from "react-router";
import { ReportsProvider } from "./context";
import { Reports } from "./one/OneReports";

export interface ReporstMainLayoutProps {}
export const ReporstMainLayout: React.FC<ReporstMainLayoutProps> = () => {
  return (
    <>
      <ReportsProvider>
        <Switch>
          <Route path="/reports" component={Reports} />
        </Switch>
      </ReportsProvider>
    </>
  );
};
