import React from "react";
export const PlusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.8 5H5V4.8V1.2C5 0.647719 5.44772 0.2 6 0.2C6.55228 0.2 7 0.647719 7 1.2V4.8V5H7.2H10.8C11.3523 5 11.8 5.44772 11.8 6C11.8 6.55228 11.3523 7 10.8 7H7.2H7V7.2V10.8C7 11.3523 6.55228 11.8 6 11.8C5.44772 11.8 5 11.3523 5 10.8V7.2V7H4.8H1.2C0.647719 7 0.2 6.55228 0.2 6C0.2 5.44772 0.647719 5 1.2 5H4.8Z"
        fill="white"
        stroke="#FB6DB2"
        stroke-width="0.4"
      />
    </svg>
  );
};

export const PlusIconDropDown: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.8 5H5V4.8V1.2C5 0.647719 5.44772 0.2 6 0.2C6.55228 0.2 7 0.647719 7 1.2V4.8V5H7.2H10.8C11.3523 5 11.8 5.44772 11.8 6C11.8 6.55228 11.3523 7 10.8 7H7.2H7V7.2V10.8C7 11.3523 6.55228 11.8 6 11.8C5.44772 11.8 5 11.3523 5 10.8V7.2V7H4.8H1.2C0.647719 7 0.2 6.55228 0.2 6C0.2 5.44772 0.647719 5 1.2 5H4.8Z"
        fill="#BFBFBF"
        stroke="white"
        stroke-width="0.4"
      />
    </svg>
  );
};
export const PlusWithCircle: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 0C5.21442 0 3.95772 0.381218 2.8888 1.09545C1.81988 1.80968 0.986756 2.82484 0.494786 4.01256C0.00281636 5.20028 -0.125905 6.50721 0.124899 7.76809C0.375703 9.02896 0.994768 10.1872 1.90381 11.0962C2.81285 12.0052 3.97104 12.6243 5.23192 12.8751C6.49279 13.1259 7.79973 12.9972 8.98745 12.5052C10.1752 12.0132 11.1903 11.1801 11.9046 10.1112C12.6188 9.04229 13 7.78558 13 6.5C12.9981 4.77667 12.3127 3.12445 11.0941 1.90586C9.87556 0.687282 8.22334 0.00186392 6.5 0V0ZM6.5 11.9167C5.42869 11.9167 4.38143 11.599 3.49066 11.0038C2.5999 10.4086 1.90563 9.56264 1.49566 8.57287C1.08568 7.5831 0.978413 6.49399 1.18742 5.44326C1.39642 4.39253 1.91231 3.42737 2.66984 2.66984C3.42738 1.9123 4.39253 1.39642 5.44326 1.18741C6.49399 0.97841 7.58311 1.08568 8.57287 1.49565C9.56264 1.90563 10.4086 2.5999 11.0038 3.49066C11.599 4.38143 11.9167 5.42869 11.9167 6.5C11.9151 7.93611 11.3439 9.31294 10.3284 10.3284C9.31294 11.3439 7.93611 11.9151 6.5 11.9167ZM9.20834 6.5C9.20834 6.64366 9.15127 6.78144 9.04969 6.88302C8.9481 6.9846 8.81033 7.04167 8.66667 7.04167H7.04167V8.66667C7.04167 8.81033 6.9846 8.9481 6.88302 9.04968C6.78144 9.15127 6.64366 9.20833 6.5 9.20833C6.35634 9.20833 6.21857 9.15127 6.11699 9.04968C6.01541 8.9481 5.95834 8.81033 5.95834 8.66667V7.04167H4.33334C4.18968 7.04167 4.0519 6.9846 3.95032 6.88302C3.84874 6.78144 3.79167 6.64366 3.79167 6.5C3.79167 6.35634 3.84874 6.21857 3.95032 6.11698C4.0519 6.0154 4.18968 5.95833 4.33334 5.95833H5.95834V4.33333C5.95834 4.18967 6.01541 4.0519 6.11699 3.95032C6.21857 3.84874 6.35634 3.79167 6.5 3.79167C6.64366 3.79167 6.78144 3.84874 6.88302 3.95032C6.9846 4.0519 7.04167 4.18967 7.04167 4.33333V5.95833H8.66667C8.81033 5.95833 8.9481 6.0154 9.04969 6.11698C9.15127 6.21857 9.20834 6.35634 9.20834 6.5Z"
        fill="#FB6DB2"
      />
    </svg>
  );
};
