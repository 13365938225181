export const LeftArrow: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.67633 14L7.5 13.1763L1.73608 7.41238C1.62673 7.30299 1.56529 7.15465 1.56529 6.99997C1.56529 6.84529 1.62673 6.69694 1.73608 6.58755L7.49008 0.834721L6.66525 0.0098877L0.912418 5.76272C0.584343 6.09089 0.400041 6.53593 0.400041 6.99997C0.400041 7.46401 0.584343 7.90904 0.912418 8.23722L6.67633 14Z"
        fill={"#212121"}
      />
    </svg>
  );
};
export const InactiveLeftArrow: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.67633 14L7.5 13.1763L1.73608 7.41238C1.62673 7.30299 1.56529 7.15465 1.56529 6.99997C1.56529 6.84529 1.62673 6.69694 1.73608 6.58755L7.49008 0.834721L6.66525 0.0098877L0.912418 5.76272C0.584343 6.09089 0.400041 6.53593 0.400041 6.99997C0.400041 7.46401 0.584343 7.90904 0.912418 8.23722L6.67633 14Z"
        fill={"#a9b2b5"}
      />
    </svg>
  );
};
export const RightArrow: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.32367 14L0.5 13.1763L6.26392 7.41238C6.37327 7.30299 6.43471 7.15465 6.43471 6.99997C6.43471 6.84529 6.37327 6.69694 6.26392 6.58755L0.509917 0.834721L1.33475 0.0098877L7.08758 5.76272C7.41566 6.09089 7.59996 6.53593 7.59996 6.99997C7.59996 7.46401 7.41566 7.90904 7.08758 8.23722L1.32367 14Z"
        fill="#212121"
      />
    </svg>
  );
};
export const InactiveRightArrow: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.32367 14L0.5 13.1763L6.26392 7.41238C6.37327 7.30299 6.43471 7.15465 6.43471 6.99997C6.43471 6.84529 6.37327 6.69694 6.26392 6.58755L0.509917 0.834721L1.33475 0.0098877L7.08758 5.76272C7.41566 6.09089 7.59996 6.53593 7.59996 6.99997C7.59996 7.46401 7.41566 7.90904 7.08758 8.23722L1.32367 14Z"
        fill={"#a9b2b5"}
      />
    </svg>
  );
};

export const FiltersIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2006 11.7338C16.4466 11.7338 16.6461 11.5343 16.6461 11.2884C16.6461 11.0423 16.4466 10.8429 16.2006 10.8429H10.5212C10.2753 10.8429 10.0758 11.0424 10.0758 11.2884C10.0758 11.5343 10.2753 11.7338 10.5212 11.7338H16.2006Z"
        fill="#212121"
        stroke="#212121"
        stroke-width="0.2"
      />
      <path
        d="M9.15101 9.47691H9.151C8.15184 9.47691 7.33945 10.2894 7.33945 11.2885C7.33945 12.2876 8.15207 13.1 9.151 13.1C10.1502 13.1 10.9625 12.2874 10.9625 11.2885C10.9625 10.2896 10.15 9.47703 9.15101 9.47691ZM9.15097 12.2091C8.64339 12.2091 8.2303 11.796 8.2303 11.2885C8.2303 10.7809 8.64339 10.3678 9.15097 10.3678C9.65855 10.3678 10.0716 10.7809 10.0716 11.2885C10.0716 11.796 9.65855 12.2091 9.15097 12.2091Z"
        fill="#212121"
        stroke="#212121"
        stroke-width="0.2"
      />
      <path
        d="M7.7862 11.7338C7.99775 11.7338 8.17494 11.5862 8.22034 11.3884H8.23179V11.2884C8.23179 11.0424 8.03257 10.8429 7.78647 10.8429H1.34542C1.09949 10.8429 0.9 11.0424 0.9 11.2884C0.9 11.5343 1.09948 11.7338 1.34542 11.7338H7.7862Z"
        fill="#212121"
        stroke="#212121"
        stroke-width="0.2"
      />
      <path
        d="M16.1977 7.57998C16.4437 7.57998 16.6431 7.3805 16.6431 7.13456C16.6431 6.88862 16.4437 6.68913 16.1977 6.68913L5.82199 6.68913C5.57605 6.68913 5.37656 6.88862 5.37656 7.13456C5.37656 7.38049 5.57604 7.57998 5.82199 7.57998L16.1977 7.57998Z"
        fill="#212121"
        stroke="#212121"
        stroke-width="0.2"
      />
      <path
        d="M4.45764 8.9457C5.4568 8.9457 6.26918 8.13308 6.26918 7.13416C6.26918 6.13512 5.4568 5.32261 4.45764 5.32261C3.45848 5.32261 2.64609 6.13512 2.64609 7.13416C2.64609 8.13332 3.4586 8.9457 4.45764 8.9457ZM4.45794 8.05483C3.95036 8.05483 3.53727 7.64174 3.53727 7.13416C3.53727 6.62657 3.95026 6.21349 4.45794 6.21349C4.96552 6.21349 5.37861 6.62658 5.37861 7.13416C5.37861 7.64174 4.96552 8.05483 4.45794 8.05483Z"
        fill="#212121"
        stroke="#212121"
        stroke-width="0.2"
      />
      <path
        d="M3.09025 7.57998C3.33618 7.57998 3.53567 7.3805 3.53567 7.13456C3.53567 6.8886 3.33606 6.68913 3.09025 6.68913H1.34542C1.09949 6.68913 0.9 6.88862 0.9 7.13456C0.9 7.38049 1.09948 7.57998 1.34542 7.57998H3.09025Z"
        fill="#212121"
        stroke="#212121"
        stroke-width="0.2"
      />
      <path
        d="M10.9057 3.15713C11.1172 3.15713 11.2944 3.00952 11.3398 2.81171H11.3513V2.71171C11.3513 2.46581 11.152 2.26628 10.906 2.26628L1.34542 2.26628C1.09949 2.26628 0.9 2.46577 0.9 2.71171C0.9 2.95764 1.09948 3.15713 1.34542 3.15713L10.9057 3.15713Z"
        fill="#212121"
        stroke="#212121"
        stroke-width="0.2"
      />
      <path
        d="M12.2699 4.52285C13.2691 4.52285 14.0815 3.71047 14.0815 2.71153C14.0815 1.71248 13.2688 0.899985 12.2699 0.899985C11.271 0.899985 10.4586 1.7125 10.4586 2.71153C10.4586 3.71046 11.271 4.52285 12.2699 4.52285ZM12.2697 3.63201C11.7621 3.63201 11.3492 3.21911 11.3492 2.71153C11.3492 2.20392 11.7621 1.79086 12.2697 1.79086C12.7773 1.79086 13.1904 2.20392 13.1904 2.71153C13.1904 3.21911 12.7773 3.63201 12.2697 3.63201Z"
        fill="#212121"
        stroke="#212121"
        stroke-width="0.2"
      />
      <path
        d="M16.1983 3.15713C16.4443 3.15713 16.6437 2.95765 16.6437 2.71171C16.6437 2.46577 16.4443 2.26628 16.1983 2.26628H13.6384C13.3925 2.26628 13.193 2.46577 13.193 2.71171C13.193 2.95764 13.3924 3.15713 13.6384 3.15713H16.1983Z"
        fill="#212121"
        stroke="#212121"
        stroke-width="0.2"
      />
    </svg>
  );
};
