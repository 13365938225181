import { MoreButtonIcon } from "../../icons/MoreIcon";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const Button: React.FC<ButtonProps> = ({ ...props }: ButtonProps) => {
  return <button {...props} />;
};
export interface MoreButtonProps {}

export const MoreButton: React.FC<MoreButtonProps> = ({
  ...props
}: MoreButtonProps) => {
  return (
    <>
      {/* <div className="w-[42px] p-[11px] h-[42px] border border-[#EDEDED] flex items-center justify-center rounded-[4px]">
        <MoreButtonIcon {...props} />
      </div> */}
    </>
  );
};
export interface SecondaryButtonProps {
  text?: String;
}
export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  text,
  ...props
}) => {
  return (
    <button className="" {...props}>
      {text}
    </button>
  );
};
