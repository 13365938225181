export const DiselIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width,
  height,
}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="7" transform="matrix(1 0 0 -1 7 7)" fill="#00AEEF" />
    </svg>
  );
};

export const ElectricIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width,
  height,
}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="7" transform="matrix(1 0 0 -1 7 7)" fill="#3BA936" />
    </svg>
  );
};
export const FlexFuelIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width,
  height,
}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="7" transform="matrix(1 0 0 -1 7 7)" fill="#98fb98" />
    </svg>
  );
};
export const GasolineIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width,
  height,
}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="7" transform="matrix(1 0 0 -1 7 7)" fill="#F25138" />
    </svg>
  );
};
