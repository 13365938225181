import Contact_Admin from './Contact-Admin.svg';
import Contact_Read from './Contact-Read.svg';

import Dashboard_Admin from './Dashboard-Admin.svg';
import Dashboard_Read from './Dashboard-Read.svg';

import Group_Admin from './Group-Admin.svg';
import Group_Read from './Group-Read.svg';


import Inspection_Admin from './Inspection-Admin.svg';
import Inspection_Read from './Inspection-Read.svg';

import Issue_Admin from './Issue-Admin.svg';
import Issue_Read from './Issue-Read.svg';


import User_Admin from './User-Admin.svg';
import User_Read from './User-Read.svg';
import Vendor_Admin from './Vendor-Admin.svg';
import Vendor_Read from './Vendor-Read.svg';




import Service_Admin from './Service-Admin.svg';
import Service_Read from './Service-Read.svg';



import Vehicle_Admin from './Vehicle-Admin.svg';
import Vehicle_Read from './Vehicle-Read.svg';
export const returnContact = (permission: string[]) => {
    const type = permission.find(x => x.startsWith('create'));
    if (type && type.split(":")[1] === "any") {
        return Contact_Admin;
    } else {
        return Contact_Read;
    }
}

export const returnDashboard = (permission: string[]) => {
    const type = permission.find(x => x.startsWith('create'));
    if (type && type.split(":")[1] === "any") {
        return Dashboard_Admin;
    } else {
        return Dashboard_Read;
    }
}


export const returnGroup = (permission: string[]) => {
    const type = permission.find(x => x.startsWith('create'));
    if (type && type.split(":")[1] === "any") {
        return Group_Admin;
    } else {
        return Group_Read;
    }
}


export const returnIssue = (permission: string[]) => {
    const type = permission.find(x => x.startsWith('create'));
    if (type && type.split(":")[1] === "any") {
        return Issue_Admin;
    } else {
        return Issue_Read;
    }
}

export const returnInspection = (permission: string[]) => {
    const type = permission.find(x => x.startsWith('create'));
    if (type && type.split(":")[1] === "any") {
        return Inspection_Admin;
    } else {
        return Inspection_Read;
    }
}
export const returnService = (permission: string[]) => {
    const type = permission.find(x => x.startsWith('create'));
    if (type && type.split(":")[1] === "any") {
        return Service_Admin;
    } else {
        return Service_Read;
    }
}


export const returnUser = (permission: string[]) => {
    const type = permission.find(x => x.startsWith('create'));
    if (type && type.split(":")[1] === "any") {
        return User_Admin;
    } else {
        return User_Read;
    }
}


export const returnVehicle = (permission: string[]) => {
    const type = permission.find(x => x.startsWith('create'));
    if (type && type.split(":")[1] === "any") {
        return Vehicle_Admin;
    } else {
        return Vehicle_Read;
    }
}


export const returnVendor = (permission: string[]) => {
    const type = permission.find(x => x.startsWith('create'));
    if (type && type.split(":")[1] === "any") {
        return Vendor_Admin;
    } else {
        return Vendor_Read;
    }
}