import React from "react";
export const MoreIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="3"
      height="15"
      viewBox="0 0 3 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 13.5C0 12.6716 0.671573 12 1.5 12C2.32843 12 3 12.6716 3 13.5C3 14.3284 2.32843 15 1.5 15C0.671573 15 0 14.3284 0 13.5Z"
        fill="#212121"
      />
      <path
        d="M0 7.5C0 6.67157 0.671573 6 1.5 6C2.32843 6 3 6.67157 3 7.5C3 8.32843 2.32843 9 1.5 9C0.671573 9 0 8.32843 0 7.5Z"
        fill="#212121"
      />
      <path
        d="M0 1.49999C0 0.671555 0.671573 -2.28882e-05 1.5 -2.28882e-05C2.32843 -2.28882e-05 3 0.671555 3 1.49999C3 2.32842 2.32843 3 1.5 3C0.671573 3 0 2.32842 0 1.49999Z"
        fill="#212121"
      />
    </svg>
  );
};
export const MoreButtonIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="20"
      height="4"
      viewBox="0 0 20 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 3.99999C3.10457 3.99999 4 3.10456 4 2C4 0.895429 3.10457 0 2 0C0.89543 0 0 0.895429 0 2C0 3.10456 0.89543 3.99999 2 3.99999Z"
        fill="#212121"
      />
      <path
        d="M10 3.99999C11.1046 3.99999 12 3.10456 12 2C12 0.895429 11.1046 0 10 0C8.89543 0 8 0.895429 8 2C8 3.10456 8.89543 3.99999 10 3.99999Z"
        fill="#212121"
      />
      <path
        d="M18 3.99999C19.1046 3.99999 20 3.10456 20 2C20 0.895429 19.1046 0 18 0C16.8954 0 16 0.895429 16 2C16 3.10456 16.8954 3.99999 18 3.99999Z"
        fill="#212121"
      />
    </svg>
  );
};
export const DropdownArrow: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.47329 0.472589C9.41132 0.410104 9.33758 0.360507 9.25634 0.326662C9.1751 0.292816 9.08797 0.275391 8.99996 0.275391C8.91195 0.275391 8.82481 0.292816 8.74358 0.326662C8.66234 0.360507 8.5886 0.410104 8.52663 0.472589L5.47329 3.52592C5.41132 3.5884 5.33758 3.638 5.25634 3.67184C5.1751 3.70569 5.08797 3.72311 4.99996 3.72311C4.91195 3.72311 4.82481 3.70569 4.74357 3.67184C4.66234 3.638 4.5886 3.5884 4.52663 3.52592L1.47329 0.472589C1.41132 0.410104 1.33758 0.360507 1.25634 0.326662C1.1751 0.292816 1.08797 0.275391 0.999959 0.275391C0.911951 0.275391 0.824814 0.292816 0.743574 0.326662C0.662335 0.360507 0.588601 0.410104 0.526625 0.472589C0.402458 0.597497 0.332764 0.766465 0.332764 0.942588C0.332764 1.11871 0.402458 1.28768 0.526625 1.41259L3.58663 4.47258C3.96163 4.84712 4.46996 5.05749 4.99996 5.05749C5.52996 5.05749 6.03829 4.84712 6.41329 4.47258L9.47329 1.41259C9.59746 1.28768 9.66716 1.11871 9.66716 0.942588C9.66716 0.766465 9.59746 0.597497 9.47329 0.472589V0.472589Z"
        fill="white"
      />
    </svg>
  );
};
