import { Form, Formik } from "formik";
import { Button } from "../../atoms/Button/Button";
import { Password } from "../../atoms/Password/Password";
import { Text, FormikText } from "../../atoms/Text/Text";
import * as Yup from "yup";
import { message, Checkbox } from "antd";
import { useHistory } from "react-router";
import { useAuth } from "./context";
export const ForgotPassword: React.FC<{}> = () => {

  const {
    actions: { ForgetPassword },
  } = useAuth();

  return (
    <div
      className="flex justify-center  p-10 rounded-sm flex-col w-full"
      style={{ minWidth: 350 }}
    >
      <div>
        <Formik
          onSubmit={(values, actions) => {
            const { email } = values;
            ForgetPassword(values);
          }}
          validationSchema={Yup.object({
            email: Yup.string().required("Email is required"),
          })}
          initialValues={{ email: "" }}
        >
          {({ values, setFieldValue, setErrors, resetForm }) => (
            <>
              {" "}
              <div className="">
                <p className="mb-4 font-medium text-[16px]">
                  Forgot Your Password??
                </p>
              </div>
              <Form>
                <div>
                  <FormikText label="Email" name="email" required />
                </div>
                <div className="submit-container mt-7">
                  <Button
                    type="submit"
                    className="bg-primary text-white px-10 py-3 font-bold text-[14px] w-full rounded-[5px]"
                  >
                    Send Link
                  </Button>
                </div>
              </Form>
              <h4 className="mt-5 text-center">
                <span>
                  <a
                    href="/login"
                    className="no-underline color-primary font-semibold text-[14px]"
                  >
                    Back to Login
                  </a>
                </span>
              </h4>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};
