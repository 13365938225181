import react from "react";
import { Link } from "react-router-dom";
import { BreadCrumbs } from "../../../atoms/BreadCrumb/BreadCrumb";
import { Button, MoreButton } from "../../../atoms/Button/Button";
import { PlusIcon } from "../../../icons";
import { ActiveEditPen } from "../../../icons/edit-pen";
import { Comments } from "../../../atoms/Comments/Comments";
export interface IssueDetailProps {}
export const IssueDetail: React.FC<IssueDetailProps> = () => {
  return (
    <>
      <div className=" bg-white px-6">
        <div className="flex justify-between items-center py-[24px]">
          <BreadCrumbs />
          <div>
            <div className="flex items-center flex-row-reverse gap-4">
              {" "}
              <Link to={`/vehicles/add-vehicle`}>
                {" "}
                <Button className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center">
                  <span>
                    <PlusIcon />
                  </span>
                  Add
                </Button>
              </Link>
              <Link to={``}>
                {" "}
                <button className="text-[#FB6DB2] items-center flex text-[13px] font-semibold border border-[#FB6DB2] px-[16px] rounded-[4px] py-[10px]">
                  <div className="mr-2">
                    {" "}
                    <ActiveEditPen />
                  </div>{" "}
                  Edit
                </button>
              </Link>
              <MoreButton />
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="w-[43%]">
            <div className="common_card w-full pt-[24px] mb-[24px]">
              {" "}
              <div className="flex justify-between items-center mb-[20px]  px-[24px]">
                <h2 className="font-medium text-[18px] ">Details</h2>
                <p className="font-semibold text-[13px] cursor-pointer text-[#FB6DB2]">
                  View Timeline
                </p>
              </div>
              <div className="plain_detail">
                {" "}
                <div className="V_detail_header">Issue #</div>
                <div className="V_detail_data">270 </div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Status</div>
                <div className="V_detail_data">Open</div>
              </div>
              <div className="plain_detail">
                {" "}
                <div className="V_detail_header">Summary</div>
                <div className="V_detail_data">
                  Fuel System, Gasoline:Delivery Hoses, Lines/Piping, And
                  Fittings{" "}
                </div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Description</div>
                <div className="V_detail_data">
                  MBZ Website Shows This Recall For Our Van: Dealers will
                  inspect and repair the fuel hose connections, and replace the
                  clamps and fuel hose, as necessary, free of charge. Owner
                  notification letters{" "}
                </div>
              </div>
              <div className="plain_detail">
                {" "}
                <div className="V_detail_header">Vehicle</div>
                <div className="V_detail_data">1354 </div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Reported Date</div>
                <div className="V_detail_data">
                  <div className="flex items-center">
                    <div className="w-[44px] mr-2  h-[44px] bg-[#5F6F82] rounded-[50%] flex justify-center items-center ">
                      <p className="text-[15px] font-medium text-[white]">VJ</p>
                    </div>
                    <span>Jennifer Marracq </span>
                  </div>
                </div>
              </div>
              <div className="plain_detail">
                {" "}
                <div className="V_detail_header">Source</div>
                <div className="V_detail_data flex items-center">
                  <p className="mr-2"> Recall #32235323 </p>
                  <div className="w-[44px] h-[44px] bg-[#5F6F82] rounded-[50%] flex justify-center items-center mr-4">
                    <p className="text-[15px] font-medium text-[white]">VJ</p>
                  </div>
                </div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Due Date </div>
                <div className="V_detail_data">-</div>
              </div>
              <div className="plain_detail">
                {" "}
                <div className="V_detail_header">Due Odometer </div>
                <div className="V_detail_data">270 </div>
              </div>
            </div>
          </div>
          <div className="common_card  w-[56%] h-[620px]">
            <div className="flex justify-between items-center px-[24px] py-[25px] bg-[#FAFAFA]">
              <h2 className="text-[16px] font-medium">Comments</h2>{" "}
            </div>
            <Comments />
          </div>
        </div>
      </div>
    </>
  );
};
