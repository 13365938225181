export const Status = [
    {
        value: "active",
        label: "Active",
    },
    {
        value: "deactivated",
        label: "Deactivated",
    }
];

export const getStatusFromKey = (key: string) =>
    Status.find((x) => x.value === key);
