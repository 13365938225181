import { Switch, Route, Redirect } from "react-router";
import { AllIssues } from "./all/AllIssues";
import { IssueDetail } from "./one/IssueDetails";
import { OneIssues } from "./one/OneIssues";
export interface IssuesMainLayoutProps {}

export const IssuesMainLayout: React.FC<IssuesMainLayoutProps> = (
  props: IssuesMainLayoutProps
) => {
  return (
    <Switch>
      <Route exact path="/issues" component={AllIssues} />
      <Route exact path="/issues/add" component={OneIssues} />
      <Route exact path="/issues/:id" component={IssueDetail} />
      <Redirect to="/issues?page=1&perPage=10" />
    </Switch>
  );
};
