import { useField } from "formik";
import Select, { Props as RSProps, components } from "react-select";
import "./select.scss";
export interface SelectProps extends RSProps {
  label?: string;
  error?: string;
  inputClassName?: string;
  selectClassName?: string;
  must?: boolean;
  isDisabled?: any;
}

export const SingleSelect: React.FC<SelectProps> = ({
  label,
  error,
  inputClassName,
  selectClassName,
  must,
  isDisabled,
  ...props
}: SelectProps) => {
  return (
    <div className="form-input-group w-full">
      {label && (
        <div className="form-label mb-3 flex font-medium w-full">
          <p>{label}</p>
          {must && <span className=" ml-[2px] text-[#FB6DB2]"> *</span>}
        </div>
      )}
      <div className={` ${inputClassName && `${inputClassName}`} form-input`}>
        <Select
          classNamePrefix={"select"}
          isDisabled={isDisabled}
          className={`${
            selectClassName && `${selectClassName}`
          } text-[13px] rounded-[5px] ${
            error ? "border-red-500 border" : "border-[#F0F0F0] "
          }`}
          {...props}
        />
      </div>
      {error && (
        <div className="form-error text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export interface FormikSelectProps extends SelectProps {
  name: string;
  readOnly?: boolean;
}

export const FormikSinglSelect: React.FC<FormikSelectProps> = ({
  name,
  selectClassName,
  readOnly,
  onChange,
  ...props
}: FormikSelectProps) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || "";
  return (
    <SingleSelect
      {...props}
      {...field}
      isDisabled={readOnly}
      error={error}
      onChange={onChange}
    />
  );
};

export const SelectWithIcon: React.FC<FormikSelectProps> = ({
  name,
  selectClassName,
  isDisabled,
  onChange,
  ...props
}: FormikSelectProps) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || "";
  const { Option } = components;
  const IconOption = (props: any) => (
    <>
      {" "}
      <Option {...props}>
        <div className="flex items-center">
          <p className="mr-[15px]"> {props.data.icon} </p>
          {props.data.label}
        </div>
      </Option>
    </>
  );

  return (
    <SingleSelect
      {...props}
      {...field}
      error={error}
      isDisabled={isDisabled}
      components={{ Option: IconOption }}
      onChange={onChange}
    ></SingleSelect>
  );
};
