import { Drawer } from "antd";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "../../../../atoms/Button/Button";
import { FormikText } from "../../../../atoms/Text/Text";
import { SaveIcon } from "../../../../icons/SaveIcon";
import * as Yup from "yup";
import { useMake } from "../context";
export interface AddMakeProps {
  makeDragger: any;
  onClose?: any;
}

export const AddMake: React.FC<AddMakeProps> = ({ makeDragger, onClose }) => {
  const {
    state: { make },
    actions: { createOne, updateOne, getAllMake },
  } = useMake();
  const [initialValues, setInitialValues]: any = useState({
    name: "",
  });

  useEffect(() => {
    if (!makeDragger.isNew && Object.keys(make).length > 0) {
      setInitialValues(make);
    } else {
      setInitialValues({ name: "" });
    }
  }, [makeDragger, make]);

  return (
    <>
      <Drawer
        visible={makeDragger.visible}
        placement="right"
        onClose={onClose}
        width="33%"
        title={makeDragger.isNew ? "Add Make" : "Edit Make"}
        className=""
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Make is required"),
          })}
          onSubmit={(values) => {
            if (makeDragger.isNew) {
              //create
              createOne({ ...values, type: "make" }, () => {
                onClose();
              });
            } else {
              //update
              updateOne(make.id, { ...values, type: "make" }, () => {
                getAllMake(false, { type: "make" });
                onClose();
              });
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Form className="" id="my-form">
              <FormikText label="Make" name="name" />
              <div className="flex justify-end mt-8">
                {" "}
                <Button
                  className="border mr-2  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                  type="button"
                  onClick={onClose}
                >
                  <span className="flex items-center text-[#FB6DB2]">
                    Cancel
                  </span>
                </Button>
                <Button
                  type="submit"
                  onClick={() => {}}
                  className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                >
                  <span>
                    <SaveIcon />
                  </span>
                  Save Make
                </Button>{" "}
              </div>
            </Form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};
