import { Form, Formik } from "formik";
import { Button } from "../../atoms/Button/Button";
import { FormikPassword, Password } from "../../atoms/Password/Password";
import { ReadOnlyText, Text } from "../../atoms/Text/Text";
import * as Yup from "yup";
import CookieStorage from "../../services/storage/cookie.storage";
import { message, Checkbox } from "antd";
import { useAuth } from "./context";
import { useParams } from "react-router";
import { useEffect, useState } from "react";

export const RegisterLogin: React.FC<{}> = () => {
  const [initialValues, setinitialValues]: any = useState({
    fistName: "",
    lastName: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const { invitationCode }: any = useParams();
  const {
    actions: { getUserByInvite, InviteLogin },
  } = useAuth();
  useEffect(() => {
    getUserByInvite(invitationCode, (res) => {
      setinitialValues({
        ...initialValues,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
      });
    });
  }, []);

  return (
    <div
      className="flex justify-center  p-10 rounded-sm flex-col"
      style={{ maxWidth: 660, minWidth: 440 }}
    >
      <div className="mb-5">
        <p className="font-medium text-[16px]">Register</p>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          let data = {
            inviteCode: invitationCode,
            password: values.password,
          };
          InviteLogin(data, (res) => {
            CookieStorage.write("token", res.token);
            localStorage.setItem("user", JSON.stringify(res?.user));
            localStorage.setItem(
              "permission",
              JSON.stringify(res?.user?.role?.modules)
            );
            window.location.reload();
          });
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .min(8, "Password is too Short")
            .max(20, "Password is too Large")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              "Enter at least one uppercase letter, one lowercase letter, one number and one special character"
            )
            .required("Password is required"),
          confirm_password: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required"),
        })}
      >
        {({ values }) => (
          <>
            {" "}
            <Form>
              {" "}
              <div>
                <div className="mb-[22px]">
                  <div className="flex justify-between items-center mb-4">
                    {" "}
                    <div className="mr-4">
                      {" "}
                      <ReadOnlyText
                        label="First Name"
                        name="firstName"
                        value={initialValues.firstName}
                      />
                    </div>
                    <ReadOnlyText
                      label="Last Name"
                      value={initialValues.lastName}
                    />
                  </div>
                  <div className="mb-5">
                    <ReadOnlyText
                      label="Email"
                      name="email"
                      value={initialValues.email}
                    />
                  </div>
                  <div className="flex  justify-between items-center mb-4 ">
                    {" "}
                    <div className="min-w-[280px] mr-4">
                      {" "}
                      <FormikPassword
                        label="New Password"
                        name="password"
                        required
                        inputClassName="adjust_select_height"
                      />
                    </div>
                    <div className="min-w-[280px]">
                      <FormikPassword
                        label="Confirm New password"
                        name="confirm_password"
                        inputClassName="adjust_select_height"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="submit-container flex items-center flex-col justify-center max-w-[200px] m-auto   mt-10">
                <Button
                  type="submit"
                  className="bg-primary text-white px-10 py-3 font-bold text-[14px]  w-full rounded-[5px]"
                >
                  Register
                </Button>
                <h4 className="font-normal mt-7 text-[#7B7B7B] text-center text-[12px]">
                  Go back to login?{" "}
                  <span>
                    <a
                      href="/login"
                      className="no-underline color-primary font-nromal "
                    >
                      Click here
                    </a>
                  </span>
                </h4>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
