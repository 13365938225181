import { Redirect, Route, Switch } from "react-router";
import { AllPlanning } from "./all/AllPlanning";
import { OnePlanning } from "./one/onePlanning";

export interface ScheduleMainLayoutProps {}
export const PlanningMainLayout: React.FC<ScheduleMainLayoutProps> = () => {
  return (
    <>
      <Switch>
        <Route exact path="/schedule/planning/add" component={OnePlanning} />
        <Route
          exact
          path="/schedule/planning/edit/:id"
          component={OnePlanning}
        />
        <Route exact path="/schedule/planning" component={AllPlanning} />
        {/* <Redirect to="/schedule/planning" /> */}
      </Switch>
    </>
  );
};
