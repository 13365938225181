import { Space, Table as AntTable, Tag } from "antd";
import { TableProps } from "antd/lib/table";
export type ITableProps<T> = TableProps<T>;

export const Table: React.FC<ITableProps<any>>= (props) => {
  return (
    <div>
      <AntTable {...props} />
    </div>
  );
};
