import { Switch, Route, Redirect } from "react-router";
import { ContactMainLayout } from "./contacts/ContactsMainLayout";
import { DashboardMailLayout } from "./dashboards/dashboardMainLayout";
import { GroupMainLayout } from "./groups/GroupsLayout";
import { InspectionMainLayout } from "./inspections/InspectionMainLayout";
import { IssuesMainLayout } from "./issues/IssuesMainLayout";
import { VehicleMainLayout } from "./vehicles/VehiclesMainLayout";
import { VendorMainLayout } from "./vendors/VendorsLayout";
import { ServiceMainLayout } from "./remainders/ServiceMainLayout";
import { UsersMainLayout } from "./user-management/UsersLayout";
import { ProcurementMainLayout } from "./procurement/ProcurementMainLayout";
import { MasterMainLayout } from "./masterSettings/MasterMainLayout";
import { ServiceHistoryLayout } from "./servicesHistory/ServiceHistoryLayout";
import { ScheduleMainLayout } from "./schedule/ScheduleMainLayout";
import { ReporstMainLayout } from "./reports/ReportsMainLayout";

export interface MainLayoutProps {}

export const MainLayout: React.FC<MainLayoutProps> = (
  props: MainLayoutProps
) => {
  return (
    <Switch>
      <Route path="/master" component={MasterMainLayout} />

      <Route exact path="/dashboard" component={DashboardMailLayout} />
      <Route path="/vehicles" component={VehicleMainLayout} />
      <Route path="/inspections" component={InspectionMainLayout} />
      <Route path="/inspections-forms" component={InspectionMainLayout} />
      {/* <Route path="/service-remainder" component={ServiceMainLayout} /> */}
      {/* <Route path="/vehicle-remainder" component={ServiceMainLayout} /> */}
      <Route path="/services" component={ServiceHistoryLayout} />
      <Route path="/vendors" component={VendorMainLayout} />
      <Route path="/clients" component={GroupMainLayout} />
      <Route path="/contact" component={ContactMainLayout} />
      <Route path="/issues" component={IssuesMainLayout} />
      <Route path="/user-management" component={UsersMainLayout} />
      <Route path="/procurement" component={ProcurementMainLayout} />
      <Route path="/schedule" component={ScheduleMainLayout} />
      <Route path="/reports" component={ReporstMainLayout} />
      {/* <Route
          exact
          path="/verify/:type"
          render={(props) => {
            const { search } = props.location;
            if (search.length) {
              return (
                <VerifyLink
                // {...props}
                />
              );
            }
            return <Redirect to="/page-not-found" />;
          }}
        />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password/:token" component={ResetPassword} /> */}
      <Redirect to="/dashboard" />
    </Switch>
  );
};
