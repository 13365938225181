import { AfterSaleInformations } from "./sub-sections/LoanInformation";
import { PurchaseInformations } from "./sub-sections/PurchaseDetail";
import { SellingInformations } from "./sub-sections/Warranty";

interface FinancialInformationProps {
  existingData?: any;
  id: string;
  isNew: boolean;
  formRef: any;
}

export const FinancialInformations: React.FC<FinancialInformationProps> = ({
  isNew,
  existingData,
  id,
  formRef,
}: FinancialInformationProps) => {
  return (
    <div className="card_default rounded-[5px] mb-10 p-[24px]">
      {" "}
      <PurchaseInformations
        id={id}
        initialData={existingData}
        formRef={formRef}
      />
      {/* <SellingInformations
        id={id}
        initialData={existingData}
        formRef={formRef}
      />
      <AfterSaleInformations
        id={id}
        initialData={existingData}
        formRef={formRef}
      /> */}
    </div>
  );
};
