import { useReducer } from "react";
import { container } from "../services/storage/context.container";
import { CookieStorage } from "../services/storage/cookie.storage";
import { LocalStorage } from "../services/storage/localstorage.storage";


const ApiRoutes = {
    adminLogin: '/admins/login',
    localLogin: '/auth/local/login',
    changePassword: '/auth/local/change-password'
}


export type StateType = {
    hasSession: boolean,
};

const initialState: StateType = {
    hasSession: false
};

const reducer = (state: StateType, action: {
    type: string;
    payload?: any;
}) => {
    const { type, payload } = action;
    switch (type) {
        default:
            return state;
    }
}

export const {
    useContext: useAuth,
    Provider: AuthProvider,
} = container(() => {
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        hasSession: !!CookieStorage.read('token')
    });

    return {
        state,
        actions: {
            logout: () => { dispatch({ type: "LOGOUT" }) }
        },
    };
})