import { useReducer } from "react";
import { container } from "../../../services/storage/context.container";
import { APIService } from "../../../services/api/request";
import { message } from "antd";
import { buildQueryString } from "../../../services/utils/url";
import {
  destroyMessage,
  showMessage,
  UpdatedSuccessfully,
} from "../../../utils";

const ApiRoutes = {
  base: `/makes`,
};

export type StateType = {
  minimalDocuments: any;
  documents: any;
  pagination: any;
  subModel: any;
  parentMakeDetails: any;
  make: any;
};
const initialState: StateType = {
  documents: [],
  parentMakeDetails: [],
  subModel: [],
  pagination: {},
  make: {},
  minimalDocuments: [],
};

const reducer = (
  state: StateType,
  action: {
    type: String;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_MININAL_MAKES":
      return {
        ...state,
        minimalDocuments: payload.map((x: any) => ({
          value: x.id,
          label: x.name,
        })),
      };
    case "GET_MAKES":
      return { ...state, ...payload };
    case "GET_PARENT_MAKES":
      return { ...state, parentMakeDetails: payload.documents };
    case "GET_ONE_MAKE":
      return { ...state, make: { ...payload } };
  }
};
export const { useContext: useMake, Provider: MakeProvider } = container(() => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });
  const getAllMake = (
    mininal?: boolean,
    params?: any,
    callback?: (data: any) => void
  ) => {
    let isParent = false;
    showMessage();
    if (params) {
      if (!params.parent) {
        params.parent = null;
      }
      if (params.parent) {
        isParent = true;
      } else isParent = false;
      params = buildQueryString(params);
    }
    APIService.get(
      mininal
        ? `${ApiRoutes.base}?all=true&filter[type]=make`
        : `${ApiRoutes.base}${params ? `${params}` : ""}`,
      undefined,
      process.env.REACT_APP_API_ENDPOINT
    )
      .then((res: any) => {
        destroyMessage("loading");
        if (mininal) {
          dispatch({ type: "GET_MININAL_MAKES", payload: res.data });
        } else {
          isParent
            ? dispatch({ type: "GET_PARENT_MAKES", payload: res.data })
            : dispatch({ type: "GET_MAKES", payload: res.data });
        }
        if (typeof callback === "function") {
          callback(res);
        }
      })
      .catch((error: any) => {
        if (typeof error === "object" && error.status) {
          message.error(error.message);
        }
      });
  };

  const getOneMake = (id: string, callback?: (data: any) => void) => {
    showMessage();
    APIService.get(
      `${ApiRoutes.base}/${id}`,

      undefined,
      process.env.REACT_APP_API_ENDPOINT
    )
      .then((response: any) => {
        destroyMessage("loading");
        dispatch({ type: "GET_ONE_MAKE", payload: response.data });
      })
      .catch((error: any) => {
        if (typeof error === "object" && error.status) {
          message.error(error.message);
        }
      });
  };
  const createOne = (data: any, callback?: (data: any) => void) => {
    APIService.post(
      `${ApiRoutes.base}`,
      data,
      process.env.REACT_APP_API_ENDPOINT
    )
      .then((res: any) => {
        message.success("Created Successfully!!!");
        // history.goBack();
        getAllMake(false, { type: "make" }, (response: any) => {});
        if (typeof callback === "function") {
          callback({});
        }
      })
      .catch((error: any) => {
        if (typeof error === "object" && error.status) {
          message.error(error.message);
        } else {
          message.error("Group Creation Failed");
        }
      });
  };
  const updateOne = (id: string, data: any, callback?: (data: any) => void) => {
    APIService.put(
      `${ApiRoutes.base}/${id}`,
      data,
      process.env.REACT_APP_API_ENDPOINT
    )
      .then((res: any) => {
        UpdatedSuccessfully();

        callback && callback({});
      })
      .catch((error: any) => {
        if (typeof error === "object" && error.status) {
          message.error(error.message);
        }
      });
  };
  const deleteOne = (
    id: string,
    callback?: (response: any) => void,
    parentId?: any
  ) => {
    APIService.delete(
      `${ApiRoutes.base}/${id}`,
      {},
      process.env.REACT_APP_API_ENDPOINT
    )
      .then((response: any) => {
        message.success("Deleted Successfully!!!");
        parentId && getAllMake(false, { parent: parentId });

        callback && callback(response);
      })
      .catch((error: any) => {
        if (typeof error === "object" && error.status) {
          message.error(error.message);
        }
      });
  };
  // const getMake = (callback?: (data: any) => void) => {
  //   APIService.get(
  //     "/makes?all=true",
  //     undefined,
  //     process.env.REACT_APP_API_ENDPOINT
  //   )
  //     .then((response) => {
  //       callback && callback(response);
  //     })
  //     .catch((error) => {
  //       if (typeof error === "object" && error.status) {
  //         message.error(error.message);
  //       }
  //     });
  // };

  return {
    state,
    actions: {
      getAllMake,
      getOneMake,
      createOne,
      updateOne,
      deleteOne,
      clearOne: () => dispatch({ type: "CLEAR_ONE_GROUP", payload: {} }),
    },
  };
});
