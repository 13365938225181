import { Avatar, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect } from "react";
import { Pagination } from "../../atoms/Pagination/pagination";
import { PermissionsGate } from "../../atoms/PermissionComponent/PermissionGate";
import { Table } from "../../atoms/Table/Table";
import { useSearchParams } from "../../hooks/useSearchParams";
import { ListTrash } from "../../icons/home";
import { ListHeader } from "../../molecules/list-header/ListHeader";
import { RandomColor } from "../../services/utils/colorcode";
import { useUsers } from "./context";
const { confirm } = Modal;
export interface AllUserInvitesProps {}

export const AllUserInvites: React.FC<AllUserInvitesProps> = () => {
  const { query, updateSearchParams }: any = useSearchParams();

  const {
    actions: { getAllInvitees, revokeInvite },
    state: {
      invites: { documents, pagination },
    },
  } = useUsers();

  useEffect(() => {
    getAllInvitees(false, { page: 1, perPage: 10, status: "invited" });
  }, []);

  useEffect(() => {
    if (Object.keys(query).length !== 0) {
      getAllInvitees(false, query);
    } else {
      updateSearchParams({ page: 1, perPage: 10 });
    }
  }, [query]);

  const columns: ColumnsType = [
    {
      title: "User",
      dataIndex: "user",
      render: (value, record: any, index) => (
        <div className="flex items-center">
          <Avatar
            style={{ backgroundColor: `#${RandomColor()}` }}
          >{`${record?.firstName[0]}${record?.lastName[0]}`}</Avatar>
          <p className="ml-2 font-semibold text-[13px]">
            {`${record?.firstName} ${record?.lastName}`}
          </p>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (value, record: any) => (
        <div className="font-medium">{value}</div>
      ),
    },
    {
      title: "Role",
      dataIndex: "vehicleName",
      render: (value, record: any) => (
        <div className="font-semibold">{record.role?.name}</div>
      ),
    },
    {
      title: "Invited on",
      dataIndex: "createdAt",
      // sorter: true,
      render: (value, record: any) => (
        <div>
          {moment(value).format("YYYY/MM/DD")}{" "}
          <span className="text-[#767676]">
            {" "}
            at {moment(value).format("hh:mm a")}
          </span>
        </div>
      ),
    },
    {
      title: "Action",
      // sorter: true,
      render: (value, record: any) => (
        <PermissionsGate action="delete" module="user">
          <div className="flex  items-center">
            <div
              className="cursor-pointer"
              onClick={() => {
                confirm({
                  title: "Warning",
                  content: "Are you sure want to revoke the invite?",
                  onOk() {
                    revokeInvite(record.id, () => {
                      getAllInvitees(false, {
                        page: 1,
                        perPage: 10,
                        status: "invited",
                      });
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              <ListTrash />
            </div>
          </div>
        </PermissionsGate>
      ),
    },
  ];

  return (
    <>
      <div className="card_style ">
        <ListHeader
          onNewClicked={() => {}}
          onFilterClicked={() => {}}
          searchPlaceHolder="Search by Role Name"
          pagination={pagination}
        ></ListHeader>
        <Table
          className="vehicle_table"
          columns={columns}
          dataSource={documents}
          pagination={false}
        />
        <div className="mr-[24px] my-5">
          <Pagination
            pagination={pagination}
            onNext={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) + 1;
              updateSearchParams({ ...newQuery });
            }}
            onPrevious={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) - 1;
              updateSearchParams({ ...newQuery });
            }}
          />
        </div>
      </div>
    </>
  );
};
