import { message } from "antd";
import { useReducer } from "react";
import { APIService } from "../services/api/request";
import { container } from "../services/storage/context.container";
import { destroyMessage, showMessage, UpdatedSuccessfully } from "../utils";

const ApiRoutes = {
  base: `/services-history`,
};

export type StateType = {
  minimalDocuments: any;
  documents: any;
  status: any;
  groups: any;
  pagination: any;
  history: any;
  allVehicles: any;
  allVendors: any;
};

const initialState: StateType = {
  documents: [],
  groups: [],
  pagination: {},
  history: {},
  status: [],
  minimalDocuments: [],
  allVehicles: {},
  allVendors: {},
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_MININAL_COMMENTS":
      return {
        ...state,
        minimalDocuments: payload.map((x: any) => ({
          value: x.id,
          label: x.vehicleName,
        })),
      };
    case "GET_COMMENTS":
      return { ...state, documents: [...payload] };
    case "GET_GROUPS":
      return { ...state, groups: [...payload] };
    case "GET_ONE_HISTORY":
      return { ...state, history: { ...payload } };
    case "GET_VEHICLES":
      return { ...state, allVehicles: { ...payload } };
    case "GET_VENDORS":
      return { ...state, allVendors: { ...payload } };
    case "GET_ALL_STATUS":
      return {
        ...state,
        status: payload.map((x: any) => ({ value: x.id, label: x.name })),
      };
    default:
      return state;
  }
};

export const { useContext: useCommonContext, Provider: CommonProvider } =
  container(() => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });
    const getAllStatus = () => {
      showMessage();
      APIService.get(
        "/vehicles/info/vehicle-status",
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      ).then((response: any) => {
        destroyMessage("loading");
        dispatch({ type: "GET_ALL_STATUS", payload: response.data });
      });
    };
    const getAllComments = (id: any, callback?: (response: any) => void) => {
      showMessage();
      APIService.get(
        `/comments?filter[referenceId]=${id}&all=true`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          destroyMessage("loading");
          dispatch({ type: "GET_COMMENTS", payload: response.data });
          callback && callback(response.data);
        })
        .catch((err: any) => {});
    };
    const createComment = async (
      data: any,
      id: any,
      callback?: (response: any) => void
    ) => {
      APIService.post(
        `/comments/${id}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          callback && callback(response);
          getAllComments(id);
          message.success("Created Successfully!!!");
          // dispatch({ type: "GET_COMMENTS", payload: response.data });
        })
        .catch((err: any) => {});
    };

    const updateOne = (
      data: any,
      parentId: any,
      id: string,
      callback?: (data: any) => void
    ) => {
      APIService.put(
        `/comments/${id}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          UpdatedSuccessfully();
          callback && callback(res);
          getAllComments(parentId);
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };
    const deleteComment = (
      id: string,
      parentId: any,
      callback?: (response: any) => void
    ) => {
      APIService.delete(
        `/comments/${id}`,
        {},
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          getAllComments(parentId);
          callback && callback(response);
        })
        .catch((err: any) => {});
    };
    const emptyState = () => {
      dispatch({ type: "GET_COMMENTS", payload: [] });
    };
    return {
      state,
      actions: {
        getAllComments,
        createComment,
        updateOne,
        deleteComment,
        getAllStatus,
        emptyState,
      },
    };
  });
