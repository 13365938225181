import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import * as QS from "query-string";
import { parseQueryStrings } from "../services/utils/url";
export const useSearchParams = () => {
  const { search, pathname, hash } = useLocation();
  const history = useHistory();
  const [query, setQueryStrings] = useState({});
  useEffect(() => {
    setQueryStrings(parseQueryStrings(search));
  }, [search]);
  const updateSearchParams = (query: any) => {
    for (let key in query) {
      if (query[key] === "") {
        delete query[key];
      }
    }
    history.push({
      pathname: pathname,
      search: QS.stringify(query),
      hash: hash,
    });
  };
  return {
    query,
    updateSearchParams,
    hash,
  };
};
