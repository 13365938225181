import { Drawer, Empty, Tabs } from "antd";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BreadCrumbs } from "../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../atoms/Button/Button";
import { FormikSinglSelect } from "../../atoms/Select/Select";
import { FormikText } from "../../atoms/Text/Text";
import { PlusIcon } from "../../icons";
import { AllUserInvites } from "./AllUserInvites";
import { AllUsersListing } from "./AllUsersListing";
import { AllUsersRole } from "./AllUsersRole";
import { useUsers } from "./context";
import * as Yup from "yup";
import { PermissionsGate } from "../../atoms/PermissionComponent/PermissionGate";
import { isPremitted } from "../../services/utils/permission";

export interface AllUsersLayoutProps {}
const { TabPane } = Tabs;
export const AllUsersLayout: React.FC<AllUsersLayoutProps> = () => {
  const [currentTab, setCurrentTab] = useState("users");
  const [inviteDragger, setInviteDragger] = useState(false);
  const history = useHistory();
  const { hash }: any = useLocation();
  const {
    actions: { getAllRoles, createOneUser },
    state: {
      roles: { minimalDocuments: roles },
    },
  } = useUsers();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });

  useEffect(() => {
    setCurrentTab(hash || "#users");
  }, [hash]);

  useEffect(() => {
    getAllRoles(true);
  }, []);

  const formRef = useRef() as any;
  return (
    <>
      <div className="w-full h-full bg-white px-6">
        <div className="flex justify-between items-center pt-[24px] h-[50px]">
          <BreadCrumbs />
          <div>
            {currentTab === "#invites" && (
              <div className="flex flex-row-reverse gap-4">
                {" "}
                <PermissionsGate action="create" module="user">
                  <Button
                    onClick={() => setInviteDragger(true)}
                    className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                  >
                    <span>
                      <PlusIcon />
                    </span>
                    {"Add Invite"}
                  </Button>
                </PermissionsGate>
              </div>
            )}
          </div>
        </div>

        <Tabs
          className="inner_overview_tab issues_tab  py-[24px]"
          activeKey={currentTab}
          onChange={(activeKey) => {
            setCurrentTab(activeKey);
            history.push(`?page=1&perPage=10${activeKey}`, {
              hash: activeKey,
            });
          }}
        >
          <TabPane tab="Users" key="#users">
            {hash === "#users" && <AllUsersListing />}
          </TabPane>
          
          <TabPane tab="Invites" key="#invites">
            {hash === "#invites" && <AllUserInvites />}
          </TabPane>

          <TabPane
            tab={!isPremitted("role", "list") ? "" : "Roles"}
            key="#roles"
            disabled={!isPremitted("role", "list")}
          >
            {hash === "#roles" && <AllUsersRole />}
          </TabPane>
        </Tabs>
      </div>
      {inviteDragger && (
        <Drawer
          visible={inviteDragger}
          placement="right"
          onClose={() => {
            setInviteDragger(false);
          }}
          width="33%"
          title="Send Invite"
        >
          <div>
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={Yup.object({
                firstName: Yup.string().required("First Name is required"),
                lastName: Yup.string().required("Last Name is required"),
                email: Yup.string()
                  .required("Email is required")
                  .email("Enter a valid email"),
                role: Yup.mixed().required("Role is required"),
              })}
              onSubmit={(values: any, actions) => {
                createOneUser({ ...values, role: values.role.value }, () => {
                  setInviteDragger(false);
                });
              }}
            >
              {({ values, setFieldValue, setErrors }) => (
                <Form>
                  {" "}
                  <div className="mb-4">
                    <FormikText
                      label="First Name"
                      name="firstName"
                      must={true}
                    />
                  </div>
                  <div className="mb-4">
                    <FormikText label="Last Name" name="lastName" must={true} />
                  </div>
                  <div className="mb-4">
                    <FormikText label="Email" name="email" must={true} />
                  </div>
                  <FormikSinglSelect
                    isClearable
                    options={roles}
                    label="Role"
                    name="role"
                    inputClassName="adjust_select_height"
                    onChange={(newValue) => {
                      setFieldValue("role", newValue);
                    }}
                  />
                  <div className="mt-10">
                    <div className="flex flex-row-reverse gap-4">
                      {" "}
                      <Button
                        type="submit"
                        className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                      >
                        <span></span>
                        Send Invite
                      </Button>
                      <Button
                        className="border  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                        type="reset"
                        onClick={() => {
                          setErrors({});
                          setInviteDragger(false);
                        }}
                      >
                        <span className="flex items-center text-[#FB6DB2]">
                          Cancel
                        </span>
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Drawer>
      )}
    </>
  );
};
