import { Switch, Route, Redirect } from "react-router";
import { VehiclesProvider } from "../vehicles/context";
import { AllInspectionPage } from "./all/AllInspection";
import { AllFormsPage } from "./forms/AllForms";
import { InspectionsProvider } from "./InspectionContext";
import { OneInspectionPage } from "./one/OneInspection";
import { FormTemplateProvider } from "./TemplateContext";

export interface InspectionMainLayoutProps {}

export const InspectionMainLayout: React.FC<InspectionMainLayoutProps> = (
  props: InspectionMainLayoutProps
) => {
  return (
    <VehiclesProvider>
      <FormTemplateProvider>
        <InspectionsProvider>
          <Switch>
            <Route
              exact
              path="/inspections/all"
              component={AllInspectionPage}
            />
            <Route exact path="/inspections-forms/all" component={AllFormsPage} />
            <Route
              exact
              path="/inspections/:inspectionId"
              component={OneInspectionPage}
            />
            <Redirect to="/inspections/all" />
          </Switch>
        </InspectionsProvider>
      </FormTemplateProvider>
    </VehiclesProvider>
  );
};
