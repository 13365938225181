export const OKDue: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2754_5950)">
        <path
          d="M6 0.1875C2.79375 0.1875 0.1875 2.79375 0.1875 6C0.1875 9.20625 2.79375 11.8125 6 11.8125C9.20625 11.8125 11.8125 9.20625 11.8125 6C11.8125 2.79375 9.20625 0.1875 6 0.1875Z"
          fill="#3BA936"
        />
        <path
          d="M5.36413 8.24999C5.25111 8.25008 5.13918 8.22846 5.03475 8.18636C4.93033 8.14426 4.83544 8.08252 4.75554 8.00466L3.1292 6.45606C3.0477 6.37833 3.00123 6.27226 3.00002 6.16116C2.99882 6.05006 3.04296 5.94305 3.12275 5.86366C3.20255 5.78427 3.31145 5.73901 3.4255 5.73783C3.53955 5.73665 3.64941 5.77966 3.73091 5.85738L5.36112 7.40933L8.28629 4.60435C8.36878 4.53365 8.47594 4.49641 8.58574 4.50027C8.69555 4.50414 8.79966 4.54883 8.87669 4.62515C8.95372 4.70147 8.99782 4.80363 8.99992 4.91064C9.00203 5.01764 8.96198 5.12137 8.888 5.20051L5.96842 8.00843C5.88895 8.08542 5.79467 8.14639 5.69097 8.18784C5.58728 8.22929 5.47621 8.25041 5.36413 8.24999Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2754_5950">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const DueSoon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2759_5916)">
        <path
          d="M12 0.375C5.58751 0.375 0.375 5.58751 0.375 12C0.375 18.4125 5.58751 23.625 12 23.625C18.4125 23.625 23.625 18.4125 23.625 12C23.625 5.58751 18.4125 0.375 12 0.375ZM13.125 17.7525C13.125 18.375 12.6225 18.8775 12 18.8775C11.3775 18.8775 10.875 18.375 10.875 17.7525V10.5825C10.875 9.95997 11.3775 9.45749 12 9.45749C12.6225 9.45749 13.125 9.95997 13.125 10.5825V17.7525ZM13.125 6.5175C13.125 7.13997 12.6225 7.6425 12 7.6425C11.3775 7.6425 10.875 7.13997 10.875 6.5175V6.24747C10.875 5.625 11.3775 5.12247 12 5.12247C12.6225 5.12247 13.125 5.625 13.125 6.24747V6.5175Z"
          fill="#F1A309"
        />
      </g>
      <defs>
        <clipPath id="clip0_2759_5916">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
