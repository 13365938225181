import MyMapComponent from "../../../../../atoms/GoogleMaps/Maps";
import DummyMap from "../../../../../icons/designIcons/DummyMap.svg";
interface VehicleDocumentsProps {
  initialData?: any;
}
export const VehicleMap: React.FC<VehicleDocumentsProps> = ({
  initialData,
}) => {
  return (
    <>
      {initialData?.integrationType ? (
        <MyMapComponent initialData={initialData} />
      ) : (
        <div className="w-full h-[800px] relative flex justify-center items-center ">
          <p className="z-50 text-[16px] font-semibold text-[white] common_transform_translate left-[50%] absolute top-[50%]">
            No Matching Integration are Found
          </p>
          <div className="absolute opacity-50 bg-black w-full h-full rounded-[4px] "></div>
          <img
            className="w-full h-full  object-cover rounded-[4px]"
            src={DummyMap}
          />
        </div>
      )}
    </>
  );
};
