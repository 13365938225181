import { Button } from "antd";
import React from "react";
import { useSearchParams } from "../../hooks/useSearchParams";
import {
  InactiveLeftArrow,
  InactiveRightArrow,
  LeftArrow,
  RightArrow,
} from "../../icons/Pagination";
import { perPage } from "../../utils";
import { SingleSelect } from "../Select/Select";
export interface PaginationProps {
  pagination?: any;
  onNext?: any;
  onPrevious?: any;
  limit?: number;
}

const getCurrentFromTo = (
  {
    currentPage,
    total,
    totalPages,
    perPage,
  }: {
    currentPage: number;
    total: number;
    totalPages: number;
    perPage: number;
  },
  limit: number
) => {
  if (currentPage > 1 && currentPage === totalPages) {
    return `${(currentPage - 1) * perPage + 1} - ${total}`;
  } else if (currentPage === 1 && currentPage === totalPages) {
    return `1 - ${total}`;
  } else if (currentPage === 1) {
    return `1 - ${perPage}`;
  } else if (currentPage > 1 && currentPage < totalPages) {
    return `${(currentPage - 1) * perPage + 1} - ${currentPage * perPage}`;
  }
};
export const Pagination: React.FC<PaginationProps> = ({
  pagination,
  onNext,
  onPrevious,
  limit = 10,
}: PaginationProps) => {
  const { query, updateSearchParams }: any = useSearchParams();
  return (
    <>
      <div className="flex  justify-end items-center ">
        <div className="flex items-center ">
          <p className="text-[13px] font-medium mr-2">Per Page</p>
          <div className="w-[80px]">
            <SingleSelect
              className="max-w-[180px]"
              inputClassName="adjust_select_height"
              options={perPage}
              value={
                query["perPage"]
                  ? { value: query["perPage"], label: query["perPage"] }
                  : { value: query["10"], label: query["10"] }
              }
              onChange={(newValue: any) => {
                updateSearchParams({ ...query, perPage: newValue.value });
              }}
            />
          </div>
        </div>
        <p className=" text-[13px] font-[400] mx-[18px]">
          {/* {pagination?.currentPage} of {pagination?.totalPages} */}
          {getCurrentFromTo(pagination, limit)} of{" "}
          <span>{pagination.total}</span>
        </p>
        <Button
          disabled={pagination?.currentPage === 1}
          className={`h-[40px] pagination_button border-[#f0f0f0] flex items-center rounded-l-[4px] rounded-r-none rounded-b-none ${
            pagination?.currentPage === 1
              ? "cursor-not-allowed"
              : "text-[#FB6DB2]"
          }`}
          onClick={onPrevious}
        >
          {pagination?.currentPage === 1 ? (
            <InactiveLeftArrow />
          ) : (
            <LeftArrow />
          )}
        </Button>
        <Button
          onClick={onNext}
          className={`h-[40px] pagination_button border-[#f0f0f0]  rounded-l-none border-l-[0] rounded-r-[4px] ${
            pagination?.totalPages === 1 ||
            pagination?.totalPages === pagination?.currentPage
              ? "cursor-not-allowed"
              : "text-[#FB6DB2]"
          }`}
          disabled={
            pagination?.totalPages === 1 ||
            pagination?.totalPages === pagination?.currentPage
          }
        >
          {pagination?.totalPages === 1 ||
          pagination?.totalPages === pagination?.currentPage ? (
            <InactiveRightArrow />
          ) : (
            <RightArrow />
          )}
        </Button>
      </div>
    </>
  );
};
