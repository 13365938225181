import { Switch, Route, Redirect } from "react-router";
import { AllVehiclePage } from "./all/AllVehicles";
import { VehiclesProvider } from "./context";
import { AllVehicleFinancialPage } from "./financial/AllVehicleFinancial";
import { VehicleDetails } from "./one/VehicleDetails";
import { AddVehicle } from "./one/sections/sub-sections/AddVehicle";
import "../../templates/vehicles.scss";
import { VehicleEdit } from "./one/sections/sub-sections/VehicleEdit";
import { isPremitted } from "../../services/utils/permission";
import { HistoryProvider } from "../servicesHistory/context";
import { ServiceHistory } from "../servicesHistory/all/AllHistoryListing";
import { ServiceHistoryLayout } from "../servicesHistory/ServiceHistoryLayout";
import { ServiceRemainder } from "../remainders/serviceRemainders/ServiceRemainder";
export interface VehicleMainLayoutProps {}
export const VehicleMainLayout: React.FC<VehicleMainLayoutProps> = (
  props: VehicleMainLayoutProps
) => {
  return (
    <>
      <HistoryProvider>
        <VehiclesProvider>
          <Switch>
            <Route
              exact
              path="/vehicles"
              render={() => {
                if (isPremitted("vehicle", "list")) {
                  return <AllVehiclePage />;
                } else {
                  return <Redirect to="/forbidden" />;
                }
              }}
            />
            <Route
            
              path="/vehicles/add-vehicle"
              render={() => {
                if (isPremitted("vehicle", "create")) {
                  return <AddVehicle />;
                } else {
                  return <Redirect to="/forbidden" />;
                }
              }}
            />
            <Route
              exact
              path="/vehicles/:id"
              render={() => {
                if (isPremitted("vehicle", "list")) {
                  return <VehicleDetails />;
                } else {
                  return <Redirect to="/forbidden" />;
                }
              }}
            />
            <Route
              exact
              path="/vehicles/edit/:id"
              render={() => {
                if (isPremitted("vehicle", "edit")) {
                  return <VehicleEdit />;
                } else {
                  return <Redirect to="/forbidden" />;
                }
              }}
            />
            <Route
              exact
              path="/vehicles/financial"
              render={() => {
                if (isPremitted("vehicle", "list")) {
                  return <AllVehicleFinancialPage />;
                } else {
                  return <Redirect to="/forbidden" />;
                }
              }}
            />

            <Redirect to="/page-not-found" />
          </Switch>{" "}
        </VehiclesProvider>
      </HistoryProvider>
    </>
  );
};
