import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
export interface SpinnerProps {
  CustomClassName?: any;
}
export const Spinner: React.FC<SpinnerProps> = ({ CustomClassName }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  return (
    <>
      <Spin className={CustomClassName} indicator={antIcon} />
    </>
  );
};
