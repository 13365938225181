import { Empty, Tabs } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { BreadCrumbs } from "../../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../../atoms/Button/Button";
import { PlusIcon } from "../../../icons";
import { ListHeader } from "../../../molecules/list-header/ListHeader";
import { AllIssueListing } from "./AllIssueListing";

export interface AllIssuesProps {}
const { TabPane } = Tabs;
export const AllIssues: React.FC<AllIssuesProps> = () => {
  const [currentTab, setCurrentTab] = useState("all");
  const history = useHistory();
  const { hash } = useLocation();
  useEffect(() => {
    setCurrentTab(hash || "#all");
  }, [hash]);

  return (
    <>
      {" "}
      <div className="w-full h-full bg-white px-6">
        <div className="flex justify-between items-center py-[24px]">
          <BreadCrumbs />
          <div>
            <div className="flex flex-row-reverse gap-4">
              <Link to={`/issues/add`}>
                {" "}
                <Button className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center">
                  <span>
                    <PlusIcon />
                  </span>
                  Add Issue
                </Button>
              </Link>
            </div>
          </div>
        </div>

        {/* <Tabs
          className="inner_overview_tab issues_tab"
          activeKey={currentTab}
          onChange={(activeKey) => {
            setCurrentTab(activeKey);
            history.push({
              hash: activeKey,
            });
          }}
        >
          <TabPane tab="All" key="#all">
            <AllIssueListing />
          </TabPane>
          <TabPane tab="Open" key="#open">
            {" "}
            <AllIssueListing />
          </TabPane>
          <TabPane tab="Overdue" key="#overdue">
            {" "}
            <AllIssueListing />
          </TabPane>
          <TabPane tab="Resolved" key="#resolved">
            {" "}
            <AllIssueListing />
          </TabPane>
          <TabPane tab="Closed" key="#closed">
            {" "}
            <AllIssueListing />
          </TabPane>
        </Tabs> */}
        <div className="card_style ">
          {" "}
          <AllIssueListing />
        </div>
      </div>
    </>
  );
};
