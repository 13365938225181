import { Drawer, Empty, Modal, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import react, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { BreadCrumbs } from "../../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../../atoms/Button/Button";
import { CommentsDragger } from "../../../atoms/CommentsDragger/Comments";
import { Pagination } from "../../../atoms/Pagination/pagination";
import { PermissionsGate } from "../../../atoms/PermissionComponent/PermissionGate";
import { Table } from "../../../atoms/Table/Table";
import { useCommonContext } from "../../../hooks/commonStateProvider";
import { useSearchParams } from "../../../hooks/useSearchParams";
import { PlusIcon } from "../../../icons";
import { EditPenIcon } from "../../../icons/edit-pen";
import { ListTrash } from "../../../icons/home";
import { ListCommentIcon } from "../../../icons/SendComments";
import {
  AgingVehicle,
  HealthyVehicle,
  OldVehicle,
} from "../../../icons/VehicleAging";
import { ListHeader } from "../../../molecules/list-header/ListHeader";
import { getAging } from "../../../options/VehicleLoan";
import { ShowDateFormat } from "../../../utils";
import { useServiceHistory } from "../context";
import { AddServiceHistory } from "../one/AddServiceHistory";
import { returnStatusKey, StatusColorCode } from "../ServiceLoan";

export interface ServiceHistoryProps {
  hidetab?: any;
  addServiceByVehicle?: any;
  setAddServiceByVehicle?: any;
  isVehiclePage?: boolean;
}
const { confirm } = Modal;

export const ServiceHistory: React.FC<ServiceHistoryProps> = ({
  hidetab,
  addServiceByVehicle,
  setAddServiceByVehicle,
  isVehiclePage,
}) => {
  const [addService, setAdd] = useState({ visible: false, isNew: false });
  const [data, setData]: any = useState([{}]);
  const [commentsObj, setComments] = useState({ visible: false, id: "" });
  const {
    actions: { getAllComments },
  } = useCommonContext();
  const { query, updateSearchParams }: any = useSearchParams();
  const { id }: any = useParams();
  const {
    actions: {
      getAllVendors,
      getOneHistory,
      DeleteHistory,
      getAllHistory,
      getAllVehicles,
    },
    state: { history, documents, pagination },
  } = useServiceHistory();

  const columns: ColumnsType = [
    {
      title: "Service Name",
      dataIndex: "name",
      render: (value) => <>{value}</>,
    },
    {
      title: "Name",
      render: (record: any, value: any) => (
        <div className="flex items-center">
          {record?.vehicle?.financial?.purchaseDate && (
            <div className="aging mr-2 bg-[#F7F7F7] p-2 rounded-[5px]  ">
              <div className="">
                {getAging(
                  record?.vehicle?.financial?.purchaseDate,
                  record?.vehicle?.financial?.saleMillage
                ) === "old" ? (
                  <Tooltip
                    className="cursor-pointer"
                    trigger={["hover", "focus", "click"]}
                    title="Old Vehicle"
                  >
                    <div className="flex items-center">
                      {" "}
                      <OldVehicle />{" "}
                    </div>
                  </Tooltip>
                ) : getAging(
                    record?.financial?.purchaseDate,
                    record?.financial?.saleMillage
                  ) === "aging" ? (
                  <Tooltip
                    className="cursor-pointer"
                    trigger={["hover", "focus", "click"]}
                    title="Aging Vehicle"
                  >
                    <div className="flex items-center">
                      {" "}
                      <AgingVehicle />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip
                    className="cursor-pointer"
                    trigger={["hover", "focus", "click"]}
                    title="Healthy Vehicle"
                  >
                    <div className="flex items-center">
                      {" "}
                      <HealthyVehicle />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          )}
          <Link
            to={`/vehicles/${record?.vehicle?.id}`}
            className="underline font-medium text-[#398CC0]"
          >
            {record?.vehicle?.vehicleName}
          </Link>
        </div>
      ),
    },
    {
      title: "Vendor",
      render: (record: any, value: any) =>
        record?.vendor?.name ? record?.vendor?.name : "-",
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (value, record: any) => ShowDateFormat(value),
    },
    {
      title: "Attachments",
      render: (record: any, value: any) =>
        record?.attachments?.length > 0 ? (
          <a
            className="underline"
            onClick={() => {
              setSelectedValue(record.id);
            }}
          >
            <div>{record.attachments.length}</div>
          </a>
        ) : (
          "N/A"
        ),
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoiceAmount",
      render: (value, record: any) => (
        <>{record.invoiceAmount ? record.invoiceAmount : "-"}</>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      // sorter: true,
      render: (value) => (
        <p className={`capitalize font-[600] text-[${StatusColorCode(value)}]`}>
          {returnStatusKey(value)?.label}
        </p>
      ),
    },
    {
      title: "Action",
      // sorter: true,
      render: (record: any) => (
        <div className="flex  items-center">
          <div
            className="cursor-pointer  mr-[12px]"
            onClick={() => {
              setComments({ visible: true, id: record.id });
              getAllComments(record?.id);
            }}
          >
            {" "}
            <ListCommentIcon />
          </div>
          <PermissionsGate action="edit" module="service">
            <a
              className="underline flex items-center mr-[10px]"
              onClick={() => {
                setSelectedValue(record.id);
              }}
            >
              <EditPenIcon />
            </a>
          </PermissionsGate>

          <PermissionsGate action="delete" module="service">
            <div
              className="cursor-pointer"
              onClick={() => {
                confirm({
                  title: "Warning",
                  content:
                    "Are you sure want to deactivate this Service History?",
                  onOk() {
                    DeleteHistory(record.id, () => {
                      getAllHistory();
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              <ListTrash />
            </div>
          </PermissionsGate>
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (id) {
      if (Object.keys(addServiceByVehicle).length > 0) {
        setAdd(addServiceByVehicle);
      }
    }
  }, [addServiceByVehicle]);

  useEffect(() => {
    if (id) {
      updateSearchParams({ ...query, page: 1, perPage: 10 });
      getAllHistory(false, { ...query, vehicle: id });
    } else {
      if (Object.keys(query).length !== 0) {
        getAllHistory(false, query);
      } else {
        updateSearchParams({ page: 1, perPage: 10 });
      }
    }
  }, [query]);

  useEffect(() => {
    getAllVehicles();
    getAllVendors();
  }, []);

  useEffect(() => {
    if (documents) {
      documents?.map((item: any, i: any) => {
        item["key"] = i.toString();
      });
      setData(documents);
    }
  }, [documents]);

  const rowSelection = {};

  const setSelectedValue = (id: any) => {
    setAdd({ visible: true, isNew: false });
    getOneHistory(id);
  };

  return (
    <>
      <CommentsDragger
        title="Service History Comments"
        onClose={() => setComments({ visible: false, id: "" })}
        commentsObj={commentsObj}
        model="service-history"
      />
      <div className="w-full h-full bg-white px-6">
        <AddServiceHistory
          draggerKeys={addService}
          onClose={() => {
            setAdd({ isNew: false, visible: false });
            isVehiclePage &&
              setAddServiceByVehicle({ isNew: false, visible: false });
          }}
        />

        <div className="flex justify-between items-center py-[24px]">
          {!id && (
            <>
              <BreadCrumbs />
              <div>
                <div className="flex flex-row-reverse gap-4">
                  <PermissionsGate action="create" module="service-history">
                    {" "}
                    <Button
                      onClick={() => setAdd({ isNew: true, visible: true })}
                      className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                    >
                      <span>
                        <PlusIcon />
                      </span>
                      Add Service History
                    </Button>
                  </PermissionsGate>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="card_style ">
          <ListHeader pagination={pagination}></ListHeader>
          <Table
            className="vehicle_table"
            //   onChange={onTableActivity}
            columns={columns}
            dataSource={data}
            rowSelection={rowSelection}
            pagination={false}
          />
          <div className="mr-[24px] my-5">
            {" "}
            <Pagination
              pagination={pagination}
              onNext={() => {
                let newQuery: any = { ...query };
                newQuery.page = Number(newQuery.page) + 1;
                updateSearchParams({ ...newQuery });
              }}
              onPrevious={() => {
                let newQuery: any = { ...query };
                newQuery.page = Number(newQuery.page) - 1;
                updateSearchParams({ ...newQuery });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
