import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import { BreadCrumbs } from "../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../atoms/Button/Button";
import { PermissionsGate } from "../../atoms/PermissionComponent/PermissionGate";
import { StatsCard } from "../../atoms/StatsCard/statsCard";
import { PlusIcon } from "../../icons";
import {
  FlippedVehicle,
  InprogressVehicle,
  KeepingVehicle,
  OnBoarded,
  PipelineVehicle,
} from "../../icons/procurementStats";
import { AddPipeline } from "./AddPipeline";
import { useProcurement } from "./context";
import { AllPipelineListing } from "./PipelineListing";
import { ReturnStatsValue } from "./procurementLoad";

export interface ProcuremenProps {}
const { TabPane } = Tabs;
export const ProcurementLayout: React.FC<ProcuremenProps> = () => {
  const [currentTab, setCurrentTab] = useState("all");
  const { hash }: any = useLocation();
  const [procurementStats, setStats]: any = useState({});
  const [vehiclesCount, setVehiclesCount] = useState("");
  const history = useHistory();
  const [dragger, setDragger] = useState({ visible: false, isNew: false });
  useEffect(() => {
    setCurrentTab(hash || "#all");
  }, [hash]);
  const {
    actions: { getProcurementStats, getVehiclesCount },
  } = useProcurement();

  useEffect(() => {
    getProcurementStats((res) => {
      if (res) setStats(res.data);
    });
    getVehiclesCount((res) => {
      if (res) {
        setVehiclesCount(res.data.total);
      }
    });
  }, []);
  return (
    <>
      {" "}
      <div className="w-full h-full bg-white px-6">
        <div className="flex justify-between items-center pt-[24px] py-[24px] ">
          <BreadCrumbs />
          <div>
            <div className="flex flex-row-reverse gap-4">
              {" "}
              <PermissionsGate action="create" module="procurement">
                <Button
                  onClick={() => setDragger({ visible: true, isNew: true })}
                  className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                >
                  <span>
                    <PlusIcon />
                  </span>
                  {"Add New Pipeline"}
                </Button>
              </PermissionsGate>
            </div>
          </div>
        </div>
        <div className="card-holder flex items-center">
          <div className="flex justify-between items-center mb-6 w-full">
            <StatsCard
              title="Pipeline Vehicles"
              count={ReturnStatsValue(procurementStats?.pipeline)}
              icon={<PipelineVehicle />}
              bg_color="#00B0FF1A"
            />
            <StatsCard
              title="Inprogress Vehicles"
              count={ReturnStatsValue(procurementStats?.inprogress)}
              icon={<InprogressVehicle />}
              bg_color="#00B0FF1A"
            />
            <StatsCard
              title="Keeping Vehicles"
              count={ReturnStatsValue(procurementStats?.infleeted)}
              icon={<KeepingVehicle />}
              bg_color="#00B0FF1A"
            />
            <StatsCard
              title="Flipped Vehicles"
              count={ReturnStatsValue(procurementStats?.archieved)}
              icon={<FlippedVehicle />}
              bg_color="#00B0FF1A"
            />
            <StatsCard
              title="Onboarded"
              count={ReturnStatsValue(vehiclesCount && vehiclesCount)}
              icon={<OnBoarded />}
              bg_color="#00B0FF1A"
            />
          </div>
        </div>
        {dragger.visible && (
          <AddPipeline draggerKey={dragger} setDragger={setDragger} />
        )}

        <AllPipelineListing />
      </div>
    </>
  );
};
