import { Drawer } from "antd";
import { useCommonContext } from "../../hooks/commonStateProvider";
import { Comments } from "../Comments/Comments";

export interface CommentsProps {
  title?: string;
  onClose: () => any;
  commentsObj?: any;
  model?: any;
}

export const CommentsDragger: React.FC<CommentsProps> = ({
  title,
  commentsObj,
  model,
  onClose,
}) => {
  const {
    actions: { emptyState },
  } = useCommonContext();
  const closeFunction = () => {
    onClose();
    emptyState();
  };
  return (
    <>
      <Drawer
        visible={commentsObj.visible}
        title={title}
        width="46%"
        className="no_padding"
        onClose={closeFunction}
      >
        {commentsObj.visible && <Comments model={model} id={commentsObj.id} />}
      </Drawer>
    </>
  );
};
