import { Empty, Tabs } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { BreadCrumbs } from "../../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../../atoms/Button/Button";
import { PermissionsGate } from "../../../atoms/PermissionComponent/PermissionGate";
import { PlusIcon } from "../../../icons";
import { AllContactsListing } from "./AllContactListing";

export interface AllContactsProps {}

export const AllContacts: React.FC<AllContactsProps> = () => {
  const { TabPane } = Tabs;
  const [currentTab, setCurrentTab] = useState("all");
  const history = useHistory();
  
  const { hash } = useLocation();
  useEffect(() => {
    setCurrentTab(hash || "#all");
  }, [hash]);
  return (
    <>
      {" "}
      <div className="w-full h-full bg-white px-6">
        <div className="flex justify-between items-center py-[24px]">
          <BreadCrumbs />
          <div>
            <div className="flex flex-row-reverse gap-4">
              <PermissionsGate action="create" module="contact">
                <Link to={`/contact/add`}>
                  {" "}
                  <Button className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center">
                    <span>
                      <PlusIcon />
                    </span>
                    Add Contact
                  </Button>
                </Link>
              </PermissionsGate>
            </div>
          </div>
        </div>

        {/* <Tabs
          className="inner_overview_tab issues_tab"
          activeKey={currentTab}
          onChange={(activeKey) => {
            setCurrentTab(activeKey);
            history.push({
              hash: activeKey,
            });
          }}
        >
          <TabPane tab="All" key="#all">
            <AllContactsListing />{" "}
          </TabPane>
          <TabPane tab="Users" key="#users">
            {" "}
            <AllContactsListing />
          </TabPane>
          <TabPane tab="No User  Access" key="#nousers">
            {" "}
            <AllContactsListing />
          </TabPane>
          <TabPane tab="Archived" key="#archived">
            {" "}
            <AllContactsListing />
          </TabPane>
        </Tabs> */}
        <div className="card_style ">
          {" "}
          <AllContactsListing />
        </div>
      </div>
    </>
  );
};
