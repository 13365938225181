import { Calendar } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import type { CalendarMode } from "antd/es/calendar/generateCalendar";
import { useContract } from "../planning/context";
import { returnLabel } from "../ScheduleLoad";
export interface DayCalendarProps {}
export const DayCalendar: React.FC<DayCalendarProps> = () => {
  const [currentDate, setCurrentDate] = useState(
    moment().format("MMMM DD, YYYY")
  );
  const [vehicleArray, setVehicleArray]: any = useState([]);
  useEffect(() => {
    getAvailability(
      moment(currentDate).format("YYYY-MM-DD"),
      moment(currentDate).format("YYYY-MM-DD")
    );
  }, [currentDate]);
  const onPanelChange = (value: any) => {
    setCurrentDate(value.format("MMMM DD, YYYY"));
  };

  const returnDateFormat = () => {
    return moment(currentDate).format("YYYYMMDD");
  };
  const {
    actions: { getAvailability, clearAvailability },
    state: { availableVehicle },
  } = useContract();

  useEffect(() => {
    if (availableVehicle) {
      setVehicleArray(
        returnLabel(availableVehicle[returnDateFormat()]?.detailAvailable)
      );
    }
  }, [availableVehicle]);

  return (
    <>
      <div className="flex justify-between">
        <div className="w-[30%]">
          <div className="day_wise_view w-[338px] border border-[#F2F5F6] rounded-[10px]">
            <Calendar
              fullscreen={false}
              //  headerRender={headerRender}

              onSelect={(e) => onPanelChange(e)}
            />
          </div>
        </div>
        <div className="w-[70%]">
          <div className="bg-[#F5F5F5] py-[16px] rounded-t-[5px] font-medium text-[#F98938] rounded-r-5 flex items-center justify-center">
            {currentDate}
          </div>
          <div className="border border-[#F2F5F6]">
            <div className="p-[35px]">
              {vehicleArray?.length > 0 &&
                vehicleArray.map((item: any) => (
                  <div className="flex justify-between items-center mb-4">
                    <p className="text-[#686868] text-[14px] ">
                      {item.vehicleType}
                    </p>
                    <p>{item.count}</p>
                  </div>
                ))}
            </div>
            <div className="flex items-center justify-between px-[35px] py-[25px] items-center justify-between border  border-t border-[#F2F5F6] border-l-0 border-r-0 border-b-0">
              <p className="text-[#686868] text-[14px] text-[#686868] font-semibold">
                Total Available
              </p>
              <p>
                {availableVehicle &&
                  availableVehicle[returnDateFormat()]?.totalAvailable}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
