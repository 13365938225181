import { Avatar, Button, Checkbox, Drawer, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { Pagination } from "../../atoms/Pagination/pagination";
import { Table } from "../../atoms/Table/Table";
import { FormikText, Text } from "../../atoms/Text/Text";
import { useSearchParams } from "../../hooks/useSearchParams";
import { InfoIcon } from "../../icons/InfoIcon";
import { ListHeader } from "../../molecules/list-header/ListHeader";
import { RandomColor } from "../../services/utils/colorcode";
import { useUsers } from "./context";
import { returnModuleIconForPermission } from "./ModuleMapper";

export interface AllUsersRoleProps {}

const returnPermission = (action: string, permission: string[]) => {
  const currentPermission = permission.find((x) => x.startsWith(action));
  if (currentPermission) {
    const pS = currentPermission.split(":");
    return (
      <Checkbox
        indeterminate={pS[1] === "own" || pS[1] === "one"}
        checked={pS[1] === "any"}
        style={{ pointerEvents: "none" }}
        className="font-medium capitalize"
      >
        {action}
      </Checkbox>
    );
  } else {
  }
};

const returnModuleInformation = (moduleItem: any) => {
  const { module, permission } = moduleItem;
  return (
    <div className="pt-5">
      <div className="title text-[16px] capitalize font-semibold mb-2">
        {module}
      </div>
      <div className="flex justify-around p-2">
        {returnPermission("create", permission)}
        {returnPermission("list", permission)}
        {returnPermission("edit", permission)}
        {returnPermission("delete", permission)}
      </div>
    </div>
  );
};

export const AllUsersRole: React.FC<AllUsersRoleProps> = ({}) => {
  const { query, updateSearchParams }: any = useSearchParams();
  const [currentRole, setCurrentRole]: any = useState(null);
  const {
    actions: { getAllRoles },
    state: {
      roles: { documents, pagination },
    },
  } = useUsers();

  useEffect(() => {
    getAllRoles();
  }, []);

  useEffect(() => {
    if (Object.keys(query).length !== 0) {
      getAllRoles(false, query);
    } else {
      updateSearchParams({ page: 1, perPage: 10 });
    }
  }, [query]);

  const columns: ColumnsType = [
    {
      title: "Role",
      dataIndex: "name",
      render: (value, record: any) => (
        <div className="font-semibold">{value}</div>
      ),
    },
    {
      title: "Users",
      dataIndex: "users",
      render: (value, record: any) =>
        value.length ? (
          <Avatar.Group
            size={"large"}
            maxCount={3}
            maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
          >
            {value.map((user: any) => (
              <Tooltip
                title={`${user.firstName} ${user.lastName}`}
                placement="top"
              >
                <Avatar
                  style={{ backgroundColor: `${user.color}` }}
                >{`${user.firstName[0]}${user.lastName[0]}`}</Avatar>
              </Tooltip>
            ))}
          </Avatar.Group>
        ) : (
          <div className="text-[#767676]"> No Users Yet.</div>
        ),
    },
    {
      title: "Module Access",
      dataIndex: "modules",
      // sorter: true,
      render: (value, record: any) => (
        <Avatar.Group
          maxCount={3}
          maxStyle={{ display: "flex", flexDirection: "column" }}
          className="flex gap-3 items-center"
        >
          {value.map((module: any) => (
            <Tooltip
              title={module.module}
              overlayClassName="capitalize"
              placement="top"
            >
              <Avatar
                shape="square"
                size={"large"}
                style={{
                  backgroundColor: "transparent",
                  border: "2px dashed #DBDBDB",
                }}
                className="flex justify-center items-center p-[8px]"
                src={returnModuleIconForPermission(module)}
              />
            </Tooltip>
          ))}
        </Avatar.Group>
      ),
    },
    {
      title: "Action",
      render: (value, record: any) => (
        <Button
          className="rounded-[4px] px-5 py-3 text-[13px] font-semibold flex items-center gap-2 justify-center"
          onClick={() => setCurrentRole(record)}
        >
          <span>
            <InfoIcon />
          </span>
          View Informations
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="card_style ">
        <ListHeader
          onNewClicked={() => {}}
          onFilterClicked={() => {}}
          searchPlaceHolder="Search by Role Name"
          pagination={pagination}
        ></ListHeader>
        <Table
          className="vehicle_table"
          columns={columns}
          dataSource={documents}
          pagination={false}
        />
        <div className="mr-[24px] my-5">
          <Pagination
            pagination={pagination}
            onNext={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) + 1;
              updateSearchParams({ ...newQuery });
            }}
            onPrevious={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) - 1;
              updateSearchParams({ ...newQuery });
            }}
          />
        </div>
      </div>
      {currentRole && (
        <Drawer
          visible={currentRole ? true : false}
          placement="right"
          onClose={() => {
            setCurrentRole(null);
          }}
          width="33%"
          title="Role Informations"
        >
          <div>
            <div className="mb-4">
              <Text label="Role Name" value={currentRole.name} disabled />
            </div>
            <div className="mb-[30px]">
              <div className="form-label font-medium w-full flex items-start ">
                <p className="font-medium text-[14px] mb-[12px]">User List</p>
              </div>
              {currentRole.users.length ? (
                <Avatar.Group
                  size={"large"}
                  className="flex-wrap gap-2"
                  maxStyle={{ color: "#f56a00" }}
                >
                  {currentRole.users.map((user: any) => (
                    <Tooltip
                      title={`${user.firstName} ${user.lastName}`}
                      placement="top"
                    >
                      <Avatar
                        style={{ backgroundColor: `${user.color}` }}
                      >{`${user.firstName[0]}${user.lastName[0]}`}</Avatar>
                    </Tooltip>
                  ))}
                </Avatar.Group>
              ) : (
                <div className="text-[#767676]"> No Users Yet.</div>
              )}
            </div>

            <div>
              <div>
                {currentRole.modules.map((module: any) =>
                  returnModuleInformation(module)
                )}
              </div>
            </div>
          </div>
          <div>
            {currentRole.modules.map((module: any) =>
              returnModuleInformation(module)
            )}
          </div>
        </Drawer>
      )}
    </>
  );
};
