import React from "react";
export const MenuIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "28"}
      height={props.height || "15"}
      viewBox={`0 0 ${props.width || "28"} ${props.height || "22"}`}
    >
      <rect
        width="22.909"
        height="3.055"
        fill={`${props.fill || "#7B7B7B"}`}
        rx="1.527"
      ></rect>
      <rect
        width="14.764"
        height="3.055"
        y="9.164"
        fill={`${props.fill || "#7B7B7B"}`}
        rx="1.527"
      ></rect>
      <rect
        width="28"
        height="3.055"
        y="18.327"
        fill={`${props.fill || "#7B7B7B"}`}
        rx="1.527"
      ></rect>
    </svg>
  );
};

export const RentalRevenue: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8334 13.2709H1.16675C0.927581 13.2709 0.729248 13.0725 0.729248 12.8334C0.729248 12.5942 0.927581 12.3959 1.16675 12.3959H12.8334C13.0726 12.3959 13.2709 12.5942 13.2709 12.8334C13.2709 13.0725 13.0726 13.2709 12.8334 13.2709Z"
        fill="#398CC0"
      />
      <path
        d="M8.3125 13.2708H5.6875C5.44833 13.2708 5.25 13.0725 5.25 12.8333V2.33329C5.25 1.32996 5.80417 0.729126 6.7375 0.729126H7.2625C8.19583 0.729126 8.75 1.32996 8.75 2.33329V12.8333C8.75 13.0725 8.55167 13.2708 8.3125 13.2708ZM6.125 12.3958H7.875V2.33329C7.875 1.66246 7.56 1.60413 7.2625 1.60413H6.7375C6.44 1.60413 6.125 1.66246 6.125 2.33329V12.3958Z"
        fill="#398CC0"
      />
      <path
        d="M4.08333 13.2708H1.75C1.51083 13.2708 1.3125 13.0725 1.3125 12.8333V5.83329C1.3125 4.82996 1.82583 4.22913 2.68333 4.22913H3.15C4.0075 4.22913 4.52083 4.82996 4.52083 5.83329V12.8333C4.52083 13.0725 4.3225 13.2708 4.08333 13.2708ZM2.1875 12.3958H3.64583V5.83329C3.64583 5.10413 3.325 5.10413 3.15 5.10413H2.68333C2.50833 5.10413 2.1875 5.10413 2.1875 5.83329V12.3958Z"
        fill="#398CC0"
      />
      <path
        d="M12.2501 13.2709H9.91675C9.67758 13.2709 9.47925 13.0725 9.47925 12.8334V8.75004C9.47925 7.74671 9.99258 7.14587 10.8501 7.14587H11.3167C12.1742 7.14587 12.6876 7.74671 12.6876 8.75004V12.8334C12.6876 13.0725 12.4892 13.2709 12.2501 13.2709ZM10.3542 12.3959H11.8126V8.75004C11.8126 8.02087 11.4917 8.02087 11.3167 8.02087H10.8501C10.6751 8.02087 10.3542 8.02087 10.3542 8.75004V12.3959Z"
        fill="#398CC0"
      />
    </svg>
  );
};
