import { Drawer } from "antd";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "../../../../atoms/Button/Button";
import { FormikText } from "../../../../atoms/Text/Text";
import { SaveIcon } from "../../../../icons/SaveIcon";
import * as Yup from "yup";
import { FormikSinglSelect } from "../../../../atoms/Select/Select";
import { useMake } from "../context";
export interface AddModelProps {
  modelDragger: any;
  onClose?: any;
}
export const AddModel: React.FC<AddModelProps> = ({
  modelDragger,
  onClose,
}) => {
  let MakeOptions: Array<any> = [];

  const [initialValues, setInitialValues]: any = useState({
    name: "",
    parent: "",
  });
  const [makeOptions, setMakeOptions]: any = useState([]);
  const {
    state: { make, subModel, minimalDocuments, parentMakeDetails },
    actions: { createOne, updateOne, getAllMake, getOneMake },
  } = useMake();

  useEffect(() => {
    getAllMake(true, undefined, (response: any) => {});
  }, []);

  useEffect(() => {
    if (!modelDragger.isNew && Object.keys(make).length > 0) {
      setInitialValues({
        ...make,
        parent: { label: make.parent?.name, value: make.parent?.id },
      });
    } else {
      setInitialValues({ name: "", parent: "" });
    }
  }, [modelDragger, make]);
  return (
    <>
      {" "}
      <Drawer
        visible={modelDragger.visible}
        placement="right"
        onClose={onClose}
        width="33%"
        title={modelDragger.isNew ? "Add Model" : "Edit Model"}
        className=""
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Model is required"),
            parent: Yup.mixed().required("Make is required"),
          })}
          onSubmit={(values) => {
            if (modelDragger.isNew) {
              //create

              createOne(
                { ...values, parent: values.parent.value, type: "model" },
                () => {
                  onClose();
                }
              );
            } else {
              //update
              updateOne(
                make.id,
                { ...values, parent: values.parent.value, type: "model" },
                () => {
                  onClose();
                  getAllMake(false, { parent: parentMakeDetails[0].parent.id });
                }
              );
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Form className="" id="my-form">
              <FormikText label="Model" name="name" must={true} />
              <div className="min-w-[360px] mt-4">
                <FormikSinglSelect
                  must={true}
                  isClearable
                  options={minimalDocuments}
                  label="Make"
                  name="parent"
                  inputClassName="adjust_select_height"
                  onChange={(newValue) => setFieldValue("parent", newValue)}
                />
              </div>
              <div className="flex justify-end mt-8">
                {" "}
                <Button
                  className="border mr-2  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                  type="button"
                  onClick={onClose}
                >
                  <span className="flex items-center text-[#FB6DB2]">
                    Cancel
                  </span>
                </Button>
                <Button
                  type="submit"
                  onClick={() => {}}
                  className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                >
                  <span>
                    <SaveIcon />
                  </span>
                  Save Model
                </Button>{" "}
              </div>
            </Form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};
