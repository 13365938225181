import moment from "moment";
import { useEffect, useState } from "react";
import { CalenderView } from "./calenderview";
import { EventSideBar } from "./EventSideBar";

export interface MonthlyCalendarProps {
  date?: any;
}
export const MonthlyCalendar: React.FC<MonthlyCalendarProps> = ({ date }) => {
  const [numberofDays, setDays] = useState(0);
  const [showEvent, setShowEvent] = useState(false);
  const [selectedData, setSelectedData]: any = useState();
  useEffect(() => {
    setDays(moment(date).daysInMonth());
  }, [date]);
  // useEffect(() => {}, [selectedData]);

  return (
    <>
      <div className="flex justify-between overflow-hidden">
        <div
          className={`${
            showEvent ? "w-[75%]" : "w-full"
          } ease-in-out duration-500 `}
        >
          <div className="grid grid-cols-7 border border-[#F2F5F6]">
            {
              <CalenderView
                days={numberofDays}
                date={date}
                triggerEvent={showEvent}
                setTriggerEvent={setShowEvent}
                setSelectedData={setSelectedData}
              />
            }
          </div>
        </div>
        <div
          className={`${
            showEvent ? "w-[24%]" : "w-0"
          } ease-in-out duration-500 `}
        >
          <EventSideBar
            date={selectedData}
            onClose={() => setShowEvent(false)}
          />
        </div>
      </div>
    </>
  );
};
