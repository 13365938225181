import { Drawer, message } from "antd";
import { Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import * as Yup from "yup";
import { Button } from "../../atoms/Button/Button";
import { FormikDatePicker } from "../../atoms/DatePicker/DatePicker";
import { FormikSinglSelect } from "../../atoms/Select/Select";
import { FormikText } from "../../atoms/Text/Text";
import uploadIcon from "../../icons/designIcons/uploadIcon.svg";
import { CancelUpload, PdfImage } from "../../icons/UploadIcons";
import { useProcurement } from "./context";

export interface AddProgressProps {
  dragger?: boolean;
  setDragger?: any;
  data?: any;
}
export const AddProgress: React.FC<AddProgressProps> = ({
  dragger,
  setDragger,
  data,
}) => {
  const [initialValues, setInitialValues]: any = useState({
    loaner: "",
    downPayment: "",
    location: "",
    launchDate: null,
    msrp: "",
  });
  const [file, setFile]: any = useState({});
  const { id }: any = useParams();
  const {
    actions: { updateToProgress, getOneDetail },
    state: { procurement },
  } = useProcurement();

  useEffect(() => {
    if (procurement) {
      setInitialValues({
        ...initialValues,
        loaner: procurement.leadInfo?.loaner,
        downPayment: procurement.leadInfo?.downPayment,
        location: procurement.leadInfo?.location,
        launchDate: procurement.leadInfo
          ? moment(procurement.leadInfo?.launchDate)
          : null,
        msrp: procurement.leadInfo?.msrp,
      });
    }
  }, [procurement]);

  useEffect(() => {
    if (!dragger) {
      setInitialValues({
        loaner: "",
        downPayment: "",
        location: "",
        launchDate: null,
        msrp: "",
      });
    }
  }, [dragger]);
  return (
    <>
      <Drawer
        visible={dragger}
        placement="right"
        onClose={() => {
          setDragger(false);
        }}
        width="33%"
        title="In progress"
      >
        <div>
          <Formik
            // innerRef={formRef}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
              loaner: Yup.string().required("Loaner is required"),
              downPayment: Yup.string().required("DownPayment is required"),

              location: Yup.string().required("Location is required"),
              launchDate: Yup.string()
                .nullable()
                .required("Launch Date is required"),
              msrp: Yup.string().required("MSRP is required"),
            })}
            onSubmit={(values: any, actions) => {
              updateToProgress(
                id,
                {
                  ...values,
                  launchDate: moment(values.launchDate).toISOString(),
                },
                () => {
                  setDragger(false);
                  getOneDetail(id);
                }
              );
            }}
          >
            {({ values, setFieldValue, setErrors }) => (
              <Form>
                {" "}
                <div className="mb-4">
                  <FormikText label="Loaner" name="loaner" must={true} />
                </div>
                <div className="mb-4">
                  <FormikText
                    label="Down Payment /Unit"
                    name="downPayment"
                    type="number"
                    must={true}
                  />
                </div>
                <div className="mb-4">
                  <FormikText
                    label="Vehicle Location"
                    name="location"
                    must={true}
                  />
                </div>
                <div className="mb-4">
                  <FormikDatePicker
                    inputClassName="datepicket_styles"
                    label="Targeted Launch date"
                    must={true}
                    name="launchDate"
                    onChange={(value: any) =>
                      setFieldValue("launchDate", value)
                    }
                    value={values.launchDate}
                  />
                </div>
                <div className="mb-4">
                  <FormikText
                    label="MSRP"
                    name="msrp"
                    type="number"
                    must={true}
                  />
                </div>
                {Object.keys(file).length > 0 && (
                  <div className="border mt-[22px] rounded-[4px]  bg-[#FAFAFA] border-[#EBEBEB] px-[14px] py-[12px] flex justify-between items-center">
                    <div className="flex items-center">
                      <div className="w-[32px] mr-[15px] bg-[#D9D9D9] h-[32px] rounded-[3px] p-[3px]">
                        <PdfImage />
                      </div>
                      <p className="font-medium text-[14px]">{file.name}</p>
                    </div>
                    <div
                      className="cursor-pointer"
                      //   onClick={() => cancelUpload()}
                    >
                      <CancelUpload />
                    </div>
                  </div>
                )}
                <div className="mt-10">
                  <div className="flex flex-row-reverse gap-4">
                    {" "}
                    <Button
                      type="submit"
                      className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                    >
                      Save
                    </Button>
                    <Button
                      className="border  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                      type="reset"
                      onClick={() => {
                        setErrors({});
                        setDragger(false);
                      }}
                    >
                      <span className="flex items-center text-[#FB6DB2]">
                        Cancel
                      </span>
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Drawer>
    </>
  );
};
