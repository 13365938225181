import { useReducer } from "react";
import { container } from "./../../services/storage/context.container";
import { APIService } from "../../services/api/request";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { buildQueryString } from "../../services/utils/url";
import { destroyMessage, showMessage, UpdatedSuccessfully } from "../../utils";

const ApiRoutes = {
  base: `/vehicles`,
};

export type StateType = {
  minimalDocuments: any;
  documents: any;
  groups: any;
  pagination: any;
  vehicle: any;
  vehicleTypes: any;
};

const initialState: StateType = {
  documents: [],
  groups: [],
  pagination: {},
  vehicle: {},
  vehicleTypes: [],
  minimalDocuments: [],
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_MININAL_VEHICLES":
      return {
        ...state,
        minimalDocuments: payload.map((x: any) => ({
          value: x.id,
          label: x.vehicleName,
        })),
      };
    case "GET_VEHICLES":
      return { ...state, ...payload };
    case "GET_GROUPS":
      return { ...state, groups: [...payload] };
    case "GET_VEHICLE_TYPE":
      return {
        ...state,
        vehicleTypes: payload.map((x: any) => ({
          value: x.id,
          label: x.name,
        })),
      };
    case "GET_ONE_VEHICLE":
      return { ...state, vehicle: { ...payload } };
    default:
      return state;
  }
};

export const { useContext: useVehicles, Provider: VehiclesProvider } =
  container(() => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });
    let history = useHistory();
    const getAllVehicles = (
      mininal?: boolean,
      params?: any,
      GroupId?: any,
      callback?: (data: any) => void
    ) => {
      if (!mininal) {
      }
      params = buildQueryString(params);
      showMessage();
      APIService.get(
        mininal
          ? `${ApiRoutes.base}?all=true`
          : `${ApiRoutes.base}${params ? `${params}` : ""}${
              GroupId ? `&filter[group]=${GroupId}` : ""
            }`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          // showThenHide(false);
          const response: any = res.data;
          if (mininal) {
            dispatch({ type: "GET_MININAL_VEHICLES", payload: response });
          } else {
            dispatch({ type: "GET_VEHICLES", payload: response });
          }

          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const updateOne = (
      id: string,
      data: any,
      callback?: (data: any) => void
    ) => {
      showMessage();
      APIService.put(
        `${ApiRoutes.base}/${id}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          UpdatedSuccessfully();
          // dispatch({ type: "GET_VEHICLES", payload: res });

          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };
    const getRepairSumAmount = (id: any, callback?: (data: any) => void) => {
      APIService.get(
        `${ApiRoutes.base}/${id}/repair-maintenance`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          const response: any = res.data;

          callback && callback(response);
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const createOne = (data: any, callback?: (data: any) => void) => {
      APIService.post(
        `${ApiRoutes.base}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          message.success("Created Successfully!!!");
          history.goBack();
          getAllVehicles(false, { page: 1, perPage: 10 });
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          } else {
            message.error("Vehicle Creation Failed");
          }
        });
    };
    const addAssets = (url: any, data: any, callback?: (data: any) => void) => {
      APIService.post(url, data, process.env.REACT_APP_API_ENDPOINT)
        .then((res: any) => {
          message.success("Uploaded Successfully!!!");

          callback && callback(res);
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          } else {
            message.error("Vehicle Creation Failed");
          }
        });
    };

    const getOneVehicle = (id: string, callback?: (data: any) => void) => {
      showMessage();
      APIService.get(
        `${ApiRoutes.base}/${id}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          const response: any = res.data;
          dispatch({ type: "GET_ONE_VEHICLE", payload: response });
          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };
    const getVehicleTypes = (callback?: (data: any) => void) => {
      showMessage();
      APIService.get(
        `${ApiRoutes.base}/info/vehicle-types`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          const response: any = res.data;
          dispatch({ type: "GET_VEHICLE_TYPE", payload: response });
          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };
    const getAllMakeModel = (params?: any, callback?: (data: any) => void) => {
      if (params) {
        if (!params.parent) {
          params.parent = null;
        }
        params = buildQueryString(params);
      }

      APIService.get(
        `/makes${params ? `${params}` : ""}&all=true`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          const response: any = res.data;
          callback && callback(response);
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const uploadFiles = (
      uri: any,
      data: any,
      callback?: (data: any) => void
    ) => {
      APIService.put(uri, data, "")
        .then((res) => {
          callback && callback("callback");
        })
        .catch((err) => {});
    };
    const getVehicleAssests = (
      id: string,
      queryparams: any,
      callback?: (data: any) => void
    ) => {
      APIService.get(
        `${ApiRoutes.base}/${id}/assets${queryparams}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      ).then((response: any) => {
        callback && callback(response.data);
      });
    };
    const getVehicleHistory = (
      id: string,
      callback?: (response: any) => void
    ) => {
      APIService.get(
        `${ApiRoutes.base}/${id}/status-history`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          callback && callback(response);
        })
        .catch((err: any) => {});
    };
    const getAllGroups = (callback?: (response: any) => void) => {
      APIService.get(
        `/groups?all=true`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          dispatch({ type: "GET_GROUPS", payload: response.data });
          callback && callback(response.data);
        })
        .catch((err: any) => {});
    };
    const DeleteVehicle = (id: string, callback?: (response: any) => void) => {
      APIService.delete(
        `${ApiRoutes.base}/${id}`,
        {},
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          callback && callback(response);
        })
        .catch((err: any) => {});
    };
    const DeleteMultipleVehicles = (
      data?: any,
      callback?: (res: any) => void
    ) => {
      APIService.delete(
        `${ApiRoutes.base}/delete/all`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          callback && callback(response);
        })
        .catch((err: any) => {});
    };

    const CreateIntegration = (
      id: any,
      integrationType: any,
      callback?: (data: any) => void
    ) => {
      APIService.post(
        `${ApiRoutes.base}/${id}/integrations/${integrationType}`,
        {},
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          // history.goBack();
          message.success("Created Successfully!!!");
          getOneVehicle(id);
          if (typeof callback === "function") {
          }
          callback && callback(res);
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };
    const ResyncIntegration = (
      id: any,
      integrationType: any,
      callback?: (data: any) => void
    ) => {
      APIService.get(
        `${ApiRoutes.base}/${id}/integrations/${integrationType}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          message.success("Resynced Successfully!!!");
          getOneVehicle(id);
          if (typeof callback === "function") {
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };
    const DeleteIntegration = (
      id: any,
      integrationType: any,
      callback?: (data: any) => void
    ) => {
      APIService.delete(
        `${ApiRoutes.base}/${id}/integrations/${integrationType}`,
        {},
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          message.success("Deleted Successfully!!!");
          getOneVehicle(id);
          if (typeof callback === "function") {
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };
    const deleteAssets = (
      id: any,
      assetId: any,
      callback?: (data: any) => void
    ) => {
      APIService.delete(
        `${ApiRoutes.base}/${id}/assets/${assetId}`,
        {},
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          message.success("Deleted Successfully!!!");
          callback && callback(res);
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    return {
      state,
      actions: {
        getAllVehicles,
        getOneVehicle,
        updateOne,
        createOne,
        uploadFiles,
        addAssets,
        getVehicleAssests,
        getVehicleHistory,
        getAllGroups,
        DeleteVehicle,
        CreateIntegration,
        deleteAssets,
        getVehicleTypes,
        ResyncIntegration,
        getRepairSumAmount,
        DeleteIntegration,
        getAllMakeModel,
        DeleteMultipleVehicles,
      },
    };
  });
