import moment from "moment";
import {
  getCurrentVehicleTypesFromKey,
  reCamalize,
} from "../../options/VehicleLoan";
import humps from "humps";
import { VehicleTypes } from "../reports/ReportsLoad";
export const calenderArray = [
  {
    text: "Below 10 Vehicles",
    color: "#F98938",
  },
  {
    text: "10 - 20 Vehicles",
    color: "#B75BFF",
  },
  {
    text: "20+ Vehicles",
    color: "#32A02D",
  },
];
export const daysInCalender = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thur",
  "Fri",
  "Sat",
];

export const Years = () => {
  let array = [];
  let current = moment().year();
  for (let i = current - 10; i <= current + 10; i++) {
    array.push(i);
  }
  return array;
};

export const Months = [
  { key: 0, name: "January" },
  { key: 1, name: "Feburary" },
  { key: 2, name: "March" },
  { key: 3, name: "April" },
  { key: 4, name: "May" },
  { key: 5, name: "June" },
  { key: 6, name: "July" },
  { key: 7, name: "August" },
  { key: 8, name: "September" },
  { key: 9, name: "October" },
  { key: 10, name: "November" },
  { key: 11, name: "December" },
];

export const getWeeklyDateFormat = (currentWeek: any, i: any) => {
  let data = {};
  return {
    displayDate: moment()
      .week(currentWeek)
      .startOf("week")
      .add(i, "days")
      .format(" MMM D"),
    dateFormat: moment()
      .week(currentWeek)
      .startOf("week")
      .add(i, "days")
      .format("YYYYMMDD"),
    actualDate: moment()
      .week(currentWeek)
      .startOf("week")
      .add(i, "days")
      .format("YYYY-MM-DD"),
  };
};
/* 
Code to provide the number of days in current month
*/

export const getCurrentMonthDates = (days: any, date: any) => {
  // let currentMonth = moment(date).startof("month");
  let array = [];
  for (let i = 1; i <= days; i++) {
    let currentDate = date;
    let obj = {
      value: i,
      current:
        moment().format("YYYY-MM-DD") ===
          moment(new Date(currentDate).setDate(i)).format("YYYY-MM-DD") && true,
      fullDate: moment(new Date(currentDate).setDate(i)).format("YYYYMMDD"),
      actualDate: moment(new Date(currentDate).setDate(i)).format("YYYY-MM-DD"),
    };
    array.push(obj);
  }
  return array;
};
export const checkVehicleCount = (count: any) => {
  if (count <= 10) {
    return "bg-[#FFF7F2] text-[#B75BFF]";
  } else if (count > 10 && count <= 20) {
    return "bg-[#FAF4FF] text-[#B75BFF]";
  } else if (count > 20) {
    return "bg-[#F3FFF2] text-[#32A02D]";
  }
};
export const CardColor = (count: any) => {
  if (count <= 10) {
    return "bg-[#B75BFF]";
  } else if (count > 10 && count <= 20) {
    return "bg-[#B75BFF]";
  } else if (count > 20) {
    return "bg-[#32A02D]";
  }
};
/* 
Code to find the next month remaining days
*/

export const findNextMonthWeek = (date: any) => {
  let array = [];
  let endofDay = moment(date).endOf("month").format("d");
  let value = 1;
  for (let i: any = endofDay; i < 6; i++) {
    if (i === endofDay) {
      array.push({ value: value, key: "next" });
    } else {
      value = value + 1;
      array.push({ value: value, key: "next" });
    }
  }
  return array;
};
export const calculateWeek = (day: any) => {
  let weeksPerYear = moment(day).isoWeeksInYear();
  let array: any = [];
  for (let i = 1; i <= weeksPerYear; i++) {
    array.push({
      start: moment(day).week(i).startOf("week").format("MMM DD YYYY"),
      end: moment(day).week(i).endOf("week").format("MMM DD YYYY"),
      weekNumber: i,
    });
  }
  return array;
};
export const SendingDataFormat = (date: any) => {
  return date ? moment(date).format("YYYY-MM-DD") : "";
};
export const compareCamalizedKey = (key: any) => {
  let currentkey = "";
  VehicleTypes.map((item) => {
    if (humps.camelize(item) === key) {
      currentkey = item;
    }
    return currentkey;
  });
  // VehicleTypes.find((x) => humps.camelize(x) === item);
};
export const returnLabel = (data: any) => {
  let array = [];
  if (data && Object.keys(data).length > 0) {
    for (let item of data) {
      let obj: any = {
        vehicleType: item.type.name,
        count: item.totals,
      };
      array.push(obj);
    }
    return array;
  }
};
export const Schedulestatus = (status: any) => {
  switch (status) {
    case "to-do":
      return "#F25138";
    case "planning":
      return "#F2A100";
    case "signed":
      return "#1277D6";
    case "live":
      return "#FF8126";
    case "completed":
      return "#3BA936";
  }
};
export const contractStatus = [
  {
    label: "Yet-to-start",
    value: "to-do",
  },
  { label: "Completed", value: "completed" },
  { label: "Planning", value: "planning" },
  { label: "Signed", value: "signed" },
  { label: "Live", value: "live" },
];
export const getCurrentStatusFromKey = (key: string) =>
  contractStatus.find((x) => x.value === key);

export const returnVehicleId = (array: any) => {
  let idArray: any = [];
  array.map((item: any) => {
    idArray.push(item.id);
  });
  return idArray;
};
