import { ColumnsType } from "antd/lib/table";
import { checkData, checkDataAvailable } from "../../../utils";
import { Table } from "../../../atoms/Table/Table";
import { useReports } from "../context";
import { useEffect, useState } from "react";
import {
  CalculateCargoSum,
  calculatePasangerTotal,
  downloadBlob,
  getAllcounts,
} from "../ReportsLoad";
import { Button } from "../../../atoms/Button/Button";

export interface ReportsOnRentProps {}
export const HeaderArray = [
  "Name",
  "Total On Rent",
  "Total Passenger Vehicles",
  "ICE 15 Px Van",
  "ICE 12 Px Van",
  "ICE 5-7 Seat Px Van",
  "ICE Mini Van",
  "ICE Passenger Sedan",
  "ICE Full-size SUV",
  "Total Cargo Vehicles",
  "EV Cargo",
  "ICE 107ft - 159WB Cargo",
  "ICE 96.3ft - 144WB Cargo",
  "ICE MR 72ft - 130WB Cargo",
  "ICE 15px Conv Cargo",
  "ICE 12px Conv Cargo",
  "ICE 20ft Box Truck",
  "ICE 16ft Box Truck",
  "ICE 14ft Box Truck",
  "ICE Pickup Truck",
  "ICE Step Van",
  "Other Unknown",
];
export const ReportsOnRent: React.FC<ReportsOnRentProps> = () => {
  const {
    state: { documents },
  } = useReports();
  useEffect(() => {
    setData(getAllcounts(documents));
  }, [documents]);
  const [data, setData]: any = useState([]);
  useEffect(() => {
  }, [data]);
  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
      render: (value, record: any) => (
        <div>{checkDataAvailable(record.customer?.name)}</div>
      ),
    },
    {
      title: "Total On Rent",
      dataIndex: "totals",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "Total Passenger Vehicles",
      dataIndex: "",
      render: (value, record: any) => (
        <div>{calculatePasangerTotal(record)}</div>
      ),
    },
    {
      title: "ICE 15 Px Van",
      dataIndex: "ICE 15 Px Van",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "ICE 12 Px Van",
      dataIndex: "ICE 12 Px Van",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "ICE 5-7 Seat Px Van",
      dataIndex: "ICE 5-7 Seat Px Van",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "ICE Mini Van",
      dataIndex: "ICE Mini Van",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "ICE Passenger Sedan",
      dataIndex: "ICE Passenger Sedan",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "ICE Full - size SUV",
      dataIndex: "ICE Full - size SUV",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "Total Cargo Vehicles",
      dataIndex: "",
      render: (value, record: any) => <div>{CalculateCargoSum(record)}</div>,
    },
    {
      title: "EV Cargo",
      dataIndex: "EV Cargo",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "ICE 107f - 159WB Cargo",
      dataIndex: "ICE 107ft - 159WB Cargo",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "ICE 96.3ft - 144WB Cargo",
      dataIndex: "ICE 96.3ft - 144WB Cargo",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "ICE MR 72ft Cargo",
      dataIndex: "ICE MR 72ft Cargo",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "ICE 15px Conv Cargo",
      dataIndex: "ICE 15px Conv Cargo",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "ICE 12px Conv Cargo",
      dataIndex: "ICE 12px Conv Cargo",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "ICE 20ft Box Truck",
      dataIndex: "ICE 20ft Box Truck",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "ICE 16ft Box Truck",
      dataIndex: "ICE 16ft Box Truck",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "ICE 14ft Box Truck",
      dataIndex: "ICE 14ft Box Truck",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },

    {
      title: "ICE Pickup Truck",
      dataIndex: "ICE Pickup Truck",
      render: (value, record: any) => (
        <div>
          {/* {calculatePercentage(checkData(record), record.totals, "available")} */}
          {checkData(value)}
        </div>
      ),
    },
    {
      title: "ICE Step Truck",
      dataIndex: "ICE Step Truck",
      render: (value, record: any) => <div> {checkData(value)} </div>,
    },
    {
      title: "Other Unknown",
      dataIndex: "Other Unknown",
      render: (value, record: any) => <div> {checkData(value)}</div>,
    },
  ];

  function arrayToCsv() {
    let arrayFormatted: any = [HeaderArray];
    let array = documents.map((row: any) => {
      let individualData = [];
      individualData.push(checkDataAvailable(row.customer?.name));
      individualData.push(checkData(row.totals));
      individualData.push(calculatePasangerTotal(row));

      individualData.push(checkData(row["ICE 15 Px Van"]));
      individualData.push(checkData(row["ICE 12 Px Van"]));
      individualData.push(checkData(row["ICE 5-7 Seat Px Van"]));
      individualData.push(checkData(row["ICE Mini Van"]));
      individualData.push(checkData(row["ICE Passenger Sedan"]));
      individualData.push(checkData(row["ICE Full-size SUV"]));
      individualData.push(checkData(row[CalculateCargoSum(row)]));
      individualData.push(checkData(row["EV Cargo"]));
      individualData.push(checkData(row["ICE 107ft - 159WB Cargo"]));
      individualData.push(checkData(row["ICE 96.3ft - 144WB Cargo"]));
      individualData.push(checkData(row["ICE MR 72ft - 130WB Cargo"]));
      individualData.push(checkData(row["ICE 15px Conv Cargo"]));
      individualData.push(checkData(row["ICE 12px Conv Cargo"]));
      individualData.push(checkData(row["ICE 20ft Box Truck"]));
      individualData.push(checkData(row["ICE 16ft Box Truck"]));
      individualData.push(checkData(row["ICE 14ft Box Truck"]));
      individualData.push(checkData(row["ICE Pickup Truck"]));
      individualData.push(checkData(row["ICE Step Van"]));
      individualData.push(checkData(row["Other Unknown"]));
      arrayFormatted.push(individualData);
    });
    downloadBlob(arrayFormatted, `on_rent`);
  }

  return (
    <>
      <div className="flex flex-end justify-end mb-[20px]">
        <Button
          onClick={() => arrayToCsv()}
          className="rounded-[4px]   text-[#fb6db2] text-[14px] text-white font-semibold flex items-center gap-2 justify-center"
        >
          Download
        </Button>
      </div>{" "}
      <Table
        className="vehicle_table overflow_table"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </>
  );
};
