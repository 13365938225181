import { Table } from "../../../atoms/Table/Table";
import { ListHeader } from "../../../molecules/list-header/ListHeader";
import type { ColumnsType } from "antd/es/table";
import { useInspections } from "../InspectionContext";
import { useEffect, useState } from "react";
import { Drawer, Empty, message, Tag } from "antd";
import { useVehicles } from "../../vehicles/context";
import { useFormTemplates } from "../TemplateContext";
import { CreateOneInspection } from "../one/createInspection";
import { Link } from "react-router-dom";
import { Button } from "../../../atoms/Button/Button";
import { ViewInspectionResponse } from "../one/ViewInspectionResponse";

export interface AllInspectionPageProps {}

export const AllInspectionPage: React.FC<AllInspectionPageProps> = (
  props: AllInspectionPageProps
) => {
  const [openNew, setOpenNew] = useState(false);
  const [currentForm, setCurrentForm]: any = useState(null);
  const {
    actions: { getAll },
    state: { documents, pagination },
  } = useInspections();

  const columns: ColumnsType = [
    {
      title: "Vehicle",
      dataIndex: "vehicleInfo",
      render: (value) => `${value ? value.vehicleName : "N/A"}`,
    },
    {
      title: "Template",
      dataIndex: "template",
      render: (value) => `${value.name}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value) => <Tag color="blue">{value}</Tag>,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
    },
    {
      title: "Public Link",
      dataIndex: "publicSharableCode",
      render: (value) => (
        // <a
        //   href={`${process.env.REACT_APP_INSPECTION_FORM_PUBLIC_LINK}/${value}`}
        //   target="_blank"
        // >
        //   Open in New Tab
        // </a>
        <Button
          className="border py-2 px-5"
          onClick={() => {
            navigator.clipboard.writeText(
              `${process.env.REACT_APP_INSPECTION_FORM_PUBLIC_LINK}/${value}`
            );
            message.success("copied");
          }}
        >
          Copy Link
        </Button>
      ),
      // sorter: true,
    },
    {
      title: "Response",
      dataIndex: "templateData",
      render: (value, record) =>
        value ? (
          <Button
            className="border py-2 px-5"
            onClick={() => {
              setCurrentForm(record);
            }}
          >
            View Response
          </Button>
        ) : (
          "N/A"
        ),
      // sorter: true,
    },
  ];

  const {
    actions: { getAllVehicles },
  } = useVehicles();

  const {
    actions: { getAll: getAllForms },
  } = useFormTemplates();
  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (openNew) {
      getAllForms(true);
      getAllVehicles(true);
    }
  }, [openNew]);
  const onTableActivity = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {};
  return (
    <Empty
      style={{ height: 350 }}
      className="flex justify-center flex-col items-center"
      description="Yet to be developed"
    />
    // <div className="w-full h-full bg-white p-6">
    //   {/* Title, Search, Filter */}
    //   <ListHeader
    //     onNewClicked={() => {
    //       setOpenNew(true);
    //     }}
    //     onFilterChanged={(filter) => {}}
    //     onFilterClicked={() => {}}
    //   />
    //   {/* Table View */}
    //   <Table
    //     onChange={onTableActivity}
    //     columns={columns}
    //     dataSource={documents}
    //     pagination={{
    //       defaultPageSize: 10,
    //       showQuickJumper: true,
    //       showPrevNextJumpers: true,
    //       showTitle: true,
    //       showLessItems: true,
    //       total: pagination.total,
    //       position: ["topRight", "bottomRight"],
    //       showTotal: (total) => <p> Total of {total}</p>,
    //     }}
    //   />
    //   {openNew && (
    //     <Drawer
    //       title="Create a new Inspection"
    //       placement={"right"}
    //       width={"40vw"}
    //       onClose={() => setOpenNew(false)}
    //       visible={openNew}
    //     >
    //       <CreateOneInspection
    //         isNew
    //         onClose={() => {
    //           setOpenNew(false);
    //         }}
    //       />
    //     </Drawer>
    //   )}
    //   {currentForm && (
    //     <Drawer
    //       title="Inspection Response"
    //       placement={"right"}
    //       width={"40vw"}
    //       onClose={() => setCurrentForm(null)}
    //       visible={true}
    //     >
    //       <ViewInspectionResponse
    //         components={currentForm.templateComponent}
    //         responses={currentForm.templateData}
    //       />
    //     </Drawer>
    //   )}
    // </div>
  );
};
