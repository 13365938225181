export const ServiceStatus = [
  { value: "to-do", label: "To Do" },
  { value: "inprogress", label: "In Progress" },
  { value: "done", label: "Done" },
  // { value: "archieved", label: "Archieved" },
];

export const StatusColorCode = (status: string) => {
  switch (status) {
    case "to-do":
      return "#F25138";
    case "inprogress":
      return "#FF8126";
    case "done":
      return "#47B275";
    // case "archieved":
    //   return "#00AEEF";
  }
};

export const returnStatusKey = (status: any) =>
  ServiceStatus.find((item: any) => {
    if (item.value === status) return item;
  });
