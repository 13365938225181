import { Button } from "../../../../../atoms/Button/Button";
import { useRef } from "react";
import { BreadCrumbs } from "../../../../../atoms/BreadCrumb/BreadCrumb";
import { Link, useHistory } from "react-router-dom";
import { SaveIcon } from "../../../../../icons/SaveIcon";
import { VehicleInformations } from "./VehicleInformations";

interface AddVehicleProps {
  initialData?: any;
  id?: string;
  isNew?: boolean;
  licensePlate?: string;
  onClose?: () => void;
}

export const AddVehicle: React.FC<AddVehicleProps> = ({
  initialData,
  id,
  isNew,
  licensePlate,
  onClose,
}: AddVehicleProps) => {
  const formRef = useRef() as any;
  const history = useHistory();
  const handleSubmit = () => {
    formRef.current.handleSubmit();
  };

  return (
    <>
      <div className="px-[24px]">
        <div className="flex justify-between items-center py-[24px]">
          <BreadCrumbs  />
          <div>
            <div className="flex flex-row-reverse gap-4">
              {" "}
              <Button
                type="submit"
                onClick={handleSubmit}
                className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
              >
                <span>
                  <SaveIcon />
                </span>
                Save Vehicle
              </Button>
              <Button
                className="border  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                type="button"
                onClick={() => history.goBack()}
              >
                <span className="flex items-center text-[#FB6DB2]">Cancel</span>
              </Button>
            </div>
          </div>
        </div>
        <VehicleInformations isNew={true} formRef={formRef} />
      </div>
    </>
  );
};
