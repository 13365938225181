import { VehicleStatuses } from "../../options/VehicleStatus";
import humps from "humps";
export const getColorArray = (array: any) => {
  let colorArray = [];
  array.map((item: any) => {
    let color = getColor(item);
    colorArray.push(color);
  });
  return array;
};

export const getColor = (item: any) => {
  switch (item) {
    case "available":
      return "#BAEDBD";
    case "reserved":
      return "#BAEDBD";
    case "available":
      return "#BAEDBD";
    case "available":
      return "#BAEDBD";
    case "available":
      return "#BAEDBD";
  }
};
export const returnPercentage = (key: any, total: any) => {
  if (key) {
    return Math.round((key / total) * 100);
  } else {
    return 0;
  }
};
export const getCurrentVehicleTypesFromKey = (
  key: any,
  camilize: Boolean = false
) =>
  VehicleStatuses.find((x) =>
    camilize ? humps.camelize(x.value) === key : x.value === key
  );
export const getCurrentVehicleTypesForGraph = (array: any) => {
  for (let item of array.vehicleTypesNew) {
    Object.keys(item).map((key) => {
      if (key !== "totals" && key !== "vehicleTypes" && key !== "available") {
        if (item.status.length > 0) {
          item.status.map((statusItem: any) => {
            item[statusItem.status.name] = statusItem.count;
          });
        }
        // let decamalizedValue = humps.decamelize(key, { separator: "-" });
        // item[decamalizedValue] = item[key];
      } else if (key === "vehicleTypes") {
        item["vehicleType"] = item?.vehicleTypes.name;
      }
    });
  }
  return array.vehicleTypesNew;
};
export const getStatusKeyValue = (key: any, array: any) => {
  let value = 0;
  if (array?.length > 0) {
    array.map((item: any) => {
      if (item?.status?.name === key) {
        value = item.totals;
      }
    });
  }
  return value;
};
