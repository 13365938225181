import { Modal } from "antd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
export interface FixedModalProps {
  isVisible: boolean;
}
export const FixedModal: React.FC<FixedModalProps> = ({ isVisible }) => {
  return (
    <>
      <div className="w-full h-full"></div>
      <div className=""></div>
      <Modal
        className="fixed_modal"
        bodyStyle={{ height: "200px" }}
        maskClosable={false}
        visible={isVisible}
        footer={false}
        closable={false}
        centered={true}
      >
        <div className="flex items-center justify-center flex-col">
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 60, color: "#fb6db2" }}
                spin
              />
            }
          />
          <p className="font-medium mt-6 text-center">
            Please wait. It will take some time to complete the process.
          </p>
        </div>
      </Modal>
    </>
  );
};
