import moment from "moment";
import { checkData } from "../../utils";

export const ReportType = [
  { label: "Unit Calender", value: "status-anayltics" },
  { label: "On-Rent", value: "on-rent" },
];

export const VehicleTypes = [
  "ICE 15 Px Van",
  "ICE 12 Px Van",
  "ICE 5-7 Seat Px Van",
  "ICE Mini Van",
  "ICE Passenger Sedan",
  "ICE Full-size SUV",
  "EV Cargo",
  "ICE 107ft - 159WB Cargo",
  "ICE 96.3ft - 144WB Cargo",
  "ICE MR 72ft - 130WB Cargo",
  "ICE 15px Conv Cargo",
  "ICE 12px Conv Cargo",
  "ICE 20ft Box Truck",
  "ICE 16ft Box Truck",
  "ICE 14ft Box Truck",
  "ICE Pickup Truck",
  "ICE Step Van",
  "Other Unknown",
  "EV Flat-bed Acrimoto",
];
export const sendDateFormat = (date: any) => {
  return moment(date).format("YYYY-MM-DD");
};
export const returnCount = (data: any, key: string) => {
  let count = 0;
  data.map((item: any) => {
    let vehicleTypes = item.vehicleTypes;
    if (vehicleTypes?.length > 0) {
      vehicleTypes.map((obj: any) => {
        let statusArray = obj.status;
        statusArray?.map((statusObj: any) => {
          if (statusObj.status === key) {
            count = count + statusObj.count;
          }
        });
      });
    }
  });

  return count;
};
export const calculatePercentage = (data: any, total: any, key: any) => {
  if (key === "available") {
    return total
      ? (
          ((returnKeyValue("In Body Shop", data) +
            returnKeyValue("In Maintenance", data) +
            returnKeyValue("In Transit Close RA", data) +
            returnKeyValue("In Transit Open RA", data) +
            returnKeyValue("Missing", data) +
            returnKeyValue("Pending Registration", data) +
            returnKeyValue("Hold For Sale", data) +
            returnKeyValue("At Auction", data) +
            returnKeyValue("Sold", data)) /
            total) *
          100
        )
          .toFixed(2)
          .replace(/\.00$/, "") + "%"
      : 0;
  } else if (key === "fleet") {
    return total
      ? (
          ((returnKeyValue("Hold For Sale", data) +
            returnKeyValue("At Auction", data) +
            returnKeyValue("Sold", data)) /
            total) *
          100
        )
          .toFixed(2)
          .replace(/\.00$/, "") + "%"
      : 0;
  } else {
    if (total) {
      return (
        ((data["On Rent"] / total) * 100).toFixed(2).replace(/\.00$/, "") + "%"
      );
    } else {
      return "0%";
    }
  }
};
export const returnKeyValue = (key: any, data: any) => {
  return data[key] ? data[key] : 0;
};

export const changeData = (data: any, staticArray: any) => {
  let editedArray: any = [];
  if (data.length > 0) {
    data?.map((item: any) => {
      item.vehicleTypes.map((vehicleObj: any) => {
        staticArray.map((staticItem: any) => {
          if (vehicleObj.type === staticItem.value) {
            staticItem.count += getCount(vehicleObj.status);
          }
        });
      });
    });
  }
  return staticArray;
};
export const getCount = (array: any) => {
  let count = 0;
  array?.length > 0 &&
    array.map((item: any) => {
      count = count + item.count;
    });
  return count;
};
export const returnPerVehicleCount = (
  data: any,
  key: string,
  vehicleName: string
) => {
  let count = 0;
  data.map((item: any) => {
    let vehicleTypes = item.vehicleTypes;
    if (vehicleTypes?.length > 0) {
      vehicleTypes.map((obj: any) => {
        let statusArray = obj.status;
        statusArray?.map((statusObj: any) => {
          if (obj.type === vehicleName) {
          }
          if (statusObj.status === key && obj.type === vehicleName) {
            count = count + statusObj.count;
          }
        });
      });
    }
  });
  return count;
};
export const changeDocument = (array: any) => {
  let docArray = [];
  for (let doc of array) {
    let item = Object.assign({}, doc);
    if (Object.keys(item).length > 0) {
      let formattedArray = [];
      for (let dataOne of item?.data) {
        for (let vType of dataOne?.vehicleTypes) {
          let vTypeIndex = formattedArray.findIndex(
            (x) => x.name === vType.type.name
          );
          if (vTypeIndex === -1) {
            let newVType: any = Object.assign(
              {},
              {
                name: vType.type.name,
                count: 0,
              }
            );
            for (let status of vType.status) {
              newVType[status.status.name] = parseInt(status.count);
              if (!item[status.status.name])
                item[status.status.name] = parseInt(status.count);
              else {
                item[status.status.name] += parseInt(status.count);
              }
              newVType.count = status.count;
            }

            formattedArray.push(newVType);
          } else {
            for (let status of vType.status) {
              formattedArray[vTypeIndex].count += parseInt(status.count);
              if (!formattedArray[vTypeIndex][status.status.name])
                formattedArray[vTypeIndex][status.status.name] = parseInt(
                  status.count
                );
              else {
                formattedArray[vTypeIndex][status.status.name] += parseInt(
                  status.count
                );
              }
              if (!item[status.status.name])
                item[status.status.name] = parseInt(status.count);
              else item[status.status.name] += parseInt(status.count);
            }
          }
        }
      }
      item.data = formattedArray;
      docArray.push(item);
    }
  }
  return docArray;
};

export const HeaderArray = [
  "Date",
  "Total Units",
  "On Rent",
  "Available",
  "Avail.Manheim",
  "Deactivated",
  "Reserved",
  "In Bodyshop",
  "In Maintenance",
  "Awaiting Maint",
  "In Transit Close RA",
  "In Transit Open RA",
  "Missing",
  "Pending Reg",
  "Hold for Sale",
  "Auction",
  "Sold",
  "Total Asset Utilization",
  "Availability Utilization",
  "Fleet Utilization",
];
export const HeaderDetailArray = [
  "",
  "Total Units",
  "On Rent",
  "Available",
  "Avail.Manheim",
  "Reserved",
  "In Bodyshop",
  "In Maintenance",
  "Awaiting Maint",
  "In Transit Close RA",
  "In Transit Open RA",
  "Missing",
  "Pending Reg",
  "Hold for Sale",
  "Auction",
  "Sold",
  "Archieved",
];
export const downloadBlob = (content: any, filename: any) => {
  // Create a blob
  var blob = new Blob([arrayChange(content)], {
    type: "text/csv;charset=utf-8;",
  });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
};
export const arrayChange = (data: any) => {
  return data
    .map(
      (row: any) =>
        row
          .map(String) // convert every value to String
          .map((v: any) => v.replaceAll('"', '""')) // escape double colons
          .map((v: any) => `"${v}"`) // quote it
          .join(",") // comma-separated
    )
    .join("\r\n"); // rows starting on new lines
};

export const getAllcounts = (documents: any) => {
  for (let item of documents) {
    if (item.vehicleTypes?.length > 0) {
      for (let vehicleType of item.vehicleTypes) {
        if (item[vehicleType.type.name]) {
          item[vehicleType.type.name] += vehicleType.count;
        } else {
          item[vehicleType.type.name] = vehicleType.count;
        }
      }
    }
  }
  return documents;
};
export const calculatePasangerTotal = (record: any) => {
  return (
    checkData(record["ICE 15 Px Van"]) +
    checkData(record["ICE 12 Px Van"]) +
    checkData(record["ICE Mini Van"]) +
    checkData(record["ICE Full-size SUV"])
  );
};
export const CalculateCargoSum = (record: any) => {
  return (
    checkData(record["EV Cargo"]) +
    checkData(record["ICE 107ft - 159WB Cargo"]) +
    checkData(record["ICE 96.3ft - 144WB Cargo"]) +
    checkData(record["ICE MR 72ft - 130WB Cargo"]) +
    checkData(record["ICE 15px Conv Cargo"]) +
    checkData(record["ICE 20ft Box Truck"]) +
    checkData(record["ICE 12px Conv Cargo"]) +
    checkData(record["ICE 16ft Box Truck"]) +
    checkData(record["ICE 14ft Box Truck"]) +
    checkData(record["ICE Pickup Truck"]) +
    checkData(record["ICE Step Van"])
  );
};
