import { Table } from "../../../atoms/Table/Table";
import { ListHeader } from "../../../molecules/list-header/ListHeader";
import type { ColumnsType } from "antd/es/table";
import { useFormTemplates } from "../TemplateContext";
import { useEffect } from "react";
import { Tag } from "antd";

const columns: ColumnsType = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Current Version",
    dataIndex: "version",
    // sorter: true,
    render: (value) => value,
  },
  {
    title: "Components",
    dataIndex: "components",
    // sorter: true,
    render: (value) => value.map((v: any) => v.header).join(", "),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (value) => <Tag color="blue">{value}</Tag>,
  },
  {
    title: "Tags",
    dataIndex: "tags",
    // sorter: true,
    render: (value) => value.join(", "),
  },
];

export interface AllFormsPageProps {}

export const AllFormsPage: React.FC<AllFormsPageProps> = (
  props: AllFormsPageProps
) => {
  const {
    actions: { getAll },
    state: { documents, pagination },
  } = useFormTemplates();

  useEffect(() => {
    getAll();
  }, []);

  const onTableActivity = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
  };
  return (
    <div className="w-full h-full bg-white p-2">
      {/* Title, Search, Filter */}
      <ListHeader
        onNewClicked={() => {}}
        onFilterChanged={(filter) => {}}
        onFilterClicked={() => {}}
      />
      {/* Table View */}
      <Table
        onChange={onTableActivity}
        columns={columns}
        dataSource={documents}
        pagination={{
          defaultPageSize: 10,
          showQuickJumper: true,
          showPrevNextJumpers: true,
          showTitle: true,
          showLessItems: true,
          total: pagination.total,
          position: ["bottomRight"],
          showTotal: (total) => <p> Total of {total}</p>,
        }}
      />
      {/* Pagination */}
    </div>
  );
};
