import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import { BreadCrumbs } from "../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../atoms/Button/Button";
import { PlusIcon } from "../../icons";
import { ContractsProvider } from "../schedule/planning/context";
import { ProcurementProvider } from "./context";
import { AllPipelineListing } from "./PipelineListing";
import { ProcurementDetail } from "./ProcurementDetail";
import { ProcurementLayout } from "./ProcurementLayout";

export interface ProcurementMainLayoutProps {}
export const ProcurementMainLayout: React.FC<
  ProcurementMainLayoutProps
> = () => {
  return (
    <>
      <ContractsProvider>
        <ProcurementProvider>
          {" "}
          <Switch>
            <Route
              exact
              path="/procurement"
              render={() => {
                return <ProcurementLayout />;
              }}
            />
            <Route
              exact
              path="/procurement/:id"
              render={() => {
                return <ProcurementDetail />;
              }}
            />
          </Switch>
        </ProcurementProvider>
      </ContractsProvider>
    </>
  );
};
