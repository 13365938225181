import { VendorProvider } from "./context";
import { Switch, Route, Redirect } from "react-router";
import { AllVendors } from "./all/AllVendors";
import { OneVendorPage } from "./one/OneVendor";
import { isPremitted } from "../../services/utils/permission";
export interface VendorMainLayoutProps {}

export const VendorMainLayout: React.FC<VendorMainLayoutProps> = (
  props: VendorMainLayoutProps
) => {
  return (
    <>
      <VendorProvider>
        <Switch>
          <Route
            exact
            path="/vendors"
            render={() => {
              if (isPremitted("vendor", "list")) {
                return <AllVendors />;
              } else {
                return <Redirect to="/forbidden" />;
              }
            }}
          />
          <Route
            exact
            path="/vendors/edit/:id"
            render={() => {
              if (isPremitted("vendor", "edit")) {
                return <OneVendorPage />;
              } else {
                return <Redirect to="/forbidden" />;
              }
            }}
          />
          <Route
            exact
            path="/vendors/add"
            render={() => {
              if (isPremitted("vendor", "create")) {
                return <OneVendorPage />;
              } else {
                return <Redirect to="/forbidden" />;
              }
            }}
          />
        </Switch>
      </VendorProvider>
    </>
  );
};
