export const SendComment: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1032_4432)">
        <path
          d="M17.3397 0.661663C17.0708 0.389509 16.7362 0.191305 16.3684 0.0862004C16.0005 -0.0189038 15.6118 -0.0273551 15.2397 0.061663L3.23968 2.58916C2.54862 2.68394 1.89773 2.96977 1.36034 3.41447C0.822938 3.85917 0.42037 4.44508 0.197966 5.10621C-0.0244375 5.76734 -0.0578421 6.47744 0.101514 7.15653C0.26087 7.83561 0.606657 8.45673 1.09993 8.94991L2.38843 10.2377C2.45817 10.3074 2.51348 10.3902 2.55119 10.4813C2.58889 10.5724 2.60826 10.6701 2.60818 10.7687V13.1447C2.60984 13.4787 2.68674 13.8081 2.83318 14.1084L2.82718 14.1137L2.84668 14.1332C3.06644 14.575 3.42537 14.9324 3.86818 15.1502L3.88768 15.1697L3.89293 15.1637C4.1932 15.3101 4.52261 15.387 4.85668 15.3887H7.23268C7.43147 15.3885 7.62219 15.4673 7.76293 15.6077L9.05068 16.8954C9.39608 17.2446 9.80717 17.522 10.2603 17.7116C10.7134 17.9013 11.1995 17.9993 11.6907 18.0002C12.1 17.9997 12.5065 17.9328 12.8944 17.8022C13.5495 17.5871 14.1315 17.1932 14.5746 16.6649C15.0178 16.1367 15.3045 15.4952 15.4024 14.8127L17.9337 2.78641C18.0273 2.41114 18.0218 2.01798 17.9177 1.64549C17.8135 1.27299 17.6144 0.933966 17.3397 0.661663ZM3.45043 9.17866L2.16118 7.89091C1.86097 7.59793 1.65056 7.2254 1.55465 6.81703C1.45873 6.40866 1.4813 5.98141 1.61968 5.58541C1.75386 5.17916 2.00189 4.81998 2.33428 4.55061C2.66668 4.28124 3.06944 4.113 3.49468 4.06591L15.3754 1.56466L4.10668 12.8349V10.7687C4.10782 10.4734 4.05041 10.1809 3.93776 9.90795C3.82512 9.63504 3.65948 9.38716 3.45043 9.17866ZM13.9287 14.5562C13.8711 14.9704 13.6992 15.3604 13.4323 15.6824C13.1653 16.0044 12.8139 16.2456 12.4175 16.3789C12.0211 16.5123 11.5954 16.5325 11.1881 16.4373C10.7809 16.3422 10.4082 16.1354 10.1119 15.8402L8.82193 14.5502C8.61371 14.3408 8.36604 14.1748 8.09325 14.0618C7.82046 13.9487 7.52796 13.8909 7.23268 13.8917H5.16643L16.4367 2.62516L13.9287 14.5562Z"
          fill="#194279"
        />
      </g>
      <defs>
        <clipPath id="clip0_1032_4432">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const ListCommentIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2817_6640)">
        <path
          d="M13.3333 0H2.66667C1.95942 0 1.28115 0.280952 0.781049 0.781049C0.280952 1.28115 0 1.95942 0 2.66667L0 10.6667C0 11.3739 0.280952 12.0522 0.781049 12.5523C1.28115 13.0524 1.95942 13.3333 2.66667 13.3333H4.6L7.56733 15.842C7.68771 15.9439 7.8403 15.9998 7.998 15.9998C8.1557 15.9998 8.30829 15.9439 8.42867 15.842L11.4 13.3333H13.3333C14.0406 13.3333 14.7189 13.0524 15.219 12.5523C15.719 12.0522 16 11.3739 16 10.6667V2.66667C16 1.95942 15.719 1.28115 15.219 0.781049C14.7189 0.280952 14.0406 0 13.3333 0V0ZM14.6667 10.6667C14.6667 11.0203 14.5262 11.3594 14.2761 11.6095C14.0261 11.8595 13.687 12 13.3333 12H11.4C11.0849 12.0001 10.7799 12.1118 10.5393 12.3153L8 14.46L5.462 12.3153C5.22104 12.1115 4.9156 11.9998 4.6 12H2.66667C2.31304 12 1.97391 11.8595 1.72386 11.6095C1.47381 11.3594 1.33333 11.0203 1.33333 10.6667V2.66667C1.33333 2.31304 1.47381 1.97391 1.72386 1.72386C1.97391 1.47381 2.31304 1.33333 2.66667 1.33333H13.3333C13.687 1.33333 14.0261 1.47381 14.2761 1.72386C14.5262 1.97391 14.6667 2.31304 14.6667 2.66667V10.6667Z"
          fill="#494949"
        />
        <path
          d="M4.66667 4.66732H8C8.17681 4.66732 8.34638 4.59708 8.4714 4.47205C8.59643 4.34703 8.66667 4.17746 8.66667 4.00065C8.66667 3.82384 8.59643 3.65427 8.4714 3.52925C8.34638 3.40422 8.17681 3.33398 8 3.33398H4.66667C4.48986 3.33398 4.32029 3.40422 4.19526 3.52925C4.07024 3.65427 4 3.82384 4 4.00065C4 4.17746 4.07024 4.34703 4.19526 4.47205C4.32029 4.59708 4.48986 4.66732 4.66667 4.66732Z"
          fill="#494949"
        />
        <path
          d="M11.3333 6H4.66667C4.48986 6 4.32029 6.07024 4.19526 6.19526C4.07024 6.32029 4 6.48985 4 6.66667C4 6.84348 4.07024 7.01304 4.19526 7.13807C4.32029 7.26309 4.48986 7.33333 4.66667 7.33333H11.3333C11.5101 7.33333 11.6797 7.26309 11.8047 7.13807C11.9298 7.01304 12 6.84348 12 6.66667C12 6.48985 11.9298 6.32029 11.8047 6.19526C11.6797 6.07024 11.5101 6 11.3333 6Z"
          fill="#494949"
        />
        <path
          d="M11.3333 8.66602H4.66667C4.48986 8.66602 4.32029 8.73625 4.19526 8.86128C4.07024 8.9863 4 9.15587 4 9.33269C4 9.5095 4.07024 9.67907 4.19526 9.80409C4.32029 9.92912 4.48986 9.99936 4.66667 9.99936H11.3333C11.5101 9.99936 11.6797 9.92912 11.8047 9.80409C11.9298 9.67907 12 9.5095 12 9.33269C12 9.15587 11.9298 8.9863 11.8047 8.86128C11.6797 8.73625 11.5101 8.66602 11.3333 8.66602Z"
          fill="#494949"
        />
      </g>
      <defs>
        <clipPath id="clip0_2817_6640">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const UploadCommentFiles: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z"
        fill="#374957"
      />
    </svg>
  );
};
