import react, { useEffect, useRef, useState } from "react";
import { PickAddIcon } from "../../../../../icons/PickAddIcon";
import { Drawer, Tabs, Timeline } from "antd";
import { VehicleDocuments } from "./VehicleDocuments";
import { VehiclePhotos } from "./VehiclePhotos";
import { Comments } from "../../../../../atoms/Comments/Comments";
import { VehicleMap } from "./VehicleMap";
import * as Yup from "yup";

import {
  FormikSinglSelect,
  SingleSelect,
} from "../../../../../atoms/Select/Select";
import moment from "moment";
import { useVehicles } from "../../../context";
import { useParams } from "react-router";
import {
  getAging,
  getCurrentVehicleFuelTypeFromKey,
} from "../../../../../options/VehicleLoan";
import {
  colorCode,
  IntegrationType,
  ShowDateFormat,
} from "../../../../../utils";
import { getCurrentStatusFromKey } from "../../../../../options/VehicleStatus";
import {
  AgingVehicle,
  HealthyVehicle,
  OldVehicle,
} from "../../../../../icons/VehicleAging";
import { Button } from "../../../../../atoms/Button/Button";
import { Form, Formik } from "formik";
import { PermissionsGate } from "../../../../../atoms/PermissionComponent/PermissionGate";
import { useCommonContext } from "../../../../../hooks/commonStateProvider";
const { TabPane } = Tabs;
interface VehicleOverviewProps {
  initialData?: any;
}
const { Item } = Timeline;
export const VehicleOverview: React.FC<VehicleOverviewProps> = ({
  initialData,
}) => {
  const {
    actions: {
      getVehicleHistory,
      CreateIntegration,
      getOneVehicle,
      DeleteIntegration,
      ResyncIntegration,
    },
    state: { documents },
  } = useVehicles();
  const {
    actions: { getAllComments, emptyState },
  } = useCommonContext();
  const { id }: any = useParams();
  const [Statushistory, setHistory] = useState([]);
  const [drawer, setdrawer] = useState(false);
  const [integrationDrawer, setIntegrationDrawer] = useState(false);
  const [aging, setAging]: any = useState();
  const [initialValues, setInitialValues]: any = useState({
    integrationType: "",
  });

  useEffect(() => {
    getVehicleHistory(id, (res) => {
      setHistory(res.data);
    });
  }, []);

  useEffect(() => {
    return () => {
      emptyState();
    };
  }, []);

  useEffect(() => {
    if (!integrationDrawer) {
      setInitialValues({
        ...initialValues,
        integrationType: "",
      });
    }
  }, [integrationDrawer]);

  useEffect(() => {
    if (initialData && initialData.financial) {
      setAging(
        getAging(
          initialData.financial?.purchaseDate,
          initialData.financial?.saleMillage
        )
      );
    }
  }, [initialData]);
  const formRef = useRef() as any;
  return (
    <>
      <div className="p-[24px]">
        <Drawer
          title="Status Updates"
          placement="right"
          onClose={() => setdrawer(false)}
          visible={drawer}
          width="33%"
        >
          <div className="">
            <Timeline>
              {Statushistory?.map((item: any) => (
                <Item className="history" color={"#bfbfbf"}>
                  <div className="">
                    <p className="font-medium text-[15px]">
                      <span className="capitalize">{item.user}</span>{" "}
                      &nbsp;Updated Status to &nbsp;
                      <span className={`capitalize ${colorCode(item?.status)}`}>
                        {item.status.name}
                      </span>
                    </p>
                    <p className="text-[#757575] font-medium text-[13px]">
                      {ShowDateFormat(item.createdAt)}
                    </p>
                  </div>
                </Item>
              ))}
            </Timeline>
          </div>
        </Drawer>
        {integrationDrawer && (
          <Drawer
            title="Integration"
            placement="right"
            onClose={() => {
              setIntegrationDrawer(false);
            }}
            visible={integrationDrawer}
            width="33%"
          >
            <div className="">
              <Formik
                initialValues={initialValues}
                innerRef={formRef}
                enableReinitialize={true}
                onSubmit={(values: any, actions) => {
                  CreateIntegration(
                    initialData?.id,
                    values.integrationType.value,
                    () => {
                      setIntegrationDrawer(false);
                    }
                  );
                }}
                validationSchema={Yup.object({
                  integrationType: Yup.mixed().required(
                    "IntegrationType is required"
                  ),
                })}
              >
                {({ values, setFieldValue, setErrors }) => (
                  <Form>
                    <FormikSinglSelect
                      isClearable
                      must={true}
                      options={IntegrationType}
                      label="Integration Type"
                      name="integrationType"
                      value={values.integrationType}
                      inputClassName="adjust_select_height"
                      onChange={(newValue) => {
                        setFieldValue("integrationType", newValue);
                      }}
                    />
                    <div className="mt-10">
                      <div className="flex flex-row-reverse gap-4">
                        {" "}
                        <Button
                          type="submit"
                          className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                        >
                          <span></span>
                          Submit
                        </Button>
                        <Button
                          className="border  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                          type="reset"
                          id="reset-form"
                          onClick={() => {
                            setIntegrationDrawer(false);
                          }}
                        >
                          <span className="flex items-center text-[#FB6DB2]">
                            Cancel
                          </span>
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>{" "}
            </div>
          </Drawer>
        )}
        <div className="flex justify-between">
          <div className="w-[43%]">
            <div className="common_card w-full pt-[24px] mb-[24px]">
              {" "}
              <h2 className="font-medium text-[18px] pl-[24px] mb-[20px]">
                Details
              </h2>
              <div className="plain_detail">
                <div className="V_detail_header">Name</div>
                <div className="V_detail_data">{initialData.vehicleName}</div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">VIN Number</div>
                <div className="V_detail_data">{initialData.vin}</div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Zeeba Type</div>
                <div className="V_detail_data">
                  {initialData.vehicleType?.name}
                </div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Zeeba Make</div>
                <div className="V_detail_data">
                  {" "}
                  {typeof initialData?.make === "object"
                    ? initialData?.make?.name
                    : initialData?.make}
                </div>
              </div>
              <div className="plain_detail">
                {" "}
                <div className="V_detail_header">Zeeba Model</div>
                <div className="V_detail_data">
                  {typeof initialData?.model === "object"
                    ? initialData?.model?.name
                    : initialData?.model}
                </div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Year</div>
                <div className="V_detail_data">{initialData.year}</div>
              </div>
              <div className="plain_detail">
                {" "}
                <div className="V_detail_header">License Plate</div>
                <div className="V_detail_data">{initialData.licensePlate}</div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Zeeba Status</div>
                <div className="flex justify-between items-center V_detail_data">
                  <div
                    className={`capitalize ${colorCode(initialData?.status)}`}
                  >
                    {getCurrentStatusFromKey(initialData?.status)?.label}
                  </div>
                  {Statushistory?.length > 0 && (
                    <p
                      className="font-medium text-[13px] cursor-pointer text-[#FB6DB2]"
                      onClick={() => setdrawer(true)}
                    >
                      History
                    </p>
                  )}
                </div>
              </div>
              <div className="plain_detail">
                {" "}
                <div className="V_detail_header">Zeeba Group</div>
                <div className="V_detail_data">
                  {initialData?.group?.name ? initialData?.group?.name : "-"}{" "}
                </div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Fuel Type</div>
                <div className="V_detail_data">
                  {
                    getCurrentVehicleFuelTypeFromKey(initialData.fuelType)
                      ?.label
                  }
                </div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Aging Status</div>
                <div className="V_detail_data">
                  <div className="flex items-center">
                    {aging === "healthy" ? (
                      <HealthyVehicle />
                    ) : aging === "old" ? (
                      <OldVehicle />
                    ) : (
                      <AgingVehicle />
                    )}
                    <p className="ml-2 text-[13px] text-[#757575] font-medium">
                      {aging}
                    </p>
                  </div>
                  {}
                </div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Zeeba Color</div>
                <div className="V_detail_data">{initialData.color}</div>
              </div>
              <div className="plain_detail">
                {" "}
                <div className="V_detail_header">Expiration Date</div>
                <div className="V_detail_data">
                  {initialData &&
                    ShowDateFormat(initialData.registrationExpirationDate)}
                </div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">State</div>
                <div className="V_detail_data">
                  {initialData.registrationState
                    ? initialData.registrationState
                    : "-"}
                </div>
              </div>
              <div className="plain_detail items-start  ">
                {" "}
                <div className="V_detail_header ">Additional Features</div>
                <div className="V_detail_data">
                  {initialData &&
                    initialData.additionalFeatures?.length > 0 && (
                      <div className="flex flex-wrap">
                        {initialData.additionalFeatures.map((item: any) => (
                          <div className="bg-[#F1FAFF] mb-2 mr-2 p-2 rounded-[4px] text-[#398CC0] text-[13px]">
                            {item}
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Sub Model</div>
                <div className="V_detail_data">
                  {initialData.subModel ? initialData.subModel : "-"}
                </div>
              </div>
            </div>
            <div className="common_card w-full  mb-[24px]">
              <div className="flex justify-between items-center p-[24px]">
                <h2 className="font-medium text-[18px]">Integrations</h2>
                <div className="flex ">
                  {initialData?.integrationType ? (
                    <div className="flex">
                      <button
                        onClick={() =>
                          ResyncIntegration(id, initialData?.integrationType)
                        }
                        className="text-[#FB6DB2] font-semibold text-[13px] mr-[24px] flex justify-between items-center"
                      >
                        Resync
                      </button>
                      <PermissionsGate action="delete" module="vehicle">
                        <button
                          className="text-[#FB6DB2] font-semibold text-[13px]"
                          onClick={() =>
                            DeleteIntegration(id, initialData?.integrationType)
                          }
                        >
                          Remove
                        </button>
                      </PermissionsGate>
                    </div>
                  ) : (
                    <div>
                      <PermissionsGate action="create" module="vehicle">
                        <button
                          onClick={() => {
                            setIntegrationDrawer(true);
                          }}
                          className="text-[#FB6DB2] font-semibold text-[13px] flex justify-between items-center"
                        >
                          <div className="mr-2">
                            {" "}
                            <PickAddIcon />
                          </div>{" "}
                          Add
                        </button>
                      </PermissionsGate>
                    </div>
                  )}
                </div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">Integration Type</div>
                <div className="V_detail_data capitalize ">
                  {initialData && initialData.integrationType
                    ? initialData.integrationType
                    : "N/A"}
                </div>
              </div>
            </div>
            <div className="common_card w-full  mb-[24px]">
              <div className="flex justify-between items-center p-[24px]">
                <h2 className="font-medium text-[18px]">Open Issues</h2>
                <div className="flex ">
                  <button className="text-[#FB6DB2] font-semibold text-[13px] mr-[24px] flex justify-between items-center">
                    <div className="mr-2">
                      {" "}
                      <PickAddIcon />
                    </div>{" "}
                    Add
                  </button>
                  <button className="text-[#FB6DB2] font-semibold text-[13px]">
                    View All
                  </button>
                </div>
              </div>
            </div>

            <div className="common_card w-full  mb-[24px]">
              <div className="flex justify-between items-center p-[24px]">
                <h2 className="font-medium text-[18px]">Service Reminders</h2>
                <div className="flex ">
                  {" "}
                  <button className="text-[#FB6DB2] font-semibold text-[13px] mr-[24px] flex justify-between items-center">
                    <div className="mr-2">
                      {" "}
                      <PickAddIcon />
                    </div>{" "}
                    Add
                  </button>
                  <button className="text-[#FB6DB2] font-semibold text-[13px]">
                    View All
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[56%]">
            <div className="common_card w-full mb-[24px]">
              <Tabs
                defaultActiveKey="1"
                className="inner_overview_tab"
                onChange={(activeKey) => {
                  if (activeKey === "3") {
                    getAllComments(id);
                  }
                }}
              >
                <TabPane tab="Documents" key="1">
                  <VehicleDocuments />
                </TabPane>
                <TabPane tab="Photos" key="2">
                  <VehiclePhotos />
                </TabPane>
                <TabPane tab="Comments" key="3">
                  <div className="">
                    {" "}
                    <Comments model="vehicle" id={id} />
                  </div>
                </TabPane>
              </Tabs>
            </div>
            <div className="common_card w-full mb-[24px] h-[800px]">
              <VehicleMap initialData={initialData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
