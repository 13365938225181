import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Search } from "../../atoms/Search/Search";
import { Table } from "../../atoms/Table/Table";
import { useSearchParams } from "../../hooks/useSearchParams";
import { ListHeader } from "../../molecules/list-header/ListHeader";
import { useProcurement } from "./context";
import { colorCodeForVehiclesInFleet } from "./procurementLoad";
import { VinSelectedFloatBar } from "./VinSelectedFloatBar";

export interface VinsListProps {
  onInFleetVehiclesClicked: (vehiclesList: any) => void;
  setFixedModal?: any;
}
export const VinsList: React.FC<VinsListProps> = ({
  onInFleetVehiclesClicked,
  setFixedModal,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { query, updateSearchParams }: any = useSearchParams();
  const [vinsListing, setVinsListing] = useState<any>([]);
  const [searchText, setSearchText]: any = useState("");
  const [ClonedDocument, setClonedDocument]: any = useState([]);
  const { id }: any = useParams();
  const {
    actions: { getVinsList },
    state: { vinsList, procurement },
  } = useProcurement();

  useEffect(() => {
    if (Object.keys(query).length !== 0) {
      getVinsList(id, true, query);
    }
  }, [query]);

  const onSelectChange = (record: any) => {
    if (selectedRowKeys.length > 0) {
      let indexValue = selectedRowKeys.findIndex((item) => item === record.key);
      if (indexValue === -1) {
        setSelectedRowKeys((prevstate) => [...prevstate, record.key]);
      } else {
        let items = [...selectedRowKeys];
        items.splice(indexValue, 1);
        setSelectedRowKeys(items);
      }
    } else {
      setSelectedRowKeys((prevstate) => [...prevstate, record.key]);
    }
  };
  useEffect(() => {
    if (vinsList) {
      let documents: any = vinsList.data;
      documents.map((item: any, i: number) => {
        item["key"] = i.toString();
      });
      setVinsListing(documents);
      setClonedDocument(documents);
    }
  }, [vinsList]);

  const searchDocument = (value: any) => {
    if (value !== "") {
      let filteredArray = ClonedDocument?.filter((x: any) => {
        if (x.vin?.includes(value)) {
          return x.vin.includes(value);
        } else if (x.fuelType?.toLowerCase().includes(value.toLowerCase()))
          return x.fuelType.includes(value);
        else if (x.model?.toLowerCase().includes(value.toLowerCase()))
          return x.model.includes(value);
        else if (x.name?.includes(value)) return x.name.includes(value);
      });
      setVinsListing(filteredArray);
    } else {
      setSearchText(value);
      setVinsListing(ClonedDocument);
    }
  };

  const rowSelection = {
    onSelect: onSelectChange,
    onSelectAll: (selectedRows: any) => {
      if (selectedRows) {
        setSelectedRowKeys([]);
        vinsListing.map((item: any) => {
          setSelectedRowKeys((prevstate) => [...prevstate, item.key]);
        });
      } else {
        setSelectedRowKeys([]);
      }
    },
  };

  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
      render: (value, record: any) =>
        value ? (
          <div
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <Link
              className="cursor-pointer underline font-medium text-[#398CC0]"
              to={`/vehicles/${record.referenceId}`}
            >
              <div>{value}</div>
            </Link>
          </div>
        ) : (
          <div className="capitalize">Yet to be assigned</div>
        ),
    },
    {
      title: "VIN",
      dataIndex: "vin",
      render: (value, record: any) => <div className="capitalize">{value}</div>,
    },
    {
      title: "Type",
      dataIndex: "fuelType",
      render: (value, record: any) => <div className="capitalize">{value}</div>,
    },
    {
      title: "Make",
      dataIndex: "make",
      render: (value, record: any) => <div>{record?.make?.name}</div>,
    },
    {
      title: "Model",
      dataIndex: "model",
      render: (value, record: any) => <div>{record?.model?.name}</div>,
    },
    {
      title: "Year",
      dataIndex: "year",
      render: (value, record: any) => <div>{value}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value, record: any) => (
        <div className={`capitalize ${colorCodeForVehiclesInFleet(value)}`}>
          {value}
        </div>
      ),
    },
  ];

  const handleKeyDown = ({ key, target }: any) => {
    if (key === "Enter") {
      setVinsListing(ClonedDocument);

      searchDocument(searchText);
      // onFilterChanged && onFilterChanged(newFilter);
    }
  };

  return (
    <>
      {selectedRowKeys.length > 0 && (
        <VinSelectedFloatBar
          selectedItems={selectedRowKeys}
          onClose={() => {
            setSelectedRowKeys([]);
          }}
          onSuccessClick={() => {
            setFixedModal(true);
            onInFleetVehiclesClicked(
              selectedRowKeys.map((x: any) => vinsListing[x].id)
            );
            setSelectedRowKeys([]);
          }}
        />
      )}
      <div className="flex justify-between items-center p-[21px] gap-2">
        <Search
          className="w-60"
          label=""
          placeholder={"Search by name"}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
      </div>
      <Table
        className={`${
          procurement.status === "keeping" ? "show_select" : "hide_select"
        } vehicle_table`}
        columns={columns}
        dataSource={vinsListing}
        rowSelection={{
          ...rowSelection,
          selectedRowKeys,
          getCheckboxProps: (record: any) => ({
            disabled: record.status !== "inprogress", // Column configuration not to be checked
          }),
        }}
        pagination={false}
      />
    </>
  );
};
