import { Drawer } from "antd";
import { Table } from "../../atoms/Table/Table";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { useGroups } from "./context";
export interface SubClientsProps {
  dragger: any;
  onClose: any;
}

export const SubClients: React.FC<SubClientsProps> = ({ dragger, onClose }) => {
  const {
    state: { subDocuments },
    actions: { },
  } = useGroups();
  const subClientColumns: ColumnsType = [
    { title: "Client", dataIndex: "name", render: (value) => <div>{value}</div> },
    {
      title: "Assigned Vehicles",
      dataIndex: "totalVehicles",
      render: (value, record: any) => value > 0 ? (
        <Link
          to={`clients/${record.id}/vehicles`}
          className="underline font-medium text-[#398CC0]"
        >
          {value}
        </Link>
      ) : "N/A",
    },
    {
      title: "Assigned Contacts",
      dataIndex: "totalContacts",
      render: (value, record: any) => value > 0 ? (
        <Link
          to={`clients/${record.id}/contacts`}
          className="underline font-medium text-[#398CC0]"
        >
          {value}
        </Link>
      ) : "N/A",
    },
  ];
  return (
    <>
      <Drawer
        visible={dragger}
        placement="right"
        onClose={onClose}
        width="33%"
        title="Sub Clients"
        className="table_drawer"
      >
        <Table
          columns={subClientColumns}
          pagination={false}
          dataSource={subDocuments}
        ></Table>
      </Drawer>
    </>
  );
};
