import moment from "moment";
import { useEffect } from "react";
import { useContract } from "../planning/context";
import {
  checkVehicleCount,
  daysInCalender,
  findNextMonthWeek,
  getCurrentMonthDates,
} from "../ScheduleLoad";

export const CalenderView = ({
  days,
  date,
  triggerEvent,
  setTriggerEvent,
  setSelectedData,
}) => {
  let nextMonthWeek = findNextMonthWeek(date);
  let previousMonthWeek = [];
  /* calculating the previous month dates */
  const {
    actions: { getAvailability, clearAvailability },
    state: { documents, pagination, availableVehicle },
  } = useContract();
  useEffect(() => {
    clearAvailability();
    getAvailability(
      moment(date).startOf("month").format("YYYY-MM-DD"),
      moment(date).endOf("month").format("YYYY-MM-DD")
    );
  }, [date]);

  const findStartOfDay = () => {
    let previousWeekStartDate = moment(date)
      .startOf("month")
      .subtract(1, "days")
      .date();
    if (startofDay.length > 0) {
      let start = previousWeekStartDate - moment(date).startOf("month").day();
      for (let i = 0; i < startofDay.length; i++) {
        previousMonthWeek.push({ value: start + i + 1, key: "previous" });
      }
    }
  };
  let array = getCurrentMonthDates(days, date);
  let startofDay = Array.from(
    { length: moment(date).startOf("month").format("d") },
    (_, i) => i + 1
  );
  findNextMonthWeek();
  findStartOfDay();

  array = [...previousMonthWeek, ...array, ...nextMonthWeek];

  const getCurrentDay = (item) => {
    return item && item <= 7 ? daysInCalender[item] : "";
  };
  return (
    <>
      {array.length > 0 &&
        array?.map((item, index) => (
          <>
            <>
              <div
                className={` border-l-0   ${
                  (index + 1) % 7 === 0 ? "calender_box" : ""
                } border-t-0 py-2 pl-[12px] pr-[10px] min-h-[120px]  border-r border-b border-[#F2F5F7]`}
              >
                <div className="flex justify-between">
                  <p
                    className={`font-medium text-[16px] ${
                      item?.current
                        ? "bg-[#FB6DB2] w-[30px] h-[30px] items-center flex justify-center rounded-[50%] text-[white] p-[10px]"
                        : `${item?.key ? "text-[#C5C5C5]" : "text-[#303030]"}`
                    } `}
                  >
                    {item?.value}
                  </p>
                  <p className="text-[#878787] text-[14px] font-semibold">
                    {getCurrentDay(index)}
                  </p>
                </div>
                {availableVehicle &&
                  Object.keys(availableVehicle).length > 0 &&
                  !item.key && (
                    <div
                      onClick={() => {
                        setTriggerEvent(true);
                        setSelectedData(item.actualDate);
                      }}
                      className={`${checkVehicleCount(
                        availableVehicle[item?.fullDate]?.totalAvailable
                      )} mt-5 mr-2 font-semibold text-[12px] cursor-pointer  px-[${
                        triggerEvent ? "10px" : "14px"
                      }] py-[7px] rounded-[4px]`}
                    >
                      {availableVehicle[item?.fullDate]?.totalAvailable}{" "}
                      Vehicles
                    </div>
                  )}
              </div>
            </>
          </>
        ))}
    </>
  );
};
