export const VehicleStatuses = [
  {
    label: "Available",
    value: "available",
  },
  {
    label: "Available at Manheim",
    value: "available-at-manheim",
  },
  {
    label: "Reserved",
    value: "reserved",
  },
  {
    label: "On Rent",
    value: "on-rent",
  },

  {
    value: "pending-registration",
    label: "Pending Registration",
  },
  {
    value: "in-body-shop",
    label: "In Body Shop",
  },
  {
    value: "in-maintenance",
    label: "In Maintenance",
  },
  {
    value: "awaiting-body-shop",
    label: "On Lot - Awaiting Body Shop",
  },
  {
    value: "deactivated",
    label: "Deactivated",
  },
  {
    value: "archieved",
    label: "Archieved",
  },
  {
    value: "in-transit-open-ra",
    label: "In Transit Open RA",
  },
  {
    value: "in-transit-close-ra",
    label: "In Transit Close RA",
  },
  {
    value: "hold-for-sale",
    label: "Hold for Sale",
  },
  {
    value: "sold",
    label: "Sold",
  },
  {
    value: "at-auction",
    label: "At Auction",
  },
  {
    value: "missing",
    label: "Missing",
  },
  {
    value: "stolen",
    label: "Stolen",
  },
  {
    value: "sold-archived",
    label: "Sold Archived",
  },
];
// {
//   value: "pre-delivery",
//   label: "Pre-Delivery",
// },
export const getCurrentStatusFromKey = (key: any) => {
  return VehicleStatuses.find((x) => x.label === key?.name);
};

export const setCurrentStatusOfMultiselect = (key: any, statusArray: any) =>
  statusArray.find((x: any) => x.value === key);
export const getStatusArray = (queryArray: any, statusArray: any) => {
  if (queryArray) {
    if (typeof queryArray === "string") {
      return setCurrentStatusOfMultiselect(queryArray, statusArray);
    } else {
      let array: any = [];
      if (queryArray.length > 0) {
        statusArray.map((x: any) => {
          queryArray.map((queryItem: any) => {
            if (x.value === queryItem) {
              array.push(x);
            }
          });
        });
      }
      return array;
    }
  }
};
// {
//   label: "Available",
//   value: "available",
// },
// {
//   label: "Reserved",
//   value: "reserved",
// },
// {
//   label: "On Rent",
//   value: "on-rent",
// },

// {
//   value: "pending-registration",
//   label: "Pending Registration",
// },
// {
//   value: "in-body-shop",
//   label: "In Body Shop",
// },
// {
//   value: "in-maintanance",
//   label: "In Maintenance",
// },
// {
//   value: "awaiting-body-shop",
//   label: "On Lot - Awaiting Body Shop",
// },
// {
//   value: "deactivated",
//   label: "Deactivated",
// },
// {
//   value: "in-transit",
//   label: "In Transit",
// },
// {
//   value: "hold-billing",
//   label: "With Customer - Hold Billing",
// },
// {
//   value: "hold-for-sale",
//   label: "Hold for Sale",
// },
// {
//   value: "sold-not-delivered",
//   label: "Sold Not Delivered",
// },
// {
//   value: "sold-delivered",
//   label: "Sold Delivered",
// },
// {
//   value: "sold",
//   label: "Sold",
// },
// {
//   value: "at-auction",
//   label: "At Auction",
// },
// {
//   value: "missing",
//   label: "Missing",
// },
// {
//   value: "stolen",
//   label: "Stolen",
// }, {
//   label: "Available at Manhiem",
//   value: "available-at-manhiem",
// },

// {
//   value: "in-transit-open-ra",
//   label: "In Transit Open RA",
// },
// {
//   value: "in-transit-close-ra",
//   label: "In Transit Close RA",
// },
