import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useDashboard } from "./context";
import { getCurrentVehicleTypesForGraph } from "./dashboarLoan";
export interface MakeVehicleBarProps {}
// export const MakeVehicleBar: React.FC<MakeVehicleBarProps> = () => {
//   const {
//     state: { documents },
//   } = useDashboard();
//   useEffect(() => {
//     if (Object.keys(documents).length > 0) {
//       documents?.vehicleTypesNew.map(
//         (item: any) => (item["name"] = item.vehicleTypes)
//       );
//     }
//   }, [documents]);
//   return (
//     <>
//       {Object.keys(
//         documents.length > 0 && (
//           <ResponsiveContainer width="100%" height="100%">
//             <BarChart
//               width={500}
//               height={300}
//               data={documents?.vehicleTypesNew}
//               margin={{
//                 top: 20,
//                 right: 30,
//                 left: 20,
//                 bottom: 5,
//               }}
//             >
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="name" />
//               <YAxis />
//               <Tooltip />
//               <Legend />
//               <Bar dataKey="available" stackId="a" fill="#8884d8" />
//               <Bar dataKey="on-rent" stackId="a" fill="#82ca9d" />
//             </BarChart>
//           </ResponsiveContainer>
//         )
//       )}
//     </>
//   );
// };
const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
];
export const MakeVehicleBar: React.FC<MakeVehicleBarProps> = () => {
  const {
    state: { documents },
  } = useDashboard();
  const [graphData, setGraph]: any = useState([]);
  useEffect(() => {
    if (Object.keys(documents).length > 0) {
      setGraph(getCurrentVehicleTypesForGraph(documents));
    }
  }, [documents]);
  useEffect(() => {}, [graphData]);

  return (
    <div className="w-full h-full overflow-auto">
      {Object.keys(documents).length > 0 && (
        <>
          <ResponsiveContainer width={"100%"} height={400}>
            <BarChart
              className="w-full h-full"
              width={100}
              height={300}
              barSize={39}
              data={graphData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis
                dataKey="vehicleType"
                stroke="#F4F4F4"
                tick={{ fill: "#3F4549" }}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: 5,
                  color: "#3F4549",
                  marginTop: 50,
                  fontWeight: 500,
                }}
                tickMargin={10}
              />
              <YAxis
                stroke="#F4F4F4"
                tick={{ fill: "#3F4549" }}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: 12,
                  fontWeight: 500,
                  color: "#3F4549",
                }}
              />
              <Tooltip
                cursor={{ fill: "transparent" }}
                wrapperStyle={{
                  outline: "none",
                  border: "none",
                }}
              />
              <Legend
                iconType={"circle"}
                iconSize={8}
                wrapperStyle={{
                  paddingTop: "15px",
                }}
                style={{ paddingRight: 50 }}
              />
              <Bar dataKey="available" stackId="a" fill="#93E7B7" />
              <Bar dataKey="On Rent" stackId="a" fill="#5EDB79" />
              <Bar dataKey="Reserved" stackId="a" fill="#6AAE79" />
              <Bar dataKey="In Maintenance" stackId="a" fill="#FAA17B" />
              <Bar dataKey="In Body Shop" stackId="a" fill="#C6C7F8" />
              <Bar dataKey="Available at Manheim" stackId="a" fill="#A7D35E" />
              <Bar
                dataKey="On Lot - Awaiting Body Shop"
                stackId="a"
                fill="#DE8787"
              />
              <Bar dataKey="In Transit Close RA" stackId="a" fill="#FE8411" />
              <Bar dataKey="In Transit Open RA" stackId="a" fill="#E0D88D" />
              <Bar dataKey="Hold for Sale" stackId="a" fill="#87CEDE" />{" "}
              <Bar dataKey="Sold" stackId="a" fill="#A77A78" />
              <Bar dataKey="At Auction" stackId="a" fill="#EAB880" />{" "}
              <Bar dataKey="Missing" stackId="a" fill="#7DBCF7" />
              <Bar dataKey="Stolen" stackId="a" fill="#D3D3D3" />{" "}
              <Bar dataKey="Pending Registration" stackId="a" fill="#587FA7" />
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </div>
  );
};
