export const PipelineVehicle: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.356201"
        width="40"
        height="40"
        rx="4"
        fill="#00B0FF"
        fill-opacity="0.1"
      />
      <path
        d="M26.7999 20.4626H13.4666C13.2749 20.4626 13.0999 20.3793 12.9833 20.2293C12.8666 20.0876 12.8166 19.8876 12.8583 19.7043L13.7999 15.2043C14.1083 13.746 14.7333 12.4043 17.2083 12.4043H23.0666C25.5416 12.4043 26.1666 13.7543 26.4749 15.2043L27.4166 19.7126C27.4583 19.896 27.4083 20.0876 27.2916 20.2376C27.1666 20.3793 26.9916 20.4626 26.7999 20.4626ZM14.2333 19.2126H26.0249L25.2416 15.4626C25.0083 14.371 24.7333 13.6543 23.0583 13.6543H17.2083C15.5333 13.6543 15.2583 14.371 15.0249 15.4626L14.2333 19.2126Z"
        fill="#00B0FF"
      />
      <path
        d="M26.7665 29.6296H25.1999C23.8499 29.6296 23.5915 28.8546 23.4249 28.3462L23.2582 27.8462C23.0415 27.2129 23.0165 27.1296 22.2665 27.1296H17.9999C17.2499 27.1296 17.1999 27.2712 17.0082 27.8462L16.8415 28.3462C16.6665 28.8629 16.4165 29.6296 15.0665 29.6296H13.4999C12.8415 29.6296 12.2082 29.3546 11.7665 28.8712C11.3332 28.3962 11.1249 27.7629 11.1832 27.1296L11.6499 22.0546C11.7749 20.6796 12.1415 19.2129 14.8165 19.2129H25.4499C28.1249 19.2129 28.4832 20.6796 28.6165 22.0546L29.0832 27.1296C29.1415 27.7629 28.9332 28.3962 28.4999 28.8712C28.0582 29.3546 27.4249 29.6296 26.7665 29.6296ZM17.9999 25.8796H22.2665C23.7832 25.8796 24.1415 26.5546 24.4415 27.4379L24.6165 27.9546C24.7582 28.3796 24.7582 28.3879 25.2082 28.3879H26.7749C27.0832 28.3879 27.3749 28.2629 27.5832 28.0379C27.7832 27.8212 27.8749 27.5462 27.8499 27.2546L27.3832 22.1796C27.2749 21.0546 27.1415 20.4712 25.4665 20.4712H14.8165C13.1332 20.4712 12.9999 21.0546 12.8999 22.1796L12.4332 27.2546C12.4082 27.5462 12.4999 27.8212 12.6999 28.0379C12.8999 28.2629 13.1999 28.3879 13.5082 28.3879H15.0749C15.5249 28.3879 15.5249 28.3796 15.6665 27.9629L15.8332 27.4546C16.0415 26.7879 16.3499 25.8796 17.9999 25.8796Z"
        fill="#00B0FF"
      />
      <path
        d="M13.4666 17.9629H12.6333C12.2916 17.9629 12.0083 17.6796 12.0083 17.3379C12.0083 16.9962 12.2916 16.7129 12.6333 16.7129H13.4666C13.8083 16.7129 14.0916 16.9962 14.0916 17.3379C14.0916 17.6796 13.8083 17.9629 13.4666 17.9629Z"
        fill="#00B0FF"
      />
      <path
        d="M27.6331 17.9629H26.7998C26.4581 17.9629 26.1748 17.6796 26.1748 17.3379C26.1748 16.9962 26.4581 16.7129 26.7998 16.7129H27.6331C27.9748 16.7129 28.2581 16.9962 28.2581 17.3379C28.2581 17.6796 27.9748 17.9629 27.6331 17.9629Z"
        fill="#00B0FF"
      />
      <path
        d="M20.1333 15.4635C19.7916 15.4635 19.5083 15.1802 19.5083 14.8385V13.1719C19.5083 12.8302 19.7916 12.5469 20.1333 12.5469C20.475 12.5469 20.7583 12.8302 20.7583 13.1719V14.8385C20.7583 15.1802 20.475 15.4635 20.1333 15.4635Z"
        fill="#00B0FF"
      />
      <path
        d="M21.3833 15.4629H18.8833C18.5416 15.4629 18.2583 15.1796 18.2583 14.8379C18.2583 14.4962 18.5416 14.2129 18.8833 14.2129H21.3833C21.725 14.2129 22.0083 14.4962 22.0083 14.8379C22.0083 15.1796 21.725 15.4629 21.3833 15.4629Z"
        fill="#00B0FF"
      />
      <path
        d="M17.6333 23.7969H15.1333C14.7916 23.7969 14.5083 23.5135 14.5083 23.1719C14.5083 22.8302 14.7916 22.5469 15.1333 22.5469H17.6333C17.975 22.5469 18.2583 22.8302 18.2583 23.1719C18.2583 23.5135 17.975 23.7969 17.6333 23.7969Z"
        fill="#00B0FF"
      />
      <path
        d="M25.1333 23.7969H22.6333C22.2916 23.7969 22.0083 23.5135 22.0083 23.1719C22.0083 22.8302 22.2916 22.5469 22.6333 22.5469H25.1333C25.475 22.5469 25.7583 22.8302 25.7583 23.1719C25.7583 23.5135 25.475 23.7969 25.1333 23.7969Z"
        fill="#00B0FF"
      />
    </svg>
  );
};
export const FlippedVehicle: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="3" fill="#2CB67D" fill-opacity="0.1" />
      <path
        d="M22.9165 22.0829H12.9165C12.7249 22.0829 12.5499 21.9996 12.4332 21.8496C12.3165 21.7079 12.2665 21.508 12.3082 21.3246L13.0165 17.9496C13.2499 16.8496 13.7499 15.7246 15.7249 15.7246H20.1165C22.0915 15.7246 22.5915 16.8496 22.8249 17.9496L23.5332 21.3246C23.5749 21.508 23.5249 21.6996 23.4082 21.8496C23.2832 21.9996 23.1082 22.0829 22.9165 22.0829ZM13.6832 20.8329H22.1416L21.5915 18.2079C21.4165 17.3663 21.2082 16.9746 20.1082 16.9746H15.7165C14.6165 16.9746 14.4082 17.3663 14.2332 18.2079L13.6832 20.8329Z"
        fill="#2CB67D"
      />
      <path
        d="M22.8915 28.959H21.7165C20.5915 28.959 20.3582 28.2673 20.2415 27.8923L20.1165 27.5173C19.9665 27.0923 19.9665 27.0923 19.5249 27.0923H16.3249C15.8749 27.0923 15.8665 27.109 15.7332 27.5173L15.6082 27.8923C15.4832 28.2673 15.2499 28.9673 14.1249 28.9673H12.9499C12.4082 28.9673 11.8999 28.7423 11.5332 28.3423C11.1832 27.959 11.0082 27.434 11.0582 26.9173L11.4082 23.109C11.5165 21.9256 11.8999 20.834 13.9332 20.834H21.9082C23.9415 20.834 24.3249 21.9256 24.4332 23.109L24.7832 26.9173C24.8332 27.434 24.6582 27.9507 24.3082 28.3423C23.9415 28.734 23.4332 28.959 22.8915 28.959ZM21.4999 27.6923C21.5165 27.6923 21.5832 27.709 21.7165 27.709H22.8915C23.0832 27.709 23.2582 27.634 23.3832 27.4923C23.4999 27.359 23.5582 27.2006 23.5415 27.0256L23.1915 23.2173C23.1165 22.434 23.0832 22.0756 21.9082 22.0756H13.9332C12.7582 22.0756 12.7249 22.434 12.6499 23.2173L12.2999 27.0256C12.2832 27.2006 12.3415 27.3673 12.4582 27.4923C12.5832 27.634 12.7582 27.709 12.9499 27.709H14.1249C14.3082 27.709 14.3582 27.684 14.3665 27.684C14.3665 27.6756 14.3999 27.5757 14.4249 27.5007L14.5499 27.1256C14.7165 26.6173 14.9749 25.8423 16.3332 25.8423H19.5332C20.8749 25.8423 21.1249 26.5756 21.3082 27.1173L21.4415 27.5007C21.4499 27.5673 21.4749 27.659 21.4999 27.6923Z"
        fill="#2CB67D"
      />
      <path
        d="M12.9167 20.209H12.2917C11.9501 20.209 11.6667 19.9257 11.6667 19.584C11.6667 19.2423 11.9501 18.959 12.2917 18.959H12.9167C13.2584 18.959 13.5417 19.2423 13.5417 19.584C13.5417 19.9257 13.2584 20.209 12.9167 20.209Z"
        fill="#2CB67D"
      />
      <path
        d="M23.5417 20.209H22.9167C22.5751 20.209 22.2917 19.9257 22.2917 19.584C22.2917 19.2423 22.5751 18.959 22.9167 18.959H23.5417C23.8834 18.959 24.1667 19.2423 24.1667 19.584C24.1667 19.9257 23.8834 20.209 23.5417 20.209Z"
        fill="#2CB67D"
      />
      <path
        d="M16.0417 24.584H14.1667C13.8251 24.584 13.5417 24.3007 13.5417 23.959C13.5417 23.6173 13.8251 23.334 14.1667 23.334H16.0417C16.3834 23.334 16.6667 23.6173 16.6667 23.959C16.6667 24.3007 16.3834 24.584 16.0417 24.584Z"
        fill="#2CB67D"
      />
      <path
        d="M21.6667 24.584H19.7917C19.4501 24.584 19.1667 24.3007 19.1667 23.959C19.1667 23.6173 19.4501 23.334 19.7917 23.334H21.6667C22.0084 23.334 22.2917 23.6173 22.2917 23.959C22.2917 24.3007 22.0084 24.584 21.6667 24.584Z"
        fill="#2CB67D"
      />
      <path
        d="M25.5919 17.9087C25.5085 17.9087 25.4252 17.8921 25.3419 17.8587C25.0252 17.7171 24.8835 17.3504 25.0169 17.0337C25.1752 16.6754 25.0835 16.1504 24.7918 15.7171C24.5002 15.2754 24.0585 14.9837 23.6502 15.0004H23.6418C23.3002 15.0004 23.0252 14.7254 23.0168 14.3838C23.0168 14.0338 23.2919 13.7587 23.6335 13.7504C24.4919 13.7337 25.3002 14.2254 25.8252 15.0171C26.3585 15.8171 26.4835 16.7754 26.1585 17.5254C26.0668 17.7671 25.8335 17.9087 25.5919 17.9087Z"
        fill="#2CB67D"
      />
      <path
        d="M28.1834 18.7341C28.125 18.7341 28.075 18.7258 28.0167 18.7091C27.6834 18.6174 27.4917 18.2758 27.5834 17.9425C27.9 16.8091 27.6334 15.4591 26.875 14.3258C26.1167 13.1924 24.9667 12.4341 23.7917 12.2924C23.45 12.2508 23.2084 11.9424 23.25 11.6007C23.2917 11.2591 23.6084 11.0091 23.9417 11.0591C25.4667 11.2424 26.95 12.2091 27.9084 13.6424C28.8667 15.0758 29.1917 16.8091 28.7834 18.2924C28.7084 18.5508 28.4584 18.7341 28.1834 18.7341Z"
        fill="#2CB67D"
      />
    </svg>
  );
};
export const KeepingVehicle: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="3" fill="#F7B84A" fill-opacity="0.1" />
      <path
        d="M24.0832 28.9577H15.9166C14.1499 28.9577 12.7083 27.516 12.7083 25.7493V14.2494C12.7083 12.4827 14.1499 11.041 15.9166 11.041H24.0832C25.8499 11.041 27.2916 12.4827 27.2916 14.2494V25.7493C27.2916 27.516 25.8499 28.9577 24.0832 28.9577ZM15.9166 12.291C14.8416 12.291 13.9583 13.166 13.9583 14.2494V25.7493C13.9583 26.8243 14.8333 27.7077 15.9166 27.7077H24.0832C25.1582 27.7077 26.0416 26.8327 26.0416 25.7493V14.2494C26.0416 13.1744 25.1666 12.291 24.0832 12.291H15.9166Z"
        fill="#F7B84A"
      />
      <path
        d="M25.4166 21.4577H14.5833C13.5499 21.4577 12.7083 20.616 12.7083 19.5827V17.916C12.7083 16.8827 13.5499 16.041 14.5833 16.041H25.4166C26.4499 16.041 27.2916 16.8827 27.2916 17.916V19.5827C27.2916 20.616 26.4499 21.4577 25.4166 21.4577ZM14.5833 17.291C14.2416 17.291 13.9583 17.5743 13.9583 17.916V19.5827C13.9583 19.9243 14.2416 20.2077 14.5833 20.2077H25.4166C25.7583 20.2077 26.0416 19.9243 26.0416 19.5827V17.916C26.0416 17.5743 25.7583 17.291 25.4166 17.291H14.5833Z"
        fill="#F7B84A"
      />
      <path
        d="M16.6667 25.6254C16.3917 25.6254 16.125 25.5171 15.9333 25.3171C15.8833 25.2754 15.8417 25.2171 15.8 25.1587C15.7667 25.1087 15.7333 25.0421 15.7083 24.9837C15.675 24.9171 15.6583 24.8504 15.6417 24.7837C15.6333 24.7171 15.625 24.6504 15.625 24.5837C15.625 24.5171 15.6333 24.4504 15.6417 24.3837C15.6583 24.3171 15.675 24.2504 15.7083 24.1837C15.7333 24.1254 15.7667 24.0587 15.8 24.0087C15.8417 23.9504 15.8833 23.8921 15.9333 23.8504C16.3167 23.4587 17.0083 23.4587 17.4 23.8504C17.45 23.8921 17.4917 23.9504 17.5333 24.0087C17.5667 24.0587 17.6 24.1254 17.625 24.1837C17.6583 24.2504 17.675 24.3171 17.6917 24.3837C17.7 24.4504 17.7083 24.5171 17.7083 24.5837C17.7083 24.6504 17.7 24.7171 17.6917 24.7837C17.675 24.8504 17.6583 24.9171 17.625 24.9837C17.6 25.0421 17.5667 25.1087 17.5333 25.1587C17.4917 25.2171 17.45 25.2754 17.4 25.3171C17.2083 25.5171 16.9417 25.6254 16.6667 25.6254Z"
        fill="#F7B84A"
      />
      <path
        d="M23.3334 25.6254C23.0584 25.6254 22.7917 25.5171 22.6001 25.3171C22.4001 25.1254 22.2917 24.8587 22.2917 24.5837C22.2917 24.3087 22.4001 24.0421 22.6001 23.8504C22.9834 23.4587 23.6834 23.4587 24.0668 23.8504C24.2668 24.0421 24.3751 24.3087 24.3751 24.5837C24.3751 24.8587 24.2668 25.1254 24.0668 25.3171C23.8751 25.5171 23.6084 25.6254 23.3334 25.6254Z"
        fill="#F7B84A"
      />
      <path
        d="M22.0834 14.791H17.9167C17.5751 14.791 17.2917 14.5077 17.2917 14.166C17.2917 13.8243 17.5751 13.541 17.9167 13.541H22.0834C22.4251 13.541 22.7084 13.8243 22.7084 14.166C22.7084 14.5077 22.4251 14.791 22.0834 14.791Z"
        fill="#F7B84A"
      />
    </svg>
  );
};
export const InprogressVehicle: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.00683594"
        width="40"
        height="40"
        rx="3"
        fill="#FF8E3C"
        fill-opacity="0.1"
      />
      <path
        d="M24.6181 17.8967H14.9515C14.7598 17.8967 14.5848 17.8134 14.4681 17.6634C14.3515 17.5134 14.3015 17.3218 14.3431 17.1384L15.0265 13.8717C15.2515 12.7967 15.7431 11.7051 17.6598 11.7051H21.9098C23.8348 11.7051 24.3265 12.8051 24.5431 13.8717L25.2265 17.1384C25.2681 17.3218 25.2181 17.5134 25.1015 17.6634C24.9848 17.8134 24.8098 17.8967 24.6181 17.8967ZM15.7181 16.6467H23.8431L23.3181 14.1384C23.1515 13.3384 22.9598 12.9634 21.9015 12.9634H17.6515C16.6015 12.9634 16.4015 13.3384 16.2348 14.1384L15.7181 16.6467Z"
        fill="#FF8E3C"
      />
      <path
        d="M24.5928 24.5382H23.4595C22.3595 24.5382 22.1345 23.8548 22.0095 23.4882L21.8845 23.1215C21.7511 22.7215 21.7511 22.7215 21.3261 22.7215H18.2345C17.8095 22.7215 17.8095 22.7298 17.6761 23.1215L17.5511 23.4882C17.4261 23.8548 17.2011 24.5382 16.1011 24.5382H14.9678C14.4428 24.5382 13.9345 24.3131 13.5845 23.9298C13.2428 23.5548 13.0678 23.0465 13.1178 22.5382L13.4595 18.8632C13.5678 17.7132 13.9428 16.6465 15.9261 16.6465H23.6345C25.6178 16.6465 25.9928 17.7132 26.1011 18.8632L26.4428 22.5465C26.4928 23.0548 26.3178 23.5632 25.9761 23.9382C25.6261 24.3215 25.1178 24.5382 24.5928 24.5382ZM23.2678 23.2798C23.2844 23.2798 23.3511 23.2882 23.4595 23.2882H24.5928C24.7678 23.2882 24.9345 23.2215 25.0511 23.0881C25.1595 22.9715 25.2095 22.8132 25.2011 22.6548L24.8595 18.9715C24.7928 18.2298 24.7595 17.8881 23.6428 17.8881H15.9345C14.8178 17.8881 14.7845 18.2298 14.7178 18.9715L14.3761 22.6548C14.3595 22.8132 14.4095 22.9715 14.5261 23.0881C14.6428 23.2131 14.8095 23.2882 14.9845 23.2882H16.1178C16.2845 23.2882 16.3345 23.2632 16.3428 23.2632C16.3345 23.2632 16.3595 23.1631 16.3845 23.0965L16.5095 22.7298C16.6678 22.2631 16.9261 21.4715 18.2511 21.4715H21.3428C22.5511 21.4715 22.8595 22.0632 23.0761 22.7132L23.2011 23.0881C23.2178 23.1631 23.2428 23.2465 23.2678 23.2798C23.2594 23.2798 23.2678 23.2798 23.2678 23.2798Z"
        fill="#FF8E3C"
      />
      <path
        d="M14.9516 16.0801H14.3433C14.0016 16.0801 13.7183 15.7967 13.7183 15.4551C13.7183 15.1134 14.0016 14.8301 14.3433 14.8301H14.9516C15.2933 14.8301 15.5766 15.1134 15.5766 15.4551C15.5766 15.7967 15.2933 16.0801 14.9516 16.0801Z"
        fill="#FF8E3C"
      />
      <path
        d="M25.2262 16.0801H24.6179C24.2763 16.0801 23.9929 15.7967 23.9929 15.4551C23.9929 15.1134 24.2763 14.8301 24.6179 14.8301H25.2262C25.5679 14.8301 25.8512 15.1134 25.8512 15.4551C25.8512 15.7967 25.5679 16.0801 25.2262 16.0801Z"
        fill="#FF8E3C"
      />
      <path
        d="M17.968 20.3145H16.1514C15.8097 20.3145 15.5264 20.0311 15.5264 19.6895C15.5264 19.3478 15.8097 19.0645 16.1514 19.0645H17.968C18.3097 19.0645 18.593 19.3478 18.593 19.6895C18.593 20.0311 18.318 20.3145 17.968 20.3145Z"
        fill="#FF8E3C"
      />
      <path
        d="M23.4097 20.3145H21.593C21.2514 20.3145 20.968 20.0311 20.968 19.6895C20.968 19.3478 21.2514 19.0645 21.593 19.0645H23.4097C23.7514 19.0645 24.0347 19.3478 24.0347 19.6895C24.0347 20.0311 23.7514 20.3145 23.4097 20.3145Z"
        fill="#FF8E3C"
      />
      <path
        d="M19.7847 26.2884C19.443 26.2884 19.1597 26.0051 19.1597 25.6634V24.8301C19.1597 24.4884 19.443 24.2051 19.7847 24.2051C20.1263 24.2051 20.4097 24.4884 20.4097 24.8301V25.6634C20.4097 26.0051 20.1263 26.2884 19.7847 26.2884Z"
        fill="#FF8E3C"
      />
      <path
        d="M19.7847 29.6224C19.443 29.6224 19.1597 29.3391 19.1597 28.9974V28.1641C19.1597 27.8224 19.443 27.5391 19.7847 27.5391C20.1263 27.5391 20.4097 27.8224 20.4097 28.1641V28.9974C20.4097 29.3391 20.1263 29.6224 19.7847 29.6224Z"
        fill="#FF8E3C"
      />
      <path
        d="M11.4514 29.6221C11.4014 29.6221 11.3514 29.6138 11.3014 29.6055C10.9681 29.5221 10.7597 29.1805 10.8431 28.8471L11.6764 25.5138C11.7597 25.1805 12.0931 24.9722 12.4347 25.0555C12.7681 25.1388 12.9764 25.4805 12.8931 25.8138L12.0597 29.1471C11.9847 29.4305 11.7347 29.6221 11.4514 29.6221Z"
        fill="#FF8E3C"
      />
      <path
        d="M28.1182 29.6226C27.8349 29.6226 27.5849 29.4309 27.5099 29.1476L26.6766 25.8143C26.5932 25.4809 26.7932 25.1393 27.1349 25.056C27.4682 24.9726 27.8099 25.1726 27.8932 25.5143L28.7266 28.8476C28.8099 29.181 28.6099 29.5226 28.2682 29.6059C28.2182 29.6143 28.1682 29.6226 28.1182 29.6226Z"
        fill="#FF8E3C"
      />
    </svg>
  );
};
export const OnBoarded: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="3" fill="#CD3EBF" fill-opacity="0.1" />
      <path
        d="M22.9247 13.6094H17.0747C14.9997 13.6094 14.5413 14.5694 14.2747 15.7462L13.333 19.9346H26.6663L25.7247 15.7462C25.458 14.5694 24.9997 13.6094 22.9247 13.6094Z"
        stroke="#CD3EBF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.3245 26.7621C28.4162 27.6679 27.6329 28.4498 26.6329 28.4498H25.0662C24.1662 28.4498 24.0412 28.0937 23.8829 27.6524L23.7162 27.1879C23.4829 26.553 23.3329 26.1272 22.1329 26.1272H17.8662C16.6662 26.1272 16.4912 26.6072 16.2829 27.1879L16.1162 27.6524C15.9579 28.0937 15.8329 28.4498 14.9329 28.4498H13.3662C12.3662 28.4498 11.5829 27.6679 11.6745 26.7621L12.1412 22.0472C12.2579 20.8859 12.4995 19.9336 14.6829 19.9336H25.3162C27.4995 19.9336 27.7412 20.8859 27.8579 22.0472L28.3245 26.7621Z"
        stroke="#CD3EBF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3333 17.6094H12.5"
        stroke="#CD3EBF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 23.0312H17.5"
        stroke="#CD3EBF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.5 23.0312H25"
        stroke="#CD3EBF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.71 13.8235C29.7311 14.5568 29.5206 15.2608 29.1418 15.8572C28.9313 16.2091 28.6576 16.5318 28.3314 16.786C27.6158 17.4215 26.6581 17.8028 25.5953 17.8224C24.2903 17.8517 23.1116 17.3237 22.3434 16.4731C22.196 16.3265 22.0698 16.1603 21.954 15.9941C21.5436 15.4172 21.3121 14.7328 21.291 13.9996C21.2594 12.7677 21.8488 11.6433 22.7959 10.91C23.5115 10.3625 24.4165 10.0203 25.4058 10.0007C26.5108 9.98118 27.5211 10.3527 28.2788 10.9784C29.1312 11.6726 29.6784 12.6796 29.71 13.8235Z"
        fill="#CD3EBF"
        stroke="#CD3EBF"
        stroke-width="0.6"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.9214 13.97L24.9843 14.9086L27.1837 12.9336"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
