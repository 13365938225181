import { Switch, Route, Redirect } from "react-router";
import { isPremitted } from "../../services/utils/permission";
import { AllUsersLayout } from "./AllUsersLayout";
import { UsersProvider } from "./context";
export interface UsersMainLayoutProps {}

export const UsersMainLayout: React.FC<UsersMainLayoutProps> = (
  props: UsersMainLayoutProps
) => {
  return (
    <>
      <UsersProvider>
        {" "}
        <Switch>
          <Route
            exact
            path="/user-management"
            render={() => {
              if (isPremitted("user", "list")) {
                return <AllUsersLayout />;
              } else {
                return <Redirect to="/forbidden" />;
              }
            }}
          />
        </Switch>
      </UsersProvider>
    </>
  );
};
