import { Drawer } from "antd";
import type { ColumnsType } from "antd/es/table";

import { useEffect, useState } from "react";
import { Table } from "../../../atoms/Table/Table";
import { Table as AntTable } from "antd";
import {
  HeaderDetailArray,
  changeData,
  downloadBlob,
  returnCount,
  returnPerVehicleCount,
} from "../ReportsLoad";
import { checkData, checkEmpty } from "../../../utils";
import { Button } from "../../../atoms/Button/Button";
export interface ReportDetailProps {
  drawerDetail?: any;
  closeFunction: any;
  data: any;
}
export const ReportDetail: React.FC<ReportDetailProps> = ({
  drawerDetail,
  closeFunction,
  data,
}) => {
  const columns: ColumnsType = [
    {
      title: "",
      dataIndex: "name",
      render: (value, record: any) => <div>{value}</div>,
    },
    {
      title: "ASSETS",
      children: [
        {
          title: "Total Units",
          dataIndex: "count",
          render: (value, record: any) => <div>{value}</div>,
        },
      ],
    },
    {
      title: "RENTALS",
      children: [
        {
          title: "On Rent",
          dataIndex: "On Rent",
          render: (value, record: any) => <div> {checkData(value)}</div>,
        },
      ],
    },
    {
      title: "AVAILABILITY",
      children: [
        {
          title: "Available",
          dataIndex: "Available",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },

        {
          title: "Avail. Manhelm",
          dataIndex: "Available at Manheim",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },
        {
          title: "Reserved",
          dataIndex: "Reserved",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },
      ],
    },
    {
      title: "FLEET OPERATIONS",
      children: [
        {
          title: "In Bodyshop",
          dataIndex: "In Body Shop",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },
        {
          title: "In Maintenance",
          dataIndex: "In Maintenance",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },
        {
          title: "Awaiting Maint",
          dataIndex: "On Lot - Awaiting Body Shop",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },
        {
          title: "In Transit Close RA",
          dataIndex: "In Transit Close RA",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },
        {
          title: "In Transit Open RA",
          dataIndex: "In Transit Open RA",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },
        {
          title: "Missing",
          dataIndex: "Missing",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },
        {
          title: "Pending Reg",
          dataIndex: "Pending Registration",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },
      ],
    },

    {
      title: "S&A OPERATIONS",
      children: [
        {
          title: "Hold for Sale",
          dataIndex: "Hold For Sale",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },
        {
          title: "Auction",
          dataIndex: "At Auction",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },
        {
          title: "Sold",
          dataIndex: "Sold",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },
        {
          title: "Sold Archived",
          dataIndex: "Sold Archived",
          render: (value, record: any) => <div>{checkData(value)}</div>,
        },
      ],
    },

    {
      title: "Archieved",
      dataIndex: "Archieved",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
  ];

  function arrayToCsv() {
    let arrayFormatted: any = [HeaderDetailArray];
    let array = drawerDetail.data.map((row: any) => {
      let individualData = [];
      individualData.push(row.name);
      individualData.push(checkData(row["count"]));
      individualData.push(checkData(row["On Rent"]));
      individualData.push(checkData(row.Available));
      individualData.push(checkData(row["Available at Manheim"]));
      individualData.push(checkData(row["Reserved"]));
      individualData.push(checkData(row["In Body Shop"]));
      individualData.push(checkData(row["In Maintenance"]));
      individualData.push(checkData(row["In Transit Close RA"]));
      individualData.push(checkData(row["In Transit Open RA"]));
      individualData.push(checkData(row["Missing"]));
      individualData.push(checkData(row["Pending Registration"]));
      individualData.push(checkData(row["Hold for Sale"]));
      individualData.push(checkData(row["At Auction"]));
      individualData.push(checkData(row["Sold"]));
      individualData.push(checkData(row["Archieved"]));

      arrayFormatted.push(individualData);
    });
    downloadBlob(
      arrayFormatted,
      `${data.type.value}_${data.start}_${data.end}`
    );
  }

  return (
    <>
      <Drawer
        open={drawerDetail.visible}
        title={"Vehicle Detail"}
        width="100%"
        onClose={closeFunction}
      >
        <div className="flex flex-end justify-end mb-[20px]">
          <Button
            onClick={() => arrayToCsv()}
            className="rounded-[4px]   text-[#fb6db2] text-[14px] text-white font-semibold flex items-center gap-2 justify-center"
          >
            Download
          </Button>
        </div>
        <Table
          className="vehicle_table overflow_table parent_header"
          columns={columns}
          dataSource={drawerDetail.data}
          pagination={false}
          bordered
        />
      </Drawer>
    </>
  );
};
