import { Link, useLocation, useParams } from "react-router-dom";
import { DoubleRightArrrowIcon } from "../../icons/double-right-arrow";

export interface BreadCrumbProps {
  lastCrumb?: string;
  showTitle?: any;
}

export const BreadCrumbs: React.FC<BreadCrumbProps> = ({
  lastCrumb,
  showTitle,
}: BreadCrumbProps) => {
  const { pathname, hash } = useLocation();
  const { id }: any = useParams();
  let pathSlipts = pathname
    .substring(1)
    .replace(hash, "")
    .split("/")
    .filter(function (e) {
      return e;
    });
  return (
    <ul className="inline-flex justify-center items-center">
      <li>
        <Link to={"/"} className="font-[500] text-[#757575] text-[13px] pr-1">
          Home
        </Link>
      </li>

      {pathSlipts.map((x, index) => (
        <div className="flex items-center" key={index}>
          {index <= pathSlipts.length - 1 && <DoubleRightArrrowIcon />}
          <li className="px-1">
            <Link
              to={`/${pathSlipts.reduce((pV: any, cV: any, i) => {
                if (cV === "edit") {
                  return `${pV}`;
                }
                if (i <= index) {
                  return `${pV}/${cV}`;
                } else {
                  return pV;
                }
              })}`}
              className="font-[500] text-[#757575] text-[13px] capitalize"
            >
              {id && index === pathSlipts.length - 1 ? showTitle : x}
            </Link>
          </li>
        </div>
      ))}
    </ul>
  );
};
