import { FiEye, FiServer } from "react-icons/fi";
import {
  ActiveContact,
  ActiveGroup,
  ActiveHomeIcon,
  ActiveIssues,
  ActiveMasterSettings,
  ActiveProcurement,
  ActiveRemainder,
  ActiveSchedule,
  ActiveVendor,
  HomeIcon,
  InactiveContact,
  InactiveGroup,
  InactiveIssues,
  InActiveProcurement,
  InactiveRemainder,
  InActiveSchedule,
  InactiveVendor,
} from "../icons/home";
import { ActiveInspectIcon, InspectIcon } from "../icons/inspection";
import { ActiveServicesIcon, ServicesIcon } from "../icons/services";
import { ActiveVehicleIcon, VehicleIcon } from "../icons/vehicle";
import "./sidebar.scss";
import { SidebarItem } from "./sidebaritem";
import smallLogo from "../../src/icons/designIcons/small_sidebar_logo.svg";
import ProjectLogo from "../../src/icons/designIcons/Logo.svg";
import CookieStorage from "../services/storage/cookie.storage";
import {
  ActiveUserManagement,
  InactiveUserManagement,
} from "../icons/UserManagement";
import { PermissionsGate } from "../atoms/PermissionComponent/PermissionGate";
import { Link, useHistory, useLocation } from "react-router-dom";
import { MenuBackIconPrimary } from "../icons";
export interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const location = useLocation();
  const history = useHistory();
  const isadminSettings = location.pathname.includes("master") ? true : false;

  return (
    <section className="z-sidebar">
      <div>
        <div
          className="w-full profile py-4 cursor-pointer flex justify-center items-center"
          id="full_sidebar_logo"
        >
          <img width={"110px"} src={ProjectLogo} className="" />
        </div>
        <div
          className="w-full profile py-4 cursor-pointer flex justify-center items-center"
          id="small_sidebar_logo"
        >
          <img width={"50px"} src={smallLogo} className="" />
        </div>

        {/* Menu Items */}
        {isadminSettings ? (
          <>
            <div className="mt-5">
              <Link to="/dashboard">
                <div className="flex items-center cursor-pointer font-[600] primary_text_color ml-5 mb-4">
                  <MenuBackIconPrimary />
                  <p className="ml-2">Back to Main page</p>
                </div>
              </Link>
              <div className="">
                <SidebarItem
                  activeIcons={<ActiveMasterSettings />}
                  inactiveIcons={<ActiveMasterSettings />}
                  label="Settings"
                  linkTo="/master"
                  hasSubMenu={true}
                  subMenus={[
                    {
                      inactiveIcons: <FiServer size={"14"} />,
                      activeIcons: <FiServer size={"14"} />,
                      label: "Make & Model",
                      linkTo: "/master/make-model",
                      isActive: false,
                    },
                  ]}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mt-5">
              <PermissionsGate action="list" module="dashboard">
                <SidebarItem
                  activeIcons={<ActiveHomeIcon />}
                  inactiveIcons={<HomeIcon />}
                  label="Dashboard"
                  linkTo="/dashboard"
                />
              </PermissionsGate>

              <PermissionsGate action="list" module="vehicle">
                <SidebarItem
                  activeIcons={<ActiveVehicleIcon />}
                  inactiveIcons={<VehicleIcon />}
                  label="Vehicles"
                  linkTo="/vehicles"
                  hasSubMenu={false}
                  subMenus={[
                    {
                      inactiveIcons: <FiServer size={"14"} />,
                      activeIcons: <FiServer size={"14"} />,
                      label: "List",
                      linkTo: "/vehicles",
                      isActive: false,
                    },
                  ]}
                />
              </PermissionsGate>

              <PermissionsGate action="list" module="service-history">
                <SidebarItem
                  inactiveIcons={<ServicesIcon />}
                  activeIcons={<ActiveServicesIcon />}
                  label="Service History"
                  linkTo="/services"
                  hasSubMenu={false}
                  subMenus={[]}
                />
              </PermissionsGate>

              <PermissionsGate action="list" module="reminder">
                <SidebarItem
                  activeIcons={<ActiveRemainder />}
                  inactiveIcons={<InactiveRemainder />}
                  label="Reminders"
                  linkTo="/service-remainder"
                  hasSubMenu={true}
                  subMenus={[
                    {
                      inactiveIcons: <FiServer size={"14"} />,
                      activeIcons: <FiServer size={"14"} />,
                      label: "Service Reminders",
                      linkTo: "/service-remainder",
                      isActive: false,
                    },
                    {
                      inactiveIcons: <FiServer size={"14"} />,
                      activeIcons: <FiServer size={"14"} />,
                      label: "Vehicle Renewals",
                      linkTo: "/vehicle-remainder",
                      isActive: false,
                    },
                  ]}
                />
              </PermissionsGate>

              <PermissionsGate action="list" module="vendor">
                <SidebarItem
                  inactiveIcons={<InactiveVendor />}
                  activeIcons={<ActiveVendor />}
                  label="Vendors"
                  linkTo="/vendors"
                  hasSubMenu={false}
                  subMenus={[]}
                />
              </PermissionsGate>

              <PermissionsGate action="list" module="group">
                <SidebarItem
                  inactiveIcons={<InactiveGroup />}
                  activeIcons={<ActiveGroup />}
                  label="Clients/Sub Clients"
                  linkTo="/clients"
                  hasSubMenu={false}
                  subMenus={[]}
                />
              </PermissionsGate>

              <PermissionsGate action="list" module="contact">
                <SidebarItem
                  inactiveIcons={<InactiveContact />}
                  activeIcons={<ActiveContact />}
                  label="Contacts"
                  linkTo="/contact?page=1&perPage=10"
                  hasSubMenu={false}
                  subMenus={[]}
                />
              </PermissionsGate>

              <PermissionsGate action="list" module="issue">
                {" "}
                <SidebarItem
                  inactiveIcons={<InactiveIssues />}
                  activeIcons={<ActiveIssues />}
                  label="Issues"
                  linkTo="/issues"
                  hasSubMenu={false}
                  subMenus={[]}
                />
              </PermissionsGate>

              <PermissionsGate action="list" module="inspection">
                <SidebarItem
                  inactiveIcons={<InspectIcon />}
                  activeIcons={<ActiveInspectIcon />}
                  label="Inspections"
                  linkTo="/inspections"
                  // /inspections-forms/all
                  hasSubMenu={false}
                  subMenus={[
                    {
                      inactiveIcons: <FiEye size={"16"} />,
                      activeIcons: <FiEye size={"16"} />,
                      label: "List",
                      linkTo: "/inspections/all",
                    },
                    {
                      inactiveIcons: <FiServer size={"14"} />,
                      activeIcons: <FiServer size={"14"} />,
                      label: "Forms",
                      linkTo: "/inspections/forms",
                    },
                  ]}
                />
              </PermissionsGate>

              <PermissionsGate action="list" module="user">
                <SidebarItem
                  inactiveIcons={<InactiveUserManagement />}
                  activeIcons={<ActiveUserManagement />}
                  label="User Management"
                  linkTo="/user-management?page=1&perPage=10#users"
                  hasSubMenu={false}
                  subMenus={[]}
                />
              </PermissionsGate>
              <PermissionsGate action="list" module="procurement">
                <SidebarItem
                  inactiveIcons={<InActiveProcurement />}
                  activeIcons={<ActiveProcurement />}
                  label="Procurement"
                  linkTo="/procurement"
                  hasSubMenu={false}
                  subMenus={[]}
                />
              </PermissionsGate>
              <PermissionsGate action="list" module="contract">
                <SidebarItem
                  inactiveIcons={<InActiveSchedule />}
                  activeIcons={<ActiveSchedule />}
                  label="Schedule"
                  linkTo="/schedule"
                  hasSubMenu={true}
                  subMenus={[
                    {
                      inactiveIcons: <FiServer size={"14"} />,
                      activeIcons: <FiServer size={"14"} />,
                      label: "Contracts",
                      linkTo: "/schedule/planning",
                      isActive: false,
                    },
                    {
                      inactiveIcons: <FiServer size={"14"} />,
                      activeIcons: <FiServer size={"14"} />,
                      label: "Availability Calender",
                      linkTo: "/schedule/calender",
                      isActive: false,
                    },
                  ]}
                />
              </PermissionsGate>
              <SidebarItem
                inactiveIcons={<InActiveSchedule />}
                activeIcons={<ActiveSchedule />}
                label="Reports"
                linkTo="/reports"
                hasSubMenu={false}
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};
