import { Drawer } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BreadCrumbs } from "../../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../../atoms/Button/Button";
import { Pagination } from "../../../atoms/Pagination/pagination";
import { SingleSelect } from "../../../atoms/Select/Select";
import { Table } from "../../../atoms/Table/Table";
import { useSearchParams } from "../../../hooks/useSearchParams";
import { PlusIcon } from "../../../icons";
import { EditPenIcon } from "../../../icons/edit-pen";
import { ListTrash } from "../../../icons/home";
import { ListHeader } from "../../../molecules/list-header/ListHeader";
import { getStatusFromKey, Status } from "../../../options/status";
import { useGroups } from "../context";
import { OneGroupPage } from "../one/OneGroup";
import { Modal } from "antd";
import { ListingSubItem } from "../../../icons/ListingSubItems";
import { render } from "@testing-library/react";
import { SubClients } from "../SubClients";
import { FiltersIcon } from "../../../icons/Pagination";
import { PermissionsGate } from "../../../atoms/PermissionComponent/PermissionGate";
import { ListCommentIcon } from "../../../icons/SendComments";
import { CommentsDragger } from "../../../atoms/CommentsDragger/Comments";
import { useCommonContext } from "../../../hooks/commonStateProvider";
const { confirm } = Modal;
export interface AllGroupsProps {}

export const AllGroups: React.FC<AllGroupsProps> = () => {
  const {
    state: { documents: allGroups, pagination },
    actions: { getAllGroups, deleteGroup, getSubClients },
  } = useGroups();
  const [dragger, setDragger] = useState(false);
  const [selectedValue, setSelectedValue]: any = useState(null);
  const [extraFilters, setExtraFilters]: any = useState({});
  const [hasFilter, setHasFilter]: any = useState(false);
  const [filtersGroup, setFiltersGroup]: any = useState({
    status: "",
  });
  const [commentsObj, setComments] = useState({ visible: false, id: "" });
  const [filterDrawer, setFiltersDrawer] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const {
    actions: { getAllComments },
  } = useCommonContext();
  useEffect(() => {
    if (selectedValue) {
      setOpen(true);
    }
  }, [selectedValue]);
  const { query, updateSearchParams }: any = useSearchParams();

  useEffect(() => {
    if (Object.keys(query).length !== 0) {
      getAllGroups(false, query);
      setExtraFilters({
        ...extraFilters,
        status: getStatusFromKey(query.status),
      });
    } else {
      updateSearchParams({ page: 1, perPage: 10 });
    }
  }, [query]);

  const updateSearch = () => {
    let queryParams: any = {};
    if (filtersGroup.status) queryParams["status"] = filtersGroup.status.value;
    return { ...query, ...queryParams };
  };

  useEffect(() => {
    let filter = { ...query };
    delete filter["page"];
    delete filter["perPage"];
    delete filter["sort"];
    const hasFilter = Object.keys(filter).length > 0;
    setHasFilter(hasFilter);
  }, [query]);

  const GetsubClientsData = (id: any) => {
    setDragger(true);
    getSubClients(id);
  };

  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
      render: (value, record: any) => (
        <div className="flex items-center">
          <span className="mr-2"> {value}</span>
          <span
            className="cursor-pointer"
            onClick={() => GetsubClientsData(record.id)}
          >
            {record.totalAssigns > 0 && <ListingSubItem />}
          </span>
        </div>
      ),
    },
    {
      title: "Sub Clients",
      dataIndex: "totalAssigns",
    },
    {
      title: "Assigned Vehicles",
      dataIndex: "totalVehicles",
      render: (value, record: any) => (
        <>
          {value ? (
            <Link
              to={`/clients/${record.id}/vehicles`}
              className="underline font-medium text-[#398CC0]"
            >
              {value}
            </Link>
          ) : (
            "N/A"
          )}
        </>
      ),
    },
    {
      title: "Assigned Contacts",
      dataIndex: "totalContacts",
      render: (value, record: any) => (
        <>
          {value ? (
            <Link
              to={`/clients/${record.id}/contacts`}
              className="underline font-medium text-[#398CC0]"
            >
              {value}
            </Link>
          ) : (
            "N/A"
          )}
        </>
      ),
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      render: (value) => moment(value).format("MMM DD, YYYY"),
    },
    {
      title: "Action",
      // sorter: true,
      render: (value, record: any) => (
        <div className="flex  items-center">
          <div
            className="cursor-pointer  mr-[12px]"
            onClick={() => {
              setComments({ visible: true, id: record.id });
              getAllComments(record?.id);
            }}
          >
            {" "}
            <ListCommentIcon />
          </div>
          <PermissionsGate action="edit" module="group">
            <div
              className="underline flex items-center mr-[10px] cursor-pointer"
              onClick={() => {
                setSelectedValue(record.id);
              }}
            >
              <EditPenIcon />
            </div>
          </PermissionsGate>
          <PermissionsGate action="delete" module="group">
            <div
              className="cursor-pointer"
              onClick={() => {
                confirm({
                  title: "Warning",
                  content: "Are you sure want to deactivate this Client?",
                  onOk() {
                    deleteGroup(record.id, () => {
                      getAllGroups();
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              <ListTrash />
            </div>
          </PermissionsGate>
        </div>
      ),
    },
  ];
  const removeAndReturnFilter = (filterName: string) => {
    let filter = { ...query };
    delete filter[filterName];
    return filter;
  };

  return (
    <div className="w-full h-full bg-white px-6">
      <CommentsDragger
        title="Clients Comments"
        onClose={() => setComments({ visible: false, id: "" })}
        commentsObj={commentsObj}
        model="group"
      />{" "}
      <Drawer
        visible={filterDrawer}
        placement="right"
        onClose={() => setFiltersDrawer(false)}
        width="33%"
        title="Filters"
        className=""
      >
        <div className="mb-8">
          <div className="mb-5">
            {" "}
            <SingleSelect
              inputClassName="adjust_select_height text-[13px]"
              className=""
              label="Status"
              placeholder="Status"
              value={filtersGroup.status}
              options={Status}
              onChange={(newValue: any) => {
                setFiltersGroup({
                  ...filtersGroup,
                  status: newValue,
                });
              }}
            />
          </div>
        </div>
        <div className="flex justify-end">
          {" "}
          <Button
            className="border mr-2  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
            type="button"
            onClick={() => {
              setFiltersGroup({
                status: "",
                type: "",
              });
              setExtraFilters({});
              updateSearchParams({ page: "1", perPage: "10" });
              setFiltersDrawer(false);
            }}
          >
            <span className="flex items-center text-[#FB6DB2]">Clear All</span>
          </Button>
          <Button
            type="submit"
            onClick={() => {
              setExtraFilters({
                ...extraFilters,
                status: filtersGroup.status,
                type: filtersGroup.type,
              });
              updateSearchParams(updateSearch());
              setFiltersDrawer(false);
            }}
            className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
          >
            Apply
          </Button>
        </div>
      </Drawer>
      <SubClients dragger={dragger} onClose={() => setDragger(false)} />
      <div className="flex justify-between items-center py-[24px]">
        <BreadCrumbs />
        <div>
          <div className="flex flex-row-reverse gap-4">
            <PermissionsGate action="create" module="group">
              <Button
                className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <span>
                  <PlusIcon />
                </span>
                Add Client
              </Button>
            </PermissionsGate>
          </div>
        </div>
      </div>
      <div className="card_style ">
        {/* Title, Search, Filter */}
        <ListHeader searchPlaceHolder="Search by Name" pagination={pagination}>
          <div className="w-[180px]">
            {" "}
            <SingleSelect
              className="max-w-[180px] mx-2"
              isClearable
              inputClassName="adjust_select_height"
              placeholder="Status"
              value={extraFilters["status"] ? extraFilters["status"] : null}
              options={Status}
              onChange={(newValue: any) => {
                updateSearchParams(
                  newValue
                    ? {
                        ...query,
                        status: newValue && newValue.value,
                      }
                    : removeAndReturnFilter("status")
                );
              }}
            />
          </div>

          {hasFilter && (
            <div
              onClick={() => {
                setFiltersDrawer(true);
                setFiltersGroup({
                  type: extraFilters["type"] ? extraFilters["type"] : null,
                  status: extraFilters["status"]
                    ? extraFilters["status"]
                    : null,
                });
              }}
              className="flex ml-2 justify-center items-center rounded-[4px] cursor-pointer border w-[40px] h-[40px] border-[#f0f0f0]"
            >
              {" "}
              <FiltersIcon />
            </div>
          )}
        </ListHeader>
        {/* Table View */}
        <Table
          className="group_table"
          columns={columns}
          dataSource={allGroups}
          pagination={false}
        />
        <div className="mr-[24px] my-5">
          <Pagination
            pagination={pagination}
            onNext={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) + 1;
              updateSearchParams({ ...newQuery });
            }}
            onPrevious={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) - 1;
              updateSearchParams({ ...newQuery });
            }}
          />
        </div>
        {/* New Client */}
        {isOpen && (
          <Drawer
            title="Create a new Client"
            placement={"right"}
            width={"35vw"}
            onClose={() => {
              setOpen(false);
              setSelectedValue(null);
            }}
            visible={isOpen}
          >
            <OneGroupPage
              id={selectedValue}
              isNew={selectedValue ? false : true}
              onClose={() => {
                setOpen(false);
                setSelectedValue(null);
              }}
            />
          </Drawer>
        )}
      </div>
    </div>
  );
};
