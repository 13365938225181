import moment from "moment";
import {
  DiselIcon,
  ElectricIcon,
  FlexFuelIcon,
  GasolineIcon,
} from "../icons/fuelType";
import humps from "humps";
export const VehicleLoans = [
  {
    value: "loan",
    label: "Loan",
  },
  {
    value: "lease",
    label: "Lease",
  },
  {
    value: "no-finance",
    label: "Not in Finance",
  },
];

export const YearOptions = [
  { label: "2022", value: 2022 },
  { label: "2021", value: 2021 },
  { label: "2020", value: 2020 },
  { label: "2019", value: 2019 },
  { label: "2018", value: 2018 },
  { label: "2017", value: 2017 },
];

export const getCurrentLoadTypeFromKey = (key: string) =>
  VehicleLoans.find((x) => x.value === key);

export const YESNO = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

export const getCurrentYESNOFromKey = (key: string) =>
  YESNO.find((x) => x.value === key);

export const VehicleTypes = [
  {
    value: "passenger-12",
    label: "12 Passenger",
  },
  {
    value: "passenger-15",
    label: "15 Passenger",
  },
  {
    value: "minivan",
    label: "Minivan (7 seater)",
  },
  {
    value: "cargo",
    label: "Cargo Standard",
  },
  {
    value: "cargo-mid",
    label: "Cargo Mid-Roof",
  },
  {
    value: "cargo-high",
    label: "Cargo High-Roof",
  },
  {
    value: "truck",
    label: "Pickup Truck",
  },
  {
    value: "suv",
    label: "SUV",
  },
  {
    value: "truck-14",
    label: "Box Truck 14'",
  },
  {
    value: "truck-20",
    label: "Box Truck 20'",
  },
  {
    value: "truck-26",
    label: "Box Truck 26'",
  },
];
export const States = [
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alabama" },
  { label: "Arizona", value: "Alabama" },
  { label: "Arkansas", value: "Alabama" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "TX", value: "TX" },
];

export const getCurrentVehicleTypesFromKey = (
  key: string,
  camilize: Boolean = false
) =>
  VehicleTypes.find((x) =>
    camilize ? humps.camelize(x.value) === key : x.value === key
  );
export const reCamalize = (key: any) => {
  let data = humps.camelize(key);
  return key ? humps.camelize(key) : "N/A";
};
export const getVehiclekey = (
  key: string,
  array: any,
  camilize: Boolean = false
) => {
  array?.find((x: any) =>
    camilize ? humps.camelize(x.value) === key : x.value == key
  );

  return array?.find((x: any) =>
    camilize ? humps.camelize(x.value) === key : x.value == key
  );
};
export const getCurrentGroup = (groupArray: any, query: any) => {
  if (groupArray && groupArray.length > 0 && query) {
    return groupArray.find((x: any) => x.value === query);
  }
};

export const VehicleFuelType = [
  {
    value: "gasoline",
    label: "Gasoline",
    icon: <GasolineIcon />,
  },
  {
    value: "diesel",
    label: "Diesel",
    icon: <DiselIcon />,
  },
  {
    value: "ev",
    label: "Electric Vehicle(EV)",
    icon: <ElectricIcon />,
  },
  {
    value: "flex-fuel",
    label: "Flex Fuel",
    icon: <FlexFuelIcon />,
  },
];
export const Modals = [{ value: "Verna", label: "Verna" }];
export const SubModals = [
  {
    value: "Top Variant",
    label: "Top Variant",
  },
];

export const ZeebaGroupList = [
  {
    value: "ags",
    label: "ags",
  },
  {
    value: "VN",
    label: "VN",
  },
  { value: "Verna", label: "Verna" },
];

export const getCurrentVehicleFuelTypeFromKey = (key: string) =>
  VehicleFuelType.find((x) => x.value === key);

export const getCurrentObjectKey = (key: any, arrayName: string) => {
  return MainArray[arrayName].find((x: any) => x.value === key);
};

export const getSelectedArray = (data: any) => {
  if (data?.length) {
    let array = AdditionalFeatures.filter((item: any) =>
      data.includes(item.value)
    );
    return array;
  } else {
    return [];
  }
};
export const AdditionalFeatures = [
  { value: "Hitch", label: "Hitch" },
  { value: "Divides", label: "Divides" },
  { value: "Extended Baggage Area", label: "Extended Baggage Area" },
  { value: "Shefling", label: "Shefling" },
];
export const MainArray: any = {
  VehicleFuelType: VehicleFuelType,
  AdditionalFeatures: AdditionalFeatures,
  ZeebaGroupList: ZeebaGroupList,
  SubModals: SubModals,
  Modals: Modals,
  States: States,
  VehicleTypes: VehicleTypes,
  YearOptions: YearOptions,
};
export const setDateField = (date: string) => {
  return date ? moment(date) : "";
};
export const sendDateFormat = (date: any) => {
  if (date) {
    return moment(date).format("MM/DD/YYYY");
  } else {
    return "";
  }
};

export const returnData = (data: any, key: string) => {
  if (data && data[key]) {
    return data[key];
  } else {
    return "-";
  }
};

export const returnPriceData = (data: any, key: string) => {
  let formator = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (data && data[key]) {
    return formator.format(data[key]);
  } else {
    return "-";
  }
};

export const getAging = (date: any, miles: any) => {
  let inputDate = date ? date : "";
  let inputmiles = miles ? miles : "";
  if (inputDate) {
    let difference = moment().diff(moment(inputDate), "days");
    if (difference < 365)
      return inputmiles ? vehicleMilageCalculation(inputmiles) : "healthy";
    else if (difference > 365 && difference <= 549)
      return inputmiles ? vehicleMilageCalculation(inputmiles) : "aging";
    else if (difference > 549)
      return inputmiles ? vehicleMilageCalculation(inputmiles) : "old";
  } else return inputmiles ? vehicleMilageCalculation(inputmiles) : "";
};

export const vehicleMilageCalculation = (inputmiles: any) => {
  let value = "";
  if (inputmiles <= 30000) {
    value = "healthy";
  } else if (inputmiles > 30000 && inputmiles <= 50000) {
    value = "aging";
  } else {
    value = "old";
  }
  return value;
};

export const returnStringArray = (array: any) => {
  let newarray: any = [];
  if (array) {
    array.map((item: any) => {
      newarray.push(item.value);
    });
    return newarray;
  } else {
    return [];
  }
};
export const UploadData = (filename: any, id: any, type: any) => {
  let data = {
    key: `vehicles/${id}/${filename}`,
    expires: 3000,
    contentType: type,
  };
  return data;
};

export const getVehicleTypeLabel = (item: any) => {
  let label: any = "";
  if (item) {
    VehicleFuelType.map((x: any) => {
      if (item === x.value) {
        label = x.label;
      }
    });
    return label;
  } else {
    return label;
  }
};
export const calculateDueDate = (date: any) => {
  if (date) return moment(date).diff(moment(), "days");
};

export const selectOptionFormat = (array: any) => {
  let newArray: any = [];
  array?.map((item: any) => {
    let obj = { label: item.name, value: item.id };
    newArray.push(obj);
  });
  return newArray;
};
