import { Formik, Form } from "formik";
import { useParams, useHistory } from "react-router";
import { Button } from "../../atoms/Button/Button";
import { FormikPassword, Password } from "../../atoms/Password/Password";
import * as Yup from "yup";
import { useAuth } from "./context";

export const ResetPassword: React.FC<{}> = () => {
  const { token }: any = useParams();
  const history = useHistory();
  const {
    actions: { ResetPassword },
  } = useAuth();
  return (
    <div
      className="flex justify-center  p-10 rounded-sm flex-col"
      style={{ maxWidth: 380, minWidth: 350 }}
    >
      <div className="mb-5">
        <p className="font-medium text-[16px]">Reset your Password</p>
      </div>
      <Formik
        onSubmit={(values, actions) => {
          let data = {
            resetHash: token,
            password: values.password,
          };
          ResetPassword(data);
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .min(8, "Password is too Short")
            .max(20, "Password is too Large")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              "Enter at least one uppercase letter, one lowercase letter, one number and one special character"
            )
            .required("Password is required"),
          confirm_password: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required"),
        })}
        initialValues={{ password: "", confirm_password: "" }}
      >
        {({ values }) => (
          <>
            {" "}
            <div className=""></div>
            <Form>
              {" "}
              <div>
                <div className="mb-[22px]">
                  <FormikPassword
                    label="New Password"
                    name="password"
                    required
                  />
                </div>
                <FormikPassword
                  label="Confirm New password"
                  name="confirm_password"
                  required
                />
              </div>
              <div className="submit-container mt-8">
                <Button
                  type="submit"
                  className="bg-primary text-white px-10 py-3 font-bold text-[14px] w-full rounded-[5px]"
                >
                  Reset Password
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
