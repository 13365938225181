import { Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { BreadCrumbs } from "../../../../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../../../../atoms/Button/Button";
import { SaveIcon } from "../../../../../icons/SaveIcon";
import { useVehicles } from "../../../context";
import { FinancialInformations } from "../FinancialInformations";
import { VehicleInformations } from "./VehicleInformations";
const { TabPane } = Tabs;
export interface VehicleEditProps {}

export const VehicleEdit: React.FC<VehicleEditProps> = ({}) => {
  const { id }: any = useParams();
  const [currentTab, setCurrentTab] = useState("info");
  const history = useHistory();
  const { hash } = useLocation();

  const {
    state: { vehicle },
    actions: { getOneVehicle },
  } = useVehicles();
  const formRef = useRef() as any;
  const handleSubmit = () => {
    formRef.current.handleSubmit();
  };
  useEffect(() => {
    getOneVehicle(id);
  }, []);
  useEffect(() => {
    setCurrentTab(hash || "#info");
  }, [hash]);
  return (
    <>
      <div className="px-[24px]">
        {" "}
        <div className="flex justify-between items-center py-[24px]">
          <BreadCrumbs showTitle={vehicle.vehicleName} />
          <div>
            <div className="flex flex-row-reverse gap-4">
              {" "}
              <Button
                type="submit"
                onClick={handleSubmit}
                className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
              >
                <span>
                  <SaveIcon />
                </span>
                Save Vehicle
              </Button>
              <Button
                className="border  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                type="button"
                onClick={() => history.goBack()}
              >
                <span className="flex items-center text-[#FB6DB2]">Cancel</span>
              </Button>
            </div>
          </div>
        </div>
        <Tabs
          activeKey={currentTab}
          onChange={(activeKey) => {
            setCurrentTab(activeKey);
            history.push({
              hash: activeKey,
            });
          }}
        >
          <TabPane
            key="#info"
            tab={
              <span className="tab-item flex justify-center items-center text-[14px]">
                Overview
              </span>
            }
          >
            <div className="mt-4">
              {" "}
              <VehicleInformations id={id} formRef={formRef} />
            </div>
          </TabPane>
          <TabPane
            key="#financial"
            tab={
              <span className="tab-item flex justify-center items-center text-[14px]">
                Financial
              </span>
            }
          >
            <div className="mt-4">
              {" "}
              <FinancialInformations
                formRef={formRef}
                existingData={vehicle}
                id={id}
                isNew={false}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
