import { Drawer, Modal } from "antd";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "../../../../atoms/Button/Button";
import { FormikText } from "../../../../atoms/Text/Text";
import { SaveIcon } from "../../../../icons/SaveIcon";
import * as Yup from "yup";
import { FormikSinglSelect } from "../../../../atoms/Select/Select";
import { Table } from "../../../../atoms/Table/Table";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { EditPenIcon } from "../../../../icons/edit-pen";
import { ListTrash } from "../../../../icons/home";
import { useMake } from "../context";
import { AddModel } from "./AddModel";
export interface SubModelProps {
  isVisibible: boolean;
  onClose?: any;
}

export const SubModel: React.FC<SubModelProps> = ({ isVisibible, onClose }) => {
  const {
    state: { documents, parentMakeDetails },
    actions: { getAllMake, getOneMake, deleteOne },
  } = useMake();
  const [data, setData] = useState([]);
  const { confirm } = Modal;
  const [modelDragger, setEditModel] = useState({
    visible: false,
    isNew: false,
  });
  useEffect(() => {
    if (parentMakeDetails) {
      parentMakeDetails?.map((item: any, i: any) => {
        item["key"] = i.toString();
      });
      setData(parentMakeDetails);
    } else {
    }
  }, [parentMakeDetails]);

  const subModel: ColumnsType = [
    {
      title: "Model",
      dataIndex: "name",
      render: (value) => <div className="capitalize">{value}</div>,
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Action",
      // sorter: true,
      render: (value, record: any) => (
        <div className="flex  items-center">
          {/* <PermissionsGate action="edit" module="vehicle"> */}
          <div
            onClick={() => {
              getOneMake(record.id);
              setEditModel({ visible: true, isNew: false });
            }}
          >
            <a className="underline flex items-center mr-[10px]">
              <EditPenIcon />
            </a>
          </div>

          <div
            className="cursor-pointer"
            onClick={() => {
              confirm({
                title: "Warning",
                content: "Are you sure want to deactivate this Model?",
                onOk() {
                  deleteOne(record.id, () => {}, record.parent?.id);
                },
                onCancel() {},
              });
            }}
          >
            <ListTrash />
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      {modelDragger.visible && (
        <AddModel
          modelDragger={modelDragger}
          onClose={() => setEditModel({ visible: false, isNew: false })}
        />
      )}{" "}
      <Drawer
        visible={isVisibible}
        placement="right"
        onClose={onClose}
        width="33%"
        title={modelDragger.isNew ? "Add Model" : "Edit Model"}
        className="table_drawer"
      >
        <Table columns={subModel} pagination={false} dataSource={data}></Table>
      </Drawer>
    </>
  );
};
