
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useGroups } from "../context";
import * as Yup from "yup";
import { FormikText } from "../../../atoms/Text/Text";
import { FormikSinglSelect, SingleSelect } from "../../../atoms/Select/Select";
import { Link } from "react-router-dom";
import { Button } from "../../../atoms/Button/Button";
import { PlusIcon } from "../../../icons/plus";
import { SaveIcon } from "../../../icons/SaveIcon";

interface OneGroupProps {
  id?: string;
  isNew: boolean;
  onClose: () => void;
}

export const OneGroupPage: React.FC<OneGroupProps> = ({ id = undefined, isNew, onClose }) => {
  const formRef = useRef() as any;
  const [initialValues, setInitialValues] = useState({
    name: "",
    parent: "",
  })
  const { actions: { getOneGroup, createOne, updateOne, clearOne, getAllGroups }, state: { group, minimalDocuments } } = useGroups();
  useEffect(() => {
    if (id) {
      getOneGroup(id);
    }
  }, [id]);
  useEffect(() => {
    getAllGroups(true);
    return () => clearOne()
  }, []);

  useEffect(() => {
    if (group) {
      delete group.id;
      delete group.createdAt;
      delete group.updatedAt;
      if (minimalDocuments) {
        const newGroup = {
          ...group,
          parent: minimalDocuments.find((x:any)=> x.value === group.parent)
        }
        setInitialValues(newGroup)
      }else{
        setInitialValues(group)
      }
      
    }
  }, [group, minimalDocuments])
  return (
    <div>
      <div className="px-[12px]">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required")
          })}
          onSubmit={(values: any) => {
            values = {
              ...values,
              parent: values.parent ? values.parent.value : values.parent
            }
            if (!isNew && id) {
              updateOne(id, values)
            } else {
              createOne(values, () => {
                onClose();
              })
            }
          }}
        >
          {({ values, setFieldValue, setErrors, resetForm }: any) => (
            <Form className="" id="my-form">
              <div className="flex flex-col gap-5">
                <FormikText label="Name" name="name" />
                <FormikSinglSelect label="Parent Client" name="parent" onChange={(newValue) => setFieldValue("parent", newValue)} options={minimalDocuments} isClearable />
              </div>
              <div className="mt-10 flex flex-row-reverse gap-4">
                <Button
                  type="submit"
                  className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                >
                  <span>
                    <SaveIcon />
                  </span>
                  {!isNew ? "Save" : "Create"} Client
                </Button>
                <Button
                  className="border py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                  type="button"
                  onClick={onClose}
                >
                  <span className="flex items-center text-[#FB6DB2]">
                    Cancel
                  </span>
                </Button>

              </div>
            </Form>)}
        </Formik>
      </div>
    </div>
  );
};
