import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { Table } from "../../../atoms/Table/Table";
import { ShowDateFormat, checkData, checkEmpty } from "../../../utils";
import { useReports } from "../context";
import { CSVLink } from "react-csv";
import {
  HeaderArray,
  arrayChange,
  calculatePercentage,
  changeDocument,
  downloadBlob,
  returnCount,
} from "../ReportsLoad";
import { ReportDetail } from "./ReportDetail";
import { Button } from "../../../atoms/Button/Button";

export interface ReportsTableProps {
  data?: any;
}
export const ReportsTable: React.FC<ReportsTableProps> = ({ data }) => {
  const {
    state: { documents },
  } = useReports();
  const [drawer, setDrawer] = useState({ visible: false, data: {} });
  const [formattedDocument, setDocument]: any = useState([]);
  useEffect(() => {
    if (Object.keys(documents).length > 0) {
      documents.map((item: any, i: any) => {
        item["index"] = i;
      });
      let docs = documents.map((x: any) => Object.assign({}, x));
      setDocument(changeDocument(docs));
    }
  }, [documents]);
  useEffect(() => {}, [formattedDocument]);
  const columns: ColumnsType = [
    {
      title: "Date",
      dataIndex: "date",
      render: (value, record: any) => <div>{ShowDateFormat(value)}</div>,
    },
    {
      title: "Total Units",
      dataIndex: "totals",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "on Rent",
      dataIndex: "On Rent",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "Available",
      dataIndex: "Available",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "Avail.Manheim",
      dataIndex: "Available at Manheim",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "Archieved",
      dataIndex: "Archieved",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "Reserved",
      dataIndex: "Reserved",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "In Bodyshop",
      dataIndex: "In Body Shop",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "In Maintenance",
      dataIndex: "In Maintenance",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "Awaiting Maint",
      dataIndex: "On Lot - Awaiting Body Shop",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "In Transit Close RA",
      dataIndex: "In Transit Close RA",
      render: (value, record: any) => (
        <div>
          {" "}
          <div>{checkData(value)}</div>
        </div>
      ),
    },
    {
      title: "In Transit Open RA",
      dataIndex: "In Transit Open RA",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "Missing",
      dataIndex: "Missing",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "Pending Reg",
      dataIndex: "Pending Registration",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "Hold for Sales",
      dataIndex: "Hold for Sale",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "Auction",
      dataIndex: "At Auction",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },
    {
      title: "Sold",
      dataIndex: "Sold",
      render: (value, record: any) => <div>{checkData(value)}</div>,
    },

    {
      title: "Total Asset Utilization",
      dataIndex: "licensePlate",
      render: (value, record: any) => (
        <div>
          {calculatePercentage(checkData(record), record.totals, "On Rent")}
        </div>
      ),
    },
    {
      title: "Availibility Utilization",
      dataIndex: "licensePlate",
      render: (value, record: any) => (
        <div>
          {calculatePercentage(checkData(record), record.totals, "Available")}
        </div>
      ),
    },
    {
      title: "Fleet Utilization",
      dataIndex: "licensePlate",
      render: (value, record: any) => (
        <div>
          {calculatePercentage(checkData(record), record.totals, "fleet")}
        </div>
      ),
    },
  ];

  function arrayToCsv() {
    let arrayFormatted: any = [HeaderArray];
    let array = formattedDocument.map((row: any) => {
      let individualData = [];
      individualData.push(ShowDateFormat(row.date));
      individualData.push(row.totals);
      individualData.push(checkData(row["On Rent"]));
      individualData.push(checkData(row.available));
      individualData.push(checkData(row["Available at Manheim"]));
      individualData.push(checkData(row["Archieved"]));
      individualData.push(checkData(row["Reserved"]));
      individualData.push(checkData(row["In Body Shop"]));
      individualData.push(checkData(row["In Maintenance"]));
      individualData.push(checkData(row[""]));
      individualData.push(checkData(row["In Transit Close RA"]));
      individualData.push(checkData(row["In Transit Open RA"]));
      individualData.push(checkData(row["Missing"]));
      individualData.push(checkData(row["Pending Registration"]));
      individualData.push(checkData(row["Hold For Sale"]));
      individualData.push(checkData(row["At Auction"]));
      individualData.push(checkData(row["Sold"]));
      individualData.push(
        calculatePercentage(checkData(row), row.totals, "On Rent")
      );
      individualData.push(
        calculatePercentage(checkData(row), row.totals, "Available")
      );
      individualData.push(
        calculatePercentage(checkData(row), row.totals, "fleet")
      );
      arrayFormatted.push(individualData);
    });
    downloadBlob(
      arrayFormatted,
      `${data.type.value}_${data.start}_${data.end}`
    );
  }

  return (
    <>
      {drawer.visible && (
        <ReportDetail
          drawerDetail={drawer}
          data={data}
          closeFunction={() => setDrawer({ visible: false, data: {} })}
        />
      )}
      {documents?.length > 0 && (
        <>
          <div className="flex items-center justify-between mb-5">
            {" "}
            <p className="text-[18px] font-semibold ">Reports</p>
            {documents?.length > 0 && (
              <Button
                onClick={() => arrayToCsv()}
                className="rounded-[4px]   text-[#fb6db2] text-[14px] text-white font-semibold flex items-center gap-2 justify-center"
              >
                Download
              </Button>
            )}
          </div>

          <Table
            className="vehicle_table overflow_table"
            columns={columns}
            dataSource={formattedDocument}
            pagination={false}
            onRow={(record, index: any) => ({
              onClick: (event) => {
                setDrawer({
                  visible: true,
                  data: {},
                });
                setDrawer({
                  visible: true,
                  data: formattedDocument[index].data,
                });
              },
            })}
          />
        </>
      )}
      {documents.length === 0 && (
        <div className="py-[20px] pl-[15px] text-[#757575] font-medium rounded-[5px] text-[14px] bg-[#f8f8f8]">
          No Reports Available
        </div>
      )}
    </>
  );
};
