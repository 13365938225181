import moment from "moment";
import { useEffect, useState } from "react";
import { IoIosClose, IoMdCloseCircleOutline } from "react-icons/io";
import { CloseIcon } from "../../../atoms/ListingSelectedBar";
import { useContract } from "../planning/context";
import { returnLabel } from "../ScheduleLoad";

export interface EventSideBarProps {
  date?: any;
  onClose?: any;
}

export const EventSideBar: React.FC<EventSideBarProps> = ({
  date,
  onClose,
}) => {
  const {
    actions: { getAvailability, clearAvailability },
    state: { availableVehicle },
  } = useContract();

  const [vehicleArray, setVehicleArray]: any = useState([]);
  useEffect(() => {
    if (vehicleArray) {
    }
  }, [vehicleArray]);
  useEffect(() => {
    if (availableVehicle) {
      setVehicleArray(
        returnLabel(availableVehicle[returnDateFormat()]?.detailAvailable)
      );
    }
  }, [date]);
  const returnDateFormat = () => {
    return moment(date).format("YYYYMMDD");
  };
  return (
    <>
      <div className="border h-full rounded-t-[5px] rounded-r-5  border-[#F2F5F6]">
        <div className="bg-[#F5F5F5] py-[16px] font-bold rounded-t-[5px] rounded-r-5 flex justify-between items-center px-[16px]">
          <p> {date && moment(date).format("MMMM DD")}</p>
          <p className="cursor-pointer" onClick={onClose}>
            <IoMdCloseCircleOutline />
          </p>
        </div>
        <div className="p-3">
          {" "}
          {vehicleArray?.length > 0 &&
            vehicleArray.map((item: any) => (
              <div className="flex justify-between items-center mb-4">
                <p className="text-[#686868] text-[14px] ">
                  {item.vehicleType}
                </p>
                <p>{item.count}</p>
              </div>
            ))}
        </div>
        <div className="flex items-center justify-between p-3 items-center justify-between border  border-t border-[#F2F5F6] border-l-0 border-r-0 border-b-0">
          <p className="text-[#686868] text-[14px] text-[#686868] font-semibold">
            Total Available
          </p>
          <p>
            {" "}
            {availableVehicle &&
              availableVehicle[returnDateFormat()]?.totalAvailable}
          </p>
        </div>
      </div>
    </>
  );
};
