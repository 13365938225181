import { Avatar, Modal, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BreadCrumbs } from "../../../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../../../atoms/Button/Button";
import { Pagination } from "../../../../atoms/Pagination/pagination";
import { PermissionsGate } from "../../../../atoms/PermissionComponent/PermissionGate";
import { Table } from "../../../../atoms/Table/Table";
import { useSearchParams } from "../../../../hooks/useSearchParams";
import { PlusIcon } from "../../../../icons";
import { EditPenIcon } from "../../../../icons/edit-pen";
import { ListTrash } from "../../../../icons/home";
import { ScheduleListingIcon } from "../../../../icons/schedule";
import { ListHeader } from "../../../../molecules/list-header/ListHeader";
import { getCurrentVehicleTypesFromKey } from "../../../../options/VehicleLoan";
import { ShowDateFormat } from "../../../../utils";
import {
  getCurrentStatusFromKey,
  returnLabel,
  Schedulestatus,
} from "../../ScheduleLoad";
import { useContract } from "../context";

export const AllPlanning: React.FC = () => {
  const { query, updateSearchParams }: any = useSearchParams();
  const [data, setData] = useState([]);
  const { confirm } = Modal;
  const {
    actions: { getAllContracts, deleteOne },
    state: { documents, pagination },
  } = useContract();

  useEffect(() => {
    if (Object.keys(query).length !== 0) {
      getAllContracts(false, query);
    } else {
      updateSearchParams({ page: 1, perPage: 10 });
    }
  }, [query]);

  useEffect(() => {
    if (documents) {
      documents.map((item: any, i: any) => {
        item["key"] = i.toString();
      });
      setData(documents);
    }
  }, [documents]);

  const columns: ColumnsType = [
    {
      title: "Client",
      dataIndex: "vehicleName",
      render: (value, record: any) => (
        <>
          <div className="flex flex-start gap-2">
            {" "}
            <div className="flex flex-col gap-1 ">
              <div className="flex items-center ">
                {/* <ScheduleListingIcon />{" "} */}
                <p className="font-medium ml-2 text-[12px]">
                  {record?.group?.name}
                </p>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Contact",
      dataIndex: "",
      render: (value, record: any) => (
        <div className={`capitalize flex items-center `}>
          <Avatar
            style={{
              backgroundColor: `${record.contact?.color}`,
              textTransform: "uppercase",
            }}
          >{`${record?.contact?.firstName[0]}${record?.contact?.lastName[0]}`}</Avatar>
          <p className="ml-1 mb-1">
            {record?.contact?.firstName} {record?.contact?.lastName}
          </p>
        </div>
      ),
    },
    {
      title: "User",
      dataIndex: "",
      render: (value, record: any) => (
        <div className={`capitalize`}>
          <p className="font-medium mb-1  text-[12px]">
            {record.user?.firstName} {record.user?.lastName}
          </p>
        </div>
      ),
    },
    {
      title: "Name of contract",
      dataIndex: "name",
      render: (value) => <div className="capitalize">{value}</div>,
    },
    {
      title: "Vehicle Type",
      dataIndex: "",
      render: (record: any) => (
        <>
          {record.vehicleInfo?.length > 0 ? (
            <p className="text-[#398CC0] cursor-pointer underline">
              {" "}
              <Tooltip
                title={
                  <div>
                    {record.vehicleInfo?.map((item: any) => (
                      <div className="flex items-center">
                        <p className="text-[#398CC0] text-[12px] mr-2">
                          {item.noOfUnits}
                        </p>
                        <p className="text-[12px]">{item?.vehicleType.name}</p>
                      </div>
                    ))}
                  </div>
                }
              >
                {`${record.vehicleInfo.length} vehicles`}{" "}
              </Tooltip>
            </p>
          ) : (
            <>-</>
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value) => (
        <div style={{ color: Schedulestatus(value) }} className={`capitalize`}>
          {getCurrentStatusFromKey(value)?.label}
        </div>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "contractStartDate",
      render: (value) => ShowDateFormat(value),
    },
    {
      title: "End Date",
      dataIndex: "contractEndDate",
      render: (value) => ShowDateFormat(value),
    },
    {
      title: "Action",
      dataIndex: "vehicleName",
      render: (value, record: any) => (
        <div className="flex  items-center">
          <PermissionsGate action="edit" module="contract">
            <Link to={`/schedule/planning/edit/${record.id}`}>
              <a className="underline flex items-center mr-[10px]">
                <EditPenIcon />
              </a>
            </Link>
          </PermissionsGate>

          <PermissionsGate action="delete" module="contract">
            <div
              className="cursor-pointer"
              onClick={() => {
                confirm({
                  title: "Warning",
                  content: "Are you sure want to delete this Contract?",
                  onOk() {
                    deleteOne(record.id);
                  },
                  onCancel() {},
                });
              }}
            >
              <ListTrash />
            </div>
          </PermissionsGate>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="w-full h-full bg-white px-6">
        <div className="flex justify-between items-center py-[24px]">
          <BreadCrumbs />
          <div>
            <div className="flex flex-row-reverse gap-4">
              <PermissionsGate action="create" module="contract">
                <Link to={`/schedule/planning/add`}>
                  {" "}
                  <Button className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center">
                    <span>
                      <PlusIcon />
                    </span>
                    Add Schedule
                  </Button>
                </Link>
              </PermissionsGate>
            </div>
          </div>
        </div>
        <div className="card_style ">
          <ListHeader
            onNewClicked={() => {}}
            onFilterChanged={(filter) => {}}
            onFilterClicked={() => {}}
            searchPlaceHolder="Search contract"
            pagination={pagination}
          ></ListHeader>
          <Table
            className="vehicle_table"
            columns={columns}
            dataSource={data}
            pagination={false}
          />
          <div className="mr-[24px] my-5">
            {" "}
            <Pagination
              pagination={pagination}
              onNext={() => {
                let newQuery: any = { ...query };
                newQuery.page = Number(newQuery.page) + 1;
                updateSearchParams({ ...newQuery });
              }}
              onPrevious={() => {
                let newQuery: any = { ...query };
                newQuery.page = Number(newQuery.page) - 1;
                updateSearchParams({ ...newQuery });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
