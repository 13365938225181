import { useReducer } from "react";
import { container } from "../../services/storage/context.container";
import { APIService } from "../../services/api/request";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { buildQueryString } from "../../services/utils/url";
import { destroyMessage, showMessage, UpdatedSuccessfully } from "../../utils";

const ApiRoutes = {
  base: `/vendors`,
};

export type StateType = {
  minimalDocuments: any;
  documents: any;
  pagination: any;
  vendor: any;
};

const initialState: StateType = {
  documents: [],
  pagination: {},
  vendor: {},
  minimalDocuments: [],
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_MININAL_VENDORS":
      return {
        ...state,
        minimalDocuments: payload.map((x: any) => ({
          value: x.id,
          label: x.name,
        })),
      };
    case "GET_VENDORS":
      return { ...state, ...payload };
    case "GET_ONE_VENDOR":
      return { ...state, vendor: { ...payload } };
    case "CLEAR_ONE_VENDOR":
      return { ...state, vendor: {} };
    default:
      return state;
  }
};

export const { useContext: useVendors, Provider: VendorProvider } = container(
  () => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });
    let history = useHistory();
    const getAllVendors = (
      mininal?: boolean,
      params?: any,
      callback?: (data: any) => void
    ) => {
      showMessage();
      params = buildQueryString(params);
      APIService.get(
        mininal
          ? `${ApiRoutes.base}?all=true`
          : `${ApiRoutes.base}${params ? `${params}` : ""}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          const response: any = res.data;
          if (mininal) {
            dispatch({ type: "GET_MININAL_VENDORS", payload: response });
          } else {
            dispatch({ type: "GET_VENDORS", payload: response });
          }

          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const updateOne = (
      id: string,
      data: any,
      callback?: (data: any) => void
    ) => {
      APIService.put(
        `${ApiRoutes.base}/${id}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          UpdatedSuccessfully();
          getOneVendor(id);
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const createOne = (data: any, callback?: (data: any) => void) => {
      APIService.post(
        `${ApiRoutes.base}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          message.success("Created Successfully!!!");
          // history.goBack();
          getAllVendors();
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          } else {
            message.error("Vendor Creation Failed");
          }
        });
    };

    const getOneVendor = (id: string, callback?: (data: any) => void) => {
      APIService.get(
        `${ApiRoutes.base}/${id}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          const response: any = res.data;
          dispatch({ type: "GET_ONE_VENDOR", payload: response });
          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };
    const deleteVendor = (id: string, callback?: (response: any) => void) => {
      APIService.delete(
        `${ApiRoutes.base}/${id}`,
        {},
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          callback && callback(response);
        })
        .catch((err: any) => {});
    };

    return {
      state,
      actions: {
        getAllVendors,
        getOneVendor,
        updateOne,
        createOne,
        deleteVendor,
        clearOne: () => dispatch({ type: "CLEAR_ONE_VENDOR", payload: {} }),
      },
    };
  }
);
