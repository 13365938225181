import { Modal } from "antd";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Button } from "../../../../../atoms/Button/Button";
import { Spinner } from "../../../../../atoms/Spinner/spinner";
import { FormikText } from "../../../../../atoms/Text/Text";
import { SaveIcon } from "../../../../../icons/SaveIcon";
import * as Yup from "yup";
import { useContract } from "../../context";
import { FormikSinglSelect } from "../../../../../atoms/Select/Select";
export interface ContactProps {
  modelData?: any;
  group: any;
  onClose: () => void;
}
export const AddContactModal: React.FC<ContactProps> = ({
  modelData,
  onClose,
  group,
}) => {
  const [spinner, setSpinner] = useState(false);
  const {
    state: { contact },
    actions: { AddContact, getAllContacts },
  } = useContract();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    group: group ? group : "",
  });
  return (
    <>
      <Modal
        maskClosable={false}
        title="Add Contact"
        // header={false}
        className="add_model"
        visible={modelData}
        footer={false}
        centered={true}
        onCancel={onClose}
      >
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              firstName: Yup.string().required("First Name is required"),
              lastName: Yup.string().required("Last Name is required"),
              email: Yup.string().required("Email is required"),
              phoneNumber: Yup.string()
                .matches(
                  /^[0-9]{10}$/,
                  "Mobile number should contain valid 10 numbers"
                )
                .required("Mobile number is required"),
            })}
            onSubmit={(values) => {
              AddContact(
                {
                  ...values,
                  phoneNumber: values.phoneNumber.toString(),
                  group: values.group.value,
                },
                () => {
                  onClose();
                  getAllContacts(group.value);
                }
              );
            }}
          >
            {({ setFieldValue }) => (
              <div>
                <Form>
                  <div className="mb-3">
                    <FormikText
                      label="First Name"
                      must={true}
                      name="firstName"
                    />
                  </div>
                  <div className="mb-3">
                    <FormikText label="Last Name" must={true} name="lastName" />
                  </div>
                  <div className="mb-3">
                    <FormikText label="Email" must={true} name="email" />
                  </div>
                  <div className="mb-5">
                    <FormikText
                      label="Phone Number"
                      type={"number"}
                      must={true}
                      name="phoneNumber"
                    />
                  </div>
                  <div className="mb-8">
                    <FormikSinglSelect
                      must={true}
                      readOnly={true}
                      isClearable
                      options={[]}
                      label="Select Client"
                      name="group"
                      inputClassName="adjust_select_height"
                    />
                  </div>
                  <div className="flex flex-row-reverse gap-4">
                    {" "}
                    <Button
                      type="submit"
                      //   onClick={() => handleSubmit()}
                      className="rounded-[4px] px-2  bg-primary text-[13px] text-white font-semibold flex items-center  justify-center"
                    >
                      <span>
                        {spinner && (
                          <Spinner CustomClassName={"spinner_secondary"} />
                        )}
                      </span>
                      Add Contact
                    </Button>
                    <Button
                      className="  py-3   px-[21px]  font-medium text-[13px] rounded-[4px]"
                      type="button"
                      onClick={onClose}
                    >
                      <span className="flex items-center text-[#FB6DB2]">
                        Cancel
                      </span>
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};
