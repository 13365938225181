import React from "react";
import activeIcon from "../../icons/designIcons/selectedClose.svg";
export const DeleteIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 4.06136H13.25C13.0566 3.12087 12.5449 2.27582 11.801 1.66863C11.0572 1.06144 10.1268 0.729239 9.16667 0.728027L7.5 0.728027C6.53983 0.729239 5.60943 1.06144 4.86562 1.66863C4.12182 2.27582 3.61008 3.12087 3.41667 4.06136H0.833333C0.61232 4.06136 0.400358 4.14916 0.244078 4.30544C0.0877973 4.46172 0 4.67368 0 4.89469C0 5.11571 0.0877973 5.32767 0.244078 5.48395C0.400358 5.64023 0.61232 5.72803 0.833333 5.72803H1.66667V16.5614C1.66799 17.666 2.1074 18.7251 2.88852 19.5062C3.66963 20.2873 4.72867 20.7267 5.83333 20.728H10.8333C11.938 20.7267 12.997 20.2873 13.7782 19.5062C14.5593 18.7251 14.9987 17.666 15 16.5614V5.72803H15.8333C16.0543 5.72803 16.2663 5.64023 16.4226 5.48395C16.5789 5.32767 16.6667 5.11571 16.6667 4.89469C16.6667 4.67368 16.5789 4.46172 16.4226 4.30544C16.2663 4.14916 16.0543 4.06136 15.8333 4.06136ZM7.5 2.39469H9.16667C9.68356 2.39532 10.1876 2.55584 10.6097 2.85423C11.0317 3.15262 11.3512 3.57427 11.5242 4.06136H5.1425C5.31549 3.57427 5.63492 3.15262 6.05699 2.85423C6.47906 2.55584 6.9831 2.39532 7.5 2.39469ZM13.3333 16.5614C13.3333 17.2244 13.0699 17.8603 12.6011 18.3291C12.1323 18.798 11.4964 19.0614 10.8333 19.0614H5.83333C5.17029 19.0614 4.53441 18.798 4.06557 18.3291C3.59673 17.8603 3.33333 17.2244 3.33333 16.5614V5.72803H13.3333V16.5614Z"
        fill="white"
      />
      <path
        d="M6.66927 15.7272C6.89028 15.7272 7.10224 15.6394 7.25852 15.4831C7.4148 15.3268 7.5026 15.1149 7.5026 14.8939V9.89388C7.5026 9.67287 7.4148 9.4609 7.25852 9.30462C7.10224 9.14834 6.89028 9.06055 6.66927 9.06055C6.44826 9.06055 6.23629 9.14834 6.08001 9.30462C5.92373 9.4609 5.83594 9.67287 5.83594 9.89388V14.8939C5.83594 15.1149 5.92373 15.3268 6.08001 15.4831C6.23629 15.6394 6.44826 15.7272 6.66927 15.7272Z"
        fill="white"
      />
      <path
        d="M9.9974 15.7272C10.2184 15.7272 10.4304 15.6394 10.5867 15.4831C10.7429 15.3268 10.8307 15.1149 10.8307 14.8939V9.89388C10.8307 9.67287 10.7429 9.4609 10.5867 9.30462C10.4304 9.14834 10.2184 9.06055 9.9974 9.06055C9.77639 9.06055 9.56442 9.14834 9.40814 9.30462C9.25186 9.4609 9.16406 9.67287 9.16406 9.89388V14.8939C9.16406 15.1149 9.25186 15.3268 9.40814 15.4831C9.56442 15.6394 9.77639 15.7272 9.9974 15.7272Z"
        fill="white"
      />
    </svg>
  );
};

export const CloseIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5359 13.0816L10.8894 12.728L10.5359 12.3745L6.71751 8.5561C6.20984 8.04842 6.20984 7.2253 6.71751 6.71762C7.22519 6.20994 8.04831 6.20994 8.55599 6.71762L12.3744 10.536L12.7279 10.8895L13.0815 10.536L16.8999 6.71762C17.4075 6.20994 18.2307 6.20994 18.7383 6.71762C19.246 7.2253 19.246 8.04842 18.7383 8.5561L14.92 12.3745L14.5664 12.728L14.92 13.0816L18.7383 16.9C19.246 17.4076 19.246 18.2308 18.7383 18.7384C18.2307 19.2461 17.4075 19.2461 16.8999 18.7384L13.0815 14.9201L12.7279 14.5665L12.3744 14.9201L8.55599 18.7384C8.04832 19.2461 7.22519 19.2461 6.71751 18.7384C6.20984 18.2308 6.20984 17.4076 6.71751 16.9L10.5359 13.0816Z"
        fill="white"
        stroke="#FB6DB2"
      />
    </svg>
  );
};
