import React from "react";
import { useAuth } from "./RouteContext";
import { Switch, Route, Redirect } from "react-router-dom";
import { SessionsLayout } from "../pages/sessions/SessionsLayout";
import { MainLayout } from "../pages/MainLayout";
import { PageNotFound } from "../pages/PageNotFound";
import { Forbidden } from "../pages/Forbidden";
import { CommonProvider } from "../hooks/commonStateProvider";

interface RouteManProps {}

const ProtectedRoutes: React.FC<{}> = () => {
  return (
    <Switch>
      <CommonProvider>
        <Route exact path="/page-not-found" render={() => <PageNotFound />} />
        <Route exact path="/forbidden" render={() => <Forbidden />} />
        <Route path="/" component={MainLayout} />
      </CommonProvider>
      <Redirect to="/" />
    </Switch>
  );
};
const OpenRoutes: React.FC<{}> = () => {
  return (
    <Switch>
      <Route
        exact
        path="/page-not-found"
        render={() => <h1>Page Not found</h1>}
      />
      <Route path="/" component={SessionsLayout} />
      <Redirect to="/" />
    </Switch>
  );
};

export const RootRoute: React.FC<RouteManProps> = (props: RouteManProps) => {
  const {
    state: { hasSession },
  } = useAuth();

  
  if (hasSession) {
    return <ProtectedRoutes />;
  } else {
    return <OpenRoutes />;
  }
};
