import React from "react";

export const EditPenIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width,
  height,
}) => {
  return (
    <svg
      width={width ? width : 16}
      height={height ? height : 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1083_2262)">
        <path
          d="M15.2355 0.765302C14.7822 0.312767 14.1679 0.0585938 13.5275 0.0585938C12.887 0.0585938 12.2727 0.312767 11.8195 0.765302L0.976799 11.608C0.6663 11.9167 0.420107 12.284 0.252464 12.6885C0.0848214 13.093 -0.000943247 13.5268 0.000132058 13.9646V15.3333C0.000132058 15.5101 0.07037 15.6797 0.195394 15.8047C0.320418 15.9297 0.489988 16 0.666799 16H2.03547C2.47331 16.0012 2.90704 15.9156 3.31157 15.748C3.71609 15.5805 4.08337 15.3344 4.39213 15.024L15.2355 4.18064C15.6878 3.72743 15.9418 3.11328 15.9418 2.47297C15.9418 1.83266 15.6878 1.21851 15.2355 0.765302V0.765302ZM3.44947 14.0813C3.07347 14.4548 2.56544 14.6651 2.03547 14.6666H1.33347V13.9646C1.33279 13.7019 1.38424 13.4416 1.48482 13.1989C1.58541 12.9562 1.73314 12.7359 1.91947 12.5506L10.1481 4.32197L11.6815 5.8553L3.44947 14.0813ZM14.2921 3.23797L12.6215 4.9093L11.0881 3.3793L12.7595 1.70797C12.8601 1.60751 12.9796 1.52786 13.1111 1.47358C13.2425 1.41929 13.3834 1.39143 13.5256 1.39158C13.6679 1.39174 13.8087 1.41991 13.94 1.47448C14.0714 1.52905 14.1907 1.60896 14.2911 1.70964C14.3916 1.81032 14.4712 1.9298 14.5255 2.06126C14.5798 2.19272 14.6077 2.33359 14.6075 2.47581C14.6074 2.61804 14.5792 2.75885 14.5246 2.89019C14.4701 3.02153 14.3901 3.14084 14.2895 3.2413L14.2921 3.23797Z"
          fill="#494949"
        />
      </g>
      <defs>
        <clipPath id="clip0_1083_2262">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const ActiveEditPen: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width,
  height,
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4683 0.53198C11.1271 0.191331 10.6647 0 10.1826 0C9.70049 0 9.23808 0.191331 8.89692 0.53198L0.735186 8.69388C0.50146 8.9263 0.31614 9.20275 0.189948 9.50725C0.0637568 9.81176 -0.000801909 10.1383 7.518e-06 10.4679V11.4982C7.518e-06 11.6312 0.0528785 11.7589 0.14699 11.853C0.241101 11.9471 0.368742 12 0.501835 12H1.53209C1.86167 12.0009 2.18816 11.9365 2.49267 11.8104C2.79717 11.6842 3.07363 11.499 3.30605 11.2653L11.4683 3.1029C11.8088 2.76175 12 2.29944 12 1.81744C12 1.33544 11.8088 0.873133 11.4683 0.53198ZM2.59647 10.5557C2.31344 10.8369 1.93102 10.9952 1.53209 10.9963H1.00366V10.4679C1.00316 10.2701 1.04188 10.0742 1.1176 9.89149C1.19331 9.70879 1.30452 9.54291 1.44477 9.40348L7.63883 3.20929L8.79304 4.36352L2.59647 10.5557ZM10.7582 2.3933L9.50061 3.65141L8.34641 2.49969L9.60449 1.24158C9.68028 1.16596 9.77022 1.106 9.86917 1.06514C9.96813 1.02427 10.0742 1.0033 10.1812 1.00342C10.2883 1.00353 10.3943 1.02474 10.4931 1.06582C10.592 1.1069 10.6818 1.16705 10.7574 1.24283C10.8331 1.31862 10.893 1.40856 10.9339 1.50752C10.9747 1.60648 10.9957 1.71252 10.9956 1.81958C10.9955 1.92664 10.9743 2.03264 10.9332 2.13151C10.8921 2.23038 10.832 2.32018 10.7562 2.39581L10.7582 2.3933Z"
        fill="#FB6DB2"
      />
    </svg>
  );
};
