import { Switch, Route, Redirect } from "react-router";
import { ForgotPassword } from "./ForgotPassword";
import { Login } from "./Login";
import { ResetPassword } from "./ResetPassword";
import "./Sessions.scss";
import { AuthProvider } from "./context";
import { RegisterLogin } from "./RegisterLogin";
import ProjectLogo from "../../icons/designIcons/Logo.svg";
export interface SessionsLayoutProps {}

export const SessionsLayout: React.FC<SessionsLayoutProps> = (
  props: SessionsLayoutProps
) => {
  return (
    <div
      className="session-page flex  justify-center items-center"
      style={{ background: "white" }}
    >
      <div
        className="session-container flex justify-center items-center flex-col rounded-[10px]"
        style={{ boxShadow: "0px 0px 5px 0px #0000001A" }}
      >
        <img width={"122px"} src={ProjectLogo} className="mt-8" />
        <AuthProvider>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/verify/:type"
              render={(props) => {
                const { search } = props.location;

                return <Redirect to="/page-not-found" />;
              }}
            />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route
              exact
              path="/register/:invitationCode"
              component={RegisterLogin}
            />
            <Route
              exact
              path="/reset-password/:token"
              component={ResetPassword}
            />
            <Redirect to="/login" />
          </Switch>
        </AuthProvider>
      </div>
    </div>
  );
};
