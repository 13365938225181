import React from "react";
import dashboard from "../../icons/designIcons/Dashboard.svg";
import { DashboardProvider } from "./context";
import { Dashboard } from "./dashboard";
export interface DashboardLayoutProps {}
export const DashboardMailLayout: React.FC<DashboardLayoutProps> = (
  props: DashboardLayoutProps
) => {
  return (
    <DashboardProvider>
      {" "}
      <Dashboard />
    </DashboardProvider>

    // <div className="dashboard-main w-full h-full overflow-scroll p-[10px]">
    //   <img className="w-full" src={dashboard} />
    // </div>
  );
};
