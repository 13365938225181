import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Pagination } from "../../../atoms/Pagination/pagination";
import { PermissionsGate } from "../../../atoms/PermissionComponent/PermissionGate";
import { Table } from "../../../atoms/Table/Table";
import { useSearchParams } from "../../../hooks/useSearchParams";
import { EditPenIcon } from "../../../icons/edit-pen";
import { ListTrash } from "../../../icons/home";
import { ListingTimer } from "../../../icons/ListingTiming";
import { ListHeader } from "../../../molecules/list-header/ListHeader";
import { useContacts } from "../context";
import { Avatar, Drawer, Modal, Select } from "antd";
import { colorCode, getCurrentStatusFromKey } from "../ContantsLoaan";
import { CommentsDragger } from "../../../atoms/CommentsDragger/Comments";
import { ListCommentIcon } from "../../../icons/SendComments";
import { useCommonContext } from "../../../hooks/commonStateProvider";
const { confirm } = Modal;
export interface AllContactsProps {
  initalData?: any;
}
export const AllContactsListing: React.FC<AllContactsProps> = () => {
  const {
    actions: { getAllContacts, deleteContact },
    state: { documents, pagination },
  } = useContacts();
  const { query, updateSearchParams }: any = useSearchParams();
  const [extraFilters, setExtraFilters]: any = useState({});
  const [commentsObj, setComments] = useState({ visible: false, id: "" });
  const [data, setData] = useState<React.Key[]>([]);
  const { id }: any = useParams();
  const {
    actions: { getAllComments },
  } = useCommonContext();
  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "Name",
      render: (value, record: any) => (
        <div className="flex items-center">
          <Avatar style={{ backgroundColor: `${record?.color}` }}>
            <span className="capitalize"> {record.firstName?.charAt(0)}</span>
            <span className="capitalize">
              {record.lastName?.charAt(0)}
            </span>{" "}
          </Avatar>

          <p className="font-medium ml-2 text-[13px]">
            {record.firstName} &nbsp;
            {record.lastName}{" "}
          </p>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (value, record: any) => value,
    },

    {
      title: "Client/Sub Client",
      dataIndex: "group",
      render: (value, record: any) => value?.name,
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (value) => (
        <p className={`capitalize text-[${colorCode(value)}]`}>
          {getCurrentStatusFromKey(value)?.label}
        </p>
      ),
    },

    {
      title: "Action",
      // sorter: true,
      render: (value, record: any) => (
        <div className="flex  items-center">
          <div
            className="cursor-pointer  mr-[12px]"
            onClick={() => {
              setComments({ visible: true, id: record.id });
              getAllComments(record?.id);
            }}
          >
            {" "}
            <ListCommentIcon />
          </div>
          <PermissionsGate action="edit" module="contact">
            <Link to={`/contact/edit/${record.id}`}>
              <a className="underline flex items-center mr-[10px]">
                <EditPenIcon />
              </a>
            </Link>
          </PermissionsGate>
          <PermissionsGate action="delete" module="contact">
            <div
              className="cursor-pointer"
              onClick={() => {
                confirm({
                  title: "Warning",
                  content: "Are you sure want to delete this contact?",
                  onOk() {
                    deleteContact(record.id, (res) => {
                      getAllContacts();
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              <ListTrash />
            </div>
          </PermissionsGate>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (documents) {
      documents.map((item: any, i: any) => {
        item["key"] = i.toString();
      });
      setData(documents);
    }
  }, [documents]);
  useEffect(() => {
    if (Object.keys(query).length !== 0) {
      getAllContacts(false, query, id);
      setExtraFilters({
        ...extraFilters,
      });
    } else {
      updateSearchParams({ page: 1, perPage: 10 });
    }
  }, [query]);
  return (
    <>
      <div className=" ">
        <CommentsDragger
          title="Contact Comments"
          onClose={() => setComments({ visible: false, id: "" })}
          commentsObj={commentsObj}
          model="contact"
        />{" "}
        <ListHeader
          onNewClicked={() => {}}
          onFilterClicked={() => {}}
          searchPlaceHolder="Search by Name Email"
          pagination={pagination}
        ></ListHeader>
        <Table
          className=""
          // onChange={onTableActivity}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
        <div className="mr-[24px] my-5">
          {" "}
          <Pagination
            pagination={pagination}
            onNext={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) + 1;
              updateSearchParams({ ...newQuery });
            }}
            onPrevious={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) - 1;
              updateSearchParams({ ...newQuery });
            }}
          />
        </div>
      </div>{" "}
    </>
  );
};
