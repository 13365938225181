import React from "react";
export const SaveIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 5C5.60444 5 5.21776 5.1173 4.88886 5.33706C4.55996 5.55682 4.30362 5.86918 4.15224 6.23463C4.00087 6.60009 3.96126 7.00222 4.03843 7.39018C4.1156 7.77814 4.30608 8.13451 4.58579 8.41421C4.86549 8.69392 5.22186 8.8844 5.60982 8.96157C5.99778 9.03874 6.39992 8.99913 6.76537 8.84776C7.13082 8.69638 7.44318 8.44004 7.66294 8.11114C7.8827 7.78224 8 7.39556 8 7C8 6.46957 7.78929 5.96086 7.41421 5.58579C7.03914 5.21071 6.53043 5 6 5ZM6 8C5.80222 8 5.60888 7.94135 5.44443 7.83147C5.27998 7.72159 5.15181 7.56541 5.07612 7.38268C5.00043 7.19996 4.98063 6.99889 5.01922 6.80491C5.0578 6.61093 5.15304 6.43275 5.29289 6.29289C5.43275 6.15304 5.61093 6.0578 5.80491 6.01921C5.99889 5.98063 6.19996 6.00043 6.38268 6.07612C6.56541 6.15181 6.72159 6.27998 6.83147 6.44443C6.94135 6.60888 7 6.80222 7 7C7 7.26522 6.89464 7.51957 6.70711 7.70711C6.51957 7.89464 6.26522 8 6 8Z"
        fill="white"
      />
      <path
        d="M11.268 2.06101L9.939 0.73201C9.70743 0.499181 9.43197 0.3146 9.12858 0.188951C8.82518 0.0633023 8.49988 -0.00091728 8.1715 9.89814e-06H2.5C1.8372 0.000803827 1.20178 0.264451 0.73311 0.73312C0.264441 1.20179 0.000793929 1.83721 0 2.50001L0 9.50001C0.000793929 10.1628 0.264441 10.7982 0.73311 11.2669C1.20178 11.7356 1.8372 11.9992 2.5 12H9.5C10.1628 11.9992 10.7982 11.7356 11.2669 11.2669C11.7356 10.7982 11.9992 10.1628 12 9.50001V3.82851C12.0009 3.50013 11.9367 3.17483 11.8111 2.87143C11.6854 2.56804 11.5008 2.29258 11.268 2.06101ZM8.5 1.04001V1.50001C8.5 1.89783 8.34196 2.27937 8.06066 2.56067C7.77936 2.84197 7.39782 3.00001 7 3.00001H5C4.60218 3.00001 4.22064 2.84197 3.93934 2.56067C3.65804 2.27937 3.5 1.89783 3.5 1.50001V1.00001H8.1715C8.28215 1.00099 8.39234 1.01441 8.5 1.04001ZM11 9.50001C11 9.89783 10.842 10.2794 10.5607 10.5607C10.2794 10.842 9.89782 11 9.5 11H2.5C2.10218 11 1.72064 10.842 1.43934 10.5607C1.15804 10.2794 1 9.89783 1 9.50001V2.50001C1 2.10219 1.15804 1.72065 1.43934 1.43935C1.72064 1.15805 2.10218 1.00001 2.5 1.00001V1.50001C2.50079 2.16281 2.76444 2.79823 3.23311 3.2669C3.70178 3.73557 4.3372 3.99922 5 4.00001H7C7.62875 3.99806 8.23358 3.75884 8.69356 3.33019C9.15355 2.90154 9.43477 2.31506 9.481 1.68801L10.561 2.76801C10.8412 3.04995 10.999 3.43101 11 3.82851V9.50001Z"
        fill="white"
      />
    </svg>
  );
};
