import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Pagination } from "../../../atoms/Pagination/pagination";
import { Table } from "../../../atoms/Table/Table";
import { EditPenIcon } from "../../../icons/edit-pen";
import { ListTrash } from "../../../icons/home";
import { ListingTimer } from "../../../icons/ListingTiming";
import { ListHeader } from "../../../molecules/list-header/ListHeader";

export interface AllIssuesProps {
  initalData?: any;
}
export const AllIssueListing: React.FC<AllIssuesProps> = () => {
  const [currentTab, setCurrentTab] = useState("users");
  const history = useHistory();
  const { hash } = useLocation();
  useEffect(() => {
    setCurrentTab(hash || "#all");
  }, [hash]);
  const columns: ColumnsType = [
    {
      title: "Issue",
      dataIndex: "Issue",
      render: (value, record: any) => (
        <Link
          to={`issues/fsdfsdfs}`}
          className="underline font-medium text-[#398CC0]"
        >
          1001
        </Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "vehicleName",
      render: (value, record: any) => "data",
    },
    {
      title: "Summary",
      dataIndex: "licensePlate",
      render: (value, record: any) => "100011",
    },
    {
      title: "Reported Date",
      dataIndex: "vin",
      // sorter: true,
    },
    {
      title: "Issue Status",
      dataIndex: "fuelType",
      render: (value, record: any) => (
        <div className="flex items-center">
          <span className="mr-3">Open</span> <ListingTimer />{" "}
        </div>
      ),
    },

    {
      title: "Action",
      // sorter: true,
      render: (value, record: any) => (
        <div className="flex  items-center">
          <Link to={`/vehicles/edit/${record.id}/#info`}>
            <a className="underline flex items-center mr-[10px]">
              <EditPenIcon />
            </a>
          </Link>
          <div className="cursor-pointer" onClick={() => {}}>
            <ListTrash />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="">
        {" "}
        <ListHeader
          onNewClicked={() => {}}
          onFilterClicked={() => {}}
          searchPlaceHolder="Search by Name, License Plate, VIN, Make, Model"
        ></ListHeader>
        <Table
          className="vehicle_table"
          // onChange={onTableActivity}
          columns={columns}
          dataSource={[{}]}
          pagination={false}
        />
        {/* <Pagination
          pagination={pagination}
          onNext={() => {
            let newQuery: any = { ...query };
            newQuery.page = Number(newQuery.page) + 1;
            updateSearchParams({ ...newQuery });
          }}
          onPrevious={() => {
            let newQuery: any = { ...query };
            newQuery.page = Number(newQuery.page) - 1;
            updateSearchParams({ ...newQuery });
          }}
        /> */}
      </div>{" "}
    </>
  );
};
