import { BreadCrumbs } from "../../atoms/BreadCrumb/BreadCrumb";

export interface DetailHeaderProps {
  title?: string;
  children?: React.ReactNode;
}

export const DetailHeader: React.FC<DetailHeaderProps> = ({
  title,
  children,
}: DetailHeaderProps) => {
  return (
    <div className="pl-2">
      {/* Title and Subtitle */}
      <div className="title-container">
        {/* <BreadCrumbs lastCrumb={title} /> */}
      </div>
      {children}
    </div>
  );
};
