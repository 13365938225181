import React, { useEffect } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import locationPin from "../../../src/icons/designIcons/locationPin.svg";
const containerStyle = {
  width: "100%",
  height: "800px",
};

let center: any;

function MyMapComponent({ initialData }: any) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCMa1cJNqAhg_RHPHRBiEAiUsBuTBUAe8U",
  });
  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      center = {
        lat: initialData?.lastKnownLocation?.lat,
        lng: initialData?.lastKnownLocation?.lon,
      };
    }
  }, [initialData]);
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div className="w-full h-full">
      {" "}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={1}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker position={center} icon={locationPin}></Marker>
        <></>
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(MyMapComponent);
