import moment from "moment";
import { GetuploadUrl } from "../../utils";

export const calculateCommentTime = (createdDate: any) => {
  let differenceInDate = moment().diff(moment(createdDate), "days");
  // return differenceInDate;
  switch (differenceInDate) {
    case 0:
      return "Today";
    case 1:
      return "Yesterday";
    default:
      return moment(createdDate).format("DD MMM YYYY");
  }
};
