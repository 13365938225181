import { useEffect, useState } from "react";
import { Pagination } from "../../atoms/Pagination/pagination";
import { Search } from "../../atoms/Search/Search";
import { useSearchParams } from "../../hooks/useSearchParams";

export interface ListHeaderProps {
  onChange?: (filter: any) => void;
  onFilterChanged?: (filter: any) => void;
  onFilterClicked?: () => void;
  onNewClicked?: () => void;
  children?: React.ReactNode;
  searchPlaceHolder?: string;
  pagination?: any;
  hash?: any;
}

export const ListHeader: React.FC<ListHeaderProps> = ({
  children,
  searchPlaceHolder,
  pagination,
  hash,
}: ListHeaderProps) => {
  const { query, updateSearchParams }: any = useSearchParams();
  const [filter, setFilter]: any = useState({});
  const [searchText, setSearchText]: any = useState("");
  const [hasFilter, setHasFilter]: any = useState(false);

  useEffect(() => {
    let filter = { ...query };
    delete filter["page"];
    delete filter["perPage"];
    delete filter["sort"];
    const hasFilter = Object.keys(filter).length > 0;
    setFilter({ ...filter });
    setSearchText(filter.q || "");
    setHasFilter(hasFilter);
  }, [query]);

  const handleKeyDown = ({ key, target }: any) => {
    if (key === "Enter") {
      let newFilter = { ...query };
      newFilter = { ...query, q: target.value };

      updateSearchParams({ ...newFilter, page: "1" });
      // onFilterChanged && onFilterChanged(newFilter);
    }
  };
  return (
    <div>
      <div className=" ">
        {/* Table, Buttons and Search Bar */}
        <div className="" style={{}}>
          <div className="flex justify-between items-start p-[21px] gap-2">
            {/* Search ToolBar */}
            <div className="flex flex-wrap w-[65%] ">
              <Search
                className="max-w-[220px]"
                label=""
                placeholder={searchPlaceHolder ? searchPlaceHolder : "Search"}
                value={searchText}
                onChange={({ target }) => {
                  setSearchText(target.value);
                }}
                onKeyDown={handleKeyDown}
              />
              {/* Filter Options and Create Button */}
              {children}
            </div>
            <div className="flex items-center justify-end w-[45%]">
              {pagination && (
                <Pagination
                  pagination={pagination}
                  onNext={() => {
                    let newQuery: any = { ...query };
                    newQuery.page = Number(newQuery.page) + 1;
                    updateSearchParams({ ...newQuery });
                  }}
                  onPrevious={() => {
                    let newQuery: any = { ...query };
                    newQuery.page = Number(newQuery.page) - 1;
                    updateSearchParams({ ...newQuery });
                  }}
                />
              )}
            </div>
          </div>
          {/* <SingleSelect label="Filters" /> */}
          {/* Filter Chips */}
          {/* {hasFilter && (
            <div className="p-2 mt-1">
              <p className="text-sm font-semibold mb-1">
                Applied Search & Filters:
              </p>
              <div className="flex gap-2">
                {Object.keys(filter).map(
                  (key) =>
                    filter[key] && (
                      <div className="flex justify-between items-center text-sm p-2 bg-primary text-white rounded cursor-pointer capitalize">
                        {filter[key]}
                        <FiX
                          size={"12"}
                          className="ml-2"
                          onClick={() => {
                            let newFilter = query;
                            delete newFilter[key];
                            updateSearchParams({ ...newFilter, page: "1" });
                          }}
                        />
                      </div>
                    )
                )}
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
