import { useReducer } from "react";
import { container } from "../../services/storage/context.container";
import { APIService } from "../../services/api/request";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { buildQueryString } from "../../services/utils/url";
import { destroyMessage, showMessage, UpdatedSuccessfully } from "../../utils";

const ApiRoutes = {
  userBase: `/users`,
  rolesBase: "/roles",
};

export type StateType = {
  invites: {
    documents: any;
    pagination: any;
    minimalDocuments: any;
    invite?: any;
  };
  roles: {
    documents: any;
    pagination: any;
    minimalDocuments: any;
    role?: any;
  };
  users: {
    documents: any;
    pagination: any;
    minimalDocuments: any;
    user?: any;
  };
};

const initialState: StateType = {
  users: { documents: [], minimalDocuments: [], pagination: {}, user: null },
  roles: { documents: [], minimalDocuments: [], pagination: {}, role: null },
  invites: {
    documents: [],
    minimalDocuments: [],
    pagination: {},
    invite: null,
  },
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_MININAL_USERS":
      return state;
    case "GET_USERS":
      return { ...state, users: { ...state.users, ...payload } };
    case "GET_INVITEES":
      return { ...state, invites: { ...state.invites, ...payload } };
    case "GET_ROLES":
      return { ...state, roles: { ...state.roles, ...payload } };
    case "GET_MINIMAL_ROLES":
      return {
        ...state,
        roles: {
          ...state.roles,
          minimalDocuments: payload.map((x: any) => ({
            ...x,
            label: x.name,
            value: x.id,
          })),
        },
      };
    case "GET_ONE_USERS":
      return { ...state, users: { ...state.users, ...payload } };
    default:
      return state;
  }
};

export const { useContext: useUsers, Provider: UsersProvider } = container(
  () => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });
    let history = useHistory();

    const getAllInvitees = (
      mininal?: boolean,
      params: any = {},
      callback?: (data: any) => void
    ) => {
      params = buildQueryString({ ...params, status: "invited" });

      showMessage();
      APIService.get(
        mininal
          ? `${ApiRoutes.userBase}?all=true&filter[status]=invited`
          : `${ApiRoutes.userBase}${params ? `${params}` : ""}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          // showThenHide(false);
          const response: any = res.data;
          if (mininal) {
            // dispatch({ type: "GET_MININAL_USERS", payload: response });
          } else {
            dispatch({ type: "GET_INVITEES", payload: response });
          }

          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const getAllUsers = (
      mininal?: boolean,
      params?: any,
      callback?: (data: any) => void
    ) => {
      if (!mininal) {
      }
      params = buildQueryString(params);
      showMessage();
      APIService.get(
        mininal
          ? `${ApiRoutes.userBase}?all=true`
          : `${ApiRoutes.userBase}${params ? `${params}` : ""}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          // showThenHide(false);
          const response: any = res.data;
          if (mininal) {
            dispatch({ type: "GET_MININAL_USERS", payload: response });
          } else {
            dispatch({ type: "GET_USERS", payload: response });
          }

          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const updateOne = (
      id: string,
      data: any,
      callback?: (data: any) => void
    ) => {
      showMessage();
      APIService.put(
        `${ApiRoutes.userBase}/${id}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          UpdatedSuccessfully();

          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const createOneUser = (data: any, callback?: (data: any) => void) => {
      APIService.post(
        `${ApiRoutes.userBase}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          message.success("Created Successfully!!!");
          history.goBack();
          getAllInvitees(false, { page: 1, perPage: 10, status: "invited" });
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          } else {
            message.error("Invite Creation Failed");
          }
        });
    };

    const getOneUser = (id: string, callback?: (data: any) => void) => {
      APIService.get(
        `${ApiRoutes.userBase}/${id}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          const response: any = res.data;
          dispatch({ type: "GET_ONE_USERS", payload: response });
          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const revokeInvite = (id: string, callback?: (data: any) => void) => {
      APIService.delete(
        `${ApiRoutes.userBase}/${id}`,
        {},
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          getAllInvitees(false, { page: 1, perPage: 10, status: "invited" });
          const response: any = res.data;

          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const getAllRoles = (
      mininal?: boolean,
      params?: any,
      callback?: (data: any) => void
    ) => {
      params = buildQueryString(params);
      showMessage();
      APIService.get(
        mininal
          ? `${ApiRoutes.rolesBase}?all=true`
          : `${ApiRoutes.rolesBase}${params ? `${params}` : ""}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          const response: any = res.data;
          if (mininal) {
            dispatch({ type: "GET_MINIMAL_ROLES", payload: response });
          } else {
            dispatch({ type: "GET_ROLES", payload: response });
          }

          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    return {
      state,
      actions: {
        getAllUsers,
        getOneUser,
        updateOne,
        createOneUser,
        getAllRoles,
        getAllInvitees,
        revokeInvite,
      },
    };
  }
);
