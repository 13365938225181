import { Button } from "antd";
import moment, { months } from "moment";
import { useEffect, useState } from "react";
import { useRef } from "react";

import { BreadCrumbs } from "../../../atoms/BreadCrumb/BreadCrumb";
import {
  InactiveLeftArrow,
  InactiveRightArrow,
  LeftArrow,
  RightArrow,
} from "../../../icons/Pagination";
import { calculateWeek, calenderArray, Months, Years } from "../ScheduleLoad";
import { DayCalendar } from "./DayCalendar";
import { MonthlyCalendar } from "./MonthlyCalendar";
import { CalenderWeeklyView } from "./WeeklyCalendar";

export interface ScheduleCalenderProps {}

export const ScheduleCalender: React.FC<ScheduleCalenderProps> = () => {
  const [viewType, setViewType] = useState("day");
  const [currentdate, setCurrentDate]: any = useState(
    moment().startOf("month")
  );
  const divItem: any = useRef(null);
  const monthDrop: any = useState(null);
  const [showDropDown, setDropDown] = useState(false);
  const [monthDropDown, setMonthDropDown] = useState(false);
  const [totalWeeks, setWeeks]: any = useState([]);
  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
  });
  const closeOpenMenus = (e: any) => {
    if (divItem && divItem.current && !divItem?.current.contains(e.target)) {
      setDropDown(false);
    }
    if (
      monthDrop &&
      monthDrop.current &&
      !monthDrop?.current.contains(e.target)
    ) {
      setMonthDropDown(false);
    }
  };
  useEffect(() => {
    if (currentdate) {
      setWeeks(calculateWeek(currentdate));
    }
  }, [currentdate]);
  useEffect(() => {
    setCurrentDate(moment().startOf("month"));
  }, [viewType]);

  return (
    <>
      {" "}
      <div className="px-[24px]">
        <div className="flex justify-between items-center py-[24px]">
          <BreadCrumbs />
        </div>
        <div
          className={`flex  ${
            viewType === "month" ? "items-start" : "items-center"
          } justify-between`}
        >
          <div>
            {viewType === "month" && (
              <div className="flex mb-[25px]">
                <div className="relative">
                  <p
                    className="text-[#D1D1D1] text-[24px] mr-[12px] cursor-pointer font-semibold"
                    onClick={() => setDropDown(true)}
                  >
                    {moment(currentdate).year()}
                  </p>
                  {showDropDown && (
                    <div
                      ref={divItem}
                      className="absolute top-[100%] overflow-y-scroll hide_scroll  border border-[#E9E9E9] rounded-[4px] h-[240px] w-[130px] bg-[white]"
                    >
                      {Years().map((item: any, i) => (
                        <p
                          key={i}
                          className="border-b last:border-b-0  border-[#F5F5F5] cursor-pointer p-[12px] text-[13px] text-[#716D6D]"
                          onClick={() => {
                            setCurrentDate(moment(currentdate).year(item));
                            setDropDown(false);
                          }}
                        >
                          {item}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
                <div className="relative">
                  <p
                    className="text-[#303030] text-[24px] mr-[12px] cursor-pointer font-semibold"
                    onClick={() => setMonthDropDown(true)}
                  >
                    {moment(currentdate).format("MMMM")}
                  </p>
                  {monthDropDown && (
                    <div
                      ref={monthDrop}
                      className="absolute bg-[white]  overflow-y-scroll hide_scroll top-[100%] border border-[#E9E9E9] rounded-[4px] h-[240px] w-[130px] bg-[white]"
                    >
                      {Months.map((item: any, i) => (
                        <p
                          key={i}
                          onClick={() => {
                            setCurrentDate(moment(currentdate).month(item.key));
                            setMonthDropDown(false);
                          }}
                          className="border-b cursor-pointer last:border-b-0  border-[#F5F5F5] p-[12px] text-[13px] text-[#716D6D] "
                        >
                          {item.name}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="flex">
              <div className="bg-[#F9F9F9] rounded-[4px] px-[12px] py-[7px] text-[#5F5F5F] font-medium  text-[13px] mr-[15px]">
                Availability
              </div>
              {calenderArray.map((item: any, i) => (
                <div
                  key={i}
                  className="flex items-center   text-[#5F5F5F]  mr-[15px]"
                >
                  <p
                    style={{ background: item.color }}
                    className=" w-[8px] h-[8px] rounded-[50%] mr-[10px]"
                  ></p>
                  <p
                    style={{ color: item.color }}
                    className=" font-medium text-[12px]"
                  >
                    {item.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex">
            <div className="border border-[#F3F3F3] flex items-center  p-[11px] rounded-[5px] mr-5">
              <p
                onClick={() => setViewType("day")}
                className={`${
                  viewType === "day"
                    ? "text-[#fb6db2] font-medium"
                    : "text-[#303030]"
                } text-[13px] cursor-pointer  pr-2 border-r border-[#F3F3F3] `}
              >
                Day
              </p>
              <p
                onClick={() => setViewType("week")}
                className={`text-[13px] ${
                  viewType === "week"
                    ? "text-[#fb6db2] font-medium"
                    : "text-[#303030]"
                }  cursor-pointer pl-2 pr-2 border-r border-[#F3F3F3]`}
              >
                Week
              </p>
              <p
                onClick={() => setViewType("month")}
                className={`text-[13px] ${
                  viewType === "month"
                    ? "text-[#fb6db2] font-medium"
                    : "text-[#303030]"
                }  cursor-pointer pl-2 `}
              >
                Month
              </p>
            </div>
            <div className="flex">
              <Button
                onClick={() => {
                  setCurrentDate(moment(currentdate).subtract(1, "month"));
                }}
                className={`h-full pagination_button border-[#f0f0f0] flex items-center rounded-l-[4px] rounded-r-none rounded-b-none text-[#FB6DB2]`}
              >
                <LeftArrow />
              </Button>
              <Button
                onClick={() => {
                  setCurrentDate(moment(currentdate).add(1, "month"));
                }}
                className={`h-full pagination_button border-[#f0f0f0]  rounded-l-none border-l-[0] rounded-r-[4px] text-[#FB6DB2]`}
              >
                <RightArrow />
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-5">
          {viewType === "day" && <DayCalendar />}
          {viewType === "week" && (
            <CalenderWeeklyView totalWeeks={totalWeeks} date={currentdate} />
          )}
          {viewType === "month" && <MonthlyCalendar date={currentdate} />}
        </div>
      </div>
    </>
  );
};
