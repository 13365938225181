import { GroupProvider } from "./context";
import { Switch, Route, Redirect } from "react-router";
import { AllGroups } from "./all/AllGroups";
import { AllVehiclePage } from "../vehicles/all/AllVehicles";
import { VehiclesProvider } from "../vehicles/context";
import { ContactsProvider } from "../contacts/context";
import { AllContacts } from "../contacts/all/AllContacts";
import { AllContactsListing } from "../contacts/all/AllContactListing";
import { isPremitted } from "../../services/utils/permission";
export interface GroupMainLayoutProps {}

export const GroupMainLayout: React.FC<GroupMainLayoutProps> = (
  props: GroupMainLayoutProps
) => {
  return (
    <>
      <GroupProvider>
        <Switch>
          <Route
            exact
            path="/clients"
            render={() => {
              if (isPremitted("group", "list")) {
                return <AllGroups />;
              } else {
                return <Redirect to="/forbidden" />;
              }
            }}
          />
        </Switch>
        <VehiclesProvider>
          <Switch>
            <Route
              path="/clients/:id/vehicles"
              render={() => {
                if (isPremitted("vehicle", "list")) {
                  return <AllVehiclePage group={true} />;
                } else {
                  return <Redirect to="/forbidden" />;
                }
              }}
            />{" "}
          </Switch>
        </VehiclesProvider>
        <ContactsProvider>
          <Switch>
            <Route
              path="/clients/:id/contacts"
              render={() => {
                if (isPremitted("contact", "list")) {
                  return <AllContactsListing />;
                } else {
                  return <Redirect to="/forbidden" />;
                }
              }}
            />
          </Switch>
        </ContactsProvider>
      </GroupProvider>
    </>
  );
};
