import { Switch, Route, Redirect } from "react-router";
import { isPremitted } from "../../services/utils/permission";
import { AllContacts } from "./all/AllContacts";
import { ContactsProvider } from "./context";
import { OneContact } from "./one/OneContact";
export interface ContactMainLayoutProps {}

export const ContactMainLayout: React.FC<ContactMainLayoutProps> = (
  props: ContactMainLayoutProps
) => {
  return (
    <ContactsProvider>
      {" "}
      <Switch>
        <Route
          exact
          path="/contact"
          render={() => {
            if (isPremitted("contact", "list")) {
              return <AllContacts />;
            } else {
              return <Redirect to="/forbidden" />;
            }
          }}
        />
        <Route
          exact
          path="/contact/add"
          render={() => {
            if (isPremitted("contact", "create")) {
              return <OneContact />;
            } else {
              return <Redirect to="/forbidden" />;
            }
          }}
        />
        <Route
          exact
          path="/contact/edit/:id"
          render={() => {
            if (isPremitted("contact", "edit")) {
              return <OneContact />;
            } else {
              return <Redirect to="/forbidden" />;
            }
          }}
        />
        <Route
          path="/clients/:id/contacts"
          render={() => {
            if (isPremitted("contact", "list")) {
              return <AllContacts />;
            } else {
              return <Redirect to="/forbidden" />;
            }
          }}
        />
        <Redirect to="/page-not-found" />
      </Switch>
    </ContactsProvider>
  );
};
