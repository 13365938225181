import { useReducer } from "react";
import { container } from "./../../services/storage/context.container";
import { APIService } from "../../services/api/request";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { buildQueryString } from "../../services/utils/url";
import { destroyMessage, showMessage } from "../../utils";
import { Console } from "console";
export type StateType = {
  minimalDocuments: any;
  documents: any;
  groups: any;
  pagination: any;
  users: any;
};
const ApiRoutes = {
  Base: `/auth`,
};

const reducer = (action: { type: string; payload?: any }) => {
  const { type, payload } = action;
};

export const { useContext: useAuth, Provider: AuthProvider } = container(() => {
  let history = useHistory();
  const Login = (data: any, callback?: (data: any) => void) => {
    showMessage();
    APIService.post(
      `${ApiRoutes.Base}/login`,
      data,
      process.env.REACT_APP_API_ENDPOINT
    )
      .then((res: any) => {
        destroyMessage('loading');
        // showThenHide(false);
        const response: any = res.data;

        if (typeof callback === "function") {
          callback(response);
        }
      })
      .catch((error: any) => {
        destroyMessage('loading');
        if (typeof error === "object" && error.status) {
          message.error(error.message);
        }
      });
  };

  const ForgetPassword = (data: any, callback?: (data: any) => void) => {
    showMessage();
    APIService.post(
      `${ApiRoutes.Base}/reset-password`,
      data,
      process.env.REACT_APP_API_ENDPOINT
    )
      .then((res: any) => {
        destroyMessage('loading');
        const response = res.data;
        if (typeof callback === "function") {
          callback(response);
        }
      })
      .catch((error: any) => {
        destroyMessage('loading');
        if (typeof error === "object" && error.status) {
          message.error(error.message);
        }
      });
  };

  const getUserByInvite = (
    queryparams: any,
    callback?: (data: any) => void
  ) => {
    APIService.get(
      `/users/invite/${queryparams}`,
      undefined,
      process.env.REACT_APP_API_ENDPOINT
    )
      .then((response: any) => {
        callback && callback(response.data);
      })
      .catch((error: any) => {
        destroyMessage('loading');
        if (typeof error === "object" && error.status) {
          message.error(error.message);
        }
      });
  };

  const ResetPassword = (data: any, callback?: (data: any) => void) => {
    showMessage();
    APIService.post(
      `${ApiRoutes.Base}/update-password`,
      data,
      process.env.REACT_APP_API_ENDPOINT
    )
      .then((res: any) => {
        destroyMessage('loading');
        history.push("/login");
      })
      .catch((error: any) => {
        destroyMessage('loading');
        if (typeof error === "object" && error.status) {
          message.error(error.message);
        }
      });
  };
  const InviteLogin = (data: any, callback?: (data: any) => void) => {
    showMessage();
    APIService.post(
      `${ApiRoutes.Base}/register`,
      data,
      process.env.REACT_APP_API_ENDPOINT
    )
      .then((res: any) => {
        destroyMessage('loading');
        // history.push("/login");
        callback && callback(res?.data);
      })
      .catch((error: any) => {
        destroyMessage('loading');
        message.error(error.message);
        if (typeof error === "object" && error.status) {
          message.error(error.message);
        }
      });
  };

  return {
    actions: {
      Login,
      ResetPassword,
      ForgetPassword,
      getUserByInvite,
      InviteLogin,
    },
  };
});
