import "./main.scss";
export interface MainProps {
  children?: React.ReactNode;
}

export const Main: React.FC<MainProps> = (props: MainProps) => {
  return (
    <main className="overflow-y-scroll w-full h-full">{props.children}</main>
  );
};
