import { Form, Formik } from "formik";
import { Button } from "../../atoms/Button/Button";
import { FormikPassword, Password } from "../../atoms/Password/Password";
import { FormikText, Text } from "../../atoms/Text/Text";
import * as Yup from "yup";
import CookieStorage from "../../services/storage/cookie.storage";
import { message, Checkbox } from "antd";
import { useAuth } from "./context";

export const Login: React.FC<{}> = () => {
  const {
    actions: { Login },
  } = useAuth();

  return (
    <div className="flex justify-center  p-10 rounded-sm flex-col">
      <Formik
        onSubmit={(values, actions) => {
          const { email, password } = values;

          Login(values, (res) => {
            CookieStorage.write("token", res.token);
            localStorage.setItem("user", JSON.stringify(res?.user));
            localStorage.setItem(
              "permission",
              JSON.stringify(res?.user?.role?.modules)
            );
            window.location.reload();
          });

          //  else {
          //     message.error("Credentials are wrong.");
          //   }
        }}
        validationSchema={Yup.object({
          email: Yup.string().required("Email is required"),
          password: Yup.mixed().required("Password is required"),
        })}
        initialValues={{
          email: "zeebasoftwares@gmail.com",
          password: "Welcome@123",
        }}
      >
        {({ values, setFieldValue, setErrors, resetForm }) => (
          <Form>
            <div className="w-full" style={{ minWidth: 300 }}>
              <div className="mb-[20px]">
                <FormikText label="Email" name="email" required />
              </div>
              <div className="mb-[18px]">
                {" "}
                <FormikPassword label="Password" name="password" required />
              </div>
            </div>
            <div className="submit-container mt-10">
              <Button
                type="submit"
                className="bg-primary text-white px-10 py-3 font-bold text-[14px] w-full rounded-[5px]"
              >
                Login
              </Button>
            </div>
            <h4 className="font-normal mt-7 text-[#7B7B7B] text-center text-[12px]">
              Forgot Password?{" "}
              <span>
                <a
                  href="/forgot-password"
                  className="no-underline color-primary font-nromal "
                >
                  Click here
                </a>
              </span>
            </h4>
          </Form>
        )}
      </Formik>
    </div>
  );
};
