export const ListingSubItem: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 4C10.3284 4 11 3.32843 11 2.5C11 1.67157 10.3284 1 9.5 1C8.67157 1 8 1.67157 8 2.5C8 3.32843 8.67157 4 9.5 4ZM9.5 5C10.8807 5 12 3.88071 12 2.5C12 1.11929 10.8807 0 9.5 0C8.11929 0 7 1.11929 7 2.5C7 3.13766 7.23873 3.71956 7.6317 4.1612L4.1612 7.6317C3.71956 7.23873 3.13766 7 2.5 7C1.11929 7 0 8.11929 0 9.5C0 10.8807 1.11929 12 2.5 12C3.88071 12 5 10.8807 5 9.5C5 9.12168 4.91597 8.76298 4.76554 8.44156L6.24067 6.96644L9.41369 10.1221C9.15224 10.5171 9 10.9908 9 11.5C9 12.8807 10.1193 14 11.5 14C12.8807 14 14 12.8807 14 11.5C14 10.1193 12.8807 9 11.5 9C10.9901 9 10.5159 9.15264 10.1205 9.41471L6.94778 6.25933L8.44156 4.76554C8.76298 4.91597 9.12168 5 9.5 5ZM11.5 13C12.3284 13 13 12.3284 13 11.5C13 10.6716 12.3284 10 11.5 10C10.6716 10 10 10.6716 10 11.5C10 12.3284 10.6716 13 11.5 13ZM4 9.5C4 10.3284 3.32843 11 2.5 11C1.67157 11 1 10.3284 1 9.5C1 8.67157 1.67157 8 2.5 8C3.32843 8 4 8.67157 4 9.5Z"
        fill="#B3B3B3"
      />
    </svg>
  );
};
