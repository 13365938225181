import { message, Modal } from "antd";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { BreadCrumbs } from "../../../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../../../atoms/Button/Button";
import { FormikDatePicker } from "../../../../atoms/DatePicker/DatePicker";
import {
  FormikSinglSelect,
  SingleSelect,
} from "../../../../atoms/Select/Select";
import humps from "humps";
import { Spinner } from "../../../../atoms/Spinner/spinner";
import { FormikText, Text } from "../../../../atoms/Text/Text";
import { PlusWithCircle } from "../../../../icons";
import { AddClientModal } from "./sections/AddClientModal";
import { AddContactModal } from "./sections/AddContactModall";
import * as Yup from "yup";
import { useContract } from "../context";
import { ListTrash } from "../../../../icons/home";
import {
  getCurrentVehicleTypesFromKey,
  getVehiclekey,
  VehicleTypes,
} from "../../../../options/VehicleLoan";
import {
  contractStatus,
  getCurrentStatusFromKey,
  returnVehicleId,
  SendingDataFormat,
} from "../../ScheduleLoad";
import Item from "antd/lib/list/Item";
import moment from "moment";
import { AssignVehicle } from "./sections/assignVehicles";
import { AssignedVehicleList } from "./sections/AssignedVehicleList";
import { CancelUpload, PdfImage } from "../../../../icons/UploadIcons";
import uploadIcon from "../../../../icons/designIcons/uploadIcon.svg";
import { addIsUploadedKey, getAwsUploadUrl } from "../../../../utils";
export const OnePlanning: React.FC = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const { confirm } = Modal;
  const [addClient, setAddClient] = useState(false);
  const formRef = useRef() as any;
  const [addContact, setAddContact] = useState(false);
  const [vehicleDetails, setVehicleDetails]: any = useState([]);
  const [status, setStatus]: any = useState();
  const [spinner, setSpinner] = useState(false);
  const [assignVehicleView, setAssignVehicle] = useState(false);
  const [selectedVehicle, setSelectedVehicles]: any = useState();
  const [showPopUp, setShowPopUp] = useState(false);
  const [attachment, setFile]: any = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: "",
    contractStartDate: null,
    contractEndDate: null,
    contact: "",
    group: "",
    user: "",
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const {
    actions: {
      getAllContacts,
      getOneContract,
      getAllGroups,
      getAllUsers,
      createOne,
      updateOne,
      clearOne,
      getAvailableVehicles,
      assignVehicles,
      getVehicleTypes,
    },
    state: { users, contacts, groups, contract, vehicleTypes },
  } = useContract();

  const handleSubmit = () => {
    formRef.current.handleSubmit();
  };

  useEffect(() => {
    getAllGroups();
    getAllUsers();
    getVehicleTypes();
  }, []);

  useEffect(() => {
    if (id) {
      getOneContract(id);
    }
  }, []);

  useEffect(() => {
    return () => {
      clearOne();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(contract).length > 0) {
      if (contract.attachments?.length > 0) {
        setFile(addIsUploadedKey(contract.attachments));
      }
      setStatus(getCurrentStatusFromKey(contract.status));
      setInitialValues({
        ...contract,
        contractStartDate: moment(contract.contractStartDate),
        contractEndDate: moment(contract.contractEndDate),
        group: { label: contract.group?.name, value: contract.group?.id },
        contact: {
          label: `${contract.contact?.firstName} ${contract.contact?.lastName}`,
          value: contract.contact?.id,
        },
        user: {
          label: `${contract.user?.firstName} ${contract.user?.lastName}`,
          value: contract.user?.id,
        },
      });
      if (contract.vehicleInfo?.length > 0) {
        let newArray = contract.vehicleInfo.map((item: any) => {
          return {
            ...item,
            vehicleType: {
              label: item.vehicleType.name,
              value: item.vehicleType.id,
            },
          };
        });
        setVehicleDetails(newArray);
      }
    }
  }, [contract]);
  useEffect(() => {
  }, [vehicleDetails]);
  const addVehicles = (index: number) => {
    let emptyFlag = false;

    if (vehicleDetails.length === 0) {
      setVehicleDetails([
        ...vehicleDetails,
        { vehicleType: "", noOfUnits: "", notes: "" },
      ]);
    }

    if (vehicleDetails.length > 0) {
      vehicleDetails.map((item: any, i: any) => {
        if (!emptyFlag) {
          if (item.vehicleType === "" || item.noOfUnits === "") {
            emptyFlag = true;
            message.error(`Vehicle details in the (${i + 1} row)  are missing`);
          } else if (item.noOfUnits === "0") {
            emptyFlag = true;
            message.error(`You cannot provide 0 as vehicle count`);
          }
        }
      });
      if (!emptyFlag) {
        setVehicleDetails([
          { vehicleType: "", noOfUnits: "", notes: "" },
          ...vehicleDetails,
        ]);
      }
    }
  };

  const vehicleValidation = () => {
    let emptyFlag = false;
    if (vehicleDetails.length > 0) {
      vehicleDetails.map((item: any, i: any) => {
        if (item.noOfUnits === "0") {
          message.error(`You cannot provide 0 as vehicle count`);
          emptyFlag = true;
        } else if (item.vehicleType === "" || item.noOfUnits === "") {
          message.error("Vehicle Details cannot be empty");
          emptyFlag = true;
          return true;
        }
      });
      return emptyFlag;
    }
  };

  const getAllVehicles = (vehicleDetail: any, values: any) => {
    if (!values.contractStartDate || !values.contractEndDate) {
      message.error("Please Provide the contract Start and End Date");
    } else if (vehicleDetail.vehicleType === "") {
      message.error("Please select Vehicle type");
    } else {
      setAssignVehicle(true);
      getAvailableVehicles(
        SendingDataFormat(values.contractStartDate),
        SendingDataFormat(values.contractEndDate),
        vehicleDetail.vehicleType.value
      );
    }
  };
  const checkStatus = () => {
    return id && contract.status !== "to-do";
  };
  const checkAddable = () => {
    if (!id) {
      return true;
    } else {
      return id && contract.status === "to-do";
    }
  };

  const assignVehicle = () => {
    if (selectedRowKeys.length === 0) {
      message.error(`Please select atleast one vehicle to assign`);
    } else if (
      selectedRowKeys.length >
      selectedVehicle.noOfUnits - selectedVehicle.totalAssigns
    ) {
      message.error(
        `You cannot assign more than ${
          selectedVehicle.noOfUnits - selectedVehicle.totalAssigns
        } vehicles`
      );
    } else {
      let data = {
        contact: contract.contact.id,
        group: contract.group.id,
        contractStartDate: contract.contractStartDate,
        contractEndDate: contract.contractEndDate,
        assignVehicles: returnVehicleId(selectedRowKeys),
      };
      assignVehicles(
        `${contract.id}/assign-vehicle/${selectedVehicle.id}`,
        data,
        (res) => {
          setAssignVehicle(false);
          getOneContract(id);
        }
      );
    }
  };

  const uploadFunction = (e: any) => {
    let files = e.target.files;
    let length = attachment.length;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let item = files[i];
        let incrementedIndex = i + 1; // incrementing an variable as loop runs before updating the state.

        /*
          condition to not upload more than five files.
        */

        if (incrementedIndex + length <= 5) {
          if (
            item.type !== "application/pdf" &&
            item.type !== "text/csv" &&
            item.type !==
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            message.error("You cannot upload other than file formats");
          } else if (item.size > 5 * 1000000) {
            message.error("Couldn't upload large files");
          } else {
            let data = {
              orginal_file: item,
              name: item.name,
              url: window.URL.createObjectURL(item),
              type: item.type,
            };
            setFile((individualFiles: any) => [...individualFiles, data]);
          }
        } else return message.error("You can upload upto 5 attachments");
      }
    }
  };
  const deleteUploaded = (i: any) => {
    attachment.filter((item: any, index: any) => {
      if (index === i) {
        attachment.splice(i, 1);
      }
      setFile([...attachment]);
    });
  };
  const uploadAttachments = async () => {
    if (attachment.length > 0) {
      let attachments: any = [];
      let uploadedArray = await getAwsUploadUrl(attachment, "contract/");
      uploadedArray.map((item: any) => {
        let obj = {
          url: item.url,
          type: item.type,
          name: item.name,
        };
        attachments.push(obj);
      });
      return attachments;
    }
  };

  return (
    <>
      {showPopUp && (
        <AssignedVehicleList
          visible={showPopUp}
          onClose={() => setShowPopUp(false)}
          modelData={selectedVehicle}
        />
      )}

      <div className="px-[24px]">
        <div className="flex justify-between items-center py-[24px]">
          <BreadCrumbs />
          <div>
            {assignVehicleView ? (
              <>
                <div className="flex flex-row-reverse gap-4">
                  {" "}
                  <Button
                    type="submit"
                    disabled={spinner}
                    onClick={() => {
                      assignVehicle();
                    }}
                    className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                  >
                    <span>
                      {spinner && (
                        <Spinner CustomClassName={"spinner_secondary"} />
                      )}
                    </span>
                    Assign Vehicles
                  </Button>
                  <Button
                    className="border  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                    type="button"
                    onClick={() => setAssignVehicle(false)}
                  >
                    <span className="flex items-center text-[#FB6DB2]">
                      Cancel
                    </span>
                  </Button>
                </div>
              </>
            ) : (
              <div className="flex flex-row-reverse gap-4">
                {" "}
                <Button
                  type="submit"
                  disabled={spinner}
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                >
                  <span>
                    {spinner && (
                      <Spinner CustomClassName={"spinner_secondary"} />
                    )}
                  </span>
                  Save Schedule
                </Button>
                <Button
                  className="border  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                  type="button"
                  onClick={() => history.goBack()}
                >
                  <span className="flex items-center text-[#FB6DB2]">
                    Cancel
                  </span>
                </Button>
              </div>
            )}
          </div>
        </div>
        {assignVehicleView ? (
          <>
            <AssignVehicle
              selectedRowKeys={selectedRowKeys}
              selectedVehicle={selectedVehicle}
              setSelectedRowKeys={setSelectedRowKeys}
            />
          </>
        ) : (
          <>
            {" "}
            <div className="">
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={Yup.object({
                  name: Yup.string().required("Name is required"),
                  contact: Yup.mixed().required("Contact is required"),
                  contractStartDate: Yup.mixed()
                    .nullable()
                    .required("Start Date is required"),
                  contractEndDate: Yup.mixed()
                    .nullable()
                    .required("End Date is required"),
                  user: Yup.mixed().required("Assigne is required"),
                  group: Yup.mixed().required("Client is required"),
                })}
                onSubmit={async (values: any, actions) => {
                  if (contract.status === "signed") {
                    if (attachment.length > 0) {
                      values["attachments"] = await uploadAttachments();
                    }
                  }

                  if (vehicleValidation()) {
                  } else {
                    setSpinner(true);
                    if (id) {
                      updateOne(
                        id,
                        {
                          ...values,

                          vehicleInfo:
                            vehicleDetails.length > 0
                              ? vehicleDetails.map(
                                  (item: any) => (
                                    (item["_id"] = item.id),
                                    delete item.id,
                                    {
                                      ...item,
                                      vehicleType: item.vehicleType.value,
                                    }
                                  )
                                )
                              : [],
                          contractStartDate: SendingDataFormat(
                            values.contractStartDate
                          ),
                          contractEndDate: SendingDataFormat(
                            values.contractEndDate
                          ),
                          contact: values.contact.value,
                          user: values.user.value,
                          group: values.group.value,
                        },
                        () => {
                          setSpinner(false);
                          history.goBack();
                          actions.resetForm();
                        }
                      );
                    } else {
                      createOne(
                        {
                          ...values,
                          vehicleInfo:
                            vehicleDetails.length > 0
                              ? vehicleDetails.map((item: any) => ({
                                  ...item,
                                  vehicleType: item.vehicleType.value,
                                }))
                              : [],
                          contractStartDate: SendingDataFormat(
                            values.contractStartDate
                          ),
                          contractEndDate: SendingDataFormat(
                            values.contractEndDate
                          ),
                          contact: values.contact.value,
                          user: values.user.value,
                          group: values.group.value,
                        },
                        () => {
                          setSpinner(false);
                          history.goBack();
                          actions.resetForm();
                        }
                      );
                    }
                  }
                }}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div className="card_default rounded-[5px] mb-10 p-[24px]">
                      <div className="flex items-center justify-between mb-9">
                        <h2 className="text-[18px] font-[600] mb-5">
                          Basic Information
                        </h2>
                        <div>
                          {id && (
                            <SingleSelect
                              label=""
                              className="w-[190px] nav_single_select "
                              inputClassName="h-full"
                              value={status}
                              options={contractStatus}
                              onChange={(newValue: any) => {
                                setStatus(newValue);
                                confirm({
                                  title: "Warning",
                                  content:
                                    "Are you sure want to update the status?",
                                  onOk() {
                                    updateOne(
                                      id,
                                      { status: newValue.value },
                                      () => {
                                        // getOneVehicle(id);
                                        window.location.reload();
                                      }
                                    );
                                  },
                                  onCancel() {
                                    setStatus(
                                      getCurrentStatusFromKey(contract.status)
                                    );
                                  },
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex gap-6 mb-[30px]">
                        <FormikText
                          label="Name "
                          must={true}
                          name="name"
                          disabled={checkStatus()}
                        />
                        <div className="min-w-[360px]">
                          <FormikSinglSelect
                            must={true}
                            readOnly={checkStatus()}
                            isClearable
                            options={groups}
                            label="Select Client"
                            name="group"
                            inputClassName="adjust_select_height"
                            onChange={(newValue: any) => {
                              setFieldValue("group", newValue);
                              if (!newValue) {
                                setFieldValue("contact", "");
                              } else {
                                getAllContacts(newValue?.value);
                                setFieldValue("contact", "");
                              }
                            }}
                          />
                          {checkAddable() && (
                            <div
                              className="flex items-center justify-end"
                              onClick={() => setAddClient(true)}
                            >
                              <button
                                className="flex items-center  mt-2"
                                type="button"
                              >
                                <PlusWithCircle />{" "}
                                <p className="ml-[6px] text-[#FB6DB2] text-[12px] font-medium">
                                  Add Client
                                </p>
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="min-w-[360px]">
                          <FormikSinglSelect
                            must={true}
                            isClearable
                            readOnly={
                              checkStatus() ? true : values.group ? false : true
                            }
                            options={contacts}
                            label="Select Contact"
                            name="contact"
                            inputClassName="adjust_select_height"
                            onChange={(newValue) =>
                              setFieldValue("contact", newValue)
                            }
                          />
                          {checkAddable() && (
                            <>
                              {" "}
                              {values.group && (
                                <div
                                  className="flex items-center justify-end"
                                  onClick={() => setAddContact(true)}
                                >
                                  <button
                                    className="flex items-center  mt-2"
                                    type="button"
                                  >
                                    <PlusWithCircle />{" "}
                                    <p className="ml-[6px] text-[#FB6DB2] text-[12px] font-medium">
                                      Add Contact
                                    </p>
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="flex gap-6 mb-[30px]">
                        <div className="min-w-[360px]">
                          <FormikDatePicker
                            inputClassName="datepicket_styles"
                            label="Start Date"
                            must={true}
                            disabled={checkStatus()}
                            name="contractStartDate"
                            onChange={(value: any) => {
                              setFieldValue("contractStartDate", value);
                              setFieldValue("contractEndDate", "");
                            }}
                            value={values.contractStartDate}
                          />
                        </div>
                        <div className="min-w-[360px]">
                          <FormikDatePicker
                            inputClassName="datepicket_styles"
                            label="End Date"
                            disabled={checkStatus()}
                            must={true}
                            isDisabled={(currentDate: any) => {
                              return (
                                currentDate &&
                                currentDate < values.contractStartDate
                              );
                            }}
                            name="contractEndDate"
                            onChange={(value: any) =>
                              setFieldValue("contractEndDate", value)
                            }
                            value={values.contractEndDate}
                          />
                        </div>
                        <div className="min-w-[360px]">
                          <FormikSinglSelect
                            must={true}
                            isClearable
                            options={users}
                            readOnly={checkStatus()}
                            label="Assigne"
                            name="user"
                            inputClassName="adjust_select_height"
                            onChange={(newValue) =>
                              setFieldValue("user", newValue)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between items-center mb-10">
                        <h1 className="text-[18px] font-semibold">
                          Vehicle Details
                        </h1>
                        {checkAddable() && (
                          <div
                            className="flex items-center "
                            onClick={() =>
                              addVehicles(vehicleDetails.length - 1)
                            }
                          >
                            <button
                              type="button"
                              className="flex items-center rounded-[4px] shadow-md p-2  mt-2"
                            >
                              <PlusWithCircle />{" "}
                              <p className="ml-[6px] text-[#FB6DB2] text-[14px] font-semibold">
                                Add Vehicle
                              </p>
                            </button>
                          </div>
                        )}
                      </div>
                      {vehicleDetails.map((item: any, i: any) => (
                        <div className="card_default rounded-[5px] mb-10 p-[24px]">
                          <div className="mb-5">
                            <div className="flex justify-between mb-[15px]">
                              <div className="min-w-[360px]">
                                <SingleSelect
                                  label="Vehicle Type"
                                  options={vehicleTypes}
                                  name={item.vehicleType}
                                  value={item.vehicleType}
                                  isDisabled={checkStatus()}
                                  inputClassName="adjust_select_height"
                                  onChange={(newValue: any) => {
                                    vehicleDetails[i].vehicleType = newValue;
                                    setVehicleDetails([...vehicleDetails]);
                                  }}
                                />
                              </div>
                              <div className="min-w-[360px]">
                                <Text
                                  label="No of Vehicles"
                                  name="noOfUnits"
                                  type={"number"}
                                  min={"1"}
                                  disabled={checkStatus()}
                                  value={item.noOfUnits}
                                  inputClassName="adjust_select_height"
                                  onChange={(newValue) => {
                                    vehicleDetails[i].noOfUnits =
                                      newValue.target.value;
                                    setVehicleDetails([...vehicleDetails]);
                                  }}
                                />
                              </div>
                              <div className="min-w-[360px]">
                                <p className="text-[14px] font-medium mb-[12px]">
                                  Notes
                                </p>
                                <textarea
                                  value={item.notes}
                                  disabled={checkStatus()}
                                  className="outline-none border-[#F0F0F0] h-[42px]  border p-[10px] w-full rounded-[5px] text-[13px] text-[#212121] font-medium"
                                  onChange={(newValue) => {
                                    vehicleDetails[i].notes =
                                      newValue.target.value;
                                    setVehicleDetails([...vehicleDetails]);
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <div className="flex justify-between items-center">
                              <div className="w-[50%] flex items-center">
                                {contract.status === "planning" &&
                                  item.totalAssigns < item.noOfUnits && (
                                    <>
                                      <div className="flex items-center">
                                        <p
                                          className="font-medium text-[#FB6DB2]  mr-10 cursor-pointer"
                                          onClick={() => {
                                            getAllVehicles(item, values);
                                            setSelectedVehicles(item);
                                          }}
                                        >
                                          Assign Vehicles
                                        </p>
                                        {}
                                      </div>
                                    </>
                                  )}
                                {item.totalAssigns > 0 && (
                                  <p
                                    className="font-medium text-[#B2B2B2] cursor-pointer"
                                    onClick={() => {
                                      setSelectedVehicles(item);
                                      setShowPopUp(true);
                                    }}
                                  >
                                    View Assigned Vehicles
                                  </p>
                                )}
                              </div>
                              {!checkStatus() &&
                                contract.status !== "signed" && (
                                  <div className="w-[50%] justify-end	flex items-end">
                                    <div
                                      className="flex items-center font-medium cursor-pointer text-[14px] text-[#22212F]"
                                      onClick={() => {
                                        vehicleDetails.splice(i, 1);
                                        setVehicleDetails([...vehicleDetails]);
                                      }}
                                    >
                                      {" "}
                                      <div className="mr-2">
                                        <ListTrash />
                                      </div>{" "}
                                      Remove
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {Object.keys(contract).length > 0 &&
                      contract?.status === "signed" && (
                        <div className="mt-8">
                          <h2 className="text-[18px] font-[600] mb-5">
                            Upload
                          </h2>
                          <div>
                            {attachment.length < 5 && (
                              <div className="mt-8 input flex items-center bg-[#FCFCFC] pl-[24px] relative field w-full h-[100px] border rounded-[10px] border-dashed border-[#EBEBEB]">
                                <input
                                  id="InputUpload"
                                  type="file"
                                  className="w-full h-full opacity-0 absolute cursor-pointer"
                                  accept=".doc,/docx,.csv,application/pdf,.xlsx"
                                  onChange={(e) => uploadFunction(e)}
                                  multiple={true}
                                />
                                <img className="mr-5" src={uploadIcon} />
                                <div>
                                  {" "}
                                  <p className="text-[16px] font-medium ">
                                    Click to Upload{" "}
                                  </p>
                                  <p className="text-[14px] text-[#757575]">
                                    Maximum file size 5 MB
                                  </p>
                                </div>
                              </div>
                            )}

                            <div className="mt-[24px]">
                              <div className="flex justify-between flex-wrap">
                                {attachment?.map((item: any, index: any) => (
                                  <div
                                    className={`border w-[49%] mb-5 rounded-[4px] bg-[#FAFAFA] ${
                                      item?.type === "application/pdf" && ""
                                    }  border-[#EBEBEB] px-[14px] py-[12px] flex justify-between items-center`}
                                  >
                                    <div className="flex  items-center">
                                      <div
                                        className={`w-[32px] mr-[15px] bg-[#D9D9D9] p-[3px] h-[32px] rounded-[3px] `}
                                      >
                                        <div className="w-[32px] mr-[15px] h-[32px] rounded-[3px] ">
                                          <a
                                            className=""
                                            href={
                                              !item?.is_uploaded
                                                ? item?.url
                                                : `${
                                                    process.env
                                                      .REACT_APP_UPLOAD_API_ENDPOINT
                                                  }/${item.url}?${Date.now()}`
                                            }
                                            target="_blank"
                                          >
                                            <PdfImage />
                                          </a>
                                        </div>
                                      </div>
                                      <p className="font-medium text-[14px]">
                                        {item?.name}
                                      </p>
                                    </div>
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => deleteUploaded(index)}
                                    >
                                      <CancelUpload />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {addClient && (
                      <AddClientModal
                        modelData={addClient}
                        onClose={() => setAddClient(false)}
                      />
                    )}

                    {addContact && (
                      <AddContactModal
                        group={values.group}
                        modelData={addContact}
                        onClose={() => setAddContact(false)}
                      />
                    )}
                  </Form>
                  //
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
};
