export interface CardProps {
  title: string;
  count: any;
  icon: any;
  bg_color: string;
}
export const StatsCard: React.FC<CardProps> = ({
  title,
  count,
  icon,
  bg_color,
}) => {
  return (
    <>
      <div className="w-[19%] p-4 border border-[#F3F3F3] rounded-[5px] ">
        <p className="font-semibold text-[14px] text-[#899AA7] mb-3">{title}</p>
        <div className="flex justify-between items-end">
          <p className="font-semibold text-[24px] tex-[#374957]">{count}</p>
          <div className={`  rounded-[3px]`}>{icon}</div>
        </div>
      </div>
    </>
  );
};
