import { Form, Formik } from "formik";
import { FiCheck, FiEdit, FiXCircle } from "react-icons/fi";
import { Button } from "../../../../../atoms/Button/Button";
import * as Yup from "yup";
import { FormikText } from "../../../../../atoms/Text/Text";
import { useEffect, useState } from "react";
import { FormikSinglSelect } from "../../../../../atoms/Select/Select";
import { FormikTextArea } from "../../../../../atoms/TextArea/TextArea";
import { useVehicles } from "../../../context";
import { DatePicker } from "antd";
import moment from "moment";
import {
  getCurrentYESNOFromKey,
  sendDateFormat,
  setDateField,
  YESNO,
} from "../../../../../options/VehicleLoan";

export interface FinancialInfoProps {
  initialData: any;
  id: string;
  formRef: any;
}

export const PurchaseInformations: React.FC<FinancialInfoProps> = ({
  initialData,
  id,
  formRef,
}: FinancialInfoProps) => {
  const [initialValues, setInitialValues]: any = useState({});
  const [maturityError, setmaturityError]: any = useState("");
  const [maturityDate, setmaturityDate]: any = useState("");
  const [purchaseDate, setpurchaseDate]: any = useState("");

  const [dateOfTitleSent, SetDateTitle]: any = useState("");
  const [soldDate, setsoldDate]: any = useState("");
  const [dateOfSale, setdateOfSale]: any = useState("");
  const [dateError, setDateError] = useState("");
  const [paymentDate, setpaymentDate]: any = useState("");
  const [dateOfTitleRecieved, setdateOfTitleRecieved]: any = useState("");
  const [sellingDate, setsellingDate]: any = useState("");
  useEffect(() => {
    setpurchaseDate(setDateField(initialData.financial?.purchaseDate));
    setmaturityDate(setDateField(initialData.financial?.maturityDate));

    // sales
    SetDateTitle(setDateField(initialData.financial?.dateOfTitleSent));
    setdateOfSale(setDateField(initialData.financial?.dateOfSale));
    setsoldDate(setDateField(initialData.financial?.soldDate));
    setdateOfTitleRecieved(
      setDateField(initialData.financial?.dateOfTitleRecieved)
    );
    setpaymentDate(setDateField(initialData.financial?.paymentDate));

    //warrenty

    setsellingDate(setDateField(initialData.financial?.sellingDate));

    setInitialValues({
      ...initialData?.financial,
      billOfSale: getCurrentYESNOFromKey(
        initialData.financial?.billOfSale || "yes"
      ),
      paidOff: getCurrentYESNOFromKey(initialData.financial?.paidOff || "yes"),
    });
  }, [initialData]);
  const {
    actions: { updateOne, getOneVehicle },
  } = useVehicles();

  const setDate = (value: any) => {
    setmaturityDate(value);
    // setmaturityError("");
  };

  return (
    <div>
      {/* Section Header */}

      <div>
        <h3 className="text-[18px] font-[600] mb-5">Purchase Information</h3>
      </div>

      {/* Form Elements */}
      <div className="mb-[36px]">
        <Formik
          initialValues={initialValues}
          innerRef={formRef}
          enableReinitialize
          onSubmit={(values, actions) => {
            updateOne(
              id,
              {
                financial: {
                  ...initialData.financial,
                  ...values,
                  billOfSale:
                    (values.billOfSale && values.billOfSale.value) || "",
                  paidOff: (values.paidOff && values.paidOff.value) || "",
                  maturityDate: sendDateFormat(maturityDate),
                  purchaseDate: sendDateFormat(purchaseDate),
                  dateOfTitleSent: sendDateFormat(dateOfTitleSent),
                  soldDate: sendDateFormat(soldDate),
                  dateOfSale: sendDateFormat(dateOfSale),
                  paymentDate: sendDateFormat(paymentDate),
                  dateOfTitleRecieved: sendDateFormat(dateOfTitleRecieved),
                  sellingDate: sendDateFormat(sellingDate),
                },
              },
              () => {
                getOneVehicle(id);
              }
            );
          }}
        >
          {({ values, setFieldValue, setErrors, resetForm }) => (
            <>
              <Form>
                <div className="flex justify-between mb-[30px]">
                  <FormikText label="Lender" name="vendor" />

                  <div className="min-w-[360px]">
                    <div className="form-input-group w-full ">
                      <div className="form-label mb-3 font-medium w-full">
                        <p>Purchase Date</p>
                      </div>
                      <div className="form-input">
                        <DatePicker
                          className="add_V_picker"
                          onChange={(value) => setpurchaseDate(value)}
                          name="purchaseDate"
                          format="MM/DD/YYYY"
                          value={purchaseDate}

                          // {...props}
                        />
                        {/* {dateError && <p className="text-[red]">{dateError}</p>} */}
                      </div>
                    </div>
                  </div>
                  <FormikText
                    label="Net Purchase Price"
                    name="price"
                    type="number"
                  />
                </div>

                <div className="flex justify-between mb-[30px]">
                  <FormikText label="MSRP" name="msrp" type="number" />
                  <FormikText
                    label="Original Loan Amount"
                    name="loanAmount"
                    type="number"
                  />
                  <FormikText
                    label="Monthly Payment"
                    name="monthlyPayment"
                    type="number"
                  />
                </div>
                <div className="flex gap-4">
                  {/* <FormikText
                  label="Loan Maturity Date"
                  name="maturityDate"
                  type={"date"}
                /> */}
                  <div className="min-w-[360px] mb-[30px]">
                    <div className="form-input-group w-full ">
                      <div className="form-label mb-3 font-medium w-full">
                        <p>Loan Maturity Date</p>
                      </div>
                      <div className="form-input">
                        <DatePicker
                          className="add_V_picker"
                          onChange={(value) => setDate(value)}
                          name="maturityDate"
                          format="MM/DD/YYYY"
                          value={maturityDate}

                          // {...props}
                        />
                        {/* {dateError && <p className="text-[red]">{dateError}</p>} */}
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </Form>
              <div className="mt-5">
                <h3 className="text-[18px] font-[600] mb-5">
                  Selling Information
                </h3>
              </div>
              <Form>
                <div className="flex gap-5 mb-[30px]">
                  <div className="mr-12">
                    <FormikText
                      label="Rental Revenue"
                      name="rentalRevenue"
                      type={"number"}
                    />
                  </div>

                  <div className="min-w-[360px]">
                    <div className="form-input-group w-full ">
                      <div className="form-label mb-3 font-medium w-full">
                        <p>Projected Selling Date</p>
                      </div>
                      <div className="form-input">
                        <DatePicker
                          className="add_V_picker"
                          onChange={(value) => setsellingDate(value)}
                          name="sellingDate"
                          format="MM/DD/YYYY"
                          value={sellingDate}

                          // {...props}
                        />
                        {/* {dateError && <p className="text-[red]">{dateError}</p>} */}
                      </div>
                    </div>
                  </div>
                  {/* <FormikText
                  label="Projected Selling Date"
                  name="sellingDate"
                  type="date"
                /> */}
                </div>
                <div className="flex justify-between mb-[30px]">
                  <FormikText
                    label="Projected Selling Price"
                    name="sellingPrice"
                    type={"number"}
                  />
                  <FormikText
                    label="Payoff Amount"
                    name="payOff"
                    type={"number"}
                  />
                  <FormikText
                    label="Estimated Equity"
                    name="estimatedEquity"
                    type={"number"}
                  />
                </div>

                <div className="flex justify-end gap-2">
                  {/* <Button
                  className="border-2 border-solid px-7 py-2"
                  type="button"
                  onClick={() => {
                    setErrors({});
                    resetForm(initialValues);
                  }}
                >
                  <span className="flex items-center">
                    <FiXCircle className="mr-1" />
                    Cancel
                  </span>
                </Button> */}
                </div>
              </Form>

              <div className="mt-5">
                <h3 className="text-[18px] font-[600] mb-5">
                  After Sale Information
                </h3>
              </div>
              <Form>
                <div className="flex justify-between mb-[30px]">
                  <FormikText
                    label="Buyer's Name"
                    name="buyerName"
                    type={"text"}
                  />
                  <div className="min-w-[360px]">
                    <div className="form-input-group w-full ">
                      <div className="form-label mb-3 font-medium w-full">
                        <p>Date Sold</p>
                      </div>
                      <div className="form-input">
                        <DatePicker
                          className="add_V_picker"
                          onChange={(value) => setsoldDate(value)}
                          name="soldDate"
                          format="MM/DD/YYYY"
                          value={soldDate}

                          // {...props}
                        />
                        {/* {dateError && <p className="text-[red]">{dateError}</p>} */}
                      </div>
                    </div>
                  </div>{" "}
                  <FormikText
                    label="Selling Price"
                    name="soldPrice"
                    type={"number"}
                  />
                </div>
                <div className="flex justify-between mb-[30px]">
                  <FormikText
                    label="Gross Equity"
                    name="grossEquity"
                    type={"number"}
                  />
                  <div className="min-w-[360px] ">
                    {" "}
                    <FormikSinglSelect
                      options={YESNO}
                      label="Bill Of Sale"
                      name="billOfSale"
                      onChange={(newValue) =>
                        setFieldValue("billOfSale", newValue)
                      }
                    />
                  </div>

                  <FormikText
                    label="Mileage at time of Sale"
                    name="saleMillage"
                    type={"number"}
                  />
                </div>
                <div className="flex justify-between mb-[30px]">
                  <div className="min-w-[360px]">
                    <div className="form-input-group w-full ">
                      <div className="form-label mb-3 font-medium w-full">
                        <p>Date Payment Received</p>
                      </div>
                      <div className="form-input">
                        <DatePicker
                          className="add_V_picker"
                          onChange={(value) => setpaymentDate(value)}
                          name="paymentDate"
                          format="MM/DD/YYYY"
                          value={paymentDate}

                          // {...props}
                        />
                        {/* {dateError && <p className="text-[red]">{dateError}</p>} */}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="min-w-[360px] ">
                    <FormikSinglSelect
                      options={YESNO}
                      label="Paid Off"
                      name="paidOff"
                      onChange={(newValue) =>
                        setFieldValue("paidOff", newValue)
                      }
                    />
                  </div>
                  <div className="min-w-[360px]">
                    <div className="form-input-group w-full ">
                      <div className="form-label mb-3 font-medium w-full">
                        <p>Date Paid Off</p>
                      </div>
                      <div className="form-input">
                        <DatePicker
                          className="add_V_picker"
                          onChange={(value) => setdateOfSale(value)}
                          name="dateOfSale"
                          format="MM/DD/YYYY"
                          value={dateOfSale}

                          // {...props}
                        />
                        {/* {dateError && <p className="text-[red]">{dateError}</p>} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between mb-[30px]">
                  <div className="min-w-[360px]">
                    <div className="form-input-group w-full ">
                      <div className="form-label mb-3 font-medium w-full">
                        <p>Date Title Sent</p>
                      </div>
                      <div className="form-input">
                        <DatePicker
                          className="add_V_picker"
                          onChange={(value) => SetDateTitle(value)}
                          name="dateOfTitleSent"
                          format="MM/DD/YYYY"
                          value={dateOfTitleSent}

                          // {...props}
                        />
                        {dateError && <p className="text-[red]">{dateError}</p>}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="min-w-[360px]">
                    <div className="form-input-group w-full ">
                      <div className="form-label mb-3 font-medium w-full">
                        <p>Date Title Received</p>
                      </div>
                      <div className="form-input">
                        <DatePicker
                          className="add_V_picker"
                          onChange={(value) => setdateOfTitleRecieved(value)}
                          name="dateOfTitleRecieved"
                          format="MM/DD/YYYY"
                          value={dateOfTitleRecieved}

                          // {...props}
                        />
                        {/* {dateError && <p className="text-[red]">{dateError}</p>} */}
                      </div>
                    </div>
                  </div>
                  <FormikText
                    label="Tracking Number"
                    name="trackingNumber"
                    type={"number"}
                  />
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};
