import React, { useEffect, useState } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
// import "./App.css";
const MyEditor = ({
  notes,
  setnotes,
  isNotesEditable,
  editorReference,
  dummyNotes,
  setDummyNotes,
  isNotes,
}: any) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorData, setEditorData] = useState(() => EditorState.createEmpty());
  const [convertedContent, setConvertedContent]: any = useState(null);
  useEffect(() => {
    if (!notes) {
      setEditorState(EditorState.createEmpty());
    } else {
      const convertedData: any = convertFromHTML(notes);
      const state: any = ContentState.createFromBlockArray(
        convertedData.contentBlocks,
        convertedData.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
    }
  }, [notes]);

  useEffect(() => { }, [convertedContent]);

  const handleEditorChange = (state: any) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    if (editorState.getCurrentContent().getPlainText().length > 0) {
      let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(currentContentAsHTML);
      setDummyNotes(currentContentAsHTML);
    } else {
      setDummyNotes("");
      setConvertedContent(null);
    }
  };
  return (
    <div className="html-editor">
      {isNotesEditable ? <Editor
        ref={editorReference}
        defaultEditorState={editorState}
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        readOnly={!isNotesEditable}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "list",
            "textAlign",
            "history",
          ],

          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
      /> : <div dangerouslySetInnerHTML={{__html: notes}} className="p-10 list-disc"></div>}

    </div>
  );
};
export default MyEditor;
