import { Modal } from "antd";

const { confirm } = Modal;

export const procurementStatus = [
  { label: "In Pipeline", value: "pipeline", disabled: false },
  { label: "In progress", value: "inprogress", disabled: false },
  { label: "Keeping", value: "keeping", disabled: false },
  { label: "Flipped", value: "flipped", disabled: false },
  { label: "Closed", value: "archieved", disabled: false },
];
export const procurementFilterStatus = [
  { label: "In Pipeline", value: "pipeline", disabled: false },
  { label: "In progress", value: "inprogress", disabled: false },
  { label: "Keeping", value: "keeping", disabled: false },
  { label: "Flipped", value: "flipped", disabled: false },
  { label: "Closed", value: "archieved", disabled: false },
  { label: "Deleted", value: "deleted", disabled: false },
];

export const statusBasesOnCurrentStatus = (
  currentStatus: any = { value: "pipeline" }
) => {
  switch (currentStatus.value) {
    case "pipeline":
      return [
        { label: "In Pipeline", value: "pipeline", disabled: false },
        { label: "In progress", value: "inprogress", disabled: false },
        { label: "Closed", value: "archieved", disabled: false },
      ];
    case "inprogress":
      return [
        { label: "In Pipeline", value: "pipeline", disabled: false },
        { label: "In progress", value: "inprogress", disabled: false },
        { label: "Keeping", value: "keeping", disabled: false },
        { label: "Flipped", value: "flipped", disabled: false },
        { label: "Closed", value: "archieved", disabled: false },
      ];
    case "keeping":
      return [
        { label: "In progress", value: "inprogress", disabled: false },
        { label: "Keeping", value: "keeping", disabled: false },
        { label: "Closed", value: "archieved", disabled: false },
      ];
    case "flipped":
      return [
        { label: "In progress", value: "inprogress", disabled: false },
        { label: "Flipped", value: "flipped", disabled: false },
        { label: "Closed", value: "archieved", disabled: false },
      ];
    case "archieved":
      return [{ label: "Closed", value: "archieved", disabled: false }];
    default:
      return [
        { label: "In Pipeline", value: "pipeline", disabled: false },
        { label: "In progress", value: "inprogress", disabled: false },
        { label: "Keeping", value: "keeping", disabled: false },
        { label: "Flipped", value: "flipped", disabled: false },
        { label: "Closed", value: "archieved", disabled: false },
      ];
  }
};

export const getCurrentStatusFromKey = (key: string) => {
  /// disable the previous status from the index of the current status
  let index = procurementStatus.findIndex((item) => item.value === key);

  if (index !== 0 && index !== -1)
    for (let i = 0; i < index; i++) {
      procurementStatus[i].disabled = true;
    }

  // returning the current status object
  return procurementStatus.find((x) => x.value === key);
};

export const getCurrentStatusFromQuery = (key: string) =>
  procurementFilterStatus.find((x) => x.value === key);

export const colorCode = (value: string) => {
  switch (value) {
    case "pipeline":
      return "text-[#FF8126]";
    case "inprogress":
      return "text-[#D9A45C]";
    case "keeping":
      return "text-[#2ACB70]";
    case "flipped":
      return "text-[#398CC0]";
    case "archieved":
      return "text-[#F25138]";
    case "deleted":
      return "text-[#F25138]";
  }
};

export const colorCodeForVehiclesInFleet = (value: string) => {
  switch (value) {
    case "pipeline":
      return "text-[#FF8126]";
    case "inprogress":
      return "text-[#D9A45C]";
    case "infleeted":
      return "text-[#2ACB70]";
    case "archieved":
      return "text-[#F25138]";
  }
};

export const ReturnStatsValue = (value: any) => {
  return value ? value : 0;
};

export const ErrorModal = (key: string) => {
  if (key === "toClosedError") {
    confirm({
      title: "Oopps!",
      okCancel: false,
      content: (
        <div>
          You cannot change status unless you provide{" "}
          {<span className="font-medium">Flipped information</span>}
        </div>
      ),
    });
  } else {
    confirm({
      title: "Oopps!",
      okCancel: false,
      content: (
        <div>
          You cannot change status unless you provide{" "}
          {<span className="font-medium">More information</span>} and
          {<span className="font-medium"> Vehicles List</span>}.
        </div>
      ),
    });
  }
};
