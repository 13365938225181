// import { Footer } from "./footer";
import { Header } from "./header";
import { Main } from "./main";

import "./layout.scss";
import { Sidebar } from "./sidebar";
import { useAuth } from "../routes/RouteContext";

export interface LayoutProps {
  children?: React.ReactNode;
  className?: string;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  className = "",
}: LayoutProps) => {
  const {
    state: { hasSession },
  } = useAuth();
  return (
    <div className={`${className} ${hasSession ? "authenticated-min" : "unauthenticated"}`}>
      <Header></Header>
      <Sidebar></Sidebar>
      <Main>{children}</Main>
      {/* <Footer></Footer> */}
    </div>
  );
};
