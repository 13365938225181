import { GetuploadUrl, UploadFiles } from "../../utils";

export const countryList = [
  { value: "india", label: "India" },
  { value: "australia", label: "Australia" },
  { value: "france", label: "France" },
  { value: "merica", label: "America" },
  { value: "uk", label: "UK" },
];
export const StatusList = [
  { value: "active", label: "Active" },
  { value: "deactivated", label: "In Active" },
];

export const getCurrentStatusFromKey = (key: string) =>
  StatusList.find((x) => x.value === key);

export const colorCode = (status: any) => {
  switch (status) {
    case "active":
      return "#2ACB70";
    case "deactivated":
      return "#F2453D";
  }
};
