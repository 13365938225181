import rect, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  returnData,
  returnPriceData,
} from "../../../../../options/VehicleLoan";
import { useVehicles } from "../../../context";

interface FinancialDetailProps {
  existingData?: any;
}

export const FinancialDetail: React.FC<FinancialDetailProps> = ({
  existingData,
}: FinancialDetailProps) => {
  const { financial } = existingData;
  const { id }: any = useParams();
  const [total, setTotal] = useState();
  const {
    actions: { getRepairSumAmount },
  } = useVehicles();
  useEffect(() => {
    getRepairSumAmount(id, (res: any) => {
      if (res) {
        setTotal(res.total);
      }
    });
  }, []);
  useEffect(() => {
  }, [total]);
  return (
    <>
      <div className="p-[24px]">
        <div className="flex justify-between">
          <div className="w-[49%] ">
            <div className="common_card w-full pt-[24px] mb-[24px]">
              <h2 className="font-medium text-[18px] pl-[24px] mb-[20px]">
                Purchase Information
              </h2>
              <div className="plain_detail">
                <div className="V_detail_header">Lender</div>
                <div className="V_detail_data">
                  {returnData(financial, "vendor")}
                </div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">Purchase Date</div>
                <div className="V_detail_data">
                  {returnData(financial, "purchaseDate")}
                </div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Net Purchase Price</div>
                <div className="V_detail_data">
                  {returnPriceData(financial, "price")}
                </div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">MSRP</div>
                <div className="V_detail_data">
                  {returnPriceData(financial, "msrp")}
                </div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Original Loan Amount</div>
                <div className="V_detail_data">
                  {returnPriceData(financial, "loanAmount")}
                </div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">Monthly Payment </div>
                <div className="V_detail_data">
                  {" "}
                  {returnPriceData(financial, "monthlyPayment")}
                </div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Loan Maturity Date</div>
                <div className="V_detail_data">
                  {returnData(financial, "maturityDate")}
                </div>
              </div>
            </div>
            <div className="common_card w-full pt-[24px]">
              <h2 className="font-medium text-[18px] pl-[24px] mb-[20px]">
                Selling Information
              </h2>
              <div className="plain_detail">
                <div className="V_detail_header">Rental Revenue</div>
                <div className="V_detail_data">
                  {returnPriceData(financial, "rentalRevenue")}
                </div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">
                  Repair and Maintenance Expenses
                </div>
                <div className="V_detail_data">
                  {total && total > 0 ? (
                    <Link
                      className="underline font-medium text-[#398CC0]"
                      to={`/vehicles/${id}#service-history`}
                    >
                      ${total}
                    </Link>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Projected Selling Date</div>
                <div className="V_detail_data">
                  {returnData(financial, "sellingDate")}
                </div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">Projected Selling Price</div>
                <div className="V_detail_data">
                  {" "}
                  {returnPriceData(financial, "sellingPrice")}
                </div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Payoff Amount</div>
                <div className="V_detail_data">
                  {returnPriceData(financial, "payOff")}{" "}
                </div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">Estimated Equity </div>
                <div className="V_detail_data">
                  {returnPriceData(financial, "estimatedEquity")}
                </div>
              </div>
            </div>
          </div>

          <div className="w-[49%]">
            <div className="common_card w-full pt-[24px]">
              <h2 className="font-medium text-[18px] pl-[24px] mb-[20px]">
                After Sales Information
              </h2>
              <div className="plain_detail">
                <div className="V_detail_header">Buyer’s Name</div>
                <div className="V_detail_data">
                  {returnData(financial, "buyerName")}
                </div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">Date Sold</div>
                <div className="V_detail_data">
                  {" "}
                  {returnData(financial, "soldDate")}
                </div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Selling Price </div>
                <div className="V_detail_data">
                  {returnPriceData(financial, "soldPrice")}
                </div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">Gross Equity</div>
                <div className="V_detail_data">
                  {returnPriceData(financial, "grossEquity")}
                </div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Bill Of Sale</div>
                <div className="V_detail_data">
                  {returnData(financial, "billOfSale")}
                </div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">Mileage at time of Sale </div>
                <div className="V_detail_data">
                  {returnData(financial, "saleMillage")}
                </div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Date Payment Received</div>
                <div className="V_detail_data">
                  {" "}
                  {returnData(financial, "paymentDate")}
                </div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">Paid Off</div>
                <div className="V_detail_data">
                  {returnData(financial, "paidOff")}
                </div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Date Paid Off</div>
                <div className="V_detail_data">
                  {returnData(financial, "dateOfSale")}
                </div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">Date Title Sent</div>
                <div className="V_detail_data">
                  {" "}
                  {returnData(financial, "dateOfTitleSent")}
                </div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Date Title Received</div>
                <div className="V_detail_data">
                  {returnData(financial, "dateOfTitleRecieved")}
                </div>
              </div>
              <div className="striped_detail">
                {" "}
                <div className="V_detail_header">Tracking Number</div>
                <div className="V_detail_data">
                  {returnData(financial, "trackingNumber")}
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
};
