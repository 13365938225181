import { useField } from "formik";
import { InfoIcon } from "../../icons/InfoIcon";
import { Tooltip } from "antd";
export interface TextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  disabled?: boolean;
  inputClassName?: string;
  must?: boolean;
  onSearchClicked?: () => void;
}

export const ReadOnlyText: React.FC<TextProps> = ({
  label,
  error,
  must,
  value,
  ...props
}: TextProps) => {
  return (
    // border: 1px solid #E9E9E9
    <div className="form-input-group">
      <div className="form-label font-medium w-full flex items-start ">
        <p className="font-medium text-[14px] mb-[12px]">{label}</p>
        {must && <span className=" ml-[2px] text-[#FB6DB2]"> *</span>}
      </div>
      <div className="form-input min-w-[280px]">
        <input
          className={`outline-none ${
            error ? "border-red-500 border" : "border-[#F0F0F0] "
          } border p-[10px] w-full rounded-[5px] cursor-not-allowed text-[13px] text-[#212121] font-medium`}
          {...props}
          readOnly={true}
          disabled={true}
          value={value}
        />
      </div>
      {error && (
        <div className="form-error text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export const Text: React.FC<TextProps> = ({
  label,
  error,
  must,
  disabled,
  ...props
}: TextProps) => {
  return (
    // border: 1px solid #E9E9E9
    <div className="form-input-group">
      <div className="form-label font-medium w-full flex items-start ">
        <p className="font-medium text-[14px] mb-[12px]">{label}</p>
        {must && <span className=" ml-[2px] text-[#FB6DB2]"> *</span>}
      </div>
      <div className="form-input min-w-[360px]">
        <input
          className={`outline-none ${
            error ? "border-red-500 border" : "border-[#F0F0F0] "
          } border p-[10px] w-full rounded-[5px] text-[13px] text-[#212121] font-medium`}
          {...props}
          disabled={disabled}
        />
      </div>
      {error && (
        <div className="form-error text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export interface FormikTextFieldGroupProps extends TextProps {
  name: string;
}
export interface FormikTextFieldIconProps extends TextProps {
  name: string;
  title?: string;
}

export const FormikText: React.FC<FormikTextFieldGroupProps> = ({
  name,
  ...props
}: FormikTextFieldGroupProps) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || "";
  return <Text {...props} {...field} error={error} />;
};

export const FormikTextWithIcon: React.FC<FormikTextFieldGroupProps> = ({
  name,
  ...props
}: FormikTextFieldGroupProps) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || "";
  return <TextWithIcon {...props} {...field} error={error} />;
};

export const TextWithIcon: React.FC<TextProps> = ({
  label,
  error,
  must,
  title,
  ...props
}: TextProps) => {
  return (
    // border: 1px solid #E9E9E9
    <div className="form-input-group">
      <div className="form-label font-medium w-full flex mb-[12px] items-center ">
        <p className="font-medium text-[14px] mr-2 ">{label}</p>
        {must && <span className=" ml-[2px] text-[#FB6DB2]"> *</span>}
        <Tooltip className="cursor-pointer" title={title}>
          <span>
            {" "}
            <InfoIcon />
          </span>
        </Tooltip>
      </div>
      <div className="form-input w-full">
        <input
          className={`outline-none ${
            error ? "border-red-500 border" : "border-[#F0F0F0] "
          } border p-[10px] w-full rounded-[5px] text-[13px] text-[#212121] font-medium`}
          {...props}
        />
      </div>
      {error && (
        <div className="form-error text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};
