import { Dropdown, Menu } from "antd";
import { useState, useEffect } from "react";
import { FiMinusSquare, FiPlusSquare } from "react-icons/fi";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { ActiveArrow, InactiveArrow } from "../icons/home";

interface MenuItemProps {
  label: string;
  linkTo: string;
  activeIcons: React.ReactNode;
  // icon: React.ReactNode;
  inactiveIcons: React.ReactNode;
  isActive?: boolean;
}

export interface SidebarItemProps extends MenuItemProps {
  hasSubMenu?: boolean;
  subMenus?: Array<MenuItemProps>;
}

const parseLocation = (pathname: string, linkTo: string, level = 0) => {
  const parsedPath = pathname.substring(1).split("/");
  const parsedLink = linkTo.substring(1).split("/");
  return parsedPath[level] === parsedLink[level];
};
const menu = (
  <Menu
    items={[
      {
        key: "1",
        label: (
          <div>
            <div className="flex p-[20px] items-center profile_border ">
              <div className="w-[32px] h-[32px] rounded-[50%] mr-[16px]"></div>
              <p className="text-[13px] font-semibold">Super Admin</p>
            </div>
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div className="p-[20px]">
            <div className="flex justify-between items-center mb-">
              <p className="text-[14px] font-medium text-[#6C6C6C]">
                My Profile
              </p>
            </div>
          </div>
        ),
      },
    ]}
  />
);
export const SidebarItem: React.FC<SidebarItemProps> = ({
  label,
  linkTo,
  hasSubMenu,
  subMenus,
  inactiveIcons,
  activeIcons,
}: SidebarItemProps) => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  let mainCurrentMenu = linkTo.includes(location.pathname)
    ? true
    : parseLocation(location.pathname, linkTo, 0);
  const module = linkTo.split("/");
  subMenus &&
    subMenus.map((item) => {
      if (item.linkTo === location.pathname) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
    });
  const innerCurrentMenu = location.pathname === module[1];
  const masterDropDown = location.pathname.includes("master") ? true : false;
  // if (masterDropDown) {
  //   setShowMenu(true);
  // }
  return (
    <div>
      <>
        {" "}
        <div
          id="full-menu"
          className={`flex justify-between items-center cursor-pointer pr-2 relative mb-[7px]`}
        >
          {/* Current Menu Highlighter */}
          {mainCurrentMenu && (
            <div className="w-1 h-full  bg-primary absolute rounded-r"></div>
          )}
          <Link
            className="w-full"
            to={linkTo}
            onClick={() => hasSubMenu && setShowMenu(!showMenu)}
          >
            <div
              className={`flex items-center p-3 pl-5 ${
                mainCurrentMenu ? "text-[#FB6DB2]" : "text-[#7B7B7B]"
              }    ${showMenu && hasSubMenu ? "pb-[0]" : "pb-[3]"}`}
            >
              {mainCurrentMenu ? activeIcons : inactiveIcons}
              <p
                className={`ml-4 text-[14px]  ${
                  mainCurrentMenu ? `font-[600]` : `font-[500]`
                }`}
              >
                {label.trim()}
              </p>
            </div>
          </Link>
          {hasSubMenu && (
            <div
              className={`px-[20px]  ${showMenu ? "pt-[10px]" : "pt-0"}`}
              onClick={() => setShowMenu(!showMenu)}
            >
              {showMenu ? <ActiveArrow /> : <InactiveArrow />}
            </div>
          )}
        </div>
        {hasSubMenu && showMenu && (
          <div
            className={` cursor-pointer relative mb-[15px] ml-[29px] border-l    border-[#EBEBEB]`}
            id="full-sub-menu"
          >
            {subMenus?.map((item: MenuItemProps, i) => (
              <Link to={item.linkTo} key={i}>
                <div className="flex justify-between items-center cursor-pointer">
                  <div
                    className={` child_dropdown flex items-center p-3 pl-7  ${
                      i === subMenus.length - 1 ? "pb-[0]" : "pb-[3]"
                    }`}
                  >
                    <p
                      className={`${
                        item.isActive ? "text-[#FB6DB2]" : "text-[#7B7B7B]"
                      } text-[13px]`}
                    >
                      {item.label}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </>
      <>
        <div id="minimize-menu" className="relative mb-[7px]">
          {mainCurrentMenu && (
            <div className="w-1 h-full active_border bg-primary absolute rounded-r"></div>
          )}
          <div id="profile-area" className="minimize profile">
            <div
              id="profile-area"
              className="full profile  flex justify-center items-center cursor-pointer"
            >
              <div className="flex justify-center items-center">
                <Link className="w-full" to={linkTo}>
                  <div
                    className={`flex items-center p-3  ${
                      innerCurrentMenu ? "text-[#FB6DB2]" : "text-[#7B7B7B]"
                    }`}
                  >
                    {mainCurrentMenu ? (
                      <>
                        {hasSubMenu ? (
                          <div>
                            {" "}
                            <Dropdown
                              overlayStyle={{ width: "200px" }}
                              className="profile_dropdown"
                              overlay={
                                <Menu>
                                  {" "}
                                  {subMenus?.map((item: MenuItemProps, i) => (
                                    <Link to={item.linkTo} key={i}>
                                      <div className="flex justify-between items-center cursor-pointer">
                                        <div
                                          className={` child_dropdown flex items-center p-3 pl-7`}
                                        >
                                          <p
                                            className={`${
                                              item.isActive
                                                ? "text-[#FB6DB2]"
                                                : "text-[#7B7B7B]"
                                            } text-[13px] font-medium`}
                                          >
                                            {item.label}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  ))}
                                </Menu>
                              }
                              placement="topRight"
                            >
                              <div>{activeIcons}</div>
                            </Dropdown>
                          </div>
                        ) : (
                          <div>{activeIcons}</div>
                        )}
                      </>
                    ) : (
                      inactiveIcons
                    )}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* {hasSubMenu && showMenu && (
          <Dropdown
            overlayStyle={{ width: "280px" }}
            className="profile_dropdown"
            overlay={menu}
            placement="bottomLeft"
          >
            <div className="bg-[#FBFAFA] flex items-center p-[20px] cursor-pointer">
              <div className="w-[32px] h-[32px] rounded-[50%] mr-[16px]">
                "data"
              </div>
              <p className="text-[13px] font-semibold">Super Admin</p>
            </div>
          </Dropdown>
        )} */}
      </>
      <></>
    </div>
  );
};
