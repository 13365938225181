import { Redirect, Route, Switch } from "react-router";
import { isPremitted } from "../../services/utils/permission";
import { VehiclesProvider } from "../vehicles/context";
import { VehicleDetails } from "../vehicles/one/VehicleDetails";
import { VehicleMainLayout } from "../vehicles/VehiclesMainLayout";
import { ServiceHistory } from "./all/AllHistoryListing";
import { HistoryProvider } from "./context";

export interface ServiceHistoryProps {}

export const ServiceHistoryLayout: React.FC<ServiceHistoryProps> = (
  props: ServiceHistoryProps
) => {
  return (
    <VehiclesProvider>
      {" "}
      <HistoryProvider>
        <Switch>
          <Route
            exact
            path="/services"
            render={() => {
              if (isPremitted("service-history", "list")) {
                return <ServiceHistory />;
              } else {
                return <Redirect to="/forbidden" />;
              }
            }}
          />

          <Redirect to="/page-not-found" />
        </Switch>
      </HistoryProvider>
    </VehiclesProvider>
  );
};
