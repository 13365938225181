import { useField } from "formik";
import Select, { Props as RSProps } from "react-select";
import { DatePicker } from "antd";

export interface DatePickerProps extends RSProps {
  label: string;
  error?: string;
  inputClassName?: string;
  onChange: any;
  value?: any;
  disabled?: any;
  must?: boolean;
  isDisabled?: any;
  // value: moment();
}

export interface FormikDateProps extends DatePickerProps {
  name: string;
}

export const FormikDatePicker: React.FC<FormikDateProps> = ({
  name,
  onChange,
  inputClassName,
  value,
  disabled,
  ...props
}: FormikDateProps) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || "";
  return (
    <DatepickerComp
      onChange={onChange}
      error={error}
      value={value}
      disabled={disabled}
      {...props}
      inputClassName={` ${inputClassName && `${inputClassName}`} text-[13px]`}
    />
  );
};

export const DatepickerComp: React.FC<DatePickerProps> = ({
  label,
  error,
  inputClassName,
  onChange,
  value,
  must,
  isDisabled,
  disabled,
  ...props
}: DatePickerProps) => {
  return (
    <div className="form-input-group w-full ">
      <div className="form-label flex mb-3 font-medium w-full">
        <p>{label}</p>
        {must && <span className=" ml-[2px] text-[#FB6DB2]"> *</span>}
      </div>
      <div className={`form-input }`}>
        {/* <input
          className={`${
            error ? "border-red-500" : "border-gray-300"
          } border p-1.5 w-full`}
          {...props}
        /> */}
        <DatePicker
          onChange={onChange}
          // placeholder="Select a date"
          // picker="date"
          disabled={disabled}
          disabledDate={isDisabled}
          format="DD/MM/YYYY"
          value={value}
          className={` ${inputClassName && `${inputClassName}`} text-[13px] ${
            error ? "border-red-500 border !important" : "border-[#F0F0F0] "
          }`}
          // {...props}
        />
      </div>
      {error && (
        <div className="form-error text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};
