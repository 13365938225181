import { message } from "antd";
import moment from "moment";
import { APIService } from "./services/api/request";
import { VehicleStatuses } from "./options/VehicleStatus";

export const ShowDateFormat = (date: any) => {
  return date ? moment(date).format("MM/DD/YYYY") : "-";
};
export const checkDataAvailable = (data: any) => {
  return data ? data : "-";
};
export const showMessage = () => {
  return message.loading({ content: "Loading...", key: "loading" });
};
export const successCreated = () => {
  return message.success({ content: "Created Successfully", key: "success" });
};
export const UpdatedSuccessfully = () => {
  return message.success({ content: "Updated Successfully", key: "update" });
};

export const destroyMessage = (key: any) => {
  message.destroy(key);
};
export const checkEmpty = (data: any) => {
  return data ? data : "N/A";
};
export const perPage = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
];
export const IntegrationType = [
  { label: "Zubie", value: "zubie" },
  // { label: "Mercedes", value: "mercedes" },
];

export const removeAndReturnFilter = (filterName: string, query: any) => {
  let filter = { ...query };
  delete filter[filterName];
  return filter;
};

export const backgroundColorCode = (value: any) => {
  if (value && Object.keys(value).length > 0) {
    let statusValue: any = VehicleStatuses.find((x) => x.label === value.name);
    if (statusValue) {
      switch (statusValue.value) {
        case "deactivated":
          return "common_red_status";
        case "archieved":
          return "common_red_status";
        case "available":
          return "available_status";
        case "reserved":
          return "reserved_status";
        case "on-rent":
          return "on_rent_status";
        case "pending-registration":
          return "pending_registration_status";
        case "in-body-shop":
          return "common_red_status";
        case "in-maintenance":
          return "common_red_status";
        case "awaiting-body-shop":
          return "common_red_status";
        case "in-transit":
          return "in_transit_status";
        case "hold-billing":
          return "in_transit_status";
        case "hold-for-sale":
          return "common_yellow_status";
        case "sold-not-delivered":
          return "common_yellow_status";
        case "sold":
          return "common_yellow_status";
        case "at-auction":
          return "common_yellow_status";
        case "missing":
          return "common_blue_status";
        case "stolen":
          return "stolen_common_status";
        case "deactivated":
          return "text-[#F25138]";
        case "archieved":
          return "text-[#F25138]";
        case "available-at-manheim":
          return "bg-[#a3abae1a]";
      }
    }
  }
};

export const colorCode = (value: any) => {
  if (value && Object.keys(value).length > 0) {
    let statusValue: any = VehicleStatuses.find((x) => x.label === value.name);
    if (statusValue) {
      switch (statusValue.value) {
        case "available":
          return "text-[#2ACB70]";
        case "reserved":
          return "text-[#29AA6E]";
        case "on-rent":
          return "text-[#11D85A]";
        case "pending-registration":
          return "text-[#053A70]";
        case "in-body-shop":
          return "text-[#F2453D]";
        case "in-maintenance":
          return "text-[#F2453D]";
        case "awaiting-body-shop":
          return "text-[#F2453D]";
        case "in-transit":
          return "#686868";
        case "hold-billing":
          return "text-[#686868]";
        case "hold-for-sale":
          return "text-[#E49131]";
        case "sold-not-delivered":
          return "text-[#E49131]";
        case "sold-delivered":
          return "text-[#E49131]";
        case "at-auction":
          return "text-[#E49131]";
        case "missing":
          return "text-[#1277D6]";
        case "stolen":
          return "text-[#262C2D]";
        case "active":
          return "text-[#47B275]";
        case "sold":
          return "text-[#E49131]";
        case "deactivated":
          return "text-[#F25138]";
        case "archieved":
          return "text-[#F25138]";
        case "in-transit-open-ra":
          return "text-[#F25138]";
        case "archieved":
          return "text-[#00AEEF]";
        case "available-at-manheim":
          return "text-[#686868]";
      }
    }
  }
};

export const returnPriceValue = (key: any) => {
  return key
    ? key.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
    : "N/A";
};

export const setSelectedValue = (item: any) => {
  if (item) {
    return {
      label: item.firstName ? `${item.firstName} ${item.lastName}` : item.name,
      value: item.id,
    };
  } else {
    return { label: "", value: "" };
  }
};

export const GetuploadUrl = async (uri: any, data: any) => {
  return await APIService.post(uri, data, process.env.REACT_APP_API_ENDPOINT);
};
export const UploadFiles = async (url: any, data: any, type: any) => {
  let uploadfile: any = await fetch(url, {
    method: "PUT",
    body: data,
    headers: {
      "Content-Type": `${type}`,
    },
  });
  if (uploadfile.status === 200) {
    return uploadfile;
  } else {
    //error
  }
};

export const addIsUploadedKey = (array: any = Array) => {
  let modifiedArray: any = [];
  array.map((item: any) => {
    item["is_uploaded"] = true;
    modifiedArray.push(item);
  });

  return modifiedArray;
};

export const UploadData = (key: any, type: any) => {
  let data = {
    key: key,
    expires: 3000,
    contentType: type,
  };
  return data;
};

export const getAwsUploadUrl = async (
  array: Array<any>,
  folderName: string
) => {
  //getting the url format object
  let dataToSend: any = [];

  for (let item of array) {
    if (!item?.is_uploaded) {
      let uploadData = UploadData(
        `${folderName}${item.name}_${Date.now()}`,
        item.type
      );
      const res: any = await GetuploadUrl("/assets/upload/write", uploadData);
      if (res) {
        let url = res.data.signedURL;
        let orginal_file = item.orginal_file; //orginal file from input
        let uploadedData = await UploadFiles(
          url,
          orginal_file,
          orginal_file.type
        );
        if (uploadedData) {
          let data = {
            name: orginal_file.name,
            url: uploadData.key,
            type: orginal_file.type,
          };
          dataToSend.push(data);
        }
      }
    } else {
      dataToSend.push(item);
    }
  }
  return dataToSend;
};
export const checkData = (data: any) => {
  return data ? data : 0;
};
