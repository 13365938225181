import { FuelTypeDonut } from "./FuelType";
import { RemainderDonut } from "./RemainderGraph";
import { VehicleStatusGraph } from "./VehicleStatusGraph";
import { Progress } from "antd";
import { VehicleMakeGraph } from "./VehicleMakeGraph";
import { useEffect, useState } from "react";
import { useDashboard } from "./context";
import { getStatusKeyValue, returnPercentage } from "./dashboarLoan";
import { MakeVehicleBar } from "./MakeVehicleBar";
export interface DashboardProps {
  value?: any;
}

export const Dashboard: React.FC<DashboardProps> = ({}) => {
  const [vehicleType, setVehicleType] = useState("12 Pasanger");
  const {
    state: { documents },
    actions: { getDashboard },
  } = useDashboard();
  useEffect(() => {
    getDashboard();
  }, []);
  return (
    <>
      <div className=" px-6 py-[24px]">
        <div className="flex justify-between items-start w-full mb-[24px] h-[410px]">
          <div className=" border border-[#F8F5F5] rounded-[16px] w-[30%] h-full pb-[0px] p-[30px]">
            <p className="font-semibold text-[14px] text-[#404040] ">
              Renewal Remainder
            </p>
            <RemainderDonut />
          </div>
          <div className="w-[68%] border border-[#F8F5F5] rounded-[16px]  h-full">
            <p className="font-semibold text-[14px] text-[#404040] py-[30px]  px-[30px]">
              Vehicle Status Summary
            </p>
            <div className="bar_graph">
              <VehicleStatusGraph />
            </div>
          </div>
        </div>
        {/* <div className="flex items-start border border-[#F8F5F5] rounded-[16px] ">
          <div className="w-[78%] make_graph  border-r border-[#F8F5F5]">
            <p className="font-semibold text-[14px] text-[#404040] py-[30px]  px-[30px]">
              Vehicle By Make
            </p>
            <VehicleMakeGraph
              vehicleType={vehicleType}
              setVehicleType={(e: any) => setVehicleType(e)}
            />
          </div>
          <div className="w-[22%]  flex items-center w-full jusitfy-center flex-col p-[30px]">
            <p className="text-[14px] font-semibold text-[#FB6DB2] mb-[40px]">
              {vehicleType}
            </p>
            <div className="flex items-center justify-between w-full mb-[30px]">
              <div className="flex items-center justify-between">
                {" "}
                <p className="w-[6px] h-[6px] rounded-[50%] mr-[10px] bg-[#FB6DB2]"></p>
                <p className="text-[12px] text-[#1C1C1C]">Total Vehicle</p>
              </div>
              <p className="text-[12px] text-[#1C1C1C]">159</p>
            </div>
            <div className="flex items-center justify-between w-full mb-[30px]">
              <div className="flex items-center justify-between">
                {" "}
                <p className="w-[6px] h-[6px] rounded-[50%] mr-[10px] bg-[#FB6DB2]"></p>
                <p className="text-[12px] text-[#1C1C1C]">Utilization</p>
              </div>
              <p className="text-[12px] text-[#1C1C1C]">159</p>
            </div>
            <div className="flex items-center justify-between w-full mb-[30px]">
              <div className="flex items-center justify-between">
                {" "}
                <p className="w-[6px] h-[6px] rounded-[50%] mr-[10px] bg-[#FB6DB2]"></p>
                <p className="text-[12px] text-[#1C1C1C]">Vehicles on Rent</p>
              </div>
              <p className="text-[12px] text-[#1C1C1C]">159</p>
            </div>
            <div className="flex items-center justify-between w-full mb-[30px]">
              <div className="flex items-center justify-between">
                {" "}
                <p className="w-[6px] h-[6px] rounded-[50%] mr-[10px] bg-[#FB6DB2]"></p>
                <p className="text-[12px] text-[#1C1C1C]">
                  Utilization Overall
                </p>
              </div>
              <p className="text-[12px] text-[#1C1C1C]">159</p>
            </div>
          </div>
        </div> */}
        <div className="flex items-start border border-[#F8F5F5] h-full p-[30px] w-full mt-[20px]  rounded-[16px] flex-col ">
          <p className="text-[13px] font-semibold text-[#404040]">
            Vehicle Detail
          </p>
          <MakeVehicleBar />
        </div>
        <div className="flex items-center justify-between mt-[24px] h-[330px]">
          <div className="flex items-start border h-full border-[#F8F5F5] p-[30px] w-[32%] justify-center  rounded-[16px] flex-col ">
            <p className="text-[13px] font-semibold text-[#404040] mb-[30px]">
              Vehicle Assignments
            </p>
            <div className="flex items-center justify-center flex-col w-full">
              <div className="bg-[#FAFAFA] rounded-[10px] mb-[20px] px-[30px] flex items-center justify-center w-full py-[25px]">
                <p className="text-[14px]">
                  <span className="text-[18px] pr-[10px] text-[#00A046] font-semibold">
                    {getStatusKeyValue("On Rent", documents?.vehicleStatus)}
                  </span>
                  Assigned
                </p>
              </div>
              <div className="bg-[#FAFAFA] rounded-[10px] px-[30px] py-[25px] flex items-center justify-center w-full">
                <p className="text-[14px]">
                  <span className="text-[18px] pr-[10px] text-[#FFC372] font-semibold">
                    {getStatusKeyValue("Available", documents?.vehicleStatus)}
                  </span>
                  Unassigned
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-start border border-[#F8F5F5] h-full p-[30px] w-[32%]  rounded-[16px] flex-col ">
            <p className="text-[13px] font-semibold text-[#404040]">Gas Type</p>
            <FuelTypeDonut />
          </div>
          <div className="flex items-start border border-[#F8F5F5] h-full p-[30px] w-[32%]  rounded-[16px] flex-col ">
            <div className="flex items-center w-full justify-between">
              <p className="text-[14px] font-semibold">Fleet Size</p>
              <p className="text-[12px]">
                <span className="text-[#FB6DB2] pr-2 text-[14px] font-semibold">
                  {documents?.totalVehicles}
                </span>
              </p>
            </div>
            <div className="w-full my-3 text-[12px]">
              <p className="text-[12px]">Utilization</p>
              <Progress
                type="line"
                percent={returnPercentage(
                  getStatusKeyValue("On Rent", documents?.vehicleStatus),
                  documents.totalVehicles
                )}
              />
            </div>
            <div className="w-full my-3">
              <p className="text-[12px]">Availability</p>
              <Progress
                type="line"
                percent={returnPercentage(
                  getStatusKeyValue("Available", documents?.vehicleStatus),
                  documents.totalVehicles
                )}
              />
            </div>
            <div className="w-full my-3">
              <p className="text-[12px]">Out of Service</p>
              <Progress
                type="line"
                percent={returnPercentage(
                  getStatusKeyValue("In Maintenance", documents?.vehicleStatus),
                  documents.totalVehicles
                )}
              />
            </div>
            <div className="w-full my-3">
              <p className="text-[12px]">Hold for sale</p>
              <Progress
                type="line"
                percent={returnPercentage(
                  getStatusKeyValue("Hold for Sale", documents?.vehicleStatus),
                  documents.totalVehicles
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
