import { FiBell, FiInfo } from "react-icons/fi";
import { useHistory, useLocation } from "react-router";
import { AutoCompleteSearch } from "../atoms/AutoCompleteSearch/AutoCompleteSearch";
import { MenuIcon } from "../icons";
import { MenuBackIcon } from "../icons/menu-back";
import "./header.scss";
import { useEffect, useState } from "react";
import hamburger from "../icons/designIcons/hamburgur.svg";
import user from ".././icons/designIcons/dummyUser.svg";
import { Button, Dropdown, Menu } from "antd";
import CookieStorage from "../services/storage/cookie.storage";
import { Logout } from "../icons/home";
import { Link } from "react-router-dom";
export interface HeaderProps {}

const LogoutFunc = () => {
  CookieStorage.delete("token");
  window.location.reload();
  localStorage.removeItem("user");
  localStorage.removeItem("permission");
};
export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const [sidebar, setSideBar] = useState("full");
  const [userData, setUserData]: any = useState({});
  useEffect(() => {
    let data = localStorage.getItem("user");
    if (data) {
      setUserData(JSON.parse(data));
    }
  }, []);

  const setClassBody = (value: string) => {
    setSideBar(value);
    if (value === "small") {
      document.body.classList.add("small_side_bar");
      document.body.classList.remove("full_side_bar");
    } else {
      document.body.classList.add("full_side_bar");
      document.body.classList.remove("small_side_bar");
    }
  };
  const { pathname, hash } = useLocation();
  const history = useHistory();
  let pathSlipts = pathname
    .substring(1)
    .replace(hash, "")
    .split("/")
    .filter(function (e) {
      return e;
    });

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div>
              <div className="flex p-[20px] items-center profile_border ">
                <div className="w-[32px] h-[32px] rounded-[50%] mr-[16px]">
                  <img
                    className="w-full h-full rounded-[50%] object-cover"
                    src={user}
                  />
                </div>
                <p className="text-[13px] font-semibold">
                  {userData?.firstName} {userData?.lastName}
                </p>
              </div>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div className="p-[20px]">
              <div className="flex justify-between items-center mb-">
                <p className="text-[14px] font-medium text-[#6C6C6C]">
                  My Profile
                </p>
              </div>
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div className="p-[20px]">
              <Link to="/master">
                {" "}
                <div className="flex justify-between items-center mb-">
                  <p className="text-[14px] font-medium text-[#6C6C6C]">
                    Admin Settings
                  </p>
                </div>
              </Link>
            </div>
          ),
        },
        {
          key: "4",
          label: (
            <div className="p-[20px]" onClick={LogoutFunc}>
              <div className="flex justify-between items-center">
                <p className="text-[14px] font-medium text-[#6C6C6C]">Logout</p>
                <div>
                  {" "}
                  <Logout />
                </div>
              </div>
            </div>
          ),
        },
      ]}
    />
  );

  return (
    <header className="z-header flex justify-between px-4 items-center bg-white py-2 border-b border-y-[#E1E1E166] border-solid">
      <div className="left-container flex p-3 items-center">
        {/* <MenuIcon/> */}
        <div className="cursor-pointer">
          <img
            width={"25px"}
            src={hamburger}
            onClick={() => {
              setClassBody(sidebar === "full" ? "small" : "full");
            }}
          />
        </div>
        <p className="font-[700] text-[#212121] text-[14px] capitalize pl-4">
          {pathSlipts[0]}
        </p>
      </div>
      <div>
        <Dropdown
          trigger={["click"]}
          overlayStyle={{ width: "280px" }}
          className="profile_dropdown"
          overlay={menu}
          placement="bottomLeft"
        >
          <div className="bg-[#FBFAFA] flex items-center p-[20px] cursor-pointer">
            <div className="w-[32px] h-[32px] rounded-[50%] mr-[16px]">
              <img
                className="w-full h-full rounded-[50%] object-cover"
                src={user}
              />
            </div>
            <p className="text-[13px] font-semibold">
              {userData?.firstName} {userData?.lastName}
            </p>
          </div>
        </Dropdown>
      </div>
    </header>
  );
};
