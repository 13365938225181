import { Modal } from "antd";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Button } from "../../../../../atoms/Button/Button";
import { FormikSinglSelect } from "../../../../../atoms/Select/Select";
import { Spinner } from "../../../../../atoms/Spinner/spinner";
import { FormikText } from "../../../../../atoms/Text/Text";
import { SaveIcon } from "../../../../../icons/SaveIcon";
import { useContract } from "../../context";
import * as Yup from "yup";
export interface ClientProps {
  modelData?: any;
  onClose: () => void;
}
export const AddClientModal: React.FC<ClientProps> = ({
  modelData,
  onClose,
}) => {
  const [spinner, setSpinner] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    parent: "",
  });
  const {
    state: { groups },
    actions: { AddGroup },
  } = useContract();
  return (
    <>
      <Modal
        maskClosable={false}
        title="Add Client"
        // header={false}
        className="add_model"
        visible={modelData}
        footer={false}
        centered={true}
        onCancel={onClose}
      >
        <div>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
              name: Yup.string().required("Name is required"),
            })}
            onSubmit={(values: any) => {
              values = {
                ...values,
                parent: values.parent ? values.parent.value : values.parent,
              };
              if (!values.parent) {
                delete values["parent"];
              }
              AddGroup(values, () => {
                onClose();
              });
            }}
          >
            {({ setFieldValue }) => (
              <div>
                <Form>
                  <div className="mb-3">
                    <FormikText label="Name" must={true} name="name" />
                  </div>
                  <div className="mb-3">
                    <FormikSinglSelect
                      label="Parent Client"
                      name="parent"
                      onChange={(newValue) => setFieldValue("parent", newValue)}
                      options={groups}
                      isClearable
                    />
                  </div>
                  <div className="flex flex-row-reverse gap-4">
                    {" "}
                    <Button
                      type="submit"
                      className="rounded-[4px] px-2  bg-primary text-[13px] text-white font-semibold flex items-center  justify-center"
                    >
                      <span>
                        {spinner && (
                          <Spinner CustomClassName={"spinner_secondary"} />
                        )}
                      </span>
                      Add Client
                    </Button>
                    <Button
                      className="  py-3   px-[21px]  font-medium text-[13px] rounded-[4px]"
                      type="button"
                      onClick={() => onClose()}
                    >
                      <span className="flex items-center text-[#FB6DB2]">
                        Cancel
                      </span>
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};
