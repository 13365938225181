export const InactiveUserManagement: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1455_14397)">
        <path
          d="M8 7.99024C8.79113 7.99024 9.56449 7.75564 10.2223 7.31611C10.8801 6.87659 11.3928 6.25187 11.6955 5.52097C11.9983 4.79007 12.0775 3.9858 11.9231 3.20988C11.7688 2.43395 11.3878 1.72122 10.8284 1.16181C10.269 0.602399 9.55629 0.221436 8.78036 0.0670951C8.00444 -0.0872458 7.20017 -0.00803248 6.46927 0.294718C5.73836 0.597469 5.11365 1.11016 4.67412 1.76796C4.2346 2.42575 4 3.19911 4 3.99024C4.00106 5.05078 4.42283 6.06758 5.17274 6.81749C5.92266 7.56741 6.93946 7.98918 8 7.99024ZM8 1.32357C8.52742 1.32357 9.04299 1.47997 9.48152 1.77298C9.92005 2.066 10.2618 2.48248 10.4637 2.96975C10.6655 3.45702 10.7183 3.99319 10.6154 4.51048C10.5125 5.02776 10.2586 5.50291 9.88562 5.87585C9.51268 6.24879 9.03752 6.50277 8.52024 6.60566C8.00296 6.70856 7.46678 6.65575 6.97951 6.45392C6.49224 6.25208 6.07577 5.91029 5.78275 5.47176C5.48973 5.03323 5.33333 4.51765 5.33333 3.99024C5.33333 3.28299 5.61428 2.60472 6.11438 2.10462C6.61448 1.60452 7.29276 1.32357 8 1.32357Z"
          fill="#9D9D9D"
        />
        <path
          d="M8 9.32422C6.40924 9.32598 4.88414 9.95869 3.75931 11.0835C2.63447 12.2084 2.00176 13.7335 2 15.3242C2 15.501 2.07024 15.6706 2.19526 15.7956C2.32029 15.9206 2.48986 15.9909 2.66667 15.9909C2.84348 15.9909 3.01305 15.9206 3.13807 15.7956C3.2631 15.6706 3.33333 15.501 3.33333 15.3242C3.33333 14.0865 3.825 12.8996 4.70017 12.0244C5.57534 11.1492 6.76232 10.6576 8 10.6576C9.23768 10.6576 10.4247 11.1492 11.2998 12.0244C12.175 12.8996 12.6667 14.0865 12.6667 15.3242C12.6667 15.501 12.7369 15.6706 12.8619 15.7956C12.987 15.9206 13.1565 15.9909 13.3333 15.9909C13.5101 15.9909 13.6797 15.9206 13.8047 15.7956C13.9298 15.6706 14 15.501 14 15.3242C13.9982 13.7335 13.3655 12.2084 12.2407 11.0835C11.1159 9.95869 9.59076 9.32598 8 9.32422Z"
          fill="#9D9D9D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1455_14397">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const ActiveUserManagement: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1722_3557)">
        <path
          d="M8 7.99024C8.79113 7.99024 9.56449 7.75564 10.2223 7.31611C10.8801 6.87659 11.3928 6.25187 11.6955 5.52097C11.9983 4.79007 12.0775 3.9858 11.9231 3.20988C11.7688 2.43395 11.3878 1.72122 10.8284 1.16181C10.269 0.602399 9.55629 0.221436 8.78036 0.0670951C8.00444 -0.0872458 7.20017 -0.00803248 6.46927 0.294718C5.73836 0.597469 5.11365 1.11016 4.67412 1.76796C4.2346 2.42575 4 3.19911 4 3.99024C4.00106 5.05078 4.42283 6.06758 5.17274 6.81749C5.92266 7.56741 6.93946 7.98918 8 7.99024ZM8 1.32357C8.52742 1.32357 9.04299 1.47997 9.48152 1.77298C9.92005 2.066 10.2618 2.48248 10.4637 2.96975C10.6655 3.45702 10.7183 3.99319 10.6154 4.51048C10.5125 5.02776 10.2586 5.50291 9.88562 5.87585C9.51268 6.24879 9.03752 6.50277 8.52024 6.60566C8.00296 6.70856 7.46678 6.65575 6.97951 6.45392C6.49224 6.25208 6.07577 5.91029 5.78275 5.47176C5.48973 5.03323 5.33333 4.51765 5.33333 3.99024C5.33333 3.28299 5.61428 2.60472 6.11438 2.10462C6.61448 1.60452 7.29276 1.32357 8 1.32357Z"
          fill="#FB6DB2"
        />
        <path
          d="M8 9.32422C6.40924 9.32598 4.88414 9.95869 3.75931 11.0835C2.63447 12.2084 2.00176 13.7335 2 15.3242C2 15.501 2.07024 15.6706 2.19526 15.7956C2.32029 15.9206 2.48986 15.9909 2.66667 15.9909C2.84348 15.9909 3.01305 15.9206 3.13807 15.7956C3.2631 15.6706 3.33333 15.501 3.33333 15.3242C3.33333 14.0865 3.825 12.8996 4.70017 12.0244C5.57534 11.1492 6.76232 10.6576 8 10.6576C9.23768 10.6576 10.4247 11.1492 11.2998 12.0244C12.175 12.8996 12.6667 14.0865 12.6667 15.3242C12.6667 15.501 12.7369 15.6706 12.8619 15.7956C12.987 15.9206 13.1565 15.9909 13.3333 15.9909C13.5101 15.9909 13.6797 15.9206 13.8047 15.7956C13.9298 15.6706 14 15.501 14 15.3242C13.9982 13.7335 13.3655 12.2084 12.2407 11.0835C11.1159 9.95869 9.59076 9.32598 8 9.32422Z"
          fill="#FB6DB2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1722_3557">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
