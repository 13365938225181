import { Redirect, Route, Switch } from "react-router";
import { ProcurementProvider } from "../procurement/context";
import { ScheduleCalender } from "./calendarModule/ScheduleCalendar";
import { AllPlanning } from "./planning/all/AllPlanning";
import { ContractsProvider } from "./planning/context";
import { PlanningMainLayout } from "./planning/planningMainLayout";

export interface ScheduleMainLayoutProps {}
export const ScheduleMainLayout: React.FC<ScheduleMainLayoutProps> = () => {
  return (
    <>
      <ProcurementProvider>
        <ContractsProvider>
          <Switch>
            <Route
              exact
              path="/schedule/calender"
              component={ScheduleCalender}
            />
            <Route path="/schedule/planning" component={PlanningMainLayout} />
            <Redirect to="/schedule/planning" />
          </Switch>
        </ContractsProvider>
      </ProcurementProvider>
    </>
  );
};
