import { useReducer } from "react";
import { container } from "../../services/storage/context.container";
import { APIService } from "../../services/api/request";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { buildQueryString } from "../../services/utils/url";
import { destroyMessage, showMessage } from "../../utils";

const ApiRoutes = {
  base: `/dashboard`,
};

export type StateType = {
  minimalDocuments: any;
  documents: any;
  pagination: any;
  group: any;
  subDocuments: any;
};

const initialState: StateType = {
  documents: {},
  subDocuments: [],
  pagination: {},
  group: {},
  minimalDocuments: [],
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_MININAL_GROUPS":
      return {
        ...state,
        minimalDocuments: payload.map((x: any) => ({
          value: x.id,
          label: x.name,
        })),
      };
    case "GET_DASHBOARD":
      return { ...state, documents: payload };

    default:
      return state;
  }
};

export const { useContext: useDashboard, Provider: DashboardProvider } =
  container(() => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });
    const getDashboard = (
      mininal?: boolean,
      params?: any,
      callback?: (data: any) => void
    ) => {
      showMessage();
      if (params) {
        if (!params.parent) {
          params.parent = null;
        }
        params = buildQueryString(params);
      }
      APIService.get(
        mininal
          ? `/vehicles/info/dashboard?all=true`
          : `/vehicles/info/dashboard${params ? `${params}` : ""}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          const response: any = res.data;
          if (mininal) {
            dispatch({ type: "GET_MININAL_GROUPS", payload: response });
          } else {
            dispatch({ type: "GET_DASHBOARD", payload: response });
          }
          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    return {
      state,
      actions: {
        getDashboard,
      },
    };
  });
