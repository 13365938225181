import { Avatar } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect } from "react";
import { Pagination } from "../../atoms/Pagination/pagination";
import { Table } from "../../atoms/Table/Table";
import { useSearchParams } from "../../hooks/useSearchParams";
import { ListHeader } from "../../molecules/list-header/ListHeader";
import { RandomColor } from "../../services/utils/colorcode";
import { useUsers } from "./context";

export interface AllUsersProps {}
export const AllUsersListing: React.FC<AllUsersProps> = ({}) => {
  const { query, updateSearchParams }: any = useSearchParams();

  const {
    actions: { getAllUsers },
    state: {
      users: { documents, pagination },
    },
  } = useUsers();

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (Object.keys(query).length !== 0) {
      getAllUsers(false, query);
    } else {
      updateSearchParams({ page: 1, perPage: 10 });
    }
  }, [query]);

  const columns: ColumnsType = [
    {
      title: "User",
      dataIndex: "user",
      render: (value, record: any, index) => (
        <div className="flex items-center">
          <Avatar
            style={{ backgroundColor: `${record.color}` }}
          >{`${record?.firstName[0]}${record?.lastName[0]}`}</Avatar>
          <p className="ml-2 font-semibold text-[13px]">
            {`${record?.firstName} ${record?.lastName}`}
          </p>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (value, record: any) => (
        <div className="font-medium">{value}</div>
      ),
    },
    {
      title: "Role",
      dataIndex: "vehicleName",
      render: (value, record: any) => (
        <div className="font-semibold">{record.role?.name}</div>
      ),
    },
    {
      title: "Last Login",
      dataIndex: "lastLoginDate",
      render: (value, record: any) =>
        value ? (
          <div className="font-bold">
            {moment(value).format("YYYY/MM/DD")}{" "}
            <span className="text-[#767676]">
              {" "}
              at {moment(value).format("hh:mm a")}
            </span>
          </div>
        ) : (
          <div className="text-[#767676]"> Not logged in Yet.</div>
        ),
    },
    // {
    //   title: "Module Access",
    //   dataIndex: "vin",
    //   // sorter: true,
    //   render: (value, record: any) => (
    //     <div className="flex">
    //       <div className="border cursor-pointer border-dashed rounded-[5px] border-[#DBDBDB] mr-1 p-2">
    //         <VehicleIcon />
    //       </div>
    //       <div className="border cursor-pointer border-dashed  rounded-[5px] border-[#DBDBDB] mr-1 p-2">
    //         <InactiveRemainder />
    //       </div>
    //       <div className="border cursor-pointer border-dashed rounded-[5px] border-[#DBDBDB] mr-1 p-2">
    //         <InactiveVendor />
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Action",
    //   // sorter: true,
    //   render: (value, record: any) => (
    //     <div className="flex  items-center">
    //       <div className="cursor-pointer" onClick={() => { }}>
    //         {" "}
    //         <MoreButtonIcon />{" "}
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <div className="card_style ">
        {" "}
        <ListHeader
          hash={"#users"}
          onNewClicked={() => {}}
          onFilterClicked={() => {}}
          searchPlaceHolder="Search by User,Email"
          pagination={pagination}
        ></ListHeader>
        <Table
          className="vehicle_table"
          // onChange={onTableActivity}
          columns={columns}
          dataSource={documents}
          pagination={false}
        />
        <div className="mr-[24px] my-5">
          <Pagination
            pagination={pagination}
            onNext={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) + 1;
              updateSearchParams({ ...newQuery });
            }}
            onPrevious={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) - 1;
              updateSearchParams({ ...newQuery });
            }}
          />
        </div>
      </div>
    </>
  );
};
