import { useState } from "react";
import { TextProps } from "../Text/Text";
import { FiSearch } from "react-icons/fi";
import { useSearchParams } from "../../hooks/useSearchParams";

export const Search: React.FC<TextProps> = ({
  label,
  error,
  className,
  inputClassName,
  onSearchClicked,
  ...props
}: TextProps) => {
  return (
    <div className={`form-input-group w-full ${className}`}>
      <div className="border-#DEDEDE border p-1.5  rounded-md form-input flex items-center border p-[10px] w-full rounded-[5px] text-[13px] text-[#212121] font-medium">
        <div
          className="passwordShow cursor-pointer mr-1"
          onClick={onSearchClicked}
        >
          <FiSearch className="" />
        </div>
        <input
          className={`w-full outline-none ${inputClassName}`}
          {...props}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
};
export interface FilterSearchProps {
  label?: string;
  error?: string;
  inputClassName?: string;
  onSearchClicked?: () => void;
  submitApi: () => void;
  className?: string;
}

// export const FilterSearch: React.FC<FilterSearchProps> = ({
//   label,
//   error,
//   className,
//   inputClassName,
//   onSearchClicked,
//   submitApi,
//   ...props
// }: FilterSearchProps) => {
//   const [searchText, setSearchText]: any = useState("");
//   const { query, updateSearchParams }: any = useSearchParams();
//   const handleKeyDown = ({ key, target }: any) => {
//     if (key === "Enter") {
//       let newFilter = { ...query };
//       newFilter = { ...query, q: target.value };
//       updateSearchParams({ ...newFilter, page: "1" });
//     }
//   };
//   return (
//     <div className={`form-input-group w-full ${className}`}>
//       <div className="border-#DEDEDE border   form-input flex items-center  p-[10px] w-full rounded-[5px] text-[13px] text-[#212121] font-medium">
//         <div
//           className="passwordShow cursor-pointer mr-1"
//           onClick={onSearchClicked}
//         >
//           <FiSearch className="" />
//         </div>
//         <input
//           className={`w-full outline-none ${inputClassName}`}
//           value={searchText}
//           {...props}
//           onChange={({ target }) => {
//             setSearchText(target.value);
//           }}
//           onKeyDown={handleKeyDown}
//         />
//       </div>
//     </div>
//   );
// };
