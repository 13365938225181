import { Bar, getElementAtEvent, getElementsAtEvent } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { useEffect, useRef, useState } from "react";
import { CategoryScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useDashboard } from "./context";
import { getCurrentVehicleTypesFromKey } from "./dashboarLoan";
Chart.register(CategoryScale);
Chart.register(ChartDataLabels);
export interface RemainderDonutProps {
  value?: any;
}
export const VehicleStatusGraph: React.FC<RemainderDonutProps> = ({
  value = 10,
}) => {
  const [yAxis, setYaxis]: any = useState([]);
  const {
    state: { documents },
  } = useDashboard();
  useEffect(() => {
    let array: any = [];
    if (documents.vehicleStatus?.length > 0) {
      for (let i of documents.vehicleStatus) {
        let obj: any = {
          x: i?.status?.name,
          y: i?.totals,
        };
        array.push(obj);
        setYaxis(array);
      }
    }
  }, [documents]);
  useEffect(() => {}, [yAxis]);
  const data = {
    datasets: [
      {
        label: "",
        data: yAxis,
        backgroundColor: [
          "#BAEDBD",
          "#C6C7F8",
          "#1C1C1C",
          "#D1B6B6",
          "#B1E3FF",
          "#95A4FC",
          "#A1E3CB",
        ],
      },
    ],
  };
  const options = {
    aspectRatio: 2.7,
    layout: {
      padding: 20,
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: "end" as const,
        align: "end" as const,
        display: false,
        padding: 10,
        backgroundColor: "#F2F2F2",
        borderRadius: 50,
      },
    },
    borderRadius: 4,
    scales: {
      y: {
        grid: {
          drawBorder: false,
          display: true,
          color: " #F4F4F4",
        },
        ticks: {
          fontFamily: "Poppins",
          font: {
            size: 12,
            fontColor: "#8A9AA5",
            fontWeight: 500,
          },
        },
        beginAtZero: true,
        min: 0,
      },
      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          fontFamily: "Poppins",
          font: {
            size: 12,
            fontColor: "#8A9AA5",
            fontWeight: 500,
          },
        },
      },
    },

    barThickness: 25,
  };
  const chartRef: any = useRef();
  const onClick = (event: any) => {
    // const elem = getElementAtEvent(chartRef.current, event);
    // elem[0]._chart.config.data.datasets[0].data.forEach((value, index) => {
    //   // set element to the original colour (resets all).
    //   elem[0]._chart.config.data.datasets[0].backgroundColor[index] =
    //     background[index];
    //   if (index == elem[0]._index) {
    //     // set the clicked element to red.
    //     elem[0]._chart.config.data.datasets[0].backgroundColor[index] = "red";
    //   }
    // });
    // props.onHandleBarClickEvent(elem[0].index, elem[0].datasetIndex);
  };
  return (
    <>
      {" "}
      <Bar
        id="donut"
        options={options}
        // onClick={onClick}
        ref={chartRef}
        className=""
        data={data}
      />
    </>
  );
};
