import { Link } from "react-router-dom"
import { Button } from "../atoms/Button/Button"

export const Forbidden: React.FC = () => {
    return <div className="w-full h-full flex justify-center items-center">
        <div className="flex items-center flex-col">
            <h1 className="text-[32px] font-medium mb-4">You are unauthorized to access</h1>
            <Link to={`/`}>
                <Button className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center">
                    <span>
                    </span>
                    Go back to Dashboard
                </Button>
            </Link>
        </div>
    </div>
}