import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import { useHistory } from "react-router";
import { BreadCrumbs } from "../../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../../atoms/Button/Button";
import { SaveIcon } from "../../../icons/SaveIcon";
import * as Yup from "yup";
import { FormikText } from "../../../atoms/Text/Text";
import { FormikSinglSelect } from "../../../atoms/Select/Select";
import { VendorTypeOptions } from "../../vendors/VendorLoans";
import { FormikDatePicker } from "../../../atoms/DatePicker/DatePicker";
import uploadIcon from "../../../icons/designIcons/uploadIcon.svg";

export interface OneIssuesProps {}

export const OneIssues: React.FC<OneIssuesProps> = () => {
  const formRef = useRef() as any;
  const history = useHistory();
  const [initialValues, setInitialValues] = useState({
    name: "",
    phoneNumber: "",
    website: "",
    address: "",
    addressLineTwo: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    contactPersonName: "",
    contactPhoneNumber: "",
    contactEmail: "",
    label: [],
  });
  const handleSubmit = () => {
    formRef.current.handleSubmit();
  };
  return (
    <>
      <div className="px-[24px]">
        <div className="flex justify-between items-center py-[24px]">
          <BreadCrumbs />
          <div>
            <div className="flex flex-row-reverse gap-4">
              {" "}
              <Button
                type="submit"
                // onClick={handleSubmit}
                className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
              >
                <span>
                  <SaveIcon />
                </span>
                Save Vehicle
              </Button>
              <Button
                className="border  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                type="button"
                // onClick={() => history.goBack()}
              >
                <span className="flex items-center text-[#FB6DB2]">Cancel</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="card_default rounded-[5px] mb-10 p-[24px]">
          <h2 className="text-[18px] font-[600] mb-5">Create Issues</h2>
          <div className="">
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              enableReinitialize
              validationSchema={Yup.object({})}
              onSubmit={(values: any, actions) => {
                values = {
                  ...values,
                  label: values.label.map((x: any) => x.value),
                  type: values.type.value,
                };
              }}
            >
              {({ values, setFieldValue, setErrors, resetForm }: any) => (
                <Form className="" id="my-form">
                  <div className="flex justify-between mb-[30px]">
                    <FormikText label="Vehicle" must={true} name="vehicle" />
                    <div className="min-w-[360px]">
                      <FormikDatePicker
                        inputClassName="datepicket_styles"
                        label="Reported Date "
                        must={true}
                        name="reported_date"
                        onChange={(value: any) =>
                          setFieldValue("reported_date", value)
                        }
                        // value={values.reported_date}
                      />
                    </div>
                    <FormikText label="Time" name="time" must={true} />
                  </div>

                  <div>
                    {" "}
                    <div className="flex justify-between mb-[30px]">
                      {" "}
                      <div className="min-w-[360px] ">
                        <FormikSinglSelect
                          must={true}
                          isClearable
                          options={VendorTypeOptions}
                          label="Labels"
                          name="labels"
                          value={values.type}
                          inputClassName="adjust_select_height"
                          onChange={(newValue) => {
                            setFieldValue("labels", newValue);
                          }}
                        />
                      </div>
                      <div className="min-w-[360px] ">
                        <FormikSinglSelect
                          must={true}
                          isClearable
                          options={VendorTypeOptions}
                          label="Reported By"
                          name="labels"
                          value={values.type}
                          inputClassName="adjust_select_height"
                          onChange={(newValue) => {
                            setFieldValue("labels", newValue);
                          }}
                        />
                      </div>
                      <div className="min-w-[360px] ">
                        <FormikSinglSelect
                          must={true}
                          isClearable
                          options={VendorTypeOptions}
                          label="Assigned to"
                          name="labels"
                          value={values.type}
                          inputClassName="adjust_select_height"
                          onChange={(newValue) => {
                            setFieldValue("labels", newValue);
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mb-[30px]">
                      <FormikText
                        label="Primary Miles "
                        name="time"
                        must={true}
                      />
                    </div>
                  </div>
                  <div className="mt-8">
                    <h2 className="text-[18px] font-[600] mb-5">
                      Overdue Settings
                    </h2>
                    <div className="flex gap-16">
                      <div className="min-w-[360px]">
                        <FormikDatePicker
                          inputClassName="datepicket_styles"
                          label="Due Date "
                          must={true}
                          name="reported_date"
                          onChange={(value: any) =>
                            setFieldValue("reported_date", value)
                          }
                          // value={values.reported_date}
                        />
                      </div>{" "}
                      <FormikText label="Primary Miles Due" name="time" />
                    </div>
                  </div>
                  <div className="mt-8">
                    <h2 className="text-[18px] font-[600] mb-5">Upload</h2>
                    <div className="flex items-center justify-between">
                      <div className="w-[50%]">
                        <h3 className="text-[14px] font-medium mb-5">Photo</h3>
                        <div className="border border-dashed relative border-[#EBEBEB] rounded-[10px] bg-[#FCFCFC] w-[400px] p-[30px] flex ">
                          <input
                            type="file"
                            id="InputUpload"
                            className="w-full h-full opacity-0 absolute cursor-pointer "
                            // onChange={(e) => uploadFunction(e)}
                            accept="image/png,image/jpeg"
                          />

                          <img className=" mr-3 " src={uploadIcon} />
                          <div>
                            <p className="text-[16px] font-medium mb-[12px]">
                              Click to Upload or Drag and Drop
                            </p>
                            <p className="text-[14px] text-[#757575]">
                              Maximum file size 5 MB
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-[50%]">
                        <h3 className="text-[14px] font-medium mb-5">
                          Documents
                        </h3>
                        <div className="border border-dashed relative border-[#EBEBEB] rounded-[10px] bg-[#FCFCFC] w-[400px] p-[30px] flex ">
                          <input
                            id="InputUpload"
                            type="file"
                            className="w-full h-full opacity-0 absolute cursor-pointer"
                            accept="application/pdf,application/msword,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            // onChange={(e) => uploadFunction(e)}
                          />

                          <img className=" mr-3 " src={uploadIcon} />
                          <div>
                            <p className="text-[16px] font-medium mb-[12px]">
                              Click to Upload or Drag and Drop
                            </p>
                            <p className="text-[14px] text-[#757575]">
                              Maximum file size 5 MB
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};
