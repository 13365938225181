import { Props as RSProps } from "react-select";
import Select from "react-select/creatable";
import { useField } from "formik";
import CreatableSelect from "react-select/creatable";
import Creatable from "react-select/creatable";

export interface CreatableSelectProps extends RSProps {
  label?: string;
  error?: string;
  inputClassName?: string;
}

export const CreateSelect: React.FC<CreatableSelectProps> = ({
  label,
  error,
  inputClassName,
  className,
  ...props
}: CreatableSelectProps) => {
  return (
    <div className={`form-input-group w-full ${className}`}>
      <div className="form-label mb-3 font-medium w-full">
        <p>{label}</p>
      </div>
      <div className={`${inputClassName && `${inputClassName}`} form-input`}>
        {/* <input
          className={`${
            error ? "border-red-500" : "border-gray-300"
          } border p-1.5 w-full`}
          {...props}
        /> */}
        <CreatableSelect className={`text-[13px]`} isMulti {...props} />
      </div>
      {error && (
        <div className="form-error text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export interface FormikSelectProps extends CreatableSelectProps {
  name: string;
}

export const FormikCreateSinglSelect: React.FC<FormikSelectProps> = ({
  name,
  onChange,
  ...props
}: FormikSelectProps) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || "";
  return (
    <SingleCreatableSelect
      {...props}
      {...field}
      error={error}
      onChange={onChange}
    />
  );
};

export const SingleCreatableSelect: React.FC<CreatableSelectProps> = ({
  label,
  error,
  inputClassName,
  ...props
}: CreatableSelectProps) => {
  return (
    <div className="form-input-group w-full ">
      <div className="form-label mb-3 font-medium w-full">
        <p>{label}</p>
      </div>
      <div className={`${inputClassName && `${inputClassName}`} form-input`}>
        {/* <input
          className={`${
            error ? "border-red-500" : "border-gray-300"
          } border p-1.5 w-full`}
          {...props}
        /> */}
        <Select className={`text-[13px]`} {...props} />
      </div>
      {error && (
        <div className="form-error text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};
export const MultiSelect: React.FC<CreatableSelectProps> = ({
  label,
  error,
  inputClassName,
  className,
  ...props
}: CreatableSelectProps) => {
  return (
    <div className={`form-input-group w-full ${className}`}>
      <div className="form-label mb-3 font-medium w-full">
        <p>{label}</p>
      </div>
      <div className={`${inputClassName && `${inputClassName}`} form-input`}>
        {/* <input
          className={`${
            error ? "border-red-500" : "border-gray-300"
          } border p-1.5 w-full`}
          {...props}
        /> */}
        <Select className={`text-[13px]`} isMulti {...props} />
      </div>
      {error && (
        <div className="form-error text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};
