import pick from "../../icons/designIcons/PickAddButton.svg";
import React from "react";

export const PickAddIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.8 5H5V4.8V1.2C5 0.647719 5.44772 0.2 6 0.2C6.55228 0.2 7 0.647719 7 1.2V4.8V5H7.2H10.8C11.3523 5 11.8 5.44772 11.8 6C11.8 6.55228 11.3523 7 10.8 7H7.2H7V7.2V10.8C7 11.3523 6.55228 11.8 6 11.8C5.44772 11.8 5 11.3523 5 10.8V7.2V7H4.8H1.2C0.647719 7 0.2 6.55228 0.2 6C0.2 5.44772 0.647719 5 1.2 5H4.8Z"
        fill="#FB6DB2"
        stroke="#FB6DB2"
        stroke-width="0.4"
      />
    </svg>
  );
};
