import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { render } from "react-dom";
import LogRocket from 'logrocket';

const { REACT_APP_ENV, REACT_APP_LOG_ROCKET_APP_ID } = process.env;
if (REACT_APP_ENV !== "development" && REACT_APP_LOG_ROCKET_APP_ID) {
  LogRocket.init(REACT_APP_LOG_ROCKET_APP_ID);
}

document.body.classList.add("full_side_bar");


render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
