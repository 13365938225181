import { message, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { useCommonContext } from "../../hooks/commonStateProvider";
import user from "../../../../../icons/designIcons/user.jpg";
import { LoadingOutlined } from "@ant-design/icons";
import { SendComment, UploadCommentFiles } from "../../icons/SendComments";
import {
  DeleteUpload,
  ListingCommentCsv,
  ListingCommentPdf,
  ListingCsv,
  ListingPdf,
  PdfImage,
  UploadDocs,
  UploadPhoto,
} from "../../icons/UploadIcons";
import { calculateCommentTime } from "./CommentsLoad";
import Item from "antd/lib/list/Item";
import { EditPenIcon } from "../../icons/edit-pen";
import { ListTrash } from "../../icons/home";
import { Spinner } from "../Spinner/spinner";
import { getAwsUploadUrl } from "../../utils";

interface VehicleCommentsProps {
  model?: string;
  id?: string;
}
export const Comments: React.FC<VehicleCommentsProps> = ({ model, id }) => {
  const [textMessage, setMessage] = useState("");
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  const [addAttachment, setAddAttachment] = useState(false);
  const [fileAttachment, setFile]: any = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [editedMessage, setEdittedMessage] = useState("");
  const [editCommentDetail, setEdit] = useState({ isEdit: false, index: 0 });
  const [userDetail, setUserDetail]: any = useState({});
  const { confirm } = Modal;
  const {
    actions: { getAllComments, createComment, updateOne, deleteComment },
    state: { documents },
  } = useCommonContext();

  const scrollToBottom = () => {
    var objDiv: any = document.getElementById("comment_div");
    objDiv.scrollTop = objDiv.scrollHeight;
  };
  useEffect(() => {
    scrollToBottom();
  });
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) setUserDetail(JSON.parse(user));
  }, []);
  const uploadAttachments = async () => {
    if (fileAttachment.length > 0) {
      let images: any = [];
      let uploadedArray = await getAwsUploadUrl(fileAttachment, `comments/`);
      uploadedArray.map((item: any) => {
        let obj = {
          url: item.url,
          type: item.type,
          name: item.name,
        };
        images.push(obj);
      });
      return images;
    }
  };

  const submitComments = async () => {
    if (textMessage || fileAttachment.length > 0) {
      setSpinner(true);
      let obj = {
        model: model,
        message: textMessage,
        images: await uploadAttachments(),
      };
      createComment(obj, id, () => {
        setSpinner(false);
        setMessage("");
        setFile([]);
        setAddAttachment(false);
      });
    } else {
      message.error("Please upload or provide a comment");
    }
  };
  useEffect(() => {
    if (!addAttachment) {
      setFile([]);
    }
  }, [addAttachment]);

  const upload = (e: any) => {
    let file = e.target.files;
    let length = fileAttachment.length;
    if (file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        let item = file[i];
        let incrementedIndex = i + 1; // incrementing an variable as loop runs before updating the state.

        /*
          condition to not upload more than five files.
        */

        if (incrementedIndex + length <= 5) {
          if (
            item.type !== "image/png" &&
            item.type !== "image/jpeg" &&
            item.type !== "image/jpg" &&
            item.type !== "application/pdf" &&
            item.type !== "text/csv" &&
            item.type !==
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            message.error("Please upload only jpeg,jpg or png images");
          } else if (item.size > 5 * 1000000) {
            message.error("Couldn't upload large files");
          } else {
            let data = {
              orginal_file: item,
              name: item.name,
              url: window.URL.createObjectURL(item),
              type: item.type,
            };
            setFile((individualFiles: any) => [...individualFiles, data]);
          }
        } else return message.error("You can upload upto 5 attachments");
      }
    }
  };
  const SubmitEditedMessage = (item: any) => {
    if (editedMessage.trim()) {
      updateOne({ message: editedMessage, isEdited: true }, id, item.id, () => {
        setEdit({ isEdit: false, index: 0 });
        setEdittedMessage("");
      });
    } else message.error("Comment cannot be empty");
  };
  const keyPress = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey && !spinner) {
      e.preventDefault();
      submitComments();
    }
  };
  return (
    <>
      <div>
        <div className="flex flex-col">
          <div
            id="comment_div"
            className="h-[430px]  overflow-y-auto hide_scroll mb-[24px] p-[24px]"
          >
            {documents?.map((item: any, i: any) => (
              <div className="flex justify-between mb-7">
                <div
                  style={{ backgroundColor: `${item.owner.color}` }}
                  className={`w-[44px] h-[44px] bg-[${item.owner.color}] rounded-[50%] flex justify-center items-center mr-4`}
                >
                  <p className="text-[15px] font-medium text-[white]">
                    {" "}
                    {item?.owner?.firstName[0]}
                    {item?.owner?.lastName[0]}
                  </p>
                </div>
                <div className="w-[90%] break-words">
                  {editCommentDetail.isEdit && i === editCommentDetail.index ? (
                    <div className="border border-[#DEDEDE] mb-2  rounded-[4px] py-2 pl-2 ">
                      <textarea
                        rows={4}
                        className="outline-none w-[98%]"
                        style={{ resize: "none" }}
                        value={editedMessage}
                        onChange={(e) => {
                          setEdittedMessage(e.target.value);
                        }}
                      />
                      <div className="flex justify-end">
                        <button
                          onClick={() => SubmitEditedMessage(item)}
                          className="mr-2 outline-none text-[white] text-[13px] font-medium bg-[#fb6db2] px-3 rounded-[4px]"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => {
                            setEdit({ isEdit: false, index: 0 });
                            setEdittedMessage("");
                          }}
                          className="outline-none border border-[#fb6db2] text-[#fb6db2] px-3 py-1 font-medium mr-2 rounded-[4px]"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div className="flex w-full items-center justify-between mb-3">
                        <div className="flex items-center">
                          <p className="text-[14px] font-semibold mr-[21px] ">
                            {item?.owner?.firstName}
                            {item?.owner?.lastName}
                          </p>
                          <p className="text-[13px] text-[#787878] font-medium">
                            {calculateCommentTime(item.createdAt)}
                          </p>
                        </div>
                        {item.owner.id === userDetail?.id && (
                          <div className="flex items-center">
                            <div
                              className="cursor-pointer mr-2"
                              onClick={() => {
                                setEdit({ isEdit: true, index: i });
                                setEdittedMessage(item.message);
                              }}
                            >
                              <EditPenIcon />
                            </div>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                confirm({
                                  title: "Warning",
                                  content:
                                    "Are you sure want to delete this Comment?",
                                  onOk() {
                                    deleteComment(item.id, id, () => {});
                                  },
                                  onCancel() {},
                                });
                              }}
                            >
                              <ListTrash />
                            </div>
                          </div>
                        )}
                      </div>
                      <p className="text-[13px] font-medium mb-2">
                        <>
                          {item?.message && item?.message}{" "}
                          {item.isEdited && (
                            <span className="pl-2 text-[#757575] ">
                              (Edited)
                            </span>
                          )}
                        </>
                      </p>
                    </>
                  )}

                  {item.images.length > 0 && (
                    <div className="flex flex-wrap  ">
                      {item.images.map((file: any) => (
                        <div>
                          <a
                            className="cursor-pointer"
                            href={`${
                              process.env.REACT_APP_UPLOAD_API_ENDPOINT
                            }/${file.url}?${Date.now()}`}
                            target="_blank"
                          >
                            {file.type === "image/jpg" ||
                            file.type === "image/jpeg" ||
                            file.type === "image/png" ? (
                              <img
                                className="w-[80px] h-[80px] mr-2 rounded-[4px] object-cover"
                                src={`${
                                  process.env.REACT_APP_UPLOAD_API_ENDPOINT
                                }/${file.url}?${Date.now()}`}
                              />
                            ) : (
                              <div className="mr-2">
                                {file.type === "text/csv" ? (
                                  <div className="flex items-center justify-center bg-[#efefef] px-1 rounded-[4px]">
                                    <ListingCommentCsv />
                                  </div>
                                ) : (
                                  <ListingCommentPdf />
                                )}{" "}
                              </div>
                            )}
                          </a>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-between p-[24px] shadow_comment relative">
            <div
              className={`w-full  upload_box flex  absolute p-[24px] bottom-[100%] left-[0px] bg-[#FFFFFF] ${
                addAttachment ? "show-upload" : "hide-upload"
              } `}
            >
              {fileAttachment.length > 0 && (
                <div className="flex">
                  {fileAttachment.map((item: any, i: any) => (
                    <>
                      <div className="flex">
                        <div className="w-[76px] mr-[15px] h-[76px] rounded-[4px] relative">
                          <div
                            onClick={() => {
                              fileAttachment.splice(i, 1);
                              setFile([...fileAttachment]);
                            }}
                            className="absolute left-[100%] cursor-pointer top-[0px] transform translate-x-[-50%] trnaslate-y-[50%]"
                          >
                            <DeleteUpload />
                          </div>
                          {item.type !== "image/png" &&
                          item.type !== "image/jpeg" &&
                          item.type !== "image/jpg" ? (
                            <>
                              {" "}
                              <div className="w-[76px] h-[76px] bg-[#D4D4D4] flex items-center justify-center rounded-[4px]">
                                <a
                                  className=""
                                  href={
                                    !item.is_uploaded
                                      ? item.url
                                      : `${
                                          process.env
                                            .REACT_APP_UPLOAD_API_ENDPOINT
                                        }/${item.url}?${Date.now()}`
                                  }
                                >
                                  {item.type === "text/csv" ? (
                                    <ListingCsv />
                                  ) : (
                                    <ListingPdf />
                                  )}
                                </a>
                              </div>
                            </>
                          ) : (
                            <>
                              {" "}
                              <img
                                className="w-full h-full object-cover rounded-[4px]"
                                src={
                                  !item.is_uploaded
                                    ? item.url
                                    : `${
                                        process.env
                                          .REACT_APP_UPLOAD_API_ENDPOINT
                                      }/${item.url}?${Date.now()}`
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              )}
              {fileAttachment.length < 5 && (
                <div className="flex items-center justify-between">
                  <div className="border-r border-right border-[#F1F1F1]">
                    <div className="flex items-center cursor-pointer relative ">
                      <div className="border relative border-dashed border-[#757575] w-[76px] h-[76px] rounded-[4px] flex flex-col items-center justify-center">
                        <div className="absolute w-full h-full opacity-0">
                          <input
                            type="file"
                            className="w-[76px] h-full cursor-pointer"
                            accept="image/png,image/jpg,image/jpeg,.doc,/docx,.csv,application/pdf,.xlsx"
                            multiple={true}
                            onChange={(e) => {
                              upload(e);
                            }}
                          />
                        </div>
                        <div className="w-[30px]  h-[30px] mb-1 border-dashed border-[#757575] rounded-[50%] border flex items-center justify-center">
                          <UploadCommentFiles />
                        </div>
                        <p className="font-medium text-[13px] text-[#212121]">
                          Add
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>{" "}
            <div
              className="w-[44px] h-[44px]  rounded-[50%] flex justify-center items-center"
              style={{ backgroundColor: `${userDetail.color}` }}
            >
              <p className="text-[15px] font-medium text-[white]">
                {userDetail?.firstName?.charAt(0)}
                {userDetail?.lastName?.charAt(0)}
              </p>
            </div>
            <div className="w-[74%]">
              {/* <input
                className="outline-0 border rounded-[6px] border-[#DEDEDE] pl-[21px] py-2 w-full"
                placeholder="Add a comment"
                value={textMessage}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  e.code === "Enter" && !spinner && submitComments();
                }}
              /> */}
              <textarea
                className="outline-0 h-[44px] outline-none w-[98%] border rounded-[6px] border-[#DEDEDE] pl-[21px] py-2 w-full"
                placeholder="Add a comment"
                value={textMessage}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => keyPress(e)}
                rows={1}
                style={{ resize: "none" }}
              />
            </div>
            <div
              className="bg-[#F3F3F3] w-[44px] h-[44px] flex items-center justify-center rounded-[6px] cursor-pointer"
              onClick={() => setAddAttachment(!addAttachment)}
            >
              <UploadCommentFiles />
            </div>
            <div
              className="bg-[#F3F3F3] w-[44px] h-[44px] flex items-center justify-center rounded-[6px] cursor-pointer"
              onClick={() => !spinner && submitComments()}
            >
              {spinner ? (
                <Spinner CustomClassName={"spinner_primary"} />
              ) : (
                <SendComment />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
